import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HomepagedataService } from 'src/app/services/homepagedata.service';
import { routeUrls } from 'src/app/utils/constants';

@Component({
  selector: 'app-cancel-loader-popup',
  templateUrl: './cancel-loader-popup.component.html',
  styleUrls: ['./cancel-loader-popup.component.scss']
})

/**
 * 
 * This component is used to display a pop-up when user is visiting a page but all the sufficient data 
 * to load the page are not present (The logics to load each screen is handled in the respective components).
 * 
 */
export class CancelLoaderPopupComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<CancelLoaderPopupComponent>,
    private router: Router,
    public HomepageService: HomepagedataService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void { }


  /**
   * 
   * A "Go To Dashboard" button is provided to resume the project from the Dashboard.
   * 
   */
  goToDashboard(){
    this.dialogRef.close(
      {
        event: 'close',
        data: {
        }
      });
    this.router.navigate([routeUrls.dashboard]);
    this.HomepageService.lidFileName=[];
  }

}

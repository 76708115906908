import { WindowService } from './utils/window/window.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PortalModule } from '@angular/cdk/portal';
import { TooltipModule } from 'ng2-tooltip-directive';
import { MatSelectModule } from '@angular/material/select';

import {
  DEFAULT_TIMEOUT,
  TimeoutInterceptor
} from './services/timeout.interceptor';

import { AppComponent } from './app.component';
import { GetStartedComponent } from './get-started/get-started.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UploadPageComponent } from './upload-page/upload-page.component';
import { FileUploadComponent } from './upload-page/file-upload/file-upload.component';
import { SideButtonPanelComponent } from './side-button-panel/side-button-panel.component';
import { VerifyComponent } from './verify/verify.component';
import { AlertBarComponent } from './utils/alert-bar/alert-bar.component';
import { ZoomComponent } from './upload-page/zoom/zoom.component';
import { LoadingSpinnerComponent } from './utils/loading-spinner/loading-spinner.component';
import { ResultsComponent, SafeHtmlPipe } from './results/results.component';
import { PageLoadingComponent } from './utils/page-loading/page-loading.component';
import { AllDoneComponent } from './all-done/all-done.component';
import { ModalModule } from './modal';
import { ArtworkCanvasComponent } from './utils/artwork-canvas/artwork-canvas.component';
import { WindowComponent } from './utils/window/window.component';
import { CallbackComponent } from './callback/callback.component';
import { AppConfiguration } from 'src/config/app-configuration';
import { DragNDropDirective } from './utils/drag-n-drop.directive';


import { OktaAuthGuard } from "./app.guard";
import { OktaAuthService } from "./app.service";

import { APP_INITIALIZER } from "@angular/core";
import { JsonAppConfigService } from "../config/json-app-config.service";
import { HeaderComponent } from './header/header.component';
import { AuthorizationInterceptor } from './services/authorization.interceptor';
import { BnNgIdleService } from 'bn-ng-idle';
import {
  MatDialogModule,
  MatDialogRef
} from "@angular/material/dialog";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { ArtworktoolComponent } from './results/artworktool/artworktool.component';
import { HomepageComponent } from './homepage/homepage.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDividerModule } from '@angular/material/divider';
import { DashboardtableComponent } from './homepage/dashboardtable/dashboardtable.component';
import { ModelpopupComponent } from './modelpopup/modelpopup.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FilterListComponent } from './filter-list/filter-list.component';
import { AddEditPopupComponent } from './verify/add-edit-popup/add-edit-popup.component';
import { DeleteBBConfirmationComponent } from './verify/delete-bb-confirmation/delete-bb-confirmation.component';
import { UploadPageQueuedComponent } from './upload-page-queued/upload-page-queued.component';
import { FileUploadQueuedComponent } from './upload-page-queued/file-upload-queued/file-upload-queued.component';
import { ZoomQueuedComponent } from './upload-page-queued/zoom-queued/zoom-queued.component';
import { QueuedProjectPopupComponent } from './upload-page-queued/queued-project-popup/queued-project-popup.component';
import { LidReplacePopupComponent } from './upload-page/lid-replace-popup/lid-replace-popup.component';
import { LogoutPopupComponent } from './header/logout-popup/logout-popup.component'; 
import {InActivityService} from './services/in-activity.service';
import { TruncatePipe } from './utils/pipes/truncate.pipe';
import { CancelLoaderPopupComponent } from './utils/cancel-loader-popup/cancel-loader-popup.component';
import {MatCheckboxModule} from '@angular/material/checkbox';

export function initializerFn(jsonAppConfigService: JsonAppConfigService) {
  return () => {
    return jsonAppConfigService.load();
  };
}


@NgModule({
  declarations: [
    AppComponent,
    GetStartedComponent,
    PageNotFoundComponent,
    UploadPageComponent,
    FileUploadComponent,
    SideButtonPanelComponent,
    DragNDropDirective,
    VerifyComponent,
    AlertBarComponent,
    ZoomComponent,
    LoadingSpinnerComponent,
    PageLoadingComponent,
    AllDoneComponent,
    ResultsComponent,
    ArtworkCanvasComponent,
    WindowComponent,
    SafeHtmlPipe,
    CallbackComponent,
    HeaderComponent,
    ArtworktoolComponent,
    HomepageComponent,
    DashboardtableComponent,
    ModelpopupComponent,
    FilterListComponent,
    AddEditPopupComponent,
    DeleteBBConfirmationComponent,
    UploadPageQueuedComponent,
    FileUploadQueuedComponent,
    ZoomQueuedComponent,
    QueuedProjectPopupComponent,
    LidReplacePopupComponent,
    LogoutPopupComponent,
    TruncatePipe,
    CancelLoaderPopupComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ModalModule,
    PortalModule,
    TooltipModule,
    MatDialogModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatButtonModule,
    MatDividerModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatIconModule,
    MatListModule,
    ScrollingModule,
    MatInputModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatCheckboxModule
  ],
  providers: [
    InActivityService,
    BnNgIdleService,
    { provide: MatDialogRef, useValue: {} },
    [{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }],
    [{ provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true }],

    [{ provide: DEFAULT_TIMEOUT, useValue: 9999000 }],
    OktaAuthGuard,
    OktaAuthService,
    {
      provide: AppConfiguration,
      deps: [HttpClient],
      useExisting: JsonAppConfigService,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [JsonAppConfigService],
      useFactory: initializerFn,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// tslint:disable deprecation

import { WindowService } from './window.service';
import {
  Component,
  ViewChild,
  OnInit,
  ComponentFactoryResolver,
  ApplicationRef,
  Injector,
  OnDestroy,
  AfterViewInit,
  ViewEncapsulation,
  Input,
  EventEmitter,
  Output
} from '@angular/core';
import { CdkPortal, DomPortalHost, DomPortalOutlet } from '@angular/cdk/portal';

/**
 * This component template wrap the projected content
 * with a 'cdkPortal'.
 */

@Component({
  selector: 'app-window',
  templateUrl: './window.component.html',
  styleUrls: ['./window.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WindowComponent implements OnDestroy, AfterViewInit {
  @ViewChild(CdkPortal) portal: CdkPortal;
  @Input() id: string;
  @Output() WindowClosed = new EventEmitter<void>();

  private externalWindow: Window = null;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector,
    private windowService: WindowService
  ) {}
  ngAfterViewInit(): void {
    this.windowService.add(this);
  }

  ngOnDestroy(): void {
    if (!!this.externalWindow) {
      this.externalWindow.close();
    }
    this.windowService.remove(this.id);
  }

  open(): void {
    const windowName ='Label Right | File Preview';
    if (this.externalWindow) {
      return this.externalWindow.focus();
    }
    this.externalWindow = window.open(
      '',
      '',
      'width=1200,height=800,left=200,top=200'
    );

    const host = new DomPortalHost(
      this.externalWindow.document.body,
      this.componentFactoryResolver,
      this.applicationRef,
      this.injector
    );
    document.querySelectorAll('link, style').forEach((htmlElement) => {
      this.externalWindow.document.head.appendChild(
        htmlElement.cloneNode(true)
      );
      this.externalWindow.document.title = windowName;
    });
    this.externalWindow.onunload = () => {
      host.dispose();
      this.externalWindow = null;
    };

    host.attach(this.portal);
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageLoadingService {
  stopoperation:boolean=true;
  spinnerState: Subject<any> = new Subject<any>();

  show(message?: string): void {
    this.spinnerState.next({ show: true, msg: message });
  }

  hide(): void {
  
    this.spinnerState.next({ show: false });
  }

updateExecute()
{
  this.stopoperation=false;
}
}

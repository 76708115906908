/**
 * 
 * The Results page encompasses two primary components: Artwork View and Report View. 
 * Additionally, there are two dynamic filters: Product and Language, and buttons to enhance 
 * user interaction and functionality. 
 * 
 */
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  HostListener,
  Pipe,
  PipeTransform,
  AfterViewInit
} from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import {
  business_rules,
  FetchStatus,
  FileUploaded,
  UploadState
} from '../utils/utils';
import { PageLoadingService } from '../utils/page-loading/page-loading.service';
import { LabelRightService } from '../services/label-right.service';
import { WindowService } from './../utils/window/window.service';
import { takeUntil } from 'rxjs/operators';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HomepagedataService } from '../services/homepagedata.service';
import { ResultsPageDataService } from '../services/results-page-data.service';
import { componentProductLanguage, errorTypes, fileKeyObject, messagesOnScreen, routeUrls, sessionStorageObject, toolTip } from '../utils/constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ArtworktoolComponent } from './artworktool/artworktool.component';
import { AlertBarService } from '../utils/alert-bar/alert-bar.service';
import { AppInsightsService } from '../services/app-insights.service';
import { Subject } from 'rxjs';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ViewSDKClientService } from '../services/view-sdkclient.service';
import { MatDialog } from '@angular/material/dialog';
import { CancelLoaderPopupComponent } from '../utils/cancel-loader-popup/cancel-loader-popup.component';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value): any {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit, AfterViewInit {
  @ViewChild('clickoutside') clickoutsideEl;
  @ViewChild('resultbtn') resultbtn;
  @ViewChild('modalImgWrap', { static: false }) modalImgWrap: ElementRef;
  @ViewChild('modalImg', { static: false }) modalImg: ElementRef;
  @ViewChild('lidImg', { static: false }) lidImg: ElementRef;
  @ViewChild('hulk') hulk: ElementRef;
  @ViewChild(ArtworktoolComponent) artworkTool: ArtworktoolComponent;
  @ViewChild('productPanel') productPanel: MatExpansionPanel;
  @ViewChild('languagePanel') languagePanel: MatExpansionPanel;
  @ViewChild('cards') cardsContainer: ElementRef | undefined;
  selectedItemId: number[]  = [];
  filterMenuOpened = false;
  preview = '';
  selectedpreview = '';
  lidPreview = '';
  artworkPreview = '';
  resulttitle = '';
  //Generic loop comparsion results
  comparsionResults = [];
  componentType = 'Result';
  //END

  Brules: business_rules[];
  dataLoaded = '';
  artwork_view = false;
  activeFilterLabel = 'No Filter';
  artwork: FileUploaded;
  language: any;
  modalImgRotation = 0;
  modalImgScale = '';
  modalImgWrapCss = {};
  imageParentClass ={};
  modalImgCss: any = {};
  lidImgCss: any = {};
  pdf_filename = '';
  artwork_parts = [];
  ResultsJson: any;
  lastHeight: any;
  artworkheight: any = '80%';
  lidheight: any;
  lidwidth: any;
  ArtworkAnnotations = [];
  AnnotationDataFinal = [];
  valuezoom = 50;
  valuezoomlid = 150;
  errStates = ['Word Mismatch', 'Error', 'Abbreviation'];
  filterCategories = [
    { id: 'Match', name: 'Match', isChecked: false },
    { id: 'MisMatch', name: 'MisMatch', isChecked: false },
    { id: 'Alert', name: 'Alert', isChecked: false },
    {id: 'Not Detected', name: 'Not Detected', isChecked: false}
  ];
  selectedFilterValues = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  resultsCategories = {
    Match: 'tr-match',
    Mismatch: 'tr-mismatch',
    Alert: 'tr-alert',
    NotDetected:'tr-notFound'
  };
  BRCategories = {
    true: 'tr-found',
    false: 'tr-not-found'
  };
  newImageURL = '';
  isLoading = true;
  pdfUrl: any;
  xfdConversionData: any =
    '<?xml version="1.0" encoding="UTF-8"?> <xfdf xmlns="http://ns.adobe.com/xfdf/" xml:space="preserve"><annots> </annots> <f href="..//><ids name=""/></xfdf>';
  extractiondata: any;
  SelectedModalOverlay: string = 'Artwork';
  enableOptions: boolean = false;
  BBrules: boolean = false;
  selectedFilterArray = [];
  fromReport = true;
  supportedLan: any = [];
  supportedBrand: any;
  productNameArr = [componentProductLanguage.allProducts];
  selectedProductName = this.productNameArr[0];
  languageArr = [componentProductLanguage.allLanguages];
  filterSelectedLanguage = this.languageArr[0];
  defaultLanguages = [];
  comparisonResultsBackup = [];
  resultsBackupForLangFilter = [];
  resultsBackupForMatchFilter = [];
  mismatchMatchFilterBachup = [];
  resultsBackupForProduct = [];
  product_comResults = [];
  selectedProductNameViewAW = this.selectedProductName;
  selectedLanguageViewAW = this.filterSelectedLanguage;
  resultsTitleForViewAW = [];
  isVarietyPack = false;
  isMultiLangauge = false;
  isProductNameAvailable = false;
  resultsTitleForViewAWBackUp = [];
  supportedLanViewAW = [];
  isProductTooltipVisible=false;
  dataAvailabilityforresults = false;
  @HostListener('document:click', ['$event'])
  clickOutside(event: MouseEvent): void {
    if (this.filterMenuOpened) {
      if (
        !this.clickoutsideEl.nativeElement.contains(
          event.target as HTMLElement
        ) &&
        !this.resultbtn.nativeElement.contains(event.target as HTMLElement)
      ) {
        if (this.filterMenuOpened === true) {
          this.filterMenuOpened = false;
        }
      }
    }
  }

  /**
   * 
   * The Report View Component provides a comprehensive overview of the results obtained from the verify page conducted 
   * on various components. The data for the report view is received from “resultsALL” or “new_resultsALL” file data, 
   * which is then converted to a JSON object for the developer’s convenience.  
   * 
   * @param document 
   * @param router 
   * @param pageLoadingService 
   * @param windowService 
   * @param labelRightService 
   * @param HomepageService 
   * @param resultsPageDataService 
   * @param snackBar 
   * @param AppInsightsService 
   * @param alertBarService 
   * @param viewSDKClient 
   * @param dialog 
   * 
   */

  constructor(
    @Inject(DOCUMENT) document,
    private router: Router,
    private pageLoadingService: PageLoadingService,
    public windowService: WindowService,
    private labelRightService: LabelRightService,
    public HomepageService: HomepagedataService,
    public resultsPageDataService: ResultsPageDataService,
    private snackBar: MatSnackBar,
    private AppInsightsService: AppInsightsService,
    private alertBarService: AlertBarService,
    public viewSDKClient: ViewSDKClientService,
    public dialog: MatDialog
  ) {
    this.pageLoadingService.show(messagesOnScreen.generatingResultsLoader);
    this.resulttitle = '';
    this.labelRightService.language.subscribe((language) => {
      this.language = language;
      this.selectedlanguage = language;
    });
  }
  previewFilePromise: any;
  xfddata: any;
  xfdToJsonData = [];
  enableTool: boolean = true;
  brrdata: any;
  selectedlanguage: any;
  resultsTitle = [];
  totalErrorcount: number = 0;
  totalAlertcount: number = 0;
  errorHighlights = [];
  comparsionsData = [];

  resultsCompData1: any;
  artworkFile: any;
  BrulesData = [];
  ResultsJsonPayload = [];
  pdfSubscription;
  pdfSubscription1;
  displayartView: Boolean = true;
  resetZoom = 50 ;
  brMultiProduct  = false;
  async ngOnInit(): Promise<void> {
    this.artwork = new FileUploaded();
    this.artwork_view = false;

    this.labelRightService.ReviewArtwork = 'Results';

    // Get spinner status
    this.pageLoadingService.spinnerState.subscribe((spinnerState) => {
      this.isLoading = spinnerState.show;
    });
    this.labelRightService.bbAllImageURL
      .pipe(takeUntil(this.destroy$))
      .subscribe((url) => {
        this.newImageURL = url;
      });
    const bbState = this.labelRightService.boundingBoxState.value;
    const coordinates = this.labelRightService.UpdatedBoundingBoxes;
    if (coordinates) {
      Object.keys(coordinates).forEach((key) => {
        let value = coordinates[key];
        this.artwork_parts.push({
          image_url: this.newImageURL,
          image_type: value[1].type.split('_')[0],
          prediction: coordinates[key]
        });
      });

      this.labelRightService.boundingBoxState.next(bbState);
    }

    this.labelRightService.language.subscribe((language) => {
      this.language = language;
      this.selectedlanguage = language;
    });
    this.labelRightService.results.subscribe(async (comparisonResult) => {
      this.resultsCompData1 = comparisonResult;
      this.updateLoadingMessage(messagesOnScreen.generatingResultsLoader);
      this.comparsionResults = [];
      // Generic code
      this.ResultsJsonPayload = [];
      // code related to product filter
      this.supportedBrand = this.labelRightService.resultsAllSupportedBrand;
      this.productNameArr = [componentProductLanguage.allProducts];
      this.productNameArr = [...this.productNameArr, ...this.supportedBrand];
      this.productNameArr = [...new Set(this.productNameArr)];
      if (this.supportedBrand.length === 1) {
        if (this.supportedBrand[0] === componentProductLanguage.nonVarietyPackProduct) {
          this.isVarietyPack = false;
          this.selectedProductNameViewAW = componentProductLanguage.nonVarietyPackProduct;
          this.isProductNameAvailable = true;
        } else {
          this.isVarietyPack = true;
          this.isProductNameAvailable = true;
        }
      } else if (this.supportedBrand.length > 1) {
        this.isVarietyPack = true;
        this.isProductNameAvailable = true;
      } else {
        this.isVarietyPack = false;
        this.isProductNameAvailable = false;
      }

      //In case of multiple api call attempts, the error count and alert count was getting doubled up if resultAll comes multiple times
      //Fix - Refresh counts in every api call attempt
      this.totalErrorcount = 0;
      this.totalAlertcount = 0;
      for (let i = 0; i < comparisonResult.length; i++) {
        this.comparsionResults.push({
          comResults: comparisonResult[i].resultType,
          comErrCount: comparisonResult[i].resultType.section_error_count,
          comAlertCount: comparisonResult[i].resultType.section_alert_count,
          comFilteredErrCount:
            comparisonResult[i].resultType.section_error_count,
          resultsTitle: comparisonResult[i].type,
          errorHighlights: comparisonResult[i].type.charAt(0),
          colorCodes: comparisonResult[i].resultType.colorCodes,
          spellcheck:
            comparisonResult[i].resultType.comparisons[0].results.comp_type,
          product_Name: comparisonResult[i].resultType.product_name
            ? comparisonResult[i].resultType.product_name
            : '',
          Lang: comparisonResult[i].resultType.lang
            ? comparisonResult[i].resultType.lang
            : [],
          diffArrayForErrorType: this.gettingErrorType(
            comparisonResult[i].resultType
          ),
          lidSpellCheck : comparisonResult[i].resultType.comparisons[0].results.lid
        });

        this.totalErrorcount =
          this.totalErrorcount + this.comparsionResults[i].comErrCount;
        this.totalAlertcount =
          this.totalAlertcount + this.comparsionResults[i].comAlertCount;
        // code related to language filter
        this.comparsionResults[i].Lang.forEach((language) => {
          if (this.languageArr.indexOf(language) === -1) {
            this.languageArr.push(language);
            this.supportedLan.push(language);
          }
        });
        this.defaultLanguages = this.languageArr;

        this.comparsionResults[i].comResults = comparisonResult[
          i
        ].resultType.display
          .filter((recordToFilter) => {
            return (
              recordToFilter.type === errorTypes.match ||
              recordToFilter.type === errorTypes.error ||
              recordToFilter.type === errorTypes.notFound ||
              recordToFilter.type == errorTypes.mismatch ||
              recordToFilter.type == errorTypes.userIgnored ||
              recordToFilter.type == errorTypes.alert ||
              recordToFilter.type == errorTypes.undetected
            );
          })
          .map((record) => {
            record.comments = record.comments
              ? {
                  commentText: record.comments.commentText,
                  timeStamp: record.comments.timeStamp,
                  commentOpened: false
                }
              : {
                  commentText: '',
                  timeStamp: '',
                  commentOpened: false
                };
            record.corrected = record.corrected ? record.corrected : false;
            record.tooltip = this.generateTooltipText(record.type);
            return record;
          });
        this.comparsionResults[i].comResultsFiltered =
          this.comparsionResults[i].comResults;
        this.comparsionsData[i] = JSON.parse(
          JSON.stringify(this.comparsionResults[i].comResults)
        );
        this.ResultsJsonPayload.push({
          type: comparisonResult[i].type,
          results: comparisonResult[i].resultType
        });
      }
      this.comparisonResultsBackup = this.comparsionResults;
      this.resultsBackupForLangFilter = this.comparisonResultsBackup;
      this.mismatchMatchFilterBachup = this.comparsionResults;
      this.resultsBackupForMatchFilter = this.comparsionResults;
      this.resultsBackupForProduct = this.comparsionResults;
      this.labelRightService.lidFile.subscribe((lid) => {
        this.lidPreview = lid.preview.url;
      });

      this.supportedLanViewAW = this.supportedLan;
      if (this.supportedLan.length === 1) {
        this.isMultiLangauge = false;
        this.filterSelectedLanguage = this.supportedLan[0];
      } else if (this.supportedLan.length >= 2) {
        this.isMultiLangauge = true;
      }
      await this.labelRightService.xfd_Data.subscribe(async (xfdDAta) => {
        if (
          this.comparsionResults &&
          xfdDAta &&
          this.artwork.getOverlay().overlay &&
          this.labelRightService.graypdfURL
        ) {
          this.pageLoadingService.hide();
        /**
         * 
         * If a user is on the Results page during a transaction and attempts to reload/refresh the page, the following logic applies: 
         * 
         * - Based on the project analysis ID stored in the session storage, files associated with that 
         * particular transaction are fetched from the database using a Java API named "/artwork/ui/artworkReviewFiles". 
         * 
         * - The project analysis ID is passed in the payload of the API request. 
         * 
         * - If the API response includes the “resultsALL”, “grayPDF”, “PDF_XFD” and “overlayALL” file keys, the required 
         * fields for the Results page are set, and the page is loaded. 
         * 
         * - If the required files are not available, the user receives a pop-up message indicating "Insufficient 
         * data to load the page" with an option to navigate to the Dashboard. 
         * 
         */
        } else if (
          this.router.url === routeUrls.root + routeUrls.results &&
          !this.dataAvailabilityforresults &&
          this.resultsPageDataService.savedProjectName ==='' &&
          !this.HomepageService.navigatingFromDashboard
        ) {
          let projectId = sessionStorage.getItem(
            sessionStorageObject.projectId
          );
          if (projectId) {
            this.resultsPageDataService
              .gettingResultsData(projectId)
              .subscribe((data) => {
                  let isResultsAllPresent = false;
                  let isGrayPdfPresent = false;
                  let isPdfXfdPresent = false;
                  let isOverlayAllPresent = false;
                  data.forEach((file)=>{
                    if(file.fileKey === fileKeyObject.resultsAll){
                      isResultsAllPresent = true;
                    }
                    if(file.fileKey === fileKeyObject.grayPdf){
                      isGrayPdfPresent = true;
                    }
                    if(file.fileKey === fileKeyObject.pdfXfd){
                      isPdfXfdPresent = true;
                    }
                    if(file.fileKey === fileKeyObject.overlayAll){
                      isOverlayAllPresent = true;
                    }
                  })
                  if(isResultsAllPresent && isGrayPdfPresent && isPdfXfdPresent && isOverlayAllPresent){
                    this.HomepageService.adhocProjectName =
                    this.HomepageService.getProjectNameValue();
                    this.dataAvailabilityforresults = true;
                    this.resultsPageDataService.assaigningData(data);
                  }
                  else{
                    this.pageLoadingService.hide();
                    const dialogForExit = this.dialog.open(CancelLoaderPopupComponent, {
                      width: 400 + 'px',
                      height: 150 + 'px'
                    });
                  }
              });
          } else {
            this.router.navigate([routeUrls.getstarted]);
          }
        }
      });
      await this.labelRightService.BRrules.subscribe(async (brdata) => {
        if (brdata) {
          this.brrdata = brdata.BRrules;
          if(this.brrdata) {
            this.brrdata = JSON.parse(this.brrdata);
            this.Brules = this.brrdata.Rules;
            this.isMultiLanguageProduct(this.Brules);
            if ((await this.Brules) != undefined) {
              if (this.Brules != undefined) {
                this.BBrules = true;
              }
            }
          }
        }
      });

      this.labelRightService.artworkFile.subscribe((artwork) => {
        this.artwork = artwork;
        this.artworkFile = artwork.getFileName();
        this.preview = this.isProductNameAvailable
          ? this.artwork.getOverlay().overlay.bb[0]
          : this.artwork.getOverlay().overlay.bb;

        if (
          artwork.state === UploadState.uploaded &&
          artwork.getOverlay().state === FetchStatus.fetching
        ) {
        } else {
          this.artworkPreview = artwork.getPreviewURL();
          this.modalSwitchImage('Nutrition Box');
        }
      });
    });
    this.pdfSubscription = await this.labelRightService.pdf_Xfd.subscribe(
      async (pdf) => {
        if ((await pdf.artwork_pdf.url) != undefined) {
          this.pdf_filename = pdf.artwork_pdf.filename;
          this.labelRightService.lidFile.subscribe(async (lid) => {
            if ((await pdf.artwork_pdf.url) != undefined) {
              this.lidPreview = lid.preview.url;
              this.labelRightService.results.subscribe((comparisonResults) => {
                if (comparisonResults.length > 0) {
                  this.enableOptions = true;
                }
              });
            }
          });
        }

        this.pdfUrl = pdf['artwork_pdf'].url;
      }
    );
  }
  isMultiLanguageProduct(brData){
   let languArray =[];
   let prodArray =[];
   brData.forEach((ele)=>{
    prodArray.push(ele.product_name)
    ele.language.forEach((lang)=>{
      languArray.push(lang)
    })
   })
   languArray = Array.from(new Set(languArray));
   prodArray = Array.from(new Set(prodArray));
   if(prodArray.length >= 2 || languArray.length >= 2){
    this.brMultiProduct = true;
   }
  }
  async ngAfterViewInit() {
    await this.labelRightService.xfd_Data.subscribe(async (xfdDAta) => {
      this.labelRightService.xdfData = xfdDAta;
      this.artworkTool.loadingArtwork();
    });
  }
  showStyle(event) {
    this.artwork_view = event.val;
  }
  // code for handling alerts
  gettingErrorType(comparisonObject) {
    let diffArray = [];
    let counter = {};
    for (let i = 0; i < comparisonObject.comparisons.length; i++) {
      let idx = comparisonObject.comparisons[i].ID.split('-')[1];
      if (idx in counter) {
        counter[idx] += 1;
      } else {
        counter[idx] = 0;
      }
      if (comparisonObject.comparisons[i].results.diff.length > 0) {
        diffArray.push({
          errorType:
            comparisonObject.comparisons[i].results.diff[counter[idx]]
              .error_type
        });
      } else {
        diffArray.push({
          errorType: comparisonObject.comparisons[i].results.type
        });
      }
    }
    return diffArray;
  }
  toggleFilterClick(): void {
    this.filterMenuOpened = !this.filterMenuOpened;
    this.alertBarService.hide();
  }
  // new changes for old filter
  cancelFilter() {
    for (const option of this.filterCategories) {
      option.isChecked = false;
    }
    this.filterMenuOpened = false;
    this.selectedFilterValues = [];
    this.applyFilterselected();
  }

  /**
   * 
   * Based on the selected type(s) Artwork View and Report View is filtered
   * 
   */
  applyFilterselected() {
    let label = '';
    if (
      this.selectedFilterValues.length === 0 ||
      this.selectedFilterValues.length === 4
    ) {
      label = errorTypes.noFilter;
    }else if(this.selectedFilterValues.length === 3){
      if (this.selectedFilterValues.includes(errorTypes.match)) {
        label =
          this.selectedFilterValues.includes(errorTypes.mismatch) &&
          this.selectedFilterValues.includes(errorTypes.alert)
            ? errorTypes.mismatch + errorTypes.alert
            : this.selectedFilterValues.includes(errorTypes.mismatch) &&
              this.selectedFilterValues.includes(errorTypes.undetected)
            ? errorTypes.mismatch + errorTypes.undetected
            : errorTypes.alert + errorTypes.undetected;
      } else {
        label = errorTypes.noFilter;
      }
    }
     else if (this.selectedFilterValues.length === 2) {
      label = this.selectedFilterValues.includes(errorTypes.match)
        ? this.selectedFilterValues[0] === errorTypes.match
          ? this.selectedFilterValues[1]
          : this.selectedFilterValues[0]
        : this.selectedFilterValues.includes(errorTypes.undetected)
        ? this.selectedFilterValues.includes(errorTypes.mismatch) &&
          this.selectedFilterValues.includes(errorTypes.undetected)
          ? errorTypes.mismatch + errorTypes.undetected
          : errorTypes.alert + errorTypes.undetected
        : errorTypes.mismatch + errorTypes.alert;
    } else {
      label = this.selectedFilterValues[0];
    }
    this.filterMenuOpened = false;
    this.matchMismatchFilter();
    if (label) {
      this.activeFilterLabel = label;
      this.artworkTool.selectedFilterLabel = label;
      this.artworkTool.loadingArtwork();
    }
  }
  matchMismatchFilter() {
    const filterFunction = (table: any[]) => {
      return table.filter(
        (res) =>
          res.type === this.selectedFilterValues[0] ||
          res.type === this.selectedFilterValues[1]
      );
    };
    const filterFunctionOne = (table: any[]) => {
      return table.filter((res) => res.type === this.selectedFilterValues[0]);
    };
    if (
      (this.selectedProductName !== componentProductLanguage.allProducts &&
        this.filterSelectedLanguage !== componentProductLanguage.allLanguages) ||
      (this.selectedProductName === componentProductLanguage.allProducts &&
        this.filterSelectedLanguage !== componentProductLanguage.allLanguages)
    ) {
      this.mismatchMatchFilterBachup = this.resultsBackupForMatchFilter;
    } else if (
      this.selectedProductName !== componentProductLanguage.allProducts &&
      this.filterSelectedLanguage === componentProductLanguage.allLanguages
    ) {
      this.mismatchMatchFilterBachup = this.resultsBackupForLangFilter;
    } else {
      this.mismatchMatchFilterBachup = this.comparisonResultsBackup;
    }
    for (let i = 0; i < this.mismatchMatchFilterBachup.length; i++) {
      this.mismatchMatchFilterBachup[i].comResultsFiltered =
        this.selectedFilterValues.length === 2
          ? filterFunction(this.mismatchMatchFilterBachup[i].comResults)
          : this.selectedFilterValues.length === 1
          ? filterFunctionOne(this.mismatchMatchFilterBachup[i].comResults)
          : (this.mismatchMatchFilterBachup[i].comResultsFiltered =
              this.mismatchMatchFilterBachup[i].comResults);
    }
    this.comparsionResults = this.mismatchMatchFilterBachup;
  }
  getSelectedValue(event) {
    const checkbox = event.target;
    if (checkbox.checked) {
      this.selectedFilterValues.push(checkbox.value);
    } else {
      let index = this.selectedFilterValues.indexOf(checkbox.value);
      this.selectedFilterValues.splice(index, 1);
    }
  }
  buttonClicked(button: string): void {
    switch (button) {
      case 'Back':
        this.dataAvailabilityforresults = true;
        this.alertBarService.hide();
        this.labelRightService.results.next({});
        this.comparsionResults = [];
        this.resultsPageDataService.original_Xfd = '';
        this.resultsPageDataService.xfdDataForAWView = '';
        this.labelRightService.xdfData = '';
        this.labelRightService.xfd_Data.next('');
        this.pageLoadingService.stopoperation = true;
        this.router.navigate([routeUrls.verify]);
        break;

        /**
         * 
         * Upon completing desired operations, users can save the latest result by clicking the "Save Result" button.
         * Clicking the button triggers a python API, passing the latest data in the payload. API: “/generateResultsFile” 
         * The API returns URLs for Excel, XFD files and XFD data. 
         * After saving, users are navigated to the "All Done" screen. 
         * 
         */
      case 'Next':
        this.alertBarService.hide();
        this.pageLoadingService.stopoperation = true;
        // adobe xfdExtraction data
        let count = 0;
        if (this.labelRightService.annotationdata == undefined) {
          this.xfdConversionData = this.labelRightService.xdfData;
        } else {
          for (
            let i = 0;
            i < this.labelRightService.annotationdata.length;
            i++
          ) {
            if (this.labelRightService.annotationdata[i] == '') {
            } else if (
              this.labelRightService.annotationdata[i].motivation == 'replying'
            ) {
              this.extractiondata = this.inreplynotexfdconversion(
                this.labelRightService.annotationdata[i]
              );

              this.xfdConversionData = this.xfdConversionData.replace(
                '</annots',
                this.extractiondata.toString() + '</annots'
              );
            } else if (
              this.labelRightService.annotationdata[i].target.selector
                .subtype == 'shape'
            ) {
              this.extractiondata = this.shapexfdconversion(
                i + 1,
                this.labelRightService.annotationdata[i]
              );

              this.xfdConversionData = this.xfdConversionData.replace(
                '</annots',
                this.extractiondata.toString() + '</annots'
              );
            } else if (
              this.labelRightService.annotationdata[i].target.selector
                .subtype == 'highlight'
            ) {
              count += 1;
              this.extractiondata = this.squaredata(
                this.labelRightService.annotationdata[i]
              );

              this.xfdConversionData = this.xfdConversionData.replace(
                '</annots',
                this.extractiondata.toString() + '</annots'
              );
            } else if (
              this.labelRightService.annotationdata[i].target.selector
                .subtype == 'freetext'
            ) {
              count += 1;
              this.extractiondata = this.freetextxfdconversion(
                count,
                this.labelRightService.annotationdata[i]
              );

              this.xfdConversionData = this.xfdConversionData.replace(
                '</annots',
                this.extractiondata.toString() + '</annots'
              );
            } else if (
              this.labelRightService.annotationdata[i].target.selector
                .subtype == 'note'
            ) {
              count += 1;
              this.extractiondata = this.stickynotexfdconversion(
                count,
                this.labelRightService.annotationdata[i]
              );

              this.xfdConversionData = this.xfdConversionData.replace(
                '</annots',
                this.extractiondata.toString() + '</annots'
              );
            }
          }
        }
        this.xfdConversionData = this.xfdConversionData.replace(
          '<f href="../',
          '<f href="' + this.pdf_filename + '"'
        );
        this.xfdConversionData = this.xfdConversionData.replace(
          '<ids name=""',
          '<ids ' +
            'original="' +
            this.artworkTool.idattributes['_attributes']['original'] +
            '" ' +
            'modified="' +
            this.artworkTool.idattributes['_attributes']['modified'] +
            '" ' +
            'name="' +
            this.artworkTool.idattributes['_attributes']['name'] +
            '"'
        );
        let resultPayload = {
          comparison: this.ResultsJsonPayload,
          supported_lang: this.supportedLan,
          supported_product_name: this.supportedBrand
        };
        this.ResultsJson = {
          artwork_file: this.artworkFile,
          artwork_parts: this.artwork_parts,
          totalerrorCount: this.totalErrorcount,
          totalalertCount: this.totalAlertcount,
          machineGeneratedXfd: this.artworkTool.finalAnnotations,
          BrRules : this.brrdata,
          resultPayload
        };

        if (this.pageLoadingService.stopoperation) {
          this.labelRightService.resultsFileURL.next('');
          this.labelRightService.humanAnnotationUrl.next('');
          this.labelRightService.resultsToAllDone = true;
          this.router.navigate([routeUrls.alldone]);
        }
        this.labelRightService.setResults(this.resultsCompData1);
        let Id = this.HomepageService.navigatingFromDashboard
          ? this.resultsPageDataService.artworkAnalysisId
          : sessionStorage.getItem(sessionStorageObject.projectId);
        this.AppInsightsService.logEvent(
          'Generated Results File Call Initiated'
        );
        this.labelRightService.generateResultsFile(
          this.ResultsJson,
          this.language.code,
          this.xfdConversionData,
          Id
        );
        break;
    }
  }

  openWindow(id: string): void {
    if (this.enableOptions) {
      this.selectedProductNameViewAW = this.isVarietyPack ? this.selectedProductName : componentProductLanguage.nonVarietyPackProduct;
      this.selectedLanguageViewAW = this.isMultiLangauge ? this.filterSelectedLanguage : this.supportedLan[0];
      if (
        id === 'artwork-window' &&
        this.isProductNameAvailable &&
        (this.selectedProductNameViewAW === componentProductLanguage.allProducts ||
          this.selectedLanguageViewAW === componentProductLanguage.allLanguages)
      ) {
        if (
          this.selectedProductNameViewAW === componentProductLanguage.allProducts &&
          this.selectedLanguageViewAW === componentProductLanguage.allLanguages
        ) {
          this.selectedProductNameViewAW = this.supportedBrand[0];
          this.selectedLanguageViewAW = this.supportedLan[0];
        } else if (
          this.selectedLanguageViewAW !== componentProductLanguage.allLanguages
        ) {
          this.selectedProductNameViewAW =  this.supportedBrand[0];
        } else {
          this.selectedLanguageViewAW = this.supportedLan[0];
        }
      }
      this.windowService.open(id);
        if (id == 'artwork-window') {
          this.alertBarService.hide();
          this.resultsTitleForViewAW = [];
          if (this.isProductNameAvailable) {
            Object.keys(this.artwork.getOverlay().overlay).forEach(
              (key, index) => {
                if (key !== 'bb') {
                  if (
                    this.artwork.getOverlay().overlay[key][1] ===
                      this.selectedProductNameViewAW &&
                    this.artwork
                      .getOverlay()
                      .overlay[key][2].includes(this.selectedLanguageViewAW)
                  ) {
                    this.resultsTitleForViewAW.push(key);
                  }
                }
              }
            );
          } else {
            this.comparsionResults.forEach((overlay) => {
              this.resultsTitleForViewAW.push(overlay.resultsTitle);
            });
          }
          this.resultsTitleForViewAWBackUp = [...this.resultsTitleForViewAW];
          this.valuezoomlid = 150;
          this.preview = this.isProductNameAvailable
            ? this.artwork.getOverlay().overlay.bb[0]
            : this.artwork.getOverlay().overlay.bb;
          this.windowService.open('artwork-window');
        } else {
          this.alertBarService.hide();
          this.valuezoom = 50;
          this.lidheight = 'auto';
          this.lidwidth = '100%';
        }
        setTimeout(() => {
          const scale = this.getModalImgScale();
          this.modalImgWrapCss = {
            transform: `rotate(0deg)`,
            'transform-origin': 'center',
            top: '5%',
            left: '10%',
            right: '10%',
            bottom:"5%"
          };
        }, 50);
    }
  }

  closeWindow(id: string): void {
    this.windowService.close(id);
  }

  modalSwitchLanguage(language: string): void {}

  modalSwitchImage(type: string): void {
    this.zoom = false;
    this.SelectedModalOverlay = type;
    this.valuezoom = 50;
    this.zoomvalue = 100;
    this.zoomvalueother = 15;
    this.zoomint = 40;
    this.modalImgRotation = 0;
    this.modalImgWrapCss = {};
    this.modalImgCss = {};
    this.imageParentClass ={}
    this.selectedpreview = type;
    this.preview = this.isProductNameAvailable
      ? this.artwork.getOverlay().overlay[type][0]
      : this.artwork.getOverlay().overlay[type];
    if (this.modalImg) {
      setTimeout(() => {
        this.getModalImgScale();
      }, 25);
    }
  }

  rotateImg(): void {
    this.modalImgRotation =
      this.modalImgRotation === 270 ? 0 : this.modalImgRotation + 90;
    const scale =
      this.modalImgRotation === 270 || this.modalImgRotation === 90
        ? '0.75'
        : '1.0';
    this.modalImgWrapCss = {
      transform: `rotate(${this.modalImgRotation}deg)`,
      'transform-origin': 'center',
      top: '5%',
      left: '10%',
      right: '10%',
      bottom:"5%"
    };
  }

  getModalImgScale(): string {
    let scale = '1.0';
    const imgWidth = (this.modalImg.nativeElement as HTMLImageElement)
      .offsetWidth;
    const imgHeight = (this.modalImg.nativeElement as HTMLImageElement)
      .offsetHeight;
    this.artworkheight =
      this.preview !==
      (this.isProductNameAvailable
        ? this.artwork.getOverlay().overlay.bb[0]
        : this.artwork.getOverlay().overlay.bb)
        ? '20%'
        : '70%';
    this.valuezoomlid = 150;
    this.modalImgWrapCss = {
      transform: `rotate(0deg)`,
      'transform-origin': 'center',
      top: '5%',
      left: '10%',
      right: '10%',
      bottom:"5%"
    };
    return scale;
  }

  /**
   * 
   * Users can add replies to machine-generated comments in the artwork view, which are reflected in the report view. 
   * 
   * @param event 
   * 
   */

  commentsadded(event) {
    this.comparsionResults.forEach((ele) => {
      ele.comResultsFiltered.map((e) => {
        if (e.id === event.replyId) {
          e.comments.commentText = event.replyComment ? event.replyComment : '';
          e.comments.timeStamp = event.replyComment ? event.replyTimestamp : '';
          e.comments.commentOpened = event.replyComment ? true : false;
        }
      });
    });
  }

  /**
   * 
   * Users can delete machine-generated annotations, marking them as “user ignored” in the report view. 
   * 
   * @param event 
   * 
   */
  userIgnoreFromArtwork(event) {
    let type = '';
    this.comparsionResults.forEach((ele) => {
      ele.comResultsFiltered.forEach((element) => {
        if (element.id === event) {
          type = ele.resultsTitle;
          if (element.type !== errorTypes.userIgnored)
            this.markAsCorrect(element, type, false);
        }
      });
    });
  }

  /**
   * 
   * Users can swap results for any mismatch or alert that will be marked as “User Ignored”, 
   * and the corresponding annotation will be removed from the artwork view. 
   * Error and alert counts are updated accordingly. 
   * 
   * @param resultObject 
   * @param type 
   * @param report 
   * 
   */
  markAsCorrect(resultObject: any, type: string, report): void {
    let errorCorrectIndex: number = 0;
    for (let i = 0; i < this.comparsionResults.length; i++) {
      if (this.comparsionResults[i].resultsTitle === type) {
        errorCorrectIndex = i;
      }
    }
    let data_1type = '';
    const data = this.comparsionsData[errorCorrectIndex];
    const tableData = this.comparsionResults[errorCorrectIndex].comResults;
    const rowErrorCount = this.comparsionResults[errorCorrectIndex].comErrCount;
    const rowAlertCount =
      this.comparsionResults[errorCorrectIndex].comAlertCount;
    var count = 0;
    var countAlert = 0;
    tableData.forEach((rowValue, i) => {
      if (rowValue.id === resultObject.id) {
        count = (tableData[i].aw_html.match(/color:red/g) || []).length;
        countAlert = (tableData[i].aw_html.match(/color:orange/g) || []).length;
        data_1type =
          data[i].type != 'User Ignored'
            ? data[i].type
            : tableData[i].aw_html.includes('color:red')
            ? 'MisMatch'
            : 'Alert';
        this.totalErrorcount =
          tableData[i].type === errorTypes.mismatch
            ? this.totalErrorcount - count
            : this.totalErrorcount;
        this.totalAlertcount =
          tableData[i].type === errorTypes.alert
            ? this.totalAlertcount - countAlert
            : this.totalAlertcount;
        this.comparsionResults[errorCorrectIndex].comErrCount =
          tableData[i].type === errorTypes.mismatch
            ? rowErrorCount - count
            : rowErrorCount;
        this.comparsionResults[errorCorrectIndex].comFilteredErrCount =
          tableData[i].type === errorTypes.mismatch
            ? rowErrorCount - count
            : rowErrorCount;
        this.comparsionResults[errorCorrectIndex].comAlertCount =
          tableData[i].type === errorTypes.alert
            ? rowAlertCount - countAlert
            : rowAlertCount;
        tableData[i].corrected = true;
        tableData[i].type = 'User Ignored';
        tableData[i].comments.commentText = '';
        tableData[i].comments.timeStamp = '';
        tableData[i].comments.commentOpened = true;
        tableData[i].tooltip = this.generateTooltipText(tableData[i].type);
      }
    });
    this.ResultsJsonPayload.forEach((ele) => {
      if (ele.type === type) {
        ele.results.display.forEach((element) => {
          if (element.id === resultObject.id) {
            ele.results.section_error_count =
              data_1type == errorTypes.mismatch
                ? ele.results.section_error_count - count
                : ele.results.section_error_count;
            ele.results.section_alert_count =
              data_1type == errorTypes.alert
                ? ele.results.section_alert_count - countAlert
                : ele.results.section_alert_count;
            element.comments.commentText = '';
            element.comments.timeStamp = '';
            element.comments.commentOpened = false;
          }
        });
      }
    });
    if (report) this.artworkTool.deleteAnnotations(resultObject.id);
  }

  /**
   * 
   * Users can undo “user ignored” comments, which will update the error/alert counts and the artwork view. 
   * 
   * @param resultObject 
   * @param type 
   * 
   */
  undoCorrection(resultObject: any, type: string): void {
    this.artworkTool.UpdateAnnotations(resultObject.id);
    let errorUndoIndex: number = 0;
    for (let i = 0; i < this.comparsionResults.length; i++) {
      if (this.comparsionResults[i].resultsTitle === type) {
        errorUndoIndex = i;
      }
    }
    let data_1type = '';
    var count = 0;
    var countAlert = 0;
    const tableData = this.comparsionResults[errorUndoIndex].comResults;
    const data = this.comparsionsData[errorUndoIndex];
    const rowErrorCount = this.comparsionResults[errorUndoIndex].comErrCount;
    const rowAlertCount = this.comparsionResults[errorUndoIndex].comAlertCount;
    tableData.forEach((rowValue, i) => {
      if (rowValue.id === resultObject.id) {
        tableData[i].corrected = false;
        countAlert = (tableData[i].aw_html.match(/color:orange/g) || []).length;
        count = (tableData[i].aw_html.match(/color:red/g) || []).length;
        data_1type =
          data[i].type != 'User Ignored'
            ? data[i].type
            : tableData[i].aw_html.includes('color:red')
            ? 'MisMatch'
            : 'Alert';
        this.totalErrorcount =
          data_1type === errorTypes.mismatch
            ? this.totalErrorcount + count
            : this.totalErrorcount;
        this.totalAlertcount =
          data_1type === errorTypes.alert
            ? this.totalAlertcount + countAlert
            : this.totalAlertcount;
        this.comparsionResults[errorUndoIndex].comErrCount =
          data_1type === errorTypes.mismatch
            ? rowErrorCount + count
            : rowErrorCount;
        this.comparsionResults[errorUndoIndex].comFilteredErrCount =
          data_1type === errorTypes.mismatch
            ? rowErrorCount + count
            : rowErrorCount;
        this.comparsionResults[errorUndoIndex].comAlertCount =
          data_1type === errorTypes.alert
            ? rowAlertCount + countAlert
            : rowAlertCount;
        tableData[i].type = data_1type;
        tableData[i].tooltip = this.generateTooltipText(tableData[i].type);
      }
    });
    this.ResultsJsonPayload.forEach((ele) => {
      if (ele.type === type) {
        ele.results.display.forEach((element) => {
          if (element.id === resultObject.id) {
            ele.results.section_error_count =
              data_1type == errorTypes.mismatch
                ? ele.results.section_error_count + count
                : ele.results.section_error_count;
            ele.results.section_alert_count =
              data_1type == errorTypes.alert
                ? ele.results.section_alert_count + countAlert
                : ele.results.section_alert_count;
          }
        });
      }
    });
  }

  displayArtworkOrLid(resultObject: any): any {
    return resultObject.aw_html ? resultObject.aw_html : '';
  }

  displayLidCorrect(resultObject: any, comResults : any): any {
    if(comResults.spellcheck === 'spellcheck'){
      if(comResults.lidSpellCheck){
        let returnObject = (resultObject.comp_html ? resultObject.comp_html : '') + ' (LID: ' + comResults.lidSpellCheck + ')';
        return returnObject;
      }
      else      
        return resultObject.comp_html ? resultObject.comp_html : '';
    }
    else
      return resultObject.comp_html ? resultObject.comp_html : '';
  }

  getRTL(): string {
    return this.language.rtl ? 'rtl' : '';
  }

  getDisplayType(row): any {
    return row.results.type === errorTypes.match ||
      row.results.type === errorTypes.notFound ||
      row.results.type === errorTypes.mismatch ||
      row.results.type === errorTypes.alert
      ? row.results.type
      : row.results.errors.error_string;
  }

  generateTooltipText(displayType): string {
    if(displayType === errorTypes.match){
      return toolTip.match;
    }else if(displayType === errorTypes.mismatch){
      return toolTip.mismatch;
    }else if(displayType === errorTypes.alert){
      return toolTip.alert;
    }else if(displayType === errorTypes.undetected){
      return toolTip.undetected;
    }else if(displayType === errorTypes.userIgnored){
      return toolTip.userIgnored;
    }
  }

  updateLoadingMessage(msg: string, forceShow = false): void {
    if (this.isLoading || forceShow) this.pageLoadingService.show(msg);
  }

  /**
   * 
   * It is used to download the artwork in PDF format and the XFD data in XFDF format. 
   * The URLs and file names of these two files are received with the file key “PDF_XFD” or “new_PDF_XFD”. 
   * 
   */
  downloadResults() {
    this.alertBarService.hide();
    let pdfFile = '';
    let xfdFile = '';
    if (this.enableOptions) {
      this.pdfSubscription1 = this.labelRightService.pdf_Xfd.subscribe(
        async (pdf) => {
          if (
            pdf.artwork_pdf.url != undefined &&
            pdf.annot_xfdf.url != undefined &&
            pdf.artwork_pdf.url != null &&
            pdf.annot_xfdf.url != null &&
            pdf.artwork_pdf.url != '' &&
            pdf.annot_xfdf.url != ''
          ) {
            pdfFile = pdf.artwork_pdf.url;
            xfdFile = pdf.annot_xfdf.url;
            window.location.replace(xfdFile);
            setTimeout(() => {
              window.location.replace(pdfFile);
              this.AppInsightsService.logEvent(
                'Downloaded XFDF/PDF data - Result',
                {
                  xfdf: xfdFile,
                  pdf: pdfFile
                }
              );
            }, 2000);
          } else {
            this.snackBar.open(
              messagesOnScreen.filesGettingGeneratedMessage,
              '',
              {
                duration: 5000
              }
            );
            this.AppInsightsService.logEvent(
              'Error in downloaded PDF/XFDF - Result'
            );
          }
        }
      );
    }
  }

  viewResult: boolean = true;
  viewArtwork: boolean = false;

  count = 0;
  toggleViewname: any = 'View Results';

  changeview(type: any) {
    if (type == 'Result') {
      this.toggleViewname = 'View Results';
      this.viewResult = true;
      this.viewArtwork = false;
      this.ArtworkAnnotations = this.artworkTool.hummanAnnotations;
    } else {
      this.toggleViewname = 'View Artwork';
      this.viewResult = false;
      this.viewArtwork = true;
    }
    this.ArtworkAnnotations = this.artworkTool.hummanAnnotations;
  }

  //Conversion part
  xfdfreetext: string = '';
  freetextxfdconversion(value, data): string {
    var jsonSelectData = data['target']['selector'];
    this.xfdfreetext =
      '<freetext   width=' +
      '"0.000000"' +
      ' creationdate=' +
      '"' +
      data['created'] +
      '"' +
      ' flags=' +
      '"print"' +
      ' date=' +
      '""' +
      ' name=' +
      '"' +
      data['id'] +
      '"' +
      ' page=' +
      '"' +
      0 +
      '"' +
      ' fringe= ' +
      '"0.500000,0.500000,0.500000,0.500000"' +
      ' rect=' +
      '"' +
      jsonSelectData.boundingBox +
      '"' +
      ' subject=' +
      '"Typewritten Text"' +
      '    title=' +
      '"' +
      value +
      data['creator']['name'] +
      '"' +
      '><contents-richtext><body xmlns=' +
      '"http://www.w3.org/1999/xhtml"' +
      ' xmlns:xfa=' +
      '"http://www.xfa.org/schema/xfa-data/1.0/"' +
      ' xfa:APIVersion="Acrobat:20.13.0"' +
      ' xfa:spec="2.0.2"' +
      '  style=' +
      '"font-size:12.0pt;text-align:left;font-weight:normal;font-style:normal;font-family:Helv;font-stretch:normal"' +
      '><p dir=' +
      '"ltr"' +
      '><span style=' +
      '"line-height:16.3pt;font-family:Helvetica"' +
      '>' +
      this.replaceSpecialChars(data['bodyValue']) +
      '</span></p ></body></contents-richtext><defaultappearance>16.25 TL /Cour 12 Tf</defaultappearance><defaultstyle>font: Helv 12.0pt;font-stretch:Normal; text-align:left; </defaultstyle></freetext>';

    return this.xfdfreetext;
  }

  xfdstickynote = '';
  stickynotexfdconversion(value, data): string {
    var jsonSelectData = data['target']['selector'];
    this.xfdstickynote =
      '<text color=' +
      '"' +
      jsonSelectData.strokeColor +
      '"' +
      ' creationdate=' +
      '"' +
      data['created'] +
      '"' +
      ' flags=' +
      '"print,nozoom,norotate"' +
      ' icon=' +
      '"Comment"' +
      ' date=' +
      '""' +
      ' name=' +
      '"' +
      data['id'] +
      '"' +
      ' page=' +
      '"' +
      0 +
      '"' +
      ' rect=' +
      '"' +
      jsonSelectData.boundingBox +
      '"' +
      ' subject=' +
      '"Sticky Note"' +
      '    title=' +
      '"' +
      value +
      data['creator']['name'] +
      '"' +
      '><contents-richtext><body xmlns=' +
      '"http://www.w3.org/1999/xhtml"' +
      ' xmlns:xfa=' +
      '"http://www.xfa.org/schema/xfa-data/1.0/"' +
      ' xfa:APIVersion="Acrobat:20.13.0"' +
      ' xfa:spec="2.0.2"' +
      '  style=' +
      '"font-size:12.0pt;text-align:left;font-weight:normal;font-style:normal;font-family:Helv;font-stretch:normal"' +
      '><p dir=' +
      '"ltr"' +
      '><span style=' +
      '"font-size:10.5pt;text-align:left;font-weight:normal;font-style:normal"' +
      '>' +
      this.replaceSpecialChars(data['bodyValue']) +
      '</span></p ></body></contents-richtext></text>';
    return this.xfdstickynote;
  }

  xfdshapeannot: string = '';
  shapexfdconversion(value, data): string {
    var jsonSelectData = data['target']['selector'];
    var inklistdata = '';
    for (let i = 0; i < jsonSelectData.inkList[0].length; i++) {
      if (i == 0) {
        inklistdata = jsonSelectData.inkList[0][i] + ',';
      } else if (i % 2 == 0) {
        inklistdata = inklistdata + jsonSelectData.inkList[0][i] + ',';
      } else {
        inklistdata = inklistdata + jsonSelectData.inkList[0][i] + ';';
      }
    }
    this.xfdshapeannot =
      '<ink color=' +
      '"' +
      jsonSelectData.strokeColor +
      '"' +
      ' creationdate=' +
      '"' +
      data['created'] +
      '"' +
      ' flags=' +
      '"print"' +
      ' date=' +
      '""' +
      ' page=' +
      '"' +
      0 +
      '"' +
      ' fringe=' +
      '"0.500000,0.500000,0.500000,0.500000"' +
      ' rect=' +
      '"' +
      jsonSelectData.boundingBox +
      '"' +
      ' subject=' +
      '"Freehand"' +
      ' title=' +
      '"' +
      value +
      data['creator']['name'] +
      '"' +
      ' name=' +
      '"' +
      data['id'] +
      '"' +
      '> <contents-richtext> <body>' +
      this.replaceSpecialChars(data.bodyValue) +
      '</body>  </contents-richtext><inklist><gesture>' +
      inklistdata +
      '</gesture></inklist></ink>';

    return this.xfdshapeannot;
  }

  inreplydata: string = '';
  inreplynotexfdconversion(data): string {
    let creatorName =
      data['creator']['name'] == undefined
        ? this.creatorNameReply
        : data['creator']['name'];
    let quadPoints;
    for (let i = 0; i < this.labelRightService.annotationdata.length; i++) {
      if (
        data['target']['source'] === this.labelRightService.annotationdata[i].id
      ) {
        quadPoints =
          this.labelRightService.annotationdata[i].target.selector.boundingBox;
      }
    }
    const responce = this.getProductandLang(data['creator']['id']);
    const selectedData = Array.isArray(responce)? responce[0]: responce; 
    this.inreplydata =
      '<text color=' +
      '"#FF0000"' +
      ' creationdate=' +
      '"' +
      data['created'] +
      '"' +
      ' inreplyto=' +
      '"' +
      data['target']['source'] +
      '"' +
      ' flags=' +
      '"print,nozoom,norotate"' +
      ' icon=' +
      '"Comment"' +
      ' date=' +
      '""' +
      ' name=' +
      '"' +
      data['id'] +
      '"' +
      ' uiId=' +
      '"' +
      data['creator']['id'] +
      '"' +
      ' product_name=' +
      '"' +
      selectedData['_attributes']['product_name'] +
      '"' +
      ' lang=' +
      '"' +
      selectedData['_attributes']['lang'] +
      '"' +
      ' page=' +
      '"' +
      0 +
      '"' +
      ' rect=' +
      '"' +
      quadPoints +
      '"' +
      ' subject=' +
      '"Sticky Note"' +
      '    title=' +
      '"' +
      creatorName +
      '"' +
      '><contents-richtext><body xmlns=' +
      '"http://www.w3.org/1999/xhtml"' +
      ' xmlns:xfa=' +
      '"http://www.xfa.org/schema/xfa-data/1.0/"' +
      ' xfa:APIVersion="Acrobat:20.13.0"' +
      ' xfa:spec="2.0.2"' +
      '  style=' +
      '"font-size:12.0pt;text-align:left;font-weight:normal;font-style:normal;font-family:Helv;font-stretch:normal"' +
      '><p dir=' +
      '"ltr"' +
      '><span style=' +
      '"font-size:10.5pt;text-align:left;font-weight:normal;font-style:normal"' +
      '>' +
      this.replaceSpecialChars(data['bodyValue']) +
      '</span></p ></body></contents-richtext></text>';
    return this.inreplydata;
  }

  creatorNameReply = '';
  squareData: string = '';
  squaredata(data): string {
    this.creatorNameReply = data['creator']['name'].substring(
      data['creator']['name'].indexOf(' ') + 1
    );
    var jsonSelectData = data['target']['selector'];
    const responce = this.getProductandLang(data['creator']['id']);
    const selectedData = Array.isArray(responce)? responce[0]: responce; 
    this.squareData =
      '<square color=' +
      '"' +
      jsonSelectData.strokeColor +
      '"' +
      ' creationdate=' +
      '"' +
      data['created'] +
      '"' +
      ' flags=' +
      '"print"' +
      ' date=' +
      '""' +
      ' name=' +
      '"' +
      data['id'] +
      '"' +
      ' uiId=' +
      '"' +
      data['creator']['id'] +
      '"' +
      ' product_name=' +
      '"' +
      selectedData['_attributes']['product_name'] +
      '"' +
      ' lang=' +
      '"' +
      selectedData['_attributes']['lang'] +
      '"' +
      ' page=' +
      '"' +
      0 +
      '"' +
      ' fringe=' +
      '"0.500000,0.500000,0.500000,0.500000"' +
      ' rect=' +
      '"' +
      jsonSelectData.boundingBox +
      '"' +
      ' subject=' +
      '"Rectangle"' +
      ' interior-color=' +
      '"' +
      jsonSelectData.strokeColor +
      '"' +
      ' opacity=' +
      '"0.4"' +
      '    title=' +
      '"' +
      data['creator']['name'] +
      '"' +
      '><contents-richtext> <body>' +
      this.replaceSpecialChars(data['bodyValue']) +
      '</body>  </contents-richtext></square>';
    return this.squareData;
  }

  zoom: boolean = false;
  zoomvalue = this.valuezoom;
  zoomvalueother = 15;
  zoomint = 40;
  zoomChangedArt(event) {
    const zoomVal = parseInt(event.target.value) - this.valuezoom;
    if (this.valuezoom > parseInt(event.target.value))
      this.zoomOut('out', zoomVal);
    else this.zoomIn('in', zoomVal);
    this.valuezoom = parseInt(event.target.value);
    this.resetZoom = parseInt(event.target.value);
  }
  replaceSpecialChars(data) {
    let hasSpecialChars = false;
    hasSpecialChars = /["'&<>]/.test(data);
    let replacedText: any;
    if (hasSpecialChars) {
      replacedText = data
        .replace(/&/g, '&amp;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&apos;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;');
    } else {
      replacedText = data;
    }
    return replacedText;
  }
  zoomOut(val?: string, zoomVal?: number) {
    this.zoom = true;
    zoomVal = zoomVal ? zoomVal : 0;
    const imgHeight = (this.modalImg.nativeElement as HTMLImageElement)
      .offsetHeight;
    const imgWidth = (this.modalImg.nativeElement as HTMLImageElement)
      .offsetWidth;
    let height: number;
    let width: number;
    if (imgHeight > 500 && imgWidth > 500) {
      height = imgHeight - 5 + zoomVal;
      width = imgWidth - 5 + zoomVal;
      if (height < 500 || width < 500) {
        this.artworkheight = '50%';
      } else {
        this.artworkheight = height + 'px';
      }
    } else {
      this.artworkheight = imgHeight + 'px';
    }
    if (
      this.preview !==
      (this.isProductNameAvailable
        ? this.artwork.getOverlay().overlay.bb[0]
        : this.artwork.getOverlay().overlay.bb)
    ) {
      height = imgHeight - 5 + zoomVal;
      width = imgWidth - 5 + zoomVal;
      this.artworkheight = height + 'px';
    }
    if (!val) this.valuezoom = this.valuezoom - 5;
    this.resetZoom = val ? this.resetZoom : this.valuezoom;
    this.modalImgWrapCss = {
      transform: `rotate(${this.modalImgRotation}deg)`,
      'transform-origin': 'center',
      'max-width': this.resetZoom === 50 ||this.valuezoom <= 50? '100%': 'none',
      'max-height': this.resetZoom === 50 ||this.valuezoom <= 50? '100%': 'none',
       height: this.resetZoom === 50 ||this.valuezoom <= 50? '': this.artworkheight,
    };
    this.imageParentClass =
    this.resetZoom === 50 ||this.valuezoom <= 50
        ? { 
          'display': 'flex',
          'align-items': 'center',
          'justify-content': 'center' }
        : (this.imageParentClass = {
            display: 'block'
          });
  }
  getProductandLang(uiId) {
  let data ;
  if(Array.isArray(this.artworkTool.xfdformatedBackup)){
      data =
    this.artworkTool.xfdformatedBackup.filter(
         (ele) => ele['_attributes']['uiId'] === uiId
       )
  }else{
    data =  this.artworkTool.xfdformatedBackup['_attributes']['uiId'] === uiId ? this.artworkTool.xfdformatedBackup:[];
  }      
    return data;
  }
  zoomIn(val?: string, zoomVal?: number) {
    this.zoom = true;
    zoomVal = zoomVal ? zoomVal : 0;
    const imgHeight = (this.modalImg.nativeElement as HTMLImageElement)
      .offsetHeight;
    const imgWidth = (this.modalImg.nativeElement as HTMLImageElement)
      .offsetWidth;
    let height: number;
    let width: Number;
    height = imgHeight + 5 + zoomVal;
    width = imgWidth + 5 + zoomVal;
    this.artworkheight = height + 'px';
    if (!val) this.valuezoom = this.valuezoom + 5;
    this.imageParentClass ={
      display: 'block'
    }
    this.modalImgWrapCss = {
      transform: `rotate(${this.modalImgRotation}deg)`,
      'transform-origin': 'center',
      'max-width': 'none',
      'max-height':'none',
      height: this.artworkheight
    };
  }
  getheight() {
    const imgHeight = (this.modalImg.nativeElement as HTMLImageElement)
      .offsetHeight;
    const imgWidth = (this.modalImg.nativeElement as HTMLImageElement)
      .offsetWidth;
    if (this.zoom == true) {
      if (this.selectedlanguage.name == 'Arabic') {
        if (
          this.SelectedModalOverlay == 'Nutrition Box' ||
          this.SelectedModalOverlay == 'Thumbnail' ||
          this.SelectedModalOverlay == 'Artwork' ||
          this.SelectedModalOverlay == 'WarningStatement' ||
          this.SelectedModalOverlay == 'BottlerAddress' ||
          this.SelectedModalOverlay == 'ProductDescription' ||
          this.SelectedModalOverlay == 'ExpiryDate' ||
          this.SelectedModalOverlay == 'StorageCondition'
        ) {
          this.lastHeight = this.zoomvalue + 'px';
          return this.lastHeight;
        } else if (this.SelectedModalOverlay == 'IngredientLine') {
          this.lastHeight = this.zoomvalue + 'px';
          return this.lastHeight;
        } else {
          this.lastHeight = this.zoomvalue + 'px';
          return this.lastHeight;
        }
      }
      //chinese related
      else {
        if (
          this.SelectedModalOverlay == 'Nutrition Box' ||
          this.SelectedModalOverlay == 'Thumbnail' ||
          this.SelectedModalOverlay == 'Artwork'
        ) {
          this.lastHeight = this.zoomvalue + 'px';
          return this.lastHeight;
        } else if (this.SelectedModalOverlay == 'IngredientLine') {
          this.lastHeight = this.zoomvalue + 'px';
          return this.lastHeight;
        } else {
          this.lastHeight = this.zoomvalue + 'px';
          return this.lastHeight;
        }
      }

      //return your desiderd value in pixel or in percentage
    } else {
      if (this.selectedlanguage.name == 'Arabic') {
        if (
          this.SelectedModalOverlay == 'Nutrition Box' ||
          this.SelectedModalOverlay == 'Thumbnail' ||
          this.SelectedModalOverlay == 'Artwork' ||
          this.SelectedModalOverlay == 'WarningStatement' ||
          this.SelectedModalOverlay == 'BottlerAddress' ||
          this.SelectedModalOverlay == 'ProductDescription' ||
          this.SelectedModalOverlay == 'ExpiryDate' ||
          this.SelectedModalOverlay == 'StorageCondition' ||
          this.SelectedModalOverlay == 'IngredientLine'
        ) {
          this.lastHeight = '100%';
          return '100%';
        } else {
          this.lastHeight = 'auto';
          return 'auto';
        }
      } else {
        if (
          this.SelectedModalOverlay == 'Nutrition Box' ||
          this.SelectedModalOverlay == 'Thumbnail' ||
          this.SelectedModalOverlay == 'Artwork' ||
          this.SelectedModalOverlay == 'bb'
        ) {
          this.artworkheight = '100%';
          return '100%';
        } else {
          this.lastHeight = 'auto';
          return 'auto';
        }
      }
    }
  }
  lidzoom: boolean = false;
  lidzoomvalue = 170;
  getheightlid() {
    if (this.lidzoom == true) {
      return this.lidzoomvalue + '%';
    } else {
      return '170%';
    }
  }
  zoomLidChanged(event) {
    const zoomVal = parseInt(event.target.value) - this.valuezoomlid;
    this.valuezoomlid > parseInt(event.target.value)
      ? this.lidzoomOut('in', zoomVal)
      : this.lidzoomIn('out', zoomVal);
    this.valuezoomlid = parseInt(event.target.value);
  }
  firstzoom = false;
  lidzoomOut(val?: string, zoomVal?: number) {
    this.lidzoom = true;
    zoomVal = zoomVal ? zoomVal : 0;
    const image = new Image();
    image.src = this.lidImg.nativeElement.src;
    image.onload = () => {
      if (this.lidImg) {
        let imgHeight = (this.lidImg.nativeElement as HTMLImageElement)
          .offsetHeight;
        let imgWidth = (this.lidImg.nativeElement as HTMLImageElement)
          .offsetWidth;
        if (imgHeight > 500 && imgWidth > 500) {
          this.lidheight = imgHeight - 10 + zoomVal;
          this.lidwidth = imgWidth - 10 + zoomVal;
        }
        this.lidheight = this.getheightChineselid();
        this.lidwidth = this.getwidthChineselid();
      }
    };
    if (!val) this.valuezoomlid -= 10;
  }
  lidzoomIn(val?: string, zoomVal?: number) {
    this.lidzoom = true;
    zoomVal = zoomVal ? zoomVal : 0;
    const image = new Image();
    image.src = this.lidImg.nativeElement.src;
    image.onload = () => {
      if (this.lidImg) {
        let imgHeight = (this.lidImg.nativeElement as HTMLImageElement)
          .offsetHeight;
        let imgWidth = (this.lidImg.nativeElement as HTMLImageElement)
          .offsetWidth;
        this.lidheight = imgHeight + 10 + zoomVal;
        this.lidwidth = imgWidth + 10 + zoomVal;
        this.lidheight = this.getheightChineselid();
        this.lidwidth = this.getwidthChineselid();
      }
    };
    if (!val) this.valuezoomlid += 10;
  }
  lidzoomvaluechinese = 350;
  getwidthChineselid() {
    if (this.lidzoom == true) {
      return this.lidwidth + 'px';
    }
  }
  getheightChineselid() {
    if (this.lidzoom == true) {
      return this.lidheight + 'px';
    }
  }

  ngOnDestroy() {
    if (this.pdfSubscription) this.pdfSubscription.unsubscribe();
    if (this.pdfSubscription1) this.pdfSubscription1.unsubscribe();
    this.artwork_view = false;
    this.displayartView = false;
  }
  productNameSelected(e) {
    let langArray = [];
    let compResult = [];
    this.supportedLanViewAW = [];
    this.cancelFilter();
    if (e === componentProductLanguage.allProducts) {
      langArray = this.defaultLanguages;
      compResult = this.comparisonResultsBackup;
    } else {
      langArray = [componentProductLanguage.allLanguages];
      this.comparisonResultsBackup.forEach((results) => {
        if (results.product_Name === e) {
          compResult.push(results);
          results.Lang.forEach((language) => {
            if (langArray.indexOf(language) === -1) {
              langArray.push(language);
            }
          });
        }
      });
    }
    this.comparsionResults = compResult;
    this.languageArr = langArray;
    this.product_comResults = compResult;
    this.resultsBackupForLangFilter = compResult;
    this.filterSelectedLanguage = this.languageArr[0];
    this.artworkTool.selectedProduct = e;
    this.artworkTool.selectedLanguage = this.filterSelectedLanguage;
    this.artworkTool.productandLanguageFilter();

    if (this.isMultiLangauge) this.selectedLanguageViewAW = this.languageArr[0];
    else this.selectedLanguageViewAW = this.supportedLan[0];
    if (e !== componentProductLanguage.allProducts) {
      this.artworkTool.selectedProduct = e;
    }
    this.supportedLanViewAW = [...this.languageArr];
    this.supportedLanViewAW.forEach((lang, index) => {
      if (lang === componentProductLanguage.allLanguages)
        this.supportedLanViewAW.splice(index, 1);
    });
  }

  languageSelected(e) {
    this.cancelFilter();
    let compResult = [];
    if (
      e === componentProductLanguage.allLanguages &&
      this.selectedProductName === componentProductLanguage.allProducts
    ) {
      compResult = this.comparisonResultsBackup;
    } else if (
      e === componentProductLanguage.allLanguages &&
      this.selectedProductName !== componentProductLanguage.allProducts
    ) {
      compResult = this.product_comResults;
    } else {
      compResult = this.resultsBackupForLangFilter.filter((results) =>
        results.Lang.includes(e)
      );
    }
    this.resultsBackupForMatchFilter = compResult;
    this.comparsionResults = compResult;
    this.artworkTool.selectedLanguage = e;
    this.artworkTool.productandLanguageFilter();
  }

  productNameSelectedViewAW(e) {
    this.productPanel.close();
    this.selectedProductNameViewAW = e;
    this.resultsTitleForViewAW = [];
    let langArray = [];
    if (e === componentProductLanguage.allProducts) {
      langArray = [...this.defaultLanguages];
      this.resultsTitleForViewAW = [...this.resultsTitleForViewAWBackUp];
    } else {
      Object.keys(this.artwork.getOverlay().overlay).forEach((key, index) => {
        if (this.artwork.getOverlay().overlay[key][1] === e && key !== 'bb') {
          this.resultsTitleForViewAW.push(key);
          if (this.isMultiLangauge) {
            this.artwork.getOverlay().overlay[key][2].forEach((language) => {
              if (langArray.indexOf(language) === -1) {
                langArray.push(language);
              }
            });
          }
        }
      });
    }
    this.supportedLanViewAW = [...langArray];
    if (this.isMultiLangauge)
      this.selectedLanguageViewAW = this.supportedLanViewAW[0];
    else this.selectedLanguageViewAW = this.supportedLan[0];
  }

  languageSelectedViewAW(e) {
    this.languagePanel.close();
    this.resultsTitleForViewAW = [];
    this.selectedLanguageViewAW = e;
    Object.keys(this.artwork.getOverlay().overlay).forEach((key, index) => {
      if (
        this.artwork.getOverlay().overlay[key] !== undefined &&
        this.artwork.getOverlay().overlay[key] !== null &&
        this.artwork.getOverlay().overlay[key] !== '' &&
        key !== 'bb'
      ) {
        if (
          this.artwork.getOverlay().overlay[key][1] ===
          this.selectedProductNameViewAW
        ) {
          this.artwork.getOverlay().overlay[key][2].forEach((language) => {
            if (language === e) {
              this.resultsTitleForViewAW.push(key);
            }
          });
        }
      }
    });
  }
  languageWrapping(langArray) {
    let lang = '';
    lang = langArray
      .map((e) => e.substring(0, 2).replace(/\s/g, ''))
      .join(', ');
    return lang;
  }
  mappingLanguages(langArray) {
    return langArray.map((e) => e.replace(/\s/g, '')).join(',');
  }

  showTooltip(e) {
    this.isProductTooltipVisible = true;
  }
  
  hideTooltip(e) {
    this.isProductTooltipVisible = false;
  }
  onCommentChange(event, res, index):void{
    this.brrdata.Rules[index].comments.commentText = event.target.value;
    this.brrdata.Rules[index].comments.commentOpened = false;
  }

  /**
    * 
    * Users can select any annotation in the Artwork View by clicking on it. 
    * Upon selecting an annotation, the corresponding result in the Report View is highlighted. 
    * Additionally, the Report View automatically scrolls to ensure that the highlighted result 
    * row is brought into view. This ensures that users can easily locate and focus on the relevant result.  
    * 
   */
  navigateToSelectedCard($event){
    let indexes = []
    this.comparsionResults.forEach((comData,index)=>{
      comData.comResultsFiltered.forEach((resData)=>{
        if(resData.id === $event){
          indexes.push(index)
          this.scrollToItem(indexes)
        }
      })
    })
  }

  /**
   * 
   * Automatic scrolls to ensure that the highlighted result row is brought into view
   * @param index 
   * 
   */
  scrollToItem(index: number[]): void {
    if (this.cardsContainer) {
      let element = this.cardsContainer.nativeElement.children[index[0]-1];
      if(element) {
        if(element.offsetHeight > 600){
          element = this.cardsContainer.nativeElement.children[index[0]]    
        }
      }
      else {
        element = this.cardsContainer.nativeElement.children[index[0]]    
      }
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center'
        });
        this.selectedItemId = index;
      }
    }
  }
}
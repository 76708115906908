<div class="uploadMainDiv">
    <p *ngIf="HomepageService.adhocProjectName && (HomepageService.navigateToUploadPageQueued)" class="projectName">
        Project - {{HomepageService.adhocProjectName}}
        <img src="../../../assets/images/add-to-queue-project-icon.svg" class="projectIconForQueued">
    </p>
    <div class="page-container container-fluid">
        <div class="row no-gutters">
            <div class="" style="width:100%">
                <app-side-button-panel title="File Upload"
                    [type] = "componentType"
                    description={{choosePackageCategoryMessage}}
                    [disablePrimary]="!previewAvailable" (button)="buttonClicked($event)"
                    (selectedPackageCategory)="selectedPackageValue($event)"
                    (selectedMultiLingualArtwork) ="selectedMultiLingualArtwork($event)"
                    [valueForDisable]="disableValue"
                    [fileUploadStartedFlag] = "fileUploadStartedFlag"
                    ></app-side-button-panel>
            </div>
            <div class="" style="width:88%">
                <div class="row">
                    <div class="col-6">
                        <app-file-upload-queued title="Artwork" [images]="['assets/images/camera.svg']"
                            firstMessage={{dropArtworkMessage}} acceptedExt=".pdf, .PDF, .Pdf"
                            (zoom)="zoomedIn($event)" [projectId]='projectId' [packageCategory]="packageCategory"
                            [isMultiLingualArtwork]="isMultiLingualArtwork"
                            (selectedFile)="fileSElected($event)"
                            (artlidPreview)="previewImage($event)"
                            (fileUploadStarted)="fileUploadStarted($event)"
                            ></app-file-upload-queued>
                    </div>
                    <div class="col-6">
                        <app-file-upload-queued title="LID"
                            [images]="['assets/images/spreadsheet.svg', 'assets/images/pdf.svg']"
                            firstMessage={{dropFilesMessage}} acceptedExt=".xlsx, .xls, .pdf"
                            (zoom)="zoomedIn($event)" [projectId]='projectId' [packageCategory]="packageCategory"
                            [isMultiLingualArtwork]="isMultiLingualArtwork"
                            (selectedFile)="fileSElected($event)"
                            (artlidPreview)="previewImage($event)"
                            (fileUploadStarted)="fileUploadStarted($event)"
                            ></app-file-upload-queued>
                    </div>
                </div>
            </div>
            <app-zoom-queued *ngIf="!(preview == '')" image="{{preview}}"
                (closeZoom)="zoomedOut($event)"></app-zoom-queued>
        </div>
    </div>
</div>
// tslint:disable: object-literal-key-quotes
import { BoundingBoxState } from './../utils';

export const defaultHeaders = {
  ing: 'IngredientLine',
  nf: 'NutritionFact',
  tn: 'Thumbnail',
  nc: 'NetContent',
  pk1: 'Package1',
  upc: 'UPC',
  ws: 'WarningStatement',
  pk: 'Package',
  rc: 'Recycle',
  dci: 'DesignCenterInfo',
  awi: 'ArtworkInfo',
  ari: 'ApproverInfo',
  pl: 'ProductLogo',
  cn: 'Connect',
  ba:'BottlerAddress',
  pd:'ProductDescription',
  exd:'ExpiryDate',
  sc:'StorageCondition',
  psc:'ProductStandardCode',
  sl:'ShelfLife',
  md:'ManufactureDate',
  ln:'LegalName'

};

export const supportedLanguages = [
  { name: 'English', code: 'en', rtl: false, headers: defaultHeaders },
  { name: 'Chinese', code: 'zh', rtl: false, headers: defaultHeaders },
  {
    name: 'Arabic',
    code: 'ar',
    rtl: true,
    headers: {
      // ing: 'Ingredients Area',
       tm: 'Trademark'
    }
  }
];

export function findLanguageByCode(code = null): any {
  let language: any = {
    name: 'NA',
    code: null,
    rtl: false,
    headers: defaultHeaders
  };

  for (const lang of supportedLanguages) {
    if (lang.code === code) {
      language = lang;
      language.headers = { ...defaultHeaders, ...language.headers };
      break;
    }
  }
  return language;
}

export function hasRequiredBoundingBoxes(
  bbState: BoundingBoxState,
  language
): boolean {
  if (!language || !language.value) {
    return false;
  }
  let hasRequiredBoxes = false;
  switch (language.value.code) {
    case 'ar':
      // hasRequiredBoxes =
      //   !!bbState.ingPredictions &&
      //   !!bbState.nfPredictions &&
      //   !!bbState.ncPredictions &&
      //   !!bbState.tnPredictions &&
      //   !!bbState.tmPredictions;
      break;
    case 'en':
      // hasRequiredBoxes =
      //   !!bbState.ingPredictions &&
      //   !!bbState.nfPredictions &&
      //   !!bbState.ncPredictions &&
      //   !!bbState.tnPredictions;
      break;
    case 'zh':
      // hasRequiredBoxes =
      //   !!bbState.ingPredictions &&
      //   !!bbState.nfPredictions &&
      //   !!bbState.ncPredictions &&
      //   !!bbState.tnPredictions;
      break;
  }
  return hasRequiredBoxes;
}

/**
 * The ‘get-started’ component serves as the entry point for users after a successful login. 
 * It provides functionality for changing region-category in the header and navigation to different screens.  
 * 1. Region-Category Selection: Users can change the region-category in the Header on Get-started page. 
 * 2. Navigation Buttons: 
 * i. Dashboard: Navigate to the main dashboard. 
 * ii. New Ad hoc Review: Go to the screen for ad hoc project uploads. 
 * iii. New Queued Review: Access the queued project upload screen. 
 * 
*/
import { PlatformLocation, Location } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OktaAuthService } from '../app.service';
import { HomepagedataService } from '../services/homepagedata.service';
import { LabelRightService } from '../services/label-right.service';
import { DashboardReusableService } from '../services/dashboard-reusable.service';
import { AppInsightsService } from '../services/app-insights.service';
import { routeUrls } from '../utils/constants';
import { ResultsPageDataService } from '../services/results-page-data.service';

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['../base.scss', './get-started.component.scss']
})
export class GetStartedComponent implements OnInit {
  isAuthenticated: boolean;
  constructor(
    private router: Router,
    private oktaAuth: OktaAuthService,
    private labelright:LabelRightService,
    private DashboardfilterService:DashboardReusableService,
    private AppInsightService:AppInsightsService,
    private HomepageService:HomepagedataService,
    private resultsPageDataService:ResultsPageDataService
 ) {
    this.router.routeReuseStrategy.shouldReuseRoute=()=>{
      return false;
  };
 }

  public href: string = '';

  /**
   * If OKTA response doesn't provide the username within 10 seconds of loading the page, the user is automatically 
   * logged out. This ensures security and helps manage user sessions effectively. 
   * 
  */
  ngOnInit(): void {
    this.oktaAuth.$isAuthenticated.subscribe(
      (val) => (this.isAuthenticated = val)
    );
    this.labelright.getConnection();
    this.AppInsightService.logPageView("Testing in getStarted page");
    this.HomepageService.clearSessionValue();
    setTimeout(() => {
      if(this.labelright.oktaUserName === null || this.labelright.oktaUserName === '' || 
          this.labelright.oktaUserName === undefined){
        this.oktaAuth.logout();
      }
    }, 10000);
  }
  newAdhocReview(){
    this.HomepageService.clearSessionValue();
    this.resultsPageDataService.original_Xfd = '';
    this.resultsPageDataService.xfdDataForAWView = '';
    this.DashboardfilterService.resetDashBoardFilter();
    this.HomepageService.navigatingFromDashboard = false;
    this.labelright.reset();
  }

  newQueuedReview(){
    this.HomepageService.clearSessionValue();
    this.labelright.resetQueued();
    this.DashboardfilterService.resetDashBoardFilter();
    this.HomepageService.navigatingFromDashboard = false;
    this.HomepageService.navigateToUploadPageQueued=true;
    this.router.navigate([routeUrls.uploadqueued]);
   }
}

import { ɵHttpInterceptingHandler } from "@angular/common/http";

export abstract class AppConfiguration {
  serviceUrl: string;
  oktaClientId: string;
  oktaIssuer: string;
  hostedUrl: string;
  requestTimeout: number;
  enableLogs: boolean;
  idleTimeout:number;
  enableTool:boolean
}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs/internal/observable/throwError';
import { OktaAuthService } from '../app.service';
import { AlertBarService } from '../utils/alert-bar/alert-bar.service';
import { messagesOnScreen } from '../utils/constants';
import { AppInsightsService } from './app-insights.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor( 
    private reusableService: OktaAuthService,
    private alertBarService: AlertBarService,
    private appInsight : AppInsightsService
  ) { }
  /**
   * 
   * If the error status is 401 and error message is "TokenInvalidOrExpired", user is auto logged out.
   * For other error scenarios, a message is shown on the screen as "Something went wrong".
   * 
   * @param error 
   * @returns 
   */
  handleError(error: HttpErrorResponse) {
    if(error.error.status === 401 && error.error.message){
      if(JSON.parse(error.error.message).Reason=== 'TokenInvalidOrExpired'){
        this.reusableService.logout()
      }
      else{
        console.log(error);
        this.alertBarService.show(messagesOnScreen.somethingWentWrongMessage);
        return throwError(error);
      }
    }
    this.appInsight.logEvent(
      'Error in API -' , error
    );
    this.alertBarService.show(messagesOnScreen.somethingWentWrongMessage);
    return throwError(error);
  }
}

<div class="uploadMainDiv">
  <p *ngIf="HomepageService.adhocProjectName && !(HomepageService.navigatingFromDashboard)" class="projectName"> Project
    -
    {{HomepageService.adhocProjectName}}
    <span *ngIf="!(HomepageService.isAutomated)">
      <img src="../../../assets/images/adhocproject.svg" class="projectIcon">
    </span>
  </p>
  <p *ngIf="resultsPageDataService.savedProjectName && (HomepageService.navigatingFromDashboard)" class="projectName">
    Project - {{resultsPageDataService.savedProjectName}}
    <span *ngIf="!(HomepageService.isAutomated) && !(HomepageService.isBatched)">
      <img src="../../../assets/images/adhocproject.svg" class="projectIcon">
    </span>
    <span *ngIf ="(HomepageService.isBatched)">
      <img src="../../../assets/images/add-to-queue-project-icon.svg" class="projectIconForQueued">
    </span>
  </p>
  <div class="page-container container-fluid">
    <div class="row no-gutters inner-container">
      <div class="" style="width:100%">
        <app-side-button-panel title="Verify Boxes" description="" (button)="buttonClicked($event)">
          <div class="descrip">
            <p>Make sure all inspection areas are within the appropriate bounding boxes.</p>
          </div>
        </app-side-button-panel>

      </div>
      <div class="" style='width:100%'>
        <div style="display:flex;">
          <!-- <div class="col"> -->
          <div class="col-md-2 left-pane" style="background-color: white;">
            <mat-list>
              <!--Visible only if the "supported_product_name" contains product names other than "Common". 
              It is displayed for Variety packs and allows filtering by product name. -->
              <mat-list-item *ngIf="isProductNameVisible" class="lang-selector" style="margin-top: 10px;">
                <mat-form-field appearance="fill" class="form-field-Verify ">
                  <mat-select [(value)]="selectedproductName" disableOptionCentering
                    (selectionChange)="productNameSelected($event.value)"
                    matTooltip="{{selectedproductName}}"
                    #tooltip10="matTooltip" [matTooltipPosition]="'right'">
                    <mat-option *ngFor="let option of productNameArr" [value]="option" matTooltip={{option}} #tooltip1="matTooltip" [matTooltipPosition]="'right'">
                      {{ option  | truncate : 15 }}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix>arrow_drop_down</mat-icon>
                </mat-form-field>
              </mat-list-item>
              <!--Contains all languages of the detected bounding boxes. 
              The list updates dynamically based on added or deleted boxes. -->
              <mat-list-item class="lang-selector" style="
              margin-top: 10px;">
                <mat-form-field appearance="fill" class="form-field-Verify ">
                  <mat-select [(value)]="selectedLang" disableOptionCentering
                    (selectionChange)="langSelected($event.value)"
                    matTooltip="{{selectedLang}}"
                    #tooltip17="matTooltip" [matTooltipPosition]="'right'">
                    <mat-option *ngFor="let option of langArr" [value]="option" matTooltip={{option}} #tooltip2="matTooltip" [matTooltipPosition]="'right'">
                      {{ option }}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix>arrow_drop_down</mat-icon>
                </mat-form-field>
              </mat-list-item>
              <div class="hidden"></div>
              <!--Displays a unique list of detected and undetected components. Enabled options correspond to 
              detected components, while disabled options represent undetected components. The list updates based on 
              added or deleted boxes. -->
              <cdk-virtual-scroll-viewport [itemSize]="10" class="example-viewport">
              <ng-container *ngIf="Tiles.length">
                  <mat-list-item [ngStyle]="{
                  'background-color': tbValue === allComponents ? '#dee2e3' : ''
                }" (click)="highlight(allComponents)" matTooltip={{allComponents}} #tooltip18="matTooltip" [matTooltipPosition]="'right'">
                    <span class="dot" style="background-color:white;" ></span>
                    &nbsp;
                    <div matListItemTitle>All Components</div>
                  </mat-list-item>
                  <mat-list-item *ngFor="let title of Tiles; let i = index" [ngStyle]="{
                  'background-color': tbValue === title.titles ? '#dee2e3' : ''
                }" (click)="highlight(title.titles)" matTooltip={{title.titlename}} #tooltip3="matTooltip" [matTooltipPosition]="'right'">
                    <span class="dot" [ngStyle]="{
                    'background-color': title.colorcodes
                  }"></span> &nbsp;
                    <div matListItemTitle> {{ title.titlename}}</div>
                  </mat-list-item>
                </ng-container>
                <ng-container *ngIf="deletedBBs.length">
                  <mat-list-item class="deleted-items" disabled *ngFor="let deletedBB of deletedBBs; let i = index">
                      <span class="dot1" [ngStyle]="{
                      'background-color': deletedBB.colorcodes
                    }"></span> &nbsp;
                      <div matListItemTitle> {{ deletedBB.titlename}}</div>
                    </mat-list-item>
              </ng-container>
            </cdk-virtual-scroll-viewport>
            </mat-list>
          </div>
          <div class="col-ms-1" style="padding: 10px;"></div>
          <div class="main-container">
            <div class="legend">
              <div class="row no-gutters">

                <div class="col-9">
                  <div class="addNew-wrapper">
                    <button class="addNew" (click)="addNewBox($event)" matTooltip="Add a new box" #tooltip11="matTooltip" [matTooltipPosition]="'below'">
                      <img class="reset-icon" src="/assets/images/add-icon.svg"><span class="button-text">Add Box</span>
                    </button>
                  </div>
                  <div class="addNew-wrapper">
                    <button class="addNew" (click)="editBB($event)" matTooltip="Edit an existing box" #tooltip12="matTooltip" [matTooltipPosition]="'below'">
                      <img class="edit-icon" src="/assets/images/edit-icon.svg"><span class="button-text">Edit Box</span>
                    </button>
                  </div>
                  <div class="addNew-wrapper">
                    <button class="delete" (click)="deleteBB()" matTooltip="Delete an existing box" #tooltip13="matTooltip" [matTooltipPosition]="'below'">
                      <img class="reset-icon" src="/assets/images/delete-icon.svg"><span class="button-text">Delete Box</span>
                    </button>
                  </div>
                  <div [ngClass]="labelRightService.isUndoButtonVisible ? 'recenter-wrapper-multi-lingual' : 'recenter-wrapper' ">
                    <button *ngIf="labelRightService.isUndoButtonVisible" class="undo" (click)="undo_click()" matTooltip="Undo reset" #tooltip14="matTooltip" [matTooltipPosition]="'below'">
                      <img class="undo-icon" src="/assets/images/undo_reset.svg"><span class="button-text">Undo Reset</span>
                    </button>
                    <button class="reset" (click)="reset_click()" matTooltip="Reset all the boxes to initial state" #tooltip15="matTooltip" [matTooltipPosition]="'below'">
                      <img class="reset-icon" src="/assets/images/reset.svg"><span class="button-text">Reset All Boxes</span>
                    </button>

                    <button class="recenter" (click)="recenter_click()" matTooltip="Recenter the artwork and the boxes" #tooltip16="matTooltip" [matTooltipPosition]="'below'">
                      <img class="recenter-icon" src="/assets/images/recenter.svg"><span class="button-text">Re-center Artwork</span>
                    </button>

                    <span>
                      <img class="rotate-icon" (click)="rotateClicked()" src="/assets/images/rotate.svg"
                        matTooltip="Rotate Artwork Clockwise" #tooltip1="matTooltip" [matTooltipPosition]="'below'">
                    </span>
                  </div>
                </div>

                <div class="col-3">
                  <div class="zoom-scroll-container mb-3">
                    <div class="row no-gutters">
                      <div class="col-1 " (click)="zoomIconChange('out')"><span
                          [ngClass]="{'noCursor': valuezoom <=50 }" class="zoom-icon zoom-out"></span></div>
                      <div class="col-9">
                        <input type="range" [min]='min' [max]='max' [value]='valuezoom' id="zoom-slider" class="slider"
                          (input)="zoomChanged($event)">
                      </div>
                      <div class="col-1 " (click)="zoomIconChange('in')"><span
                          [ngClass]="{'noCursor': valuezoom >=1800 }" class="zoom-icon zoom-in"></span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="canvas">
              <app-artwork-canvas *ngIf="artwork.state !== 0" [artworkUrl]="newImageURL" [zoom]="zoomLevel"
                [boundingBoxes]="boundingBoxes" (editItemEvent)="getChildData($event)"
                (addItemEvent)="getChildDataAdd($event)"
                (deleteItemEvent)="getChildDataDelete($event)"
                (deleteItemEventForSnackbar)="getChildDataDeleteForSnackbar()"
                (editItemEventForSnackbar)="getChildDataEditForSnackbar()"
                (deleteItemEventForSnackbarCancel)="getChildDataDeleteForSnackbar()"
                (editItemEventForSnackbarCancel)="getChildDataEditForSnackbar()"
                (autoFocusEvent)="getautoFocus($event)"
                (editItemEventForDeleteAdd)="getChildDataToDeleteAddForEdit($event)"
                (editItemEventBrandLanguage)="getChildDataBrandLanguage($event)"
                (zoomValueChanged) ="zoomUpdate($event)" ></app-artwork-canvas>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-overlay d-flex">
    <div class="modal-background" (click)="closeZoomWindow()"></div>
    <div class="modal-zoom align-self-center">
        <div class="close-button" (click)="closeZoomWindow()">
            <img src="../../../assets/images/close.svg" />
        </div>
        <div class="modal-zoom">
            <img src="{{ image }}" />
        </div>
    </div>
</div>
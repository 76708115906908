/**
 * The Canvas Bounding Box class manages the logic for creating rectangles on the canvas. 
 * It is responsible for creating boxes and setting attributes for each individual box. 
 * The box coordinates, height, width, colour are received from the model response.  
 * 
 * */
import Konva from 'konva';
import { Prediction, AllAnchors } from './utils';
import { LabelRightService } from '../services/label-right.service';

export class CanvasBB {
  type: string;
  topLeft: Prediction;
  bottomRight: Prediction;
  width: number;
  height: number;
  layer: Konva.Layer;
  rect: Konva.Rect;
  transformer: Konva.Transformer;
  modified: boolean;
  bbDef: any;
  boundingboxname: any;
  value = 300;
  lang: string;
  isRoiIdAvailable : boolean;

  constructor(type: string, predictions: Prediction[], color: string, lang: string) {
    this.modified = false;
    this.type = type;
    this.bbDef = color;
    this.lang = lang;
    if (predictions.length === 2) {
      this.topLeft = predictions[0];
      this.bottomRight = predictions[1];
      this.calculateLength();
    }
    this.layer = new Konva.Layer();
    this.rect = new Konva.Rect({
      x: this.topLeft.x,
      y: this.topLeft.y,
      width: this.width,
      height: this.height,
      fill: '#ff3a0032',
      stroke: this.bbDef,
      strokeWidth: 5,
      draggable: true
    });
    if(this.topLeft.roi_id != undefined && this.topLeft.roi_id != null && 
      this.topLeft.box_refinement != undefined && this.topLeft.box_refinement!= null){
        this.isRoiIdAvailable = true;
    }

    this.rect.on('dragend', () => {
      LabelRightService.instance.isUndoButtonVisible=false;
      const { x, y } = this.rect.getAttrs();
      this.topLeft.x = parseInt(x, 10);
      this.topLeft.y = parseInt(y, 10);
      this.topLeft.x = this.rect.attrs.x;
      this.topLeft.y = this.rect.attrs.y;
      if(this.isRoiIdAvailable) {
        this.bottomRight.box_refinement = true;
        this.topLeft.box_refinement = true;
      }
      this.updateBottomRight();
    });
    this.rect.on('click', () => {
      this.layer.moveToTop();
      this.layer.draw();
    });

    this.rect.on('transformend', () => {
      LabelRightService.instance.isUndoButtonVisible=false;
      let { width, height, scaleX, scaleY, x, y, rotation } = this.rect.getAttrs();
      if (scaleX == undefined) {
        scaleX = 1;
      }
      if (scaleY == undefined) {
        scaleY = 1;
      }
      const newWidth = parseInt(`${width * scaleX}`, 10);
      const newHeight = parseInt(`${height * scaleY}`, 10);

      this.width = newWidth;
      this.height = newHeight;

      this.rect.setAttr('width', this.width);
      this.rect.setAttr('height', this.height);
      this.rect.setAttr('scaleX', 1);
      this.rect.setAttr('scaleY', 1);

      this.rect.setAttr('x', parseInt(x, 10));
      this.rect.setAttr('y', parseInt(y, 10));
      this.topLeft.x = parseInt(x, 10);
      this.topLeft.y = parseInt(y, 10);
      if (rotation == -180 || rotation == 180) {
        this.bottomRight.y = this.topLeft.y - newHeight;
        this.bottomRight.x = this.topLeft.x - newWidth;
      } else {
        this.bottomRight.y = this.topLeft.y + newHeight;
        this.bottomRight.x = this.topLeft.x + newWidth;
      }
      if(this.isRoiIdAvailable) {
        this.bottomRight.box_refinement = true;
        this.topLeft.box_refinement = true;
      }
    });

    this.rect.on('dragmove', () => {
    });

    this.layer.add(this.rect);
  }
  /**
   * Every box is created with the provided inputs and added on the artwork layer by layer. 
   * Every box has a Transformer around itself, which makes the box transformable. 
   * The transformer’s border width is made 0, to make the content inside the box more readable and clickable.  
   * 
   * @param color 
   */
  setupTransformer(color?:any): void {
    this.transformer = new Konva.Transformer({
      rotateEnabled: false,
      anchorStroke: color ? color : this.bbDef,
      anchorFill: color ? color : this.bbDef,
      anchorSize: 5,
      anchorCornerRadius: 5,
      enabledAnchors: AllAnchors,
      borderStroke: color ? color : this.bbDef,
      borderStrokeWidth: 0,
      keepRatio: false,
      nodes: [this.rect]
    });
    this.layer.add(this.transformer);
    this.layer.draw();
  }

  updateBottomRight(): void {
    this.bottomRight.x = Number(this.topLeft.x) + (this.width);
    this.bottomRight.y = Number(this.topLeft.y) + (this.height);
  }

  calculateLength(): void {
    this.width = this.bottomRight.x - this.topLeft.x;
    this.height = this.bottomRight.y - this.topLeft.y;
  }

  update(topLeft: Prediction, bottomRight: Prediction): void {
    this.topLeft = topLeft;
    this.bottomRight = bottomRight;
    this.calculateLength();
    this.rect.x(this.topLeft.x);
    this.rect.y(this.topLeft.y);
    this.rect.width(this.width);
    this.rect.height(this.height);
    this.layer.draw();
    if (!this.layer.isVisible()) {
      this.layer.hide();
    }
  }

  draw(): void {
    if (!!this.layer) {
      this.layer.draw();
    } else {
    }
  }

  checkType(type: string): boolean {
    return this.type === type;
  }

  colorChange(color){
    this.rect.stroke(color);
  }
}

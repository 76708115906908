/**
 * For Python API calls, the configurations are handled in the “label-right.service.ts” file. 
 * This separation allows distinct management of API calls for the Python backend. 
 *
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  FileUploaded,
  UploadState,
  FetchStatus,
  BoundingBoxState,
  Orchestrator,
  pdf_xfd,
  business_rules,
  compresults
} from '../utils/utils';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import {
  findLanguageByCode,
  hasRequiredBoundingBoxes
} from '../utils/languages/language-codes';
import { AlertBarService } from '../utils/alert-bar/alert-bar.service';
import { Router } from '@angular/router';
import { HomepagedataService } from './homepagedata.service';
import { AppInsightsService } from './app-insights.service';
import { CookieService } from 'ngx-cookie-service';
import { componentProductLanguage, routeUrls, sessionStorageObject } from '../utils/constants';


@Injectable({
  providedIn: 'root'
})
export class LabelRightService {
  static instance : LabelRightService;
  private ARTWORK_ORCHESTRATOR = '/orchTrigger/artworkOrchestrator';
  private LID_ORCHESTRATOR = '/orchTrigger/lidOrchestrator';
  private COMPARISON_ORCHESTRATOR = '/orchTrigger/comparisonOrchestrator';
  private BB_ORCHESTRATOR = '/orchTrigger/bbOrchestrator';
  private extractionOrchestrator = '/orchTrigger/extractionOrchestrator'
  // fetching the generateResultsFileEndpoint value from environment.ts file. Refer that file for more info
  // private generateResultsFileEndpoint = environment.apiKey;
  private generateResultsFileEndpoint = '/generateResultsFile';
  private API_URL = '';
  //New Code - Store the username and user email
  public oktaUserName;
  public oktaEmail;
  public isUserNameSet = false;
  artworkFile: BehaviorSubject<FileUploaded>;
  lidFile: BehaviorSubject<FileUploaded>;
  lidUploadFile: BehaviorSubject<FileUploaded>;
  startOCRState: BehaviorSubject<any>;
  getOverlayState: BehaviorSubject<any>;
  boundingBoxState: BehaviorSubject<BoundingBoxState>;
  language: BehaviorSubject<any>;
  results: BehaviorSubject<any>;
  pdf_Xfd: BehaviorSubject<pdf_xfd>;
  xfd_Data :BehaviorSubject<string>;
  business_rule: BehaviorSubject<business_rules>;
  resultsFileURL: BehaviorSubject<string>;
  BRrules: BehaviorSubject<any>;
  humanAnnotationUrl: BehaviorSubject<string>;
  xfdDataUpdated: string;
  results1: BehaviorSubject<compresults>;
  // orchTrigger response data
  currentArtOrchestrator: Orchestrator;
  currentLidOrchestrator: Orchestrator;
  currentComparisonOrchestator: Orchestrator;
  currentBBOrchestrator: Orchestrator;
  currentExtractionOrchestrator: Orchestrator;
  emptyOrchestrator: Orchestrator = {
    id: '',
    purgeHistoryDeleteUri: '',
    rewindPostUri: '',
    sendEventPostUri: '',
    statusQueryGetUri: '',
    terminatePostUri: ''
  };
  annotationdata: any;
  overylayData: OverlayData[];
  resultsData: any[];
  ReviewArtwork: any;
  UpdatedBoundingBoxes: any;
  comResultsData = [];
  colorCodes = []
  public observerVariable: Array<any>;
  verifyToUpload = false;
  resultsToAllDone = false;
  uploadToVerify = false;
  bbAllImageURL: BehaviorSubject<string>;
  bbAllDataBackup: any;
  bbAllDataBackupURL: any;
  bbAllSupportedLanguage: any;
  bbAllComponents: any;
  bbAllSupportedProducts: any = [];
  myIDMUserData : any;
  myIDMUserEmail : any;
  myIDMUserRoles : any;
  myIDMUserDefaultValue : any;
  isProductNameVisible = false;
  bbAllRoiIdObject = []; 
  resultsAllSupportedLang =[];
  resultsAllSupportedBrand =[];
  isProductNameAvailable = false;
  isRoiIdAvailable = false;
  isResetBBClicked = false;
  isUndoResetClicked = false;
  isUndoButtonVisible = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private alertBarService: AlertBarService,
    private HomepageService: HomepagedataService,
    private cookie:CookieService,
    private AppInsightsService:AppInsightsService
     ) {
    LabelRightService.instance = this;
    this.artworkFile = new BehaviorSubject<FileUploaded>(new FileUploaded());
    this.lidFile = new BehaviorSubject<FileUploaded>(new FileUploaded());
    this.lidUploadFile = new BehaviorSubject<FileUploaded>(new FileUploaded());
    this.observerVariable = new Array<any>()
    this.pdf_Xfd = new BehaviorSubject<pdf_xfd>(new pdf_xfd());
    this.boundingBoxState = new BehaviorSubject<BoundingBoxState>(
      new BoundingBoxState()
    );
    this.language = new BehaviorSubject<any>(findLanguageByCode());
    this.results = new BehaviorSubject<any>({});
    this.results1 = new BehaviorSubject<any>({});
    this.BRrules = new BehaviorSubject<any>({});
    this.resultsFileURL = new BehaviorSubject<string>('');
    this.humanAnnotationUrl = new BehaviorSubject<string>('');
    this.xfd_Data = new BehaviorSubject<string>('');
    this.xfdDataUpdated = null;
    this.bbAllImageURL = new BehaviorSubject<string>('');
    this.currentArtOrchestrator = null;
    this.currentLidOrchestrator = null;
    this.currentComparisonOrchestator = null;
    this.API_URL = environment.apiURL;
    this.currentArtOrchestrator = this.emptyOrchestrator;
    this.currentComparisonOrchestator = this.emptyOrchestrator;
    this.currentLidOrchestrator = this.emptyOrchestrator;
    this.currentBBOrchestrator = this.emptyOrchestrator;
    this.currentExtractionOrchestrator = this.emptyOrchestrator;
  }
  getConnection()
  {
    this.currentArtOrchestrator = this.emptyOrchestrator;
    this.currentComparisonOrchestator = this.emptyOrchestrator;
    this.currentLidOrchestrator = this.emptyOrchestrator;
    this.currentBBOrchestrator = this.emptyOrchestrator;
    this.currentExtractionOrchestrator = this.emptyOrchestrator;
  }
  
  getAccessToken(){
   return this.cookie.get('access_token');
  }
  public restBoundingBox(): void {
    this.boundingBoxState.next(new BoundingBoxState());
  }

  public resetArt(): void {
    this.artworkFile.next(new FileUploaded());
    this.stopArtOrch();
  }

  public resetLID(): void {
    this.lidFile.next(new FileUploaded());
    this.stopLidOrch();
  }
  public resetLIDUpload(): void {
    this.lidUploadFile.next(new FileUploaded())
    this.stopLidOrch();
  }
  public resetPDF_XFD(): void {
    this.pdf_Xfd.next(new pdf_xfd());
  }
  public resetResults(): void {
    this.results.next({});
  }

  public resetBRRules(): void {
    this.BRrules.next({});
  }

  public setResults(results: any): void {
    this.results.next(results);
  }

  public reset(): void {
    this.resetLID();
    this.resetArt();
    this.resetResults();
    this.resetBRRules();
    this.resetPDF_XFD();
    this.annotationdata =[]
    this.boundingBoxState.next(new BoundingBoxState());
    this.pdfFileUrl = '';
    this.xdfData = '';
    this.xfd_Data.next('');
    this.graypdfURL = '';
    this.resultsAllSupportedBrand = [];
    this.resultsAllSupportedLang = [];
    this.HomepageService.adhocProjectName = ''
    this.HomepageService.projectAuther = '';
    this.HomepageService.manualArtworkProgress = false;
    this.HomepageService.navigatingFromDashboard = false;
    this.HomepageService.disableDeleteButton = true;
    this.HomepageService.resultPreviousButton = true;
    this.HomepageService.alldoneSentAWHButton = false;
    this.HomepageService.navigateToUploadPageQueued= false;
    this.bbAllImageURL.next('');
    this.router.navigate([routeUrls.upload]);
    sessionStorage.removeItem(sessionStorageObject.packageCategory);
    sessionStorage.removeItem(sessionStorageObject.isMultiLingualArtwork);
    this.HomepageService.clearSessionValue();
  }
  public resetQueued(): void {
    this.resetLID();
    this.resetArt();
    this.resetResults();
    this.resetBRRules();
    this.resetPDF_XFD();
    this.boundingBoxState.next(new BoundingBoxState());
    this.pdfFileUrl = '';
    this.xdfData = '';
    this.xfd_Data.next('');
    this.graypdfURL = ''
    this.HomepageService.adhocProjectName = ''
    this.HomepageService.projectAuther = '';
    this.HomepageService.manualArtworkProgress = false;
    this.HomepageService.navigatingFromDashboard = false;
    this.HomepageService.disableDeleteButton = true;
    this.HomepageService.resultPreviousButton = true;
    this.HomepageService.alldoneSentAWHButton = false;
    this.HomepageService.clearSessionValue();
    this.bbAllImageURL.next('');
    this.HomepageService.lidFileName=[];
    this.router.navigate([routeUrls.uploadqueued]);
    sessionStorage.removeItem(sessionStorageObject.packageCategory);
    sessionStorage.removeItem(sessionStorageObject.isMultiLingualArtwork);
  }

  public resetQueuedOnReload(): void {
    this.resetLID();
    this.resetArt();
    this.resetResults();
    this.resetBRRules();
    this.resetPDF_XFD();
    this.boundingBoxState.next(new BoundingBoxState());
    this.pdfFileUrl = '';
    this.xdfData = '';
    this.xfd_Data.next('');
    this.graypdfURL = ''
    this.HomepageService.adhocProjectName = ''
    this.HomepageService.projectAuther = '';
    this.HomepageService.manualArtworkProgress = false;
    this.HomepageService.navigatingFromDashboard = false;
    this.HomepageService.disableDeleteButton = true;
    this.HomepageService.resultPreviousButton = true;
    this.HomepageService.alldoneSentAWHButton = false;
    this.bbAllImageURL.next('');
    this.HomepageService.clearSessionValue();
    this.HomepageService.lidFileName =[];
  }
  graypdfURL = '';

  /**
   * 
   * function is used to create the required objects for the bounding boxes based on the model response from the 
   * "bbALL" or ”new_bbALL” file data.
   * @param data 
   * @param url 
   * 
   */
  mappingBoundingBoxes(data, url) {
    this.bbAllImageURL.next(url);
    this.boundingBoxState.next(new BoundingBoxState());
    const bbState = this.boundingBoxState.value;
    if (bbState.getFetchStatus() === FetchStatus.notStarted) {
      bbState.setFetchStatus(FetchStatus.fetching);
    }
    this.bbAllSupportedLanguage = (data.supported_language)?(data.supported_language):[];
    this.bbAllComponents = data.all_components?JSON.parse(data.all_components):[];
    this.bbAllSupportedProducts = (data.supported_product_name)?(data.supported_product_name):[];
    let bb_data = JSON.parse(data.bounding_boxes)
    this.bbAllRoiIdObject=[];
    if (this.bbAllSupportedProducts.length === 1) {
      if (this.bbAllSupportedProducts[0] === componentProductLanguage.nonVarietyPackProduct) {
        this.isProductNameVisible = false;
        this.isProductNameAvailable = true;
      }
      else {
        this.isProductNameVisible = true;
        this.isProductNameAvailable = true;
      }
    }
    else if (this.bbAllSupportedProducts.length > 1) {
      this.isProductNameVisible = true;
      this.isProductNameAvailable = true;
    }
    else {
      this.isProductNameVisible = false;
      this.isProductNameAvailable = false;
    }
    for (let i = 0; i < bb_data.length; i++) {
      if (bb_data[i].prediction[0]) {
        var conpred = [];
        if (bb_data[i].prediction[0].box_refinement != undefined && bb_data[i].prediction[0].box_refinement != null &&
          bb_data[i].prediction[0].roi_id != undefined && bb_data[i].prediction[0].roi_id != null) {
          this.isRoiIdAvailable=true;
          for (let j = 0; j < bb_data[i].prediction.length; j++) {
            conpred.push(
              [
                {
                  x: bb_data[i].prediction[j].box.x1,
                  y: bb_data[i].prediction[j].box.y1, 
                  type: bb_data[i].prediction[j].iconNames,
                  lang: bb_data[i].prediction[j].lang,
                  type_name : bb_data[i].bbType,
                  ...(this.isProductNameAvailable && {product_name : bb_data[i].prediction[j].product_name}),
                  box_refinement: bb_data[i].prediction[j].box_refinement,
                  roi_id: Number(bb_data[i].prediction[j].roi_id)
                },
                {
                  x: bb_data[i].prediction[j].box.x2,
                  y: bb_data[i].prediction[j].box.y2,
                  type: bb_data[i].prediction[j].iconNames,
                  lang: bb_data[i].prediction[j].lang,
                  type_name : bb_data[i].bbType,
                  ...(this.isProductNameAvailable && {product_name : bb_data[i].prediction[j].product_name}),
                  box_refinement: bb_data[i].prediction[j].box_refinement,
                  roi_id: Number(bb_data[i].prediction[j].roi_id)
                }
              ]
            );
            this.bbAllRoiIdObject.push(
              {
                roi_id: Number(bb_data[i].prediction[j].roi_id),
                type: bb_data[i].prediction[j].iconNames
              }
            );
          }
        }
        else {
          this.isRoiIdAvailable=false;
          for (let j = 0; j < bb_data[i].prediction.length; j++) {
            conpred.push(
              [
                {
                  x: bb_data[i].prediction[j].box.x1,
                  y: bb_data[i].prediction[j].box.y1,
                  type: bb_data[i].prediction[j].iconNames,
                  lang: bb_data[i].prediction[j].lang,
                  type_name : bb_data[i].bbType,
                  ...(this.isProductNameAvailable && {product_name : bb_data[i].prediction[j].product_name})
                },
                {
                  x: bb_data[i].prediction[j].box.x2,
                  y: bb_data[i].prediction[j].box.y2,
                  type: bb_data[i].prediction[j].iconNames,
                  lang: bb_data[i].prediction[j].lang,
                  type_name : bb_data[i].bbType,
                  ...(this.isProductNameAvailable && {product_name : bb_data[i].prediction[j].product_name})
                }
              ]
            );
          }
        }
      }
      bbState.setPredictions(
        bb_data[i].iconNames,
        conpred,
        i,
        bb_data[i].colorCodes,
        bb_data[i].bbType
      );
    }
    if (this.checkBoundingBoxState(bbState)) {
      this.alertBarService.hide();
      bbState.setFetchStatus(FetchStatus.fetched);
    }
    this.boundingBoxState.next(bbState);
  }

  addNewBB(e): void {
    this.boundingBoxState.value.boundingBoxes = e.prediction;
    if (this.boundingBoxState.value.boundingBoxType.indexOf(e.type) === -1) {
      this.boundingBoxState.value.boundingBoxType.push(e.type);
      this.boundingBoxState.value.colorCodes.push(e.colorcode);
      this.boundingBoxState.value.boundingTitlename.push(e.typeName);
      this.boundingBoxState.value.AllboundingBoxes.push(e.prediction)
    }
    else {
      this.boundingBoxState.value.AllboundingBoxes.forEach((allBB) => {
        for (let bb of allBB) {
          if (bb[0].type.split("_")[0] === e.type) {
            allBB.push(e.pred)
            break;
          }
        }
      });
    }
  }

  deleteBB(e): void {
    this.boundingBoxState.value.AllboundingBoxes.forEach((allBB, i) => {
      allBB.forEach((bb, index) => {
        if (bb[0].type === e) {
          allBB.splice(index, 1);
          if (allBB.length == 0) {
            this.boundingBoxState.value.boundingBoxType.splice(i, 1);
            this.boundingBoxState.value.colorCodes.splice(i, 1);
            this.boundingBoxState.value.boundingTitlename.splice(i, 1);
            this.boundingBoxState.value.AllboundingBoxes.splice(i, 1);
          }
        }
      });
    });
  }
  pdfFileUrl = '';
  xdfData = '';
  orignalXfdData ='';

  public checkOverlayState(artwork: FileUploaded): boolean {
    return (
      artwork.overlayResponse.overlay.bb !== '' &&
      artwork.overlayResponse.overlay.IngredientLine !== '' &&
      artwork.overlayResponse.overlay.NutritionFact !== ''
    );
  }

  // SETUP ORCHESTRATOR FUNCTIONS
  public artOrchestrator(files: FileList, file_name: string, blobURL: any, projectId: any): void {
    if (files.length > 0) {
      const file = this.artworkFile.value;
      file.file = files.item(0);
      file.state = UploadState.uploading;
      this.artworkFile.next(file);
      const body = {
        file_name: file_name,
        userId: sessionStorage.getItem(sessionStorageObject.name),
        artworkAnalysisId: projectId,
        originalFileName: files[0].name
      };
      const options = {
        headers: {
          timeout: `${9999000}`
        }
      };
      this.http
        .post(`${this.API_URL}${this.ARTWORK_ORCHESTRATOR}`, body, options)
        .subscribe(
          (data: any) => {
            this.currentArtOrchestrator = data;
          },
          (error) => {
            this.AppInsightsService.logException(error);
          }
        );
    }
  }

  public lidOrchestrator(files: FileList, file_name: string[], blobURL: any, projectId: any, key: any, originalFileNameArrayForMultiLid?: any): void {
    if (files.length > 0) {
      const file = this.lidFile.value;
      // file.file = files.item(0);
      file.state = UploadState.uploading;
      let originalFileNameArray = [];
      if(originalFileNameArrayForMultiLid){
        originalFileNameArray = originalFileNameArrayForMultiLid
      }
      else {
        for(let i=0; i<files.length ; i++){
          originalFileNameArray.push(files[i].name);
        }
      }
      if (key === 'artworkAnalysisId') {
        this.lidFile.next(file);
      } else if (key === 'lidAnalysisId') {
        this.lidUploadFile.next(file)
      }
      const body = {
        file_name: file_name,
        userId:  sessionStorage.getItem(sessionStorageObject.name),
        artworkAnalysisId: projectId,
        originalFileName: originalFileNameArray
      };
      const options = {
        headers: {
          timeout: `${9999000}`
        }
      };
      this.http
        .post(`${this.API_URL}${this.LID_ORCHESTRATOR}`, body, options)
        .subscribe(
          (data: any) => {
            this.currentLidOrchestrator = data;
          },
          (error) => {
            this.AppInsightsService.logException(error);
          }
        );
    }
  }

  public compareOrchestrator(comparison): void {
    const options = {
      headers: {
        timeout: `${9999000}`
      }
    };
    this.http
      .post(
        `${this.API_URL}${this.COMPARISON_ORCHESTRATOR}`,
        comparison,
        options
      )
      .subscribe(
        (data: any) => {
          this.currentComparisonOrchestator = data;
          const tempArt = this.artworkFile.value;
          tempArt.setOverlayState(FetchStatus.fetching);
          this.artworkFile.next(tempArt);
        },
        (error) => {
          this.AppInsightsService.logException(error);
        }
      );
  }
  public bbOrchestrator(boundingBoxData): Promise<any> {
    this.boundingBoxState.next(new BoundingBoxState());
    const options = {
      headers: {
        timeout: `${9999000}`
      }
    };
    return this.http
      .post(`${this.API_URL}${this.BB_ORCHESTRATOR}`, boundingBoxData, options)
      .pipe(tap((data: any) => (this.currentBBOrchestrator = data)))
      .toPromise();
  }
  public ExtractionOrchestrator(analysisID): Observable<any> {
    const userId = sessionStorage.getItem(sessionStorageObject.name);
    return this.http
      .post(`${this.API_URL}${this.extractionOrchestrator}`,
        {
          "artworkAnalysisId": analysisID,
          "userId": userId
        });
  }

  private checkBoundingBoxState(bbState: BoundingBoxState): boolean {
    if (
      bbState.getFetchStatus() === FetchStatus.fetching &&
      hasRequiredBoundingBoxes(bbState, this.language)
    ) {
      return true;
    }
    return false;
  }

  createLinksObject(): any {
    const link_array = [
      { type: 'artwork-original', ...this.artworkFile.value.original },
      { type: 'lid-original', ...this.lidFile.value.original },
      { type: 'artwork-pdf', ...this.pdf_Xfd.value.artwork_pdf },
      { type: 'annot-xfd', ...this.pdf_Xfd.value.annot_xfdf }
    ];

    //Conditionally add artwork part overlay links
    const overlays = this.artworkFile.value.overlayResponse.overlay;
    Object.keys(overlays).forEach((key, index) => {
      if (overlays[key] && overlays[key].length > 0) {
        link_array.push({
          type: `${key}-overlay`,
          url: overlays[key],
          filename: null
        });
      }
    });

    return link_array;
  }

  generateResultsFile(results, language, xfd_data, Id): any {
    const Uistatus = "labelrightApp"
    const options = {
      headers: {}
    };
    const body = {
      results: results,
      language: language,
      xfddata: xfd_data,
      original_xfdData:this.orignalXfdData,
      userID:  sessionStorage.getItem(sessionStorageObject.name),
      links: this.createLinksObject(),
      artworkAnalysisId: Id,
      requestGenerated: Uistatus
    };
    this.http
      .post(`${this.API_URL}${this.generateResultsFileEndpoint}`, body, options)
      .subscribe((response: any) => {
        this.AppInsightsService.logEvent("Generate Result File Call Completed");
        this.resultsFileURL.next(response.results);
        this.humanAnnotationUrl.next(response.huxfd);
        this.xfdDataUpdated = (response.xfdf_data);
      },
        (error) => {
          this.AppInsightsService.logException(error);
        }
      );
  }
  // check if a message received matches any running orchestrators
  public stopArtOrch(): void {
    // Midsize Art Orch
    if (
      !!this.currentArtOrchestrator &&
      this.currentArtOrchestrator.terminatePostUri !== ''
    ) {
      const options = {
        headers: {}
      };
      this.http
        .post(
          this.currentArtOrchestrator.terminatePostUri.replace(
            'http://',
            'https://'
          ),
          options
        )
        .subscribe(
          (response) => {
            this.currentArtOrchestrator = this.emptyOrchestrator;
          },
          (error) => {
            this.currentArtOrchestrator = this.emptyOrchestrator;
            this.AppInsightsService.logException(error);
          }
        );
    }
  }
  public stopLidOrch(): void {
    if (
      !!this.currentLidOrchestrator &&
      this.currentLidOrchestrator.terminatePostUri !== ''
    ) {
      const options = {
        headers: {}
      };
      this.http
        .post(
          this.currentLidOrchestrator.terminatePostUri.replace(
            'http://',
            'https://'
          ),
          options
        )
        .subscribe(
          (response) => {
            this.currentLidOrchestrator = this.emptyOrchestrator;
          },
          (error) => {
            this.currentLidOrchestrator = this.emptyOrchestrator;
            this.AppInsightsService.logException(error);
          }
        );
    }
  }

  public selectLanguage(lang: any): void {
    const languageObject = findLanguageByCode(lang);
    this.language.next(languageObject);
  }

  public setIsUserNameSet(value: boolean) {
    this.isUserNameSet = value;
  }


}
export class OverlayData {
  overlay_type: number;
  img_overlay_url: string;
}




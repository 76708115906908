import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OktaAuthService } from 'src/app/app.service';
import { dbOrMyidm, loginResponse } from 'src/app/utils/constants';

@Component({
  selector: 'app-logout-popup',
  templateUrl: './logout-popup.component.html',
  styleUrls: ['./logout-popup.component.scss']
})
export class LogoutPopupComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<LogoutPopupComponent>,
    public oktaAuth: OktaAuthService
  ) {
    dialogRef.disableClose = true;
  }
  countdownMinutes: number;
  countdownSeconds: number;
  isChosenRouteDB: boolean;
  ngOnInit(): void {
    if (loginResponse.loginResponseFrom === dbOrMyidm.myidm) {
      this.isChosenRouteDB = false;
    } else {
      this.isChosenRouteDB = true;
    }
    if (this.data.InActive) this.startCountdown();
  }

  logout() {
    this.dialogRef.close({
      event: 'close',
      data: {}
    });
    this.oktaAuth.logout();
  }
  private startCountdown() {
    const countdown = this.data.countdownDuration; 
    const startTimestamp = performance.now();
    const updateCountdown = () => {
      const elapsedMilliseconds = performance.now() - startTimestamp;
      const remainingMilliseconds = Math.max(0, countdown - elapsedMilliseconds);

      this.countdownMinutes = Math.floor(remainingMilliseconds / 1000 / 60);
      this.countdownSeconds = Math.floor((remainingMilliseconds / 1000) % 60);

      if (remainingMilliseconds > 0) {
        requestAnimationFrame(updateCountdown);
      } else {
        this.dialogRef.close({
          event: 'close',
          data: {continue:false}
        });
      }
    };
    updateCountdown();
  }

  closeDialog(): void {
    this.dialogRef.close({
      event: 'close',
      data: {continue:true}
    });
  }
}

<div class="inner-wrapper">
  <div class="white-container">
    <div class="loader">Loading...</div>
    <p class="status-msg">{{spinnerMsg}}</p>
    <p>That’s what I like</p>
    <button *ngIf="!isExitButtonHidden" mat-stroked-button color="warn"
      [ngClass]="isUploadPage ? 'upload-cancel-button' : 'cancel-button'" (click)="cancelLoading()">
      <img src="../../../assets/images/cancel_for_loader.svg" />
      <span class="button-text" matTooltip="Go To Dashboard" #tooltip="matTooltip" [matTooltipPosition]="'right'">
        Exit Transaction
      </span>
    </button>
  </div>
</div>
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LogoutPopupComponent } from '../header/logout-popup/logout-popup.component';
import { OktaAuthService } from '../app.service';
import { TimestampConstants } from '../utils/constants';
@Injectable({
  providedIn: 'root'
})
export class InActivityService {
  private inactivityTimeout = TimestampConstants.inactivityTimeout;
  private warningThreshold = TimestampConstants.warningThreshold;
  private countdownTimer: number;

  constructor(private dialog: MatDialog, public oktaAuth: OktaAuthService) {
    this.initializeInactivityDetection();
  }
  private initializeInactivityDetection() {
    document.addEventListener('mousemove', () => this.userActivityDetected());
    document.addEventListener('keydown', () => this.userActivityDetected());
    this.startCountdown();
  }
  private startCountdown() {
    this.countdownTimer = window.setTimeout(() => {
      this.showCountdownAlert();
    }, this.inactivityTimeout - this.warningThreshold);
  }
  private showCountdownAlert() {
    const dialogRef = this.dialog.open(LogoutPopupComponent, {
      width: 500 + 'px',
      height: 200 + 'px',
      data: { countdownDuration: this.warningThreshold, InActive: true }
    });
    dialogRef.afterClosed().subscribe((ele) => {
      if(ele.data.continue){
        console.log("condition");
        this.restartCountdown();
      } else{
        console.log(ele)
        this.oktaAuth.logout();
      }
    });
  }

  private userActivityDetected() {
    this.restartCountdown();
  }
  private restartCountdown() {
    clearTimeout(this.countdownTimer);
    this.startCountdown();
  }
}

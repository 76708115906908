<div class="uploadMainDiv">
  <p
    *ngIf="
      HomepageService.adhocProjectName &&
      !HomepageService.navigatingFromDashboard
    "
    class="projectName"
  >
    Project - {{ HomepageService.adhocProjectName }}
    <span *ngIf="!HomepageService.isAutomated">
      <img src="../../../assets/images/adhocproject.svg" class="projectIcon" />
    </span>
  </p>
  <p
    *ngIf="
      resultsPageDataService.savedProjectName &&
      HomepageService.navigatingFromDashboard
    "
    class="projectName"
  >
    Project - {{ resultsPageDataService.savedProjectName }}
    <span *ngIf="!HomepageService.isAutomated && !HomepageService.isBatched">
      <img src="../../../assets/images/adhocproject.svg" class="projectIcon" />
    </span>
    <span *ngIf="HomepageService.isBatched">
      <img
        src="../../../assets/images/add-to-queue-project-icon.svg"
        class="projectIconForQueued"
      />
    </span>
  </p>
  <div class="page-container container-fluid">
    <div class="clearfix">
      <h3 class="mr-4">Results</h3>
      <span *ngIf="totalErrorcount > 0" class="header-err-label">
        {{ totalErrorcount }}
        Mismatch(es) | {{ totalAlertcount }} Alert(s)detected</span
      >

      <app-side-button-panel
        [title]="resulttitle"
        [disablePrimary]="viewSDKClient.previewRendered"
        [type]="componentType"
        description=""
        (button)="buttonClicked($event)"
      ></app-side-button-panel>
    </div>
    <p class="header-desc">
      Verify and view any errors with your upload artwork and LID
    </p>
    <div class="row no-gutters">
      <div class="" style="width: 100%">
        <div class="container-fluid">
          <!--Toggle button -->
          <div class="row">
            <div class="col-12 headers-box ingredients-box">
              <h4 class="mr-1">{{ toggleViewname }}</h4>
              <!--Visible only if the "supported_product_name" contains product names other than "Common", in “resultsALL” file data. 
              It is displayed for Variety packs and allows filtering by product name. -->          
              <mat-form-field
                appearance="fill"
                class="form-field"
                *ngIf="isVarietyPack"
              >
                <mat-select
                  [(value)]="selectedProductName"
                  disableOptionCentering
                  (selectionChange)="productNameSelected($event.value)"
                  class="selected-value"
                  matTooltip="{{selectedProductName}}"
                  #tooltip="matTooltip" [matTooltipPosition]="'right'"
                >
                  <mat-option
                    *ngFor="let option of productNameArr"
                    [value]="option"
                  >
                   {{ option }} 
                  </mat-option>
                </mat-select>
                <mat-icon matSuffix class="arror-icon"
                  >arrow_drop_down</mat-icon
                >
              </mat-form-field>
              <!--Visible only if the "supported_lang" contains more than one language, in “resultsALL” file data. 
              It is displayed for Multilingual artworks and allows filtering by language.-->
              <mat-form-field
                appearance="fill"
                class="form-field"
                *ngIf="isMultiLangauge"
              >
                <mat-select
                  [(value)]="filterSelectedLanguage"
                  disableOptionCentering
                  (selectionChange)="languageSelected($event.value)"
                >
                  <mat-option
                    *ngFor="let option of languageArr"
                    [value]="option"
                  >
                    {{ option }}
                  </mat-option>
                </mat-select>
                <mat-icon matSuffix class="arror-icon"
                  >arrow_drop_down</mat-icon
                >
              </mat-form-field>
              <div *ngIf="enableTool" class="col-3" style="float: right">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <div class="addNew-wrapper">
                    <div class="buttonwrapper">
                      <button class="resultbtn" (click)="downloadResults()">
                        <img
                          src="/assets/images/download.svg"
                          class="img-icon"
                        /><span class="button-text">PDF/XFDF</span>
                      </button>
                    </div>
                    <div class="buttonwrapper">
                      <button
                        class="resultbtn"
                        (click)="openWindow('lid-window')"
                        style="width: 102px"
                      >
                        <img
                          src="/assets/images/previewlid.svg"
                          class="img-icon"
                        /><span class="button-text">View LID</span>
                      </button>
                    </div>

                    <div class="buttonwrapper">
                      <button
                        class="resultbtn"
                        (click)="openWindow('artwork-window')"
                        style="width: 132px"
                      >
                        <img
                          src="/assets/images/viewartwork1.svg"
                          class="img-icon"
                        /><span class="button-text">View Artwork</span>
                      </button>
                    </div>

                    <div class="buttonwraper" #resultbtn >
                      <button class="resultbtn_filter" (click)="toggleFilterClick()" [ngClass]="{'activeresultbtn':selectedFilterValues.length > 0}">
                        <img
                          src="/assets/images/filterListIcon.svg"
                          class="img-icon"
                        />
                        <span class="button-text">Filter</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngIf="filterMenuOpened"
                class="filter-menu-wrapper"
                id="filtermenu"
              >
                <div class="drop-showResult"  #clickoutside>
                  <div class="filterdiv">  
                  <label *ngFor="let a of filterCategories">
                      <input type="checkbox" 
                          (change)="getSelectedValue($event)" value="{{a.name}}"  [(ngModel)]="a.isChecked"/>
                      <span class="spanText">{{a.name}}</span>
                  </label>
              </div>
                  <button type="button" *ngIf="selectedFilterValues.length > 0" class="clearfilter btn btn-secondary" (click)="cancelFilter()" >
                      <img src="../../../assets/images/filterclose.svg" /><span style="padding-top: 6px;">Clear</span> 
                  </button>
                  <button type="button" class="submitButton btn btn-primary" (click)="applyFilterselected()">Apply Filter</button>
              </div>
              </div>
            </div>

            <!-- Artwork -->
            <div class="artworkcontainer">
              <div class="row" class="artwork-div">
                <div
                  *ngIf="displayartView"
                  class="mt-2 artwork-view col-12 results-box ingredients-box"
                  [ngStyle]="{ background: !artwork_view ? 'none' : 'white' }"
                >
                  <app-artworktool
                    (showToolart)="showStyle($event)"
                    (replyadded)="commentsadded($event)"
                    (deleteAnnotation)="userIgnoreFromArtwork($event)"
                    [displaypdf]="displayartView"
                    [hummanAnnotations]="ArtworkAnnotations"
                    [selectedFilterLabel]="activeFilterLabel"
                    (selectedAnnotation) = "navigateToSelectedCard($event)"
                  ></app-artworktool>
                </div>
              </div>
              <!-- Results        -->
              <div >
                <!-- Generic -->
                <div class="row" class="report-div" #cards>
                  <ng-container
                    *ngFor="let comresults of comparsionResults; let i = index" 
                    >
                    <div class="col-12 results-box" *ngIf="comresults.comResultsFiltered.length > 0" [class.highlight]="selectedItemId.includes(i)" >
                      <div class="clearfix box-header">
                        <h4 class="mr-1">
                          <span
                            [ngStyle]="{
                              'background-color': comresults.colorCodes,
                              padding: '5px'
                            }"
                            >{{ comresults.errorHighlights }}</span
                          >
                          {{ comresults.resultsTitle }}
                        </h4>
                        <!--For Variety Packs, the product name is mentioned alongside each component in the table.
                        For multilingual artworks, the language is specified in the respective row. -->
                        <div class = "prodLangCombo" *ngIf="(isVarietyPack && isProductNameAvailable)|| isMultiLangauge">
                          <span [ngClass]="isMultiLangauge ? 'minChars' : 'onlyProdChars'" matTooltip="{{comresults.product_Name}}" 
                          #tooltip7="matTooltip" [matTooltipPosition]="'right'" *ngIf="(isVarietyPack && isProductNameAvailable && comresults.product_Name)">{{
                            comresults.product_Name
                          }}</span
                          ><span class = "hightClass" *ngIf="isMultiLangauge && isVarietyPack && isProductNameAvailable"> | </span
                          ><span [ngClass] = "(isVarietyPack && isProductNameAvailable) ? 'minLangChars' : 'onlyLangChars'" *ngIf="isMultiLangauge && comresults.Lang" matTooltip="{{mappingLanguages(comresults.Lang)}}" 
                          #tooltip8="matTooltip" [matTooltipPosition]="'left'">{{languageWrapping(comresults.Lang) }}</span>
                        </div>
                      </div>
                      <div style="margin-left: 10px">
                        <span
                          *ngIf="
                            comresults.comErrCount > 0 &&
                            activeFilterLabel !== 'Match'
                          "
                          class="box-err-label error-span"
                          ><span
                            *ngIf="
                              comresults.comFilteredErrCount !==
                              comresults.comErrCount
                            "
                            >{{ comresults.comFilteredErrCount }} of </span
                          >{{ comresults.comErrCount }} error(s) detected</span
                        >
                        <span
                          *ngIf="
                            comresults.comAlertCount > 0 &&
                            activeFilterLabel !== 'Match'
                          "
                          class="box-alert-label"
                        >
                          {{ comresults.comAlertCount }} Alert(s) detected
                        </span>
                      </div>
                      <!--The report view consists of a table displaying the results for all detected components sent from the verify page.-->
                      <table
                        cellpadding="0"
                        cellspacing="0"
                        width="100%"
                        cols="4"
                        style="table-layout: fixed"
                      >
                        <colgroup>
                          <col class="col-result" />
                          <col class="col-lid" />
                          <col class="col-artwork" />
                          <col class="col-buttons" />
                        </colgroup>
                        <tr class="tr-header">
                          <th class="text-center result-head">Result</th>
                          <th
                            [ngClass]="getRTL()"
                            *ngIf="comresults.spellcheck === 'lid'"
                          >
                            LID
                          </th>
                          <th
                            [ngClass]="getRTL()"
                            *ngIf="comresults.spellcheck === 'spellcheck'"
                          >
                            Spellcheck
                          </th>
                          <th [ngClass]="getRTL()">Artwork</th>
                          <th class="text-center"></th>
                        </tr>

                        <ng-container
                          *ngFor="
                            let res of comresults.comResultsFiltered;
                            let i = index
                          "
                          id="innerdiv"
                        >
                          <tr
                            class="{{
                              resultsCategories[res.type]
                            }} result-table-row"
                            [class.tr-notFound] ="res.type === 'Not Detected'"
                            [class.tr-nonmatch]="res.type === 'MisMatch'"
                            [class.tr-alert]="res.type === 'Alert'"
                            [class.with-comments]="
                              res.comments.commentText != ''
                            "
                          >
                          <!-- Indicates the result type, which can be Match, Mismatch, Alert, or Not Detected-->
                            <td class="restype-span">
                              <span
                                [tooltip]="res.tooltip"
                                placement="top"
                                show-delay="500"
                              >
                                {{ res.type }}
                              </span>

                              <span *ngIf="!!res.occ"> ({{ res.occ }})</span>
                            </td>
                            <!--Depending on the Spellcheck flag, this column displays either the LID or Spellcheck information, 
                            containing the innerHTML sent as "comp_html".-->
                            <td
                              [innerHTML]="displayLidCorrect(res,comresults) | safeHtml"
                              [ngClass]="getRTL() + ' lid-data'"
                            ></td>
                            <!--Displays the innerHTML sent as "aw_html" for the corresponding component.-->
                            <td
                              [ngClass]="getRTL() + ' artwork-data'"
                              [innerHTML]="displayArtworkOrLid(res) | safeHtml"
                            ></td>
                            <td>
                              <div class="table-buttons">
                                <ng-container *ngIf="!(res.type === 'Match' || res.type === 'Not Detected')">
                                  <a
                                    *ngIf="!res.corrected"
                                    class="swap-icon"
                                    tooltip="Swap Result"
                                    placement="left"
                                    show-delay="500"
                                    (click)="
                                      markAsCorrect(
                                        res,
                                        comresults.resultsTitle,
                                        fromReport
                                      )
                                    "
                                  ></a>
                                  <a
                                    *ngIf="res.corrected"
                                    class="undo-icon"
                                    tooltip="Undo"
                                    placement="left"
                                    show-delay="500"
                                    (click)="
                                      undoCorrection(
                                        res,
                                        comresults.resultsTitle
                                      )
                                    "
                                  ></a>
                                </ng-container>
                              </div>
                              <a
                                *ngIf="res.comments.commentText != ''"
                                class="comment-icon"
                                tooltip="Comment"
                                placement="left"
                                show-delay="500"
                                (click)="
                                  res.comments.commentOpened =
                                    !res.comments.commentOpened
                                "
                              ></a>
                            </td>
                          </tr>
                          <tr
                            class="comment-row"
                            *ngIf="res.comments.commentOpened"
                          >
                            <td class="comment-row-header">Comments</td>
                            <td colspan="3">
                              <div
                                class="comment-textbox"
                                [innerHTML]="res.comments.commentText"
                              ></div>
                            </td>
                          </tr>
                        </ng-container>
                      </table>
                    </div>
                  </ng-container>
                  
                  <!--Following the results table, a report for business rules validation is displayed. 
                  This report is obtained from the file data of “BRrules” or “new_BRrules”. -->

                  <div *ngIf="BBrules" class="row">
                    <div class="col-12 results-box netcontent-box">
                      <div class="clearfix box-header">
                        <h4 class="mr-1">
                          <span class="init-label">BR</span> Regulatory Rules Validation Report
                        </h4>
                      </div>
                      <table
                        cellpadding="0"
                        cellspacing="0"
                        width="100%"
                        style="table-layout: fixed"
                      >
                        <colgroup>
                          <col class="col-product" *ngIf="brMultiProduct"/>
                          <col class="col-lid" />
                          <col class="col-artwork" />
                          <col class="col-buttons" />
                        </colgroup>
                        <tr class="tr-header">
                          <th class="text-center" *ngIf="brMultiProduct" >Product</th>
                          <th class="text-center">Rule</th>
                          <th class="text-center">Result</th>
                          <th class="text-center"></th>
                        </tr>

                        <ng-container *ngFor="let res of Brules;let i = index">
                          <tr
                            class="{{
                              BRCategories[res.status]
                            }} result-table-row"
                            [class.with-comments]="
                              res.comments.commentText != ''
                            "
                          >
                          <td *ngIf="brMultiProduct"> 
                            <p class="brProduct"  matTooltip="{{res.product_name}}"
                            #tooltip="matTooltip" [matTooltipPosition]="'above'">{{res.product_name}}</p>
                            <p class="brProduct" matTooltip="{{languageWrapping(res.language)}}"
                            #tooltip="matTooltip" [matTooltipPosition]="'above'">[{{languageWrapping(res.language)}}]</p>
                          </td>
                            <td [ngClass]="' lid-data'">
                              {{ res.Rule }}
                            </td>
                            <td [ngClass]="' artwork-data'">
                              {{ res.result }}
                            </td>
                            <td>
                              <div class="table-buttons">
                                <a
                                  class="comment-icon"
                                  (click)="
                                    res.comments.commentOpened =
                                      !res.comments.commentOpened
                                  "
                                ></a>
                              </div>
                            </td>
                          </tr>
                          <tr
                            class="comment-row"
                            *ngIf="res.comments.commentOpened"
                          >
                            <td class="comment-row-header">Comments</td>
                            <td colspan="2">
                              <textarea
                                [value]="res.comments.commentText"
                                class="comment-textbox"
                                rows="3"
                                cols="30"
                                (change)="onCommentChange($event, res, i)"
                              ></textarea>
                            </td>
                          </tr>
                        </ng-container>
                      </table>
                    </div>
                  </div>

                  <!-- END -->
                </div>
                <!-- END -->

                <!-- BRrules -->

                <!-- Added -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Opens a modal containing all component types from “overlayALL” or ”new_overlayALL” file, serving as links to 
    load corresponding images. For Variety Packs, a product filter is available in the modal. For multilingual artworks, 
    a language filter is provided. The component type list dynamically updates based on chosen filter values.-->
    <app-window id="artwork-window">
      <div class="artwork-display-container">
        <div class="row h-100">
          <div class="col-9 modal-img" #modalImgWrap [ngStyle]="imageParentClass">
            <img
              #modalImg
              [ngStyle]="modalImgWrapCss"
              class="artwork-test-image"
              [src]="preview"
              alt=""
            />
            <br />
          </div>
  
          <div class="col-3 pt-5" class ="side-options-container">
            <div class="side-options dropdowns">
                <mat-accordion>
                  <mat-expansion-panel hideToggle class="mat-elevation-z0" #productPanel *ngIf="isVarietyPack"
                  (opened)="hideTooltip()">
                    <mat-expansion-panel-header class="panel-header"
                    (mouseover)="showTooltip()"
                    (mouseenter)="showTooltip()"
                    (mouseout)="hideTooltip()"
                    (mouseleave)="hideTooltip()">
                      <mat-panel-title>
                        Product - {{selectedProductNameViewAW | truncate : 15}} 
                      </mat-panel-title>
                      <span class="panel-header-arrow"></span>
                    </mat-expansion-panel-header>
                    <mat-selection-list [multiple]="false" class="list-panel">
                      <mat-list-option *ngFor="let option of supportedBrand" 
                      (click)="productNameSelectedViewAW(option)"
                      [selected]="option === selectedProductNameViewAW">
                        {{ option | truncate : 18}}
                      </mat-list-option>
                    </mat-selection-list>       
              </mat-expansion-panel>
              <div *ngIf="isProductTooltipVisible" class="product-name-tooltip">{{selectedProductNameViewAW}}</div>
              <mat-expansion-panel hideToggle class="mat-elevation-z0" #languagePanel *ngIf="isMultiLangauge">
                <mat-expansion-panel-header class="panel-header">
                  <mat-panel-title>
                    Language - {{selectedLanguageViewAW}}
                  </mat-panel-title>
                  <span class="panel-header-arrow"></span>
                </mat-expansion-panel-header>
                <mat-selection-list [multiple]="false" class="list-panel">
                  <mat-list-option *ngFor="let option of supportedLanViewAW" 
                  (click)="languageSelectedViewAW(option)" 
                  [selected]="option === selectedLanguageViewAW">
                    {{ option }}
                  </mat-list-option>
                </mat-selection-list>       
              </mat-expansion-panel>
            </mat-accordion>
            </div>
            <div class="side-options">
            <h5>Select View</h5>
              <div *ngFor="let overlay of resultsTitleForViewAW">
                <a
                  (click)="modalSwitchImage(overlay)"
                  class="mb-3"
                  [class.selected]="overlay === selectedpreview"
                >
                  {{ overlay }}
                </a>
              </div>

              <a
                (click)="modalSwitchImage('bb')"
                class="mb-3"
                [class.selected]="'bb' === selectedpreview"
              >
                Artwork
              </a>
            </div>
            <div class="side-options">
              <a (click)="rotateImg()" class="rotate-btn mb-3">
                Rotate 90<sup>o</sup>
              </a>
            </div>
            <div class="row" style="margin-left: 25%">
              <button
                class="btn"
                (click)="zoomOut()"
                [disabled]="valuezoom <= 50"
              >
                <i class="fas fa-search-minus"></i>
              </button>
              <input
                type="range"
                [min]="50"
                [max]="1500"
                [value]="valuezoom"
                (input)="zoomChangedArt($event)"
              />
              <button
                class="btn"
                (click)="zoomIn()"
                [disabled]="valuezoom >= 1500"
              >
                <i class="fas fa-search-plus"></i>
              </button>
            </div>
            <div class="side-options">
              <div class="row" style="margin:10px;">
                <div class="legend-box match"></div>
                <div class="legend-label">Match</div>
              </div>
              <div class="row" style="margin: 10px">
                <div class="legend-box mismatch"></div>
                <div class="legend-label">Mismatch</div>
              </div>
              <div class="row" style="margin: 10px">
                <div class="legend-box"></div>
                <div class="legend-label">Not Compared</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-window>
    <!--Opens the LID file preview in a modal window. -->
    <app-window id="lid-window">
      <div class="lid-display-container">
        <div class="row divlid" style="float: right">
          <button
            class="btn"
            (click)="lidzoomOut()"
            [disabled]="valuezoomlid <= 50"
          >
            <i class="fas fa-search-minus"></i>
          </button>
          <input
            type="range"
            min="50"
            max="500"
            [value]="valuezoomlid"
            (input)="zoomLidChanged($event)"
          />
          <button
            class="btn"
            (click)="lidzoomIn()"
            [disabled]="valuezoomlid >= 500"
          >
            <i class="fas fa-search-plus"></i>
          </button>
        </div>

        <div>
          <img
            #lidImg
            [ngStyle]="{ height: lidheight, width: lidwidth }"
            class="test-image"
            [src]="lidPreview"
            alt=""
          />
        </div>
      </div>
    </app-window>
  </div>
</div>

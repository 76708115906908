<div class="main">
  <div class="wrapper">
   <div class="inner-content">
     <div *ngIf="!!title && title !=='dashboard'" class="title mb-2">{{ title }}</div>
     <div class="packageCategory" *ngIf="title === 'File Upload'">
      <mat-form-field >
        <mat-select [(value)]="selectedPackage" (selectionChange)="getselectedPackage($event.value)" 
        [disabled]="fileUploadStartedFlag || (valueForDisable && enablePackage) || (sessionPackageSelected && enablePackage)">
          <mat-option [value]="option" *ngFor="let option of PackageCategories">{{ option }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="multi-lingual-checkbox" *ngIf="title === 'File Upload'">
      <mat-checkbox [checked]="selectedMultiLingual" [color]="white" 
      (change)="setMultiLingualArtwork($event.checked)" 
      [disabled]="fileUploadStartedFlag || (valueForDisable && enablePackage) || (sessionPackageSelected && enablePackage)">
        Multi-Lingual Artwork
      </mat-checkbox>
    </div>
  </div>
    <div class="button-container">

      <div *ngIf="type!== 'Result' && !HomepageService.navigateToUploadPageQueued">
        <button [disabled]="disablePrimaryValue" [ngClass] = "disablePrimaryValue ? 'btn btn-secondary mb-3 next-button' : 'btn btn-primary mb-3 next-button'" (click)="buttonPressed('Next')">
          Next
        </button>
      </div>
      <div *ngIf="type === 'Result'">
        <button  [disabled]="disablePrimaryValue" class="btn btn-primary mb-3 save-button" (click)="buttonPressed('Next')">
          Save Result
        </button>
      </div>
      <div>
        <button *ngIf="title !== 'File Upload' && HomepageService.resultPreviousButton" (click)="buttonPressed('Back')"
          [ngClass]="type==='Result' ? 'btn btn-secondary result-back-button' : 'btn btn-secondary back-button' ">
          Previous
        </button> 
      </div>
      <div *ngIf="HomepageService.navigateToUploadPageQueued && type !== 'Result' && !HomepageService.isBatched">
        <button [disabled]="disablePrimaryValue" [ngClass] = "disablePrimaryValue ? 'btn btn-secondary mb-3 next-button' : 'btn btn-secondary mb-3 queue-button' " (click)="buttonPressed('Next')">
          Add to Queue
        </button>
      </div>

  </div>
  </div>
    <div *ngIf="!!description && description!='result'" class="description mt-4 mb-5">{{ description }}</div> 
  <ng-content></ng-content>
</div>


import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ArtworkCanvasComponent } from 'src/app/utils/artwork-canvas/artwork-canvas.component';
import { componentProductLanguage } from 'src/app/utils/constants';

@Component({
  selector: 'app-add-edit-popup',
  templateUrl: './add-edit-popup.component.html',
  styleUrls: ['./add-edit-popup.component.css']
})
export class AddEditPopupComponent implements OnInit {

  /**
  * 
  * This class handles the logic to trigger a prompt to the user to select attributes to add/edit a box on verify page.
  * 
  */

  @ViewChild(ArtworkCanvasComponent) artworkCanvas: ArtworkCanvasComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AddEditPopupComponent>,
  ) { }

  selectedBBType: any;
  selectedLang = [];
  selectedProd :any;
  isAddNewClicked: boolean;
  boundingBox: any;
  brandSelected = componentProductLanguage.nonVarietyPackProduct;
  /**
   * 
   * If Edit box button is clicked, the chosen box attributes will be passed from the parent component as default value.
   * 
   */
  ngOnInit(): void {
    this.isAddNewClicked = this.data.isAddNewClicked;
    if (!this.isAddNewClicked) {
      this.selectedLang = this.data.currentLang;
      this.selectedProd = this.data.selectedProduct;
    }
    if(!this.data.isProductVisible){
      this.selectedProd = this.brandSelected;
    }
  }
  getBBType(e) { }

  getLang(e) { }
  getProducts(product){
   this.brandSelected = product? product:this.brandSelected;
  }
  /**
   *
   * To pass selected attributes to add a box, from child to parent component
   * 
   * @param bbType 
   * @param lang 
   * 
   */
  addNewBox(bbType, lang) {
    this.dialogRef.close(
      {
        event: 'close',
        data: {
          bbType: bbType,
          lang: lang,
          product: this.brandSelected
        }
      });
  }
  /**
   * 
   * To pass selected attributes to edit a box, from child to parent component
   * @param bbType 
   * @param lang 
   * 
   */
  editBox(bbType, lang) {
    this.dialogRef.close(
      {
        event: 'close',
        data: {
          bbType: bbType,
          lang: lang,
          product:this.brandSelected
        }
      });
  }

  /**
   * It is used to close the Add/Edit pop-up whenever user wants
   */
  closeDialog(){
    this.dialogRef.close();
  }
}

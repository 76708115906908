import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LabelRightService } from '../services/label-right.service';
import { browserRefresh } from '../app.component';
import { HomepagedataService } from '../services/homepagedata.service';
import { packageCategory, sessionStorageObject } from '../utils/constants';

@Component({
  selector: 'app-side-button-panel',
  templateUrl: './side-button-panel.component.html',
  styleUrls: ['../base.scss', './side-button-panel.component.scss']
})
export class SideButtonPanelComponent {
  @Input() title: string;
  @Input() description: string;
  @Output() button = new EventEmitter<string>();
  @Input() showprevious:string;
  @Input() type : string;
  @Output() selectedPackageCategory = new EventEmitter<string>();
  @Output() selectedMultiLingualArtwork = new EventEmitter<string>();
  @Input() valueForDisable:boolean;
  @Input() fileUploadStartedFlag : boolean;
  public disablePrimaryValue: boolean;
  public  packageSelectedValue:any;
  public sessionPackageSelected : any;
  public enablePackage : boolean = true;
  public PackageCategories=[packageCategory.selectPackage];
   selectedPackage:any;
   selectedMultiLingual : boolean; 
  @Input() set disablePrimary(val: boolean) {
    this.disablePrimaryValue = val;
  }
  public browserRefresh: boolean;
  constructor(private labelRightService:LabelRightService,
    public HomepageService:HomepagedataService){
    this.packageSelectedValue='';
    this.selectedPackage='';
    this.packageSelectedValue = sessionStorage.getItem(sessionStorageObject.packageCategory);
    this.sessionPackageSelected = this.packageSelectedValue && this.packageSelectedValue!= '' ? this.packageSelectedValue:null
    this.selectedPackage = this.packageSelectedValue && this.packageSelectedValue!= '' ? this.packageSelectedValue : this.PackageCategories[0];
    this.selectedMultiLingual = JSON.parse(sessionStorage.getItem(sessionStorageObject.isMultiLingualArtwork));
    this.labelRightService.artworkFile.subscribe((artEle)=>{
      this.labelRightService.lidFile.subscribe((lidEle)=>{
       if(artEle.file == null && lidEle.file == null){
        this.enablePackage = false;
       }
       else{
        this.enablePackage =true;
       }
      })
     })
  }
  ngOnInit(){
    this.browserRefresh = browserRefresh;
    const packageTable =  JSON.parse(sessionStorage.getItem(sessionStorageObject.packageTable));
    const  region =JSON.parse(sessionStorage.getItem(sessionStorageObject.geo))?.region;
    const category =JSON.parse(sessionStorage.getItem(sessionStorageObject.geo))?.category;
    const categoryTable =  packageTable.find(x=>x.region === region);
    const packageArray = categoryTable.listCategoryWithPackages.find(x=>x.category === category);
    this.PackageCategories = [... this.PackageCategories, ...packageArray.packageType]
    sessionStorage.setItem(sessionStorageObject.packageCategory , this.selectedPackage);
    sessionStorage.setItem(sessionStorageObject.isMultiLingualArtwork , JSON.stringify(this.selectedMultiLingual));
    this.selectedMultiLingualArtwork.emit(JSON.parse(sessionStorage.getItem(sessionStorageObject.isMultiLingualArtwork)));
  }
  get disablePrimary(): boolean {
    return this.disablePrimaryValue;
  }

  buttonPressed(button: string): void {
    this.button.emit(button);
  }
  getselectedPackage(value){
    this.selectedPackageCategory.emit(value);
  }

  setMultiLingualArtwork(value){
    this.selectedMultiLingualArtwork.emit(value);
  }
}

/** 
 * The LabelRight application initializes data for every screen by receiving file data from the database. 
 * Data initialization is achieved through a common service named “results-page-data.service.ts”. 
 * 
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AlertBarService } from '../utils/alert-bar/alert-bar.service';
import { fileKeyObject, messagesOnScreen, projectstatus, sessionStorageObject } from '../utils/constants';
import { UploadState } from '../utils/utils';
import { HomepagedataService } from './homepagedata.service';
import { LabelRightService } from './label-right.service';

@Injectable({
  providedIn: 'root'
})
export class ResultsPageDataService {
  public data = {};
  public OverLayData = [];
  public language: any;
  public pdfXfdf: any;
  comResultsData = [];
  public artworkAnalysisId = '';
  public savedProjectName = '';
  xfdDataForAWView: any;
  public original_Xfd = '';
  javaApi = environment.javaApiUrl;
  isNewBBALLPresent = false;
  isNewResultsPresent = false;
  isNewPdfXfdPresent = false;
  isNewBrRulesPresent = false;
  isNewOverLayPresent = false;
  orchErrorMessage = '';
  isArtFullPresent = false;
  constructor(
    private http: HttpClient,
    private labelRightService: LabelRightService,
    private alertBarService: AlertBarService,
    private homepageService: HomepagedataService
  ) {}
  gettingResultsData(id): Observable<any> {
    return this.homepageService.gettingResultsData(id);
  }
  initialisingAllData(Artworkid) {
    this.gettingResultsData(Artworkid).subscribe((data: any[]) => {
      sessionStorage.setItem(sessionStorageObject.projectId, Artworkid);
      this.assaigningData(data);
    });
  }
  mappingdatatoobject(fileName, BlobUrl) {
    let data: any = {};
    data.filename = fileName;
    data.url = BlobUrl;
    return data;
  }
  mappingdatawithsastoken(fileName, BlobUrl, sastoken) {
    let data: any = {};
    data.filename = fileName;
    let url = BlobUrl.split('?')[0];
    data.url = url + '?' + sastoken;
    return data;
  }
  dataMappingforResultPageNavigation(result) {
    this.homepageService.navigateToUploadPageQueued = false;
    this.homepageService.isBatched = result.isBatched;
    this.homepageService.navigatingFromDashboard = true;
    this.artworkAnalysisId = result.artworkReviewRequestId;
    this.savedProjectName = result.projectName;
    this.homepageService.projectAuther = result.createdBy;
    sessionStorage.setItem(sessionStorageObject.packageCategory,result.selectedPackage ? result.selectedPackage : result.packageCategory);
    sessionStorage.setItem(sessionStorageObject.isMultiLingualArtwork, JSON.stringify(result.isMultiLingualArtwork ? result.isMultiLingualArtwork : false));
    this.homepageService.disableDeleteButton = false;
    this.homepageService.isAutomated = result.isAutomated;
    if (
      result.isAutomated &&
      (result.status === projectstatus.ResultsGenQualified ||
        result.status === projectstatus.ResultsMoved ||
        result.status === projectstatus.ResultsNotified ||
        result.status === projectstatus.ResultsNotificationError ||
        result.status === projectstatus.ResultsNotificationTimedout)
    ) {
      this.homepageService.resultPreviousButton = false;
    } else if (
      result.isAutomated &&
      result.status === projectstatus.ResultsGenNotQualified
    ) {
      this.homepageService.alldoneSentAWHButton = true;
    }
  }
  OrchErrorDisplay(data) {
    if (
      this.orchErrorMessage !== data.details &&
      data.modifiedTime >= data.createdTime
    )
      this.alertBarService.show(messagesOnScreen.somethingWentWrongMessage);
    this.orchErrorMessage = data.details;
  }
  assaigningData(data) {
    this.isNewBBALLPresent = false;
    this.isNewResultsPresent = false;
    this.isNewPdfXfdPresent = false;
    this.isNewBrRulesPresent = false;
    this.isNewOverLayPresent = false;
    for (let element of data) {
      if (element.fileKey == fileKeyObject.newBbAll) {
        this.isNewBBALLPresent = true;
      }
      if (element.fileKey == fileKeyObject.newResultsAll) {
        this.isNewResultsPresent = true;
      }
      if (element.fileKey == fileKeyObject.artFull) {
        this.isArtFullPresent = true;
      }
      if (element.fileKey === fileKeyObject.newBrRules) {
        this.isNewBrRulesPresent = true;
      }
      if (element.fileKey === fileKeyObject.newpdfXfd) {
        this.isNewPdfXfdPresent = true;
      }
      if (element.fileKey === fileKeyObject.newOverlayAll) {
        this.isNewOverLayPresent = true;
      }
    }
    data.forEach((element) => {
      if (element.fileKey == fileKeyObject.lidPreview) {
        const lidData = this.labelRightService.lidFile.value;
        lidData.preview = this.mappingdatatoobject(
          element.fileName,
          element.blobUrl
        );
        this.labelRightService.lidFile.next(lidData);
      } else if (element.fileKey == fileKeyObject.lidOriginal) {
        var modifiedFile = new File([], element.fileName, {
          type: 'application/pdf'
        });
        const file = this.labelRightService.lidFile.value;
        file.file = modifiedFile;
        file.state = UploadState.uploaded;
        file.original = this.mappingdatatoobject(
          element.fileName,
          element.blobUrl
        );
        this.labelRightService.lidFile.next(file);
      } else if (
        element.fileKey == fileKeyObject.artOriginal &&
        this.isArtFullPresent &&
        this.homepageService.navigatingFromDashboard
      ) {
        var modifiedFile = new File([], element.fileName, {
          type: 'application/pdf'
        });
        const file = this.labelRightService.artworkFile.value;
        file.file = modifiedFile;
        file.state = UploadState.uploaded;
        file.original = this.mappingdatatoobject(
          element.fileName,
          element.blobUrl
        );
        this.labelRightService.artworkFile.next(file);
      } else if (
        element.fileKey == fileKeyObject.artMid &&
        this.isArtFullPresent &&
        this.homepageService.navigatingFromDashboard
      ) {
        const ArtData = this.labelRightService.artworkFile.value;
        ArtData.state = UploadState.uploaded;
        ArtData.preview = this.mappingdatatoobject(
          element.fileName,
          element.blobUrl
        );
        this.labelRightService.artworkFile.next(ArtData);
      } else if (
        element.fileKey == fileKeyObject.artOriginal &&
        !this.homepageService.navigatingFromDashboard
      ) {
        var modifiedFile = new File([], element.fileName, {
          type: 'application/pdf'
        });
        const file = this.labelRightService.artworkFile.value;
        file.file = modifiedFile;
        file.state = UploadState.uploaded;
        file.original = this.mappingdatatoobject(
          element.fileName,
          element.blobUrl
        );
        this.labelRightService.artworkFile.next(file);
      } else if (
        element.fileKey == fileKeyObject.artMid &&
        !this.homepageService.navigatingFromDashboard
      ) {
        const ArtData = this.labelRightService.artworkFile.value;
        ArtData.state = UploadState.uploaded;
        ArtData.preview = this.mappingdatatoobject(
          element.fileName,
          element.blobUrl
        );
        this.labelRightService.artworkFile.next(ArtData);
      } else if (
        element.fileKey == fileKeyObject.brRules &&
        !this.resultsPagekeyCheck()
      ) {
        const tempBr = this.labelRightService.BRrules.value;
        tempBr.BRrules = element.fileData;
        this.labelRightService.BRrules.next(tempBr);
      } else if (
        element.fileKey == fileKeyObject.resultsAll &&
        !this.resultsPagekeyCheck()
      ) {
        var resultData = JSON.parse(element.fileData);
        var json = resultData.comparision ? resultData.comparision : resultData;
        var index = 0;
        this.comResultsData = [];
        this.labelRightService.resultsAllSupportedBrand =
          resultData.supported_product_name
            ? resultData.supported_product_name
            : [];
        this.labelRightService.resultsAllSupportedLang =
          resultData.supported_lang ? resultData.supported_lang : [];
        for (var key in json) {
          this.comResultsData.push({
            type: key,
            resultType: json[key].results.results
          });
          index++;
        }
        this.labelRightService.results.next(this.comResultsData);
      } else if (
        element.fileKey == fileKeyObject.newResultsAll &&
        this.resultsPagekeyCheck()
      ) {
        var updatedData = JSON.parse(element.fileData);
        this.comResultsData = [];
        let data = updatedData.comparison
          ? updatedData.comparison
          : updatedData;
        data.forEach((ele) => {
          this.comResultsData.push({
            type: ele.type,
            resultType: ele.results
          });
        });
        this.labelRightService.resultsAllSupportedBrand =
          updatedData.supported_product_name
            ? updatedData.supported_product_name
            : [];
        this.labelRightService.resultsAllSupportedLang =
          updatedData.supported_lang ? updatedData.supported_lang : [];
        this.labelRightService.results.next(this.comResultsData);
      } else if (
        element.fileKey == fileKeyObject.overlayAll
         &&!this.resultsPagekeyCheck()
      ) {
        let overlayData = JSON.parse(element.fileData);
        let sastoken = element.blobUrl.split('?')[1];
        let isProductNameAvailable = false;
        overlayData.forEach((olData) => {
          if (olData.overlay_type === 'bb') {
            if (olData.product_name) {
              isProductNameAvailable = true;
            }
          }
        });
        if (isProductNameAvailable) {
          for (let i = 0; i < overlayData.length; i++) {
            const tempArt = this.labelRightService.artworkFile.value;
            tempArt.setOverlayData(
              overlayData[i].overlay_type,
              overlayData[i].img_overlay_url + '?' + sastoken,
              overlayData[i].product_name,
              overlayData[i].lang
            );
            this.labelRightService.artworkFile.next(tempArt);
          }
        } else {
          for (let i = 0; i < overlayData.length; i++) {
            const tempArt = this.labelRightService.artworkFile.value;
            tempArt.setOverlayData(
              overlayData[i].overlay_type,
              overlayData[i].img_overlay_url + '?' + sastoken
            );
            this.labelRightService.artworkFile.next(tempArt);
          }
        }
      } else if (element.fileKey == fileKeyObject.language) {
        let langData = JSON.parse(element.fileData);
        this.labelRightService.selectLanguage(
          langData.primary_language_detected
        );
      } else if (
        element.fileKey == fileKeyObject.pdfXfd &&
        !this.resultsPagekeyCheck()
      ) {
        let pdf_XfdData = JSON.parse(element.fileData);
        let sastoken = element.blobUrl.split('?')[1];
        this.labelRightService.pdfFileUrl = pdf_XfdData['artwork_pdf'];
        this.labelRightService.xdfData = pdf_XfdData['xfdf_data'];
        this.labelRightService.xfd_Data.next(pdf_XfdData['xfdf_data']);
        const pdf = this.labelRightService.pdf_Xfd.value;
        pdf.artwork_pdf = this.mappingdatawithsastoken(
          pdf_XfdData.artwork_pdf.filename,
          pdf_XfdData.artwork_pdf.url,
          sastoken
        );
        pdf.annot_xfdf = this.mappingdatawithsastoken(
          pdf_XfdData.annot_xfdf.filename,
          pdf_XfdData.annot_xfdf.url,
          sastoken
        );
        this.labelRightService.pdf_Xfd.next(pdf);
      } else if (element.fileKey == fileKeyObject.grayPdf) {
        this.labelRightService.graypdfURL = '';
        let dataUrl = element.blobUrl;
        this.labelRightService.graypdfURL = dataUrl;
      } else if (element.fileKey == fileKeyObject.artDetect) {
        const artwork = this.labelRightService.artworkFile.value;
        artwork.full = this.mappingdatatoobject(
          element.fileName,
          element.blobUrl
        );
        this.labelRightService.artworkFile.next(artwork);
      } else if (
        element.fileKey == fileKeyObject.newBbAll &&
        this.isNewBBALLPresent
      ) {
        let sastoken = element.blobUrl.split('?')[1];
        let Data = JSON.parse(element.fileData);
        let ImageUrl = Data.image_url + '?' + sastoken;
        this.labelRightService.bbAllDataBackup = Data;
        this.labelRightService.bbAllDataBackupURL = Data.image_url;
        this.labelRightService.mappingBoundingBoxes(Data, ImageUrl);
      } else if (
        element.fileKey == fileKeyObject.bbAll &&
        !this.isNewBBALLPresent
      ) {
        let sastoken = element.blobUrl.split('?')[1];
        let Data = JSON.parse(element.fileData);
        let ImageUrl = Data.image_url + '?' + sastoken;
        this.labelRightService.bbAllDataBackup = Data;
        this.labelRightService.bbAllDataBackupURL = Data.image_url;
        this.labelRightService.mappingBoundingBoxes(Data, ImageUrl);
      } else if (element.fileKey == fileKeyObject.huXfd) {
        this.xfdDataForAWView = element.fileData;
      } else if (element.fileKey == fileKeyObject.originalPdfXfd) {
        let pdf_originalData = JSON.parse(element.fileData);
        this.original_Xfd = pdf_originalData['xfdf_data'];
      } else if (
        element.fileKey == fileKeyObject.newBrRules &&
        this.resultsPagekeyCheck()
      ) {
        const tempBr = this.labelRightService.BRrules.value;
        tempBr.BRrules = element.fileData;
        this.labelRightService.BRrules.next(tempBr);
      } else if (
        element.fileKey == fileKeyObject.newpdfXfd &&
        this.resultsPagekeyCheck()
      ) {
        let pdf_XfdData = JSON.parse(element.fileData);
        let sastoken = element.blobUrl.split('?')[1];
        this.labelRightService.pdfFileUrl = pdf_XfdData['artwork_pdf'];
        this.labelRightService.xdfData = pdf_XfdData['xfdf_data'];
        this.labelRightService.xfd_Data.next(pdf_XfdData['xfdf_data']);
        const pdf = this.labelRightService.pdf_Xfd.value;
        pdf.artwork_pdf = this.mappingdatawithsastoken(
          pdf_XfdData.artwork_pdf.filename,
          pdf_XfdData.artwork_pdf.url,
          sastoken
        );
        pdf.annot_xfdf = this.mappingdatawithsastoken(
          pdf_XfdData.annot_xfdf.filename,
          pdf_XfdData.annot_xfdf.url,
          sastoken
        );
        this.labelRightService.pdf_Xfd.next(pdf);
      } else if (
        element.fileKey == fileKeyObject.newOverlayAll &&
        this.resultsPagekeyCheck()
      ) {
        let overlayData = JSON.parse(element.fileData);
        let sastoken = element.blobUrl.split('?')[1];
        let isProductNameAvailable = false;
        overlayData.forEach((olData) => {
          if (olData.overlay_type === 'bb') {
            if (olData.product_name) {
              isProductNameAvailable = true;
            }
          }
        });
        if (isProductNameAvailable) {
          for (let i = 0; i < overlayData.length; i++) {
            const tempArt = this.labelRightService.artworkFile.value;
            tempArt.setOverlayData(
              overlayData[i].overlay_type,
              overlayData[i].img_overlay_url + '?' + sastoken,
              overlayData[i].product_name,
              overlayData[i].lang
            );
            this.labelRightService.artworkFile.next(tempArt);
          }
        } else {
          for (let i = 0; i < overlayData.length; i++) {
            const tempArt = this.labelRightService.artworkFile.value;
            tempArt.setOverlayData(
              overlayData[i].overlay_type,
              overlayData[i].img_overlay_url + '?' + sastoken
            );
            this.labelRightService.artworkFile.next(tempArt);
          }
        }
      }
    });
  }
  resultsPagekeyCheck() {
   if (
     this.isNewResultsPresent &&
     this.isNewBrRulesPresent &&
     this.isNewOverLayPresent &&
     this.isNewPdfXfdPresent
   ) {
    return true;
   }else{ 
    return false;
   }
  }
}

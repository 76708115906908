/**
 * 
 * This class serves as a central repository for declaring various object structures utilized throughout the application.
 * The priamry purpose of this class is to maintain consistency and reusability by providing a standardized format 
 * for defining object structures
 * 
 */
export class FileUploaded {
  file: File;
  mid: Version;
  full: Version;
  original: Version;
  preview: Version;
  state: UploadState;
  ocrResponse: { state: FetchStatus; data: any };
  overlayResponse: {
    state: FetchStatus;
    overlay: {
      NutritionFact: string;
      Thumbnail: string;
      NetContent: string;
      Trademark: string;
      WarningStatement: string;
      IngredientLine: string;
      BottlerAddress: string;
      ProductDescription: string;
      ExpiryDate: string;
      StorageCondition: string;
      bb: string;
      ProductStandardCode: string;
      ManufactureDate: string;
      ShelfLife: string;
      LegalName: string;
    };
    lang ? : [];
    product_name ? : string
  };
  comparisonData: string;

  constructor() {
    this.reset();
  }

  getFileName(): string {
    return !!this.file ? this.file.name : 'Empty File';
  }

  updateVersions(data): void {
    this.mid = data.mid;
    this.full = data.full;
    this.original = data.original;
    this.preview = data.preview;
  }

  getPreviewURL(): string {
    return this.preview.url;
  }
  getMidURL(): string {
    return this.mid.url;
  }
  getOriginalURL(): string {
    return this.original.url;
  }
  getFullURL(): string {
    return this.full.url;
  }

  getOCR(): { state: FetchStatus; data: any } {
    return this.ocrResponse;
  }

  getOverlay(): {
    state: FetchStatus;
    overlay: {
      NutritionFact: string;
      Thumbnail: string;
      NetContent: string;
      Trademark: string;
      WarningStatement: string;
      IngredientLine: string;
      BottlerAddress: string;
      ProductDescription: string;
      ExpiryDate: string;
      StorageCondition: string;
      bb: string;
      ProductStandardCode: string;
      ManufactureDate: string;
      ShelfLife: string;
      LegalName: string;
    };
  } {
    return this.overlayResponse;
  }

  setOCRData(ocrData): void {
    this.ocrResponse.data = ocrData;
  }

  setOCRState(ocrState): void {
    this.ocrResponse.state = ocrState;
  }

  setOverlayData(type, overlayData, product_name ? , lang ?): void {
    this.overlayResponse.overlay[type] = [overlayData , product_name , lang];
  }

  setOverlayState(overlayState): void {
    this.overlayResponse.state = overlayState;
  }
  reset(): void {
    this.file = null;
    const emptyVersion = {
      filename: '',
      url: ''
    };
    this.mid = emptyVersion;
    this.full = emptyVersion;
    this.original = emptyVersion;
    this.preview = emptyVersion;
    this.state = UploadState.empty;
    this.ocrResponse = { state: FetchStatus.notStarted, data: {} };
    this.overlayResponse = {
      state: FetchStatus.notStarted,
      overlay: {
        NutritionFact: '',
        IngredientLine: '',
        Trademark: '',
        NetContent: '',
        WarningStatement: '',
        StorageCondition: '',
        BottlerAddress: '',
        Thumbnail: '',
        ProductDescription: '',
        ExpiryDate: '',
        bb: '',
        ProductStandardCode: '',
        ManufactureDate: '',
        ShelfLife: '',
        LegalName: ''
      },
      lang : [],
      product_name : ''
    };
    this.comparisonData = '';
  }
}

export interface Version {
  filename: string;
  url: string;
}
export interface files {
  filename: string;
  url: string;
}

export enum UploadState {
  empty = 0,
  uploading,
  uploaded
}
export class pdf_xfd {
  artwork_pdf: files;
  annot_xfdf: files;
  business_rule: any;
}
export class comments {
  commentText: '';
  commentOpened: false;
}
export interface business_rules {
  Type: string;
  Name: string;
  result: string;
  display: string;
  comments: comments;
}

export interface model_response {
  bbType: any;
  prediction: any[];
  visible: boolean;
}

export interface PredictionValue {
  x: number;
  y: number;
  x1: number;
  y2: number;
}
export class compresults {
  type: string;
  resultType: any;
}


export class BoundingBoxState {
  boundingBoxes: Array<Prediction>;
  boundingBoxType = [];
  colorCodes = [];
  boundingTitlename = [];
  AllboundingBoxes = [];
  AllboundingBoxestype = [];
  boundingBoxFetchStatus: FetchStatus;

  constructor() {
    this.reset();
  }

  setPredictions(
    type: string,
    predictions: Array<Prediction>,
    index: number,
    colorvalue: string,
    Titlename: string
  ): void {
    if (predictions) {
      this.boundingBoxes = predictions;
      this.AllboundingBoxes[index] = this.boundingBoxes;
      this.boundingBoxType[index] = type;
      this.colorCodes[index] = colorvalue;
      this.boundingTitlename[index] = Titlename;
    } else {
    }
  }
  setFetchStatus(status: FetchStatus): void {
    this.boundingBoxFetchStatus = 2;
  }

  getFetchStatus(): FetchStatus {
    return this.boundingBoxFetchStatus;
  }

  reset(): void {
    this.boundingBoxFetchStatus = FetchStatus.notStarted;
  }
}

export interface Prediction {
  x: number;
  y: number;
  type?: any;
  lang?: any;
  type_name?: string;
  product_name?: any;
  box_refinement?: boolean;
  roi_id?: number;
}

export enum FetchStatus {
  notStarted,
  fetching,
  fetched
}

export interface Orchestrator {
  id: string;
  purgeHistoryDeleteUri: string;
  rewindPostUri: string;
  sendEventPostUri: string;
  statusQueryGetUri: string;
  terminatePostUri: string;
}

export const AllAnchors = [
  'top-left',
  'top-center',
  'top-right',
  'middle-left',
  'middle-right',
  'bottom-left',
  'bottom-center',
  'bottom-right'
];

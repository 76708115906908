import { Component } from '@angular/core';

import { PageLoadingService } from './page-loading.service';

@Component({
  selector: 'app-page-loading',
  templateUrl: './page-loading.component.html',
  styleUrls: ['./page-loading.component.scss']
})
export class PageLoadingComponent {
  showSpinner = false;
  spinnerMsg: string;

  constructor(private pageLoadingService: PageLoadingService) {
    this.pageLoadingService.spinnerState.subscribe((spinnerState) => {
      this.showSpinner = spinnerState.show;
      this.spinnerMsg = spinnerState.msg;
    });
  }
}


<div  *ngIf="showtool && showspinner && viewSDKClient.previewRendered" class="inner-wrapper">
  <div class="white-container">
    <div class="loader">Loading...</div>
    <p  class="status-msg">{{spinnerMsg}}</p>
    <p >That’s what I like</p>
  
  </div>
</div>
<div  id='pdf-div' class='pdf-view'></div>

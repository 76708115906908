import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { GetStartedComponent } from './get-started/get-started.component';
import { UploadPageComponent } from './upload-page/upload-page.component';
import { VerifyComponent } from './verify/verify.component';
import { AllDoneComponent } from './all-done/all-done.component';
import { ResultsComponent } from './results/results.component';
import { CallbackComponent } from './callback/callback.component';
import { OktaAuthGuard } from './app.guard';
import { HomepageComponent } from './homepage/homepage.component';
import { ResultsAuthGuard } from './services/results-auth.guard';
import { UploadPageQueuedComponent } from './upload-page-queued/upload-page-queued.component';
import { routeUrls } from './utils/constants';

const routes: Routes = [
  { path: '', redirectTo: routeUrls.getstarted, pathMatch: 'full' },
  { path: 'home', component: CallbackComponent },
  {
    path: routeUrls.getstarted,
    component: GetStartedComponent,
    canActivate: [OktaAuthGuard]
  },
  {
    path: routeUrls.upload,
    component: UploadPageComponent,
    canActivate: [OktaAuthGuard]
  },
  {
    path: routeUrls.verify, 
    component: VerifyComponent,
    canActivate: [OktaAuthGuard]
  },
  {
    path: routeUrls.alldone,
    component: AllDoneComponent,
    canActivate: [OktaAuthGuard]
  },
  {
    path: routeUrls.results,
    component: ResultsComponent,
    canActivate: [OktaAuthGuard, ResultsAuthGuard]
  },
  {
    path: routeUrls.dashboard,
    component: HomepageComponent,
    canActivate: [OktaAuthGuard]
  },
  {
    path: routeUrls.uploadqueued,
    component: UploadPageQueuedComponent,
    canActivate: [OktaAuthGuard]
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: [OktaAuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div class="dashboardpageContainer">
<header class ="dashboardHeader">
  <app-header (changeinOption)="changeintabledata($event)"></app-header>
  <app-alert-bar></app-alert-bar> 
</header>
<div id="page-blocker" *ngIf="!(inprogressData && reviewedData)">
  <app-loading-spinner [spinnerMsg]="spinnerMsg"></app-loading-spinner>
</div>
<div class="maindiv" *ngIf="inprogressData && reviewedData ">
  <button mat-flat-button color="warn" class="queuedButton" (click)="newQueuedReview()"><span> <img src="../../../assets/images/add-to-queue-small.svg" /></span> New Queued Review</button>
  <button mat-flat-button color="warn" class="adhocButton" (click)="newAdhocReview()"><span> <img src="../../../assets/images/power.svg" /></span> New ad hoc Review</button>
  <div class="tabsDiv">
    <!-- related to lidrequest Tab -->
    <!-- <svg style="margin-top:2px;" [ngClass]="lidtable ? 'tabimage':''" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"
      height="30px" viewBox="0 0 24 24" width="24px" fill="#D8D8D8">
      <g>
        <rect fill="none" height="24" width="24" />
        <g>
          <path
            d="M19,5v14H5V5H19 M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3L19,3z" />
        </g>
        <path d="M14,17H7v-2h7V17z M17,13H7v-2h10V13z M17,9H7V7h10V9z" />
      </g>
    </svg>
    <button class="tablabels " (click)="labellidselect()" [ngClass]="lidtable ? 'activeButton':''">

      <span class="tabheader">LID Requests</span>

    </button><span [ngClass]="lidtable ? 'requestCount':'countdisableclass'">({{lidRequestData.length}})</span> -->
 <!-- related to lidrequest Tab -->
    <svg style="margin-top:5px;" [ngClass]="ReviewInprogressTable ? 'tabimage':''" xmlns="http://www.w3.org/2000/svg" height="24px"
      viewBox="0 0 24 24" width="24px" fill="#D8D8D8">
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path
        d="M6 2v6h.01L6 8.01 10 12l-4 4 .01.01H6V22h12v-5.99h-.01L18 16l-4-4 4-3.99-.01-.01H18V2H6zm10 14.5V20H8v-3.5l4-4 4 4zm-4-5l-4-4V4h8v3.5l-4 4z" />
    </svg>
    <button class="tablabels" [ngClass]="ReviewInprogressTable ? 'activeButton':''" (click)="labelinprogressselect()">
      <span class="tabheader">Review In Progress</span>
    </button><span [ngClass]="ReviewInprogressTable ? 'requestCount':'countdisableclass'">({{inprogressData.length}})</span>

    <svg style="margin-top:3px;" [ngClass]="Reviewedtable ? 'tabimage':''" xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 24 24"
      width="30px" fill="#D8D8D8">
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
    </svg>

    <button class="tablabels" [ngClass]="Reviewedtable ? 'activeButton':''" (click)="labelreviewdselect()">
      <span class="tabheader">Reviewed</span>
    </button><span [ngClass]="Reviewedtable ? 'requestCount':'countdisableclass'">({{reviewedData.length}})</span>

  </div>
  <div >
    <!-- <app-dashboardtable *ngIf="lidtable"  [firstTabdata] ="lidRequestData" [tabidentity]="firsttab" ></app-dashboardtable> -->
    <app-dashboardtable *ngIf="ReviewInprogressTable"  [firstTabdata] ="inprogressData" [tabidentity]="secondtab" ></app-dashboardtable>
    <app-dashboardtable *ngIf="Reviewedtable" [firstTabdata] ="reviewedData" [tabidentity]="thirdtab" ></app-dashboardtable>
  </div>
</div>
</div>
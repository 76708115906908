/**
 * This class manages the display and interaction of the login functionality in the header 
 *
 */


import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { OktaAuthService } from '../app.service';
import { Router } from '@angular/router';
import { PageLoadingService } from '../utils/page-loading/page-loading.service';
import { LabelRightService } from '../services/label-right.service';
import { HomepagedataService } from '../services/homepagedata.service';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { categoryAndModelCategory, dbOrMyidm, loginResponse, messagesOnScreen, regionObject, routeUrls, sessionStorageObject, userRoles } from '../utils/constants';
import { LogoutPopupComponent } from './logout-popup/logout-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public userName: string;
  public email: string;
  isLoading = true;
  locations = [];
  selectedLocation: any;
  public anycondition = false;

  @Output() changeinOption = new EventEmitter<any>();
  constructor(
    public oktaAuth: OktaAuthService,
    private cdref: ChangeDetectorRef,
    public router: Router,
    private pageLoadingService: PageLoadingService,
    private labelRightService: LabelRightService,
    private homepageService: HomepagedataService,
    private cookie: CookieService,
    public dialog: MatDialog
  ) {

  }

  /**
   * Based on the region-category received from the Database or MyIDM, the list of region-category is loaded in the Header,
   * and it is made selectable only in Get-started screen or Dashboard screen. 
   * 
   */
  ngOnInit(): void {
    // Get spinner status
    this.pageLoadingService.spinnerState.subscribe((spinnerState) => {
      this.isLoading = spinnerState.show;
    });
    let name = sessionStorage.getItem(sessionStorageObject.name);
    if (name) {
      this.userName = name;
      this.labelRightService.oktaUserName = this.userName;    
    }
    let sessionData = JSON.parse(sessionStorage.getItem(sessionStorageObject.geo));
    if (sessionData) {
      let category = sessionData.category;
      let region = sessionData.region
      this.selectedLocation = region + "-" + category;
      this.locations = JSON.parse(sessionStorage.getItem(sessionStorageObject.allgeoCat));
      this.settinggeocategoryValue(this.selectedLocation);
    }
    else {
      const intervalObj = interval(2000).pipe(takeWhile(() => !this.anycondition))
        .subscribe(() => {
          this.pageLoadingService.show(messagesOnScreen.fetchingDataLoader);
          const UserData = this.cookie.get('currentUser');
          if (UserData) {
            this.userName = UserData;
            this.labelRightService.oktaUserName = this.userName;
            sessionStorage.setItem(sessionStorageObject.name, UserData);
            if (loginResponse.loginResponseFrom === dbOrMyidm.db) {
              this.homepageService.getgeoAndCategory().subscribe((response) => {
                if(response === undefined){
                  this.oktaAuth.logout();
                }
                if (response.length > 0 ) {
                  this.locations = response;
                  sessionStorage.setItem(sessionStorageObject.allgeoCat, JSON.stringify(response));
                  let category = JSON.parse(sessionStorage.getItem(sessionStorageObject.geo))?.category;
                  let region = JSON.parse(sessionStorage.getItem(sessionStorageObject.geo))?.region
                  this.selectedLocation = category && region ? region + '-' + category : this.locations[0].region + "-" + this.locations[0].category;
                  this.settinggeocategoryValue(this.selectedLocation);
                  this.anycondition = true;
                  this.pageLoadingService.hide();
                }
                if(response === null || response.length === 0 ) {
                  const dialogRef = this.dialog.open(LogoutPopupComponent, {
                    width: 500 + 'px',
                    height: 150 + 'px',
                    data: { InActive: false }
                  });
                  intervalObj.unsubscribe();
                }
              })
            }
            else {
              let myidmdataobj = [];
              if(this.labelRightService.myIDMUserData === undefined){
                this.oktaAuth.logout();
              }
              else if (this.labelRightService.myIDMUserData.length >= 1 
                && this.labelRightService.myIDMUserRoles.indexOf(userRoles.labelRightUserRole) > -1) {
                for (let i = 0; i < this.labelRightService.myIDMUserData.sort().length; i++) {
                  let reg = this.labelRightService.myIDMUserData[i].split(" - ")[0];
                  let cat = this.labelRightService.myIDMUserData[i].split(" - ")[1];
                  let modelCat = this.categoryModelCategoryMapping(reg, cat);
                  myidmdataobj.push({
                    "userId": this.labelRightService.myIDMUserEmail,
                    "region": this.labelRightService.myIDMUserData[i].split(" - ")[0],
                    "category": this.labelRightService.myIDMUserData[i].split(" - ")[1],
                    "modelCategory": modelCat
                  })
                }
                this.locations = myidmdataobj;
                sessionStorage.setItem(sessionStorageObject.allgeoCat, JSON.stringify(myidmdataobj));
                let category = JSON.parse(sessionStorage.getItem(sessionStorageObject.geo))?.category;
                let region = JSON.parse(sessionStorage.getItem(sessionStorageObject.geo))?.region
                let firstValue = category && region ? region + '-' + category : this.locations[0].region + "-" + this.locations[0].category;
                this.selectedLocation = this.labelRightService.myIDMUserDefaultValue !== '' && this.labelRightService.myIDMUserDefaultValue !== null 
                  && this.labelRightService.myIDMUserDefaultValue !== undefined ? this.labelRightService.myIDMUserDefaultValue : firstValue;
                this.settinggeocategoryValue(this.selectedLocation);
                this.anycondition = true;
                this.pageLoadingService.hide();
              }
              else if (this.labelRightService.myIDMUserData === null || this.labelRightService.myIDMUserData.length === 0 
                 || this.labelRightService.myIDMUserRoles.indexOf(userRoles.labelRightUserRole) === -1) {
                const dialogRef = this.dialog.open(LogoutPopupComponent, {
                  width: 500 + 'px',
                  height: 170 + 'px',
                  data: { InActive: false }
                });
                intervalObj.unsubscribe();
              }
            }
          }
        })
    }
  }

  /**
   * The Header contains the application name, clicking on which user will be navigated to the get-started screen. 
   * 
   */
  navigateTogetStarted() {
    this.router.navigate([routeUrls.getstarted]);
  }
  cancelCurrentOperation() {
    this.pageLoadingService.hide()
    this.pageLoadingService.updateExecute();
  }
  redirect() {
    this.labelRightService.ReviewArtwork = "get-started"
    this.router.navigate([routeUrls.getstarted]);
  }
  geoChange(val) {
    this.settinggeocategoryValue(val);
  }
  settinggeocategoryValue(type) {
    let data: any;
    let region = type.split("-")[0];
    let category = type.split("-")[1];
    data = this.locations.find(x => x.category === category && x.region === region)
    this.homepageService.setgeoCategoryData = data;
    sessionStorage.setItem(sessionStorageObject.geo, JSON.stringify(data));
    this.changeinOption.emit(data);
  }
  getOptionValue(option) {
    return option.region + "-" + option.category;
  }

  categoryModelCategoryMapping(region, category) {
    if (region === regionObject.usRegion && category === categoryAndModelCategory.socialBeverages) {
      return categoryAndModelCategory.sbeverage;
    }
    else if (region === regionObject.usRegion && category === categoryAndModelCategory.gatorade) {
      return categoryAndModelCategory.gbeverage;
    }
    else if (region === regionObject.chinaRegion && category === categoryAndModelCategory.beverages) {
      return categoryAndModelCategory.beverages;
    }
    else if (region === regionObject.chinaRegion && category === categoryAndModelCategory.snacks) {
      return categoryAndModelCategory.foods;
    }
    else if (region === regionObject.chinaRegion && category === categoryAndModelCategory.quaker) {
      return categoryAndModelCategory.quaker;
    }
    else if (region === regionObject.saudiRegion && category === categoryAndModelCategory.beverages) {
      return categoryAndModelCategory.beverages;
    }
    else if (region === regionObject.latamRegion && category === categoryAndModelCategory.beverages) {
      return categoryAndModelCategory.beverages;
    }
    else if (region === regionObject.latamRegion && category === categoryAndModelCategory.foods) {
      return categoryAndModelCategory.foods;
    }
  }
}

import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { AlertBarService } from '../../utils/alert-bar/alert-bar.service';
import { UploadState, FileUploaded } from '../../utils/utils';
import { LabelRightService } from 'src/app/services/label-right.service';
import { Router } from '@angular/router';
import { HomepagedataService } from 'src/app/services/homepagedata.service';
import { PageLoadingService } from 'src/app/utils/page-loading/page-loading.service';
import { ResultsPageDataService } from 'src/app/services/results-page-data.service';
import { AzureBlobStorageServiceService } from 'src/app/services/azure-blob-storage-service.service';
import { formatDate } from '@angular/common';
import { AppInsightsService } from 'src/app/services/app-insights.service';
import { routeUrls, packageCategory, messagesOnScreen, fileKeyObject, sessionStorageObject, keyArraysConstants, TimestampConstants, fileType, errorTypes } from 'src/app/utils/constants';
import { MatTableDataSource } from '@angular/material/table';
import { LidReplacePopupComponent } from 'src/app/upload-page/lid-replace-popup/lid-replace-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { DashboardReusableService } from 'src/app/services/dashboard-reusable.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-file-upload-queued',
  templateUrl: './file-upload-queued.component.html',
  styleUrls: ['./file-upload-queued.component.scss']
})
export class FileUploadQueuedComponent implements OnInit, OnChanges {
  @Input() images: Array<string>;
  @Input() title: string;
  @Input() firstMessage: string;
  @Input() acceptedExt: string;
  @Output() zoom = new EventEmitter<string>();
  @Input() projectId: number;
  @Input() packageCategory: string;
  @Input() isMultiLingualArtwork: boolean;
  @Output() selectedFile = new EventEmitter<boolean>();
  @Output() artlidPreview = new EventEmitter<{}>();
  @Output() fileUploadStarted = new EventEmitter<boolean>();
  @ViewChild('artworkImage', { static: false }) artworkImage: ElementRef;
  file: FileUploaded;
  hovered: boolean;
  hoveredClass: string;
  artworkUpload;
  boundingSubscription: Subscription;
  spinnerMsg: string;
  spinnerShow: boolean = false;
  filekeyCondition = false;
  artworkImageName = '';
  lidImageName = '';
  public artworkAnalysisId: string;
  public incomingPackage: string;
  uploadedLidFile = [];
  artworkFirstMessage = messagesOnScreen.dropArtworkMessage;
  public displayedColumns: string[];
  public dataSource = new MatTableDataSource<any>([]);
  lidsSubmitted = false;
  isSubmitEnabled = false;
  isAdhocProject = false;
  varietyPack = packageCategory.varietyPack;
  public isMultiLingualForTemplate : boolean;

  constructor(
    private router: Router,
    private alertBarService: AlertBarService,
    private labelRightService: LabelRightService,
    private lidrequestdataservice: HomepagedataService,
    private pageservice: PageLoadingService,
    private azureblobservice: AzureBlobStorageServiceService,
    private resultPageService: ResultsPageDataService,
    private AppInsightsService: AppInsightsService,
    public dialog: MatDialog,
    private DashboardfilterService:DashboardReusableService
  ) {
    this.dataSource.data = [];
    this.lidrequestdataservice.lidOriginalFileName = [];
  }

  ngOnInit(): void {
    this.labelRightService.resetQueuedOnReload();
    sessionStorage.setItem(sessionStorageObject.isMultiLingualArtwork , 
      (sessionStorage.getItem(sessionStorageObject.isMultiLingualArtwork) != 'null' && 
      sessionStorage.getItem(sessionStorageObject.isMultiLingualArtwork) != '' && 
      sessionStorage.getItem(sessionStorageObject.isMultiLingualArtwork) != 'undefined') ?
      sessionStorage.getItem(sessionStorageObject.isMultiLingualArtwork) : 'false' );
    this.resultPageService.original_Xfd='';
    this.resultPageService.xfdDataForAWView ='';
    this.DashboardfilterService.resetDashBoardFilter();
    this.hovered = false;
    this.hoveredClass = '';
    this.artworkUpload = 0;
    if (this.title === 'Artwork') {
      this.labelRightService.artworkFile.subscribe((artwork: FileUploaded) => {
        this.spinnerMsg = messagesOnScreen.processingArtworkLoader;
        this.file = artwork;
        if (this.file.state === UploadState.uploaded && this.file.getPreviewURL() && !this.artworkUpload) {
          const starttime = new Date().getTime();
          const img = new Image();
          img.src = this.file.getPreviewURL();
          img.onload = () => {
            const endTime = new Date().getTime();
            const timeTaken = endTime - starttime;
            this.spinnerShow = false;
            this.AppInsightsService.logEvent("BLOB URL Call For Queued Project - Artwork",
              {
                url: this.file.getPreviewURL(),
                timeTaken: timeTaken
              });
          }
          this.artworkUpload = 1;
          this.artlidPreview.emit({
            title: this.title,
            value: true
          });
        }
      });
    }
    else {
      this.labelRightService.lidFile.subscribe((lid: FileUploaded) => {
        this.spinnerMsg = messagesOnScreen.processingLidLoader;
        this.file = lid;
        if (this.file.state === UploadState.uploaded && this.file.getPreviewURL()) {
          const starttime = new Date().getTime();
          const img = new Image();
          img.src = this.file.getPreviewURL();
          img.onload = () => {
            const endTime = new Date().getTime();
            const timeTaken = endTime - starttime;
            this.spinnerShow = false;
            this.AppInsightsService.logEvent(" BLOB URL Call For Queued Project - LID",
              {
                url: this.file.getPreviewURL(),
                timeTaken: timeTaken
              });
            this.artlidPreview.emit({
              title: this.title,
              value: true
            });
          }
        }
      });
    }
    this.incomingPackage = this.packageCategory ? this.packageCategory : sessionStorage.getItem(sessionStorageObject.packageCategory);
    this.isMultiLingualForTemplate = JSON.parse(sessionStorage.getItem(sessionStorageObject.isMultiLingualArtwork));
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.incomingPackage = this.packageCategory ? this.packageCategory : sessionStorage.getItem(sessionStorageObject.packageCategory);
      this.isMultiLingualForTemplate = JSON.parse(sessionStorage.getItem(sessionStorageObject.isMultiLingualArtwork));
      this.dataSource.data = [];
      this.uploadedLidFile = [];
      this.lidrequestdataservice.lidOriginalFileName = [];
    }
  }
  /**
   * on file drop handler
   */
  onFileDropped($event): void {
    if (this.title === 'LID'){
      if(this.incomingPackage === packageCategory.varietyPack ||
        JSON.parse(sessionStorage.getItem(sessionStorageObject.isMultiLingualArtwork))) {
      this.newBatchProcess($event)
    }
    else{
      this.uploadFile($event);
    }
  }
    else{
      this.uploadFile($event);
    }

  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files): void {
    this.fileUploadStarted.emit(true);
    if (this.title === 'LID' && (this.incomingPackage === packageCategory.varietyPack ||
      JSON.parse(sessionStorage.getItem(sessionStorageObject.isMultiLingualArtwork))))
      this.newBatchProcess(files)
    else
      this.uploadFile(files);
  }
  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  uploadFile(files: FileList): void {
    this.spinnerMsg = messagesOnScreen.uploadingFileLoader;
    this.spinnerShow = false;
    this.alertBarService.hide();
    if ((this.incomingPackage == ''
      || this.incomingPackage == packageCategory.selectPackage ||
      this.incomingPackage == null) && ((sessionStorage.getItem(sessionStorageObject.packageCategory) == '') ||
        !(sessionStorage.getItem(sessionStorageObject.packageCategory)) || (sessionStorage.getItem(sessionStorageObject.packageCategory) == packageCategory.selectPackage))) {
      this.alertBarService.show(messagesOnScreen.selectPackageCategoryMessage);
    }
    else if (!this.isFileExtAccepted(files[0].name)) {
      this.alertBarService.show(messagesOnScreen.wrongFileUploadMessage);
      return;
    } else {
      this.packageCategory = this.packageCategory == '' ? sessionStorage.getItem(sessionStorageObject.packageCategory) : this.packageCategory;
      this.uploadfileArchestratorcalls(files);
    }
  }
  formatfileName(fileName: string) {
    let currentDate = formatDate(new Date(), 'yyyyMMdd HHmmss', 'en', 'GMT');
    let datepart = currentDate.substring(0, 8);
    let timepart = currentDate.substring(9, 15);
    let formattedDate = datepart + '-' + timepart + '_';
    let formatedFileName: string = '';
    let key: string = '';
    fileName = fileName.replace(/[!@#$%^&*()+={}':;",<>?/]/g, '_');
    fileName = fileName.replace('[', '_');
    fileName = fileName.replace(']', '_');
    if (this.title === 'Artwork') {
      if (fileName.includes("_ARTWORK_"))
        return fileName;
      key = 'ARTWORK_';
      formatedFileName = formattedDate + key + fileName;
    }
    else {
      if (fileName.includes("_LID_"))
        return fileName;
      key = 'LID_';
      formatedFileName = formattedDate + key + fileName;
    }
    return formatedFileName;
  }

  uploadfileArchestratorcalls(files) {
    this.isAdhocProject = false;
    this.spinnerShow = true;
    let file_name: string = this.formatfileName(files[0].name);
    let analysisId = sessionStorage.getItem(sessionStorageObject.projectId) ? sessionStorage.getItem(sessionStorageObject.projectId) : '';
    const file_title = this.title ? this.title : "LID";
    this.AppInsightsService.logEvent("Upload For Queued Project" + file_title + " file initiated", {
      title: this.title,
    });
    if (this.title === 'Artwork') {
      let sasTokenVal;
      this.lidrequestdataservice.getSasToken().subscribe(val => {
        sasTokenVal = val;
        this.fileUploadStarted.emit(true);
        this.azureblobservice.uploadDocument(sasTokenVal, files[0], file_name, this.isAdhocProject, analysisId, () => {
          if (this.router.url === routeUrls.root + routeUrls.uploadqueued) {
            this.spinnerMsg = messagesOnScreen.uploadingArtworkLoader;
            this.lidrequestdataservice.artworkFileName = file_name;
            this.lidrequestdataservice.artworkOriginalFileName = files[0].name;
            this.processingArtworkData(file_name, this.packageCategory);
          }
        })
      });
    } else {
      let sasTokenVal;
      this.lidrequestdataservice.lidFileName=[];
      this.lidrequestdataservice.lidOriginalFileName=[];
      this.lidrequestdataservice.getSasToken().subscribe(val => {
        sasTokenVal = val;
        this.fileUploadStarted.emit(true);
        this.azureblobservice.uploadDocument(sasTokenVal, files[0], file_name, this.isAdhocProject, analysisId, () => {
          if (this.router.url === routeUrls.root + routeUrls.uploadqueued) {
            this.spinnerMsg = messagesOnScreen.uploadingLidLoader;
            this.lidrequestdataservice.lidFileName.push(file_name);
            this.lidrequestdataservice.lidOriginalFileName.push(files[0].name);
            this.processingLIDData(this.lidrequestdataservice.lidFileName, this.packageCategory);
          }
        })
      });
    }
    sessionStorage.setItem(sessionStorageObject.packageCategory, this.packageCategory);
    this.AppInsightsService.logEvent("Upload For Queued Project" + file_title + " file completed", {
      title: this.title,
      filename: file_name
    });
    this.selectedFile.emit(true);
    this.filekeyCondition = false;
  }

  isFileExtAccepted(fileName): boolean {
    const acceptedExt = this.acceptedExt.split(',').map((ext) => ext.trim().toLocaleLowerCase());
    const fileExt = fileName.substr(fileName.lastIndexOf('.'));
    return acceptedExt.includes(fileExt.toLocaleLowerCase());
  }

  toggleHovered(event: string): void {
    if (event === 'entered') {
      this.hovered = true;
      this.hoveredClass = 'file-opt-visible';
    } else {
      this.hovered = false;
      this.hoveredClass = '';
    }
  }

  toggleZoom(): void {
    this.zoom.emit(this.file.getPreviewURL());
  }

  /**
   * After the files are successfully uploaded to the Blob storage, the orchestrator APIs are called. 
   * “/api/batchTrigger/artworkpreviewOrch'”: Called when artwork is uploaded successfully 
   * @param file_name 
   * @param packageCategory 
   */

  processingArtworkData(file_name, packageCategory) {
    this.fileUploadStarted.emit(true);
    this.filekeyCondition = false;
    this.lidrequestdataservice.artworkPreview(file_name, packageCategory).subscribe((data) => {
      if (data) {
        if (this.router.url === routeUrls.root + routeUrls.uploadqueued) {
          this.artworkImageName = data.img_name;
          this.pollingForFileKeys(fileType.artwork, data.img_name);
        }
      }
    });
  }

  /**
   * "/api/ batchTrigger /lidpreviewOrch'": For non-VP and non-multilingual when single Lid is uploaded successfully and 
   * for VP or multilingual projects, after multiple Lids are uploaded to the Blob storage successfully and 
   * “Submit” button is clicked. 
   * @param file_name 
   * @param packageCategory 
   */

  processingLIDData(file_name, packageCategory) {
    this.fileUploadStarted.emit(true);
    this.filekeyCondition = false;
    this.lidrequestdataservice.lidPreview(file_name, packageCategory).subscribe((data) => {
      if (data) {
        if (this.router.url === routeUrls.root + routeUrls.uploadqueued) {
          this.lidImageName = data.img_name;
          this.pollingForFileKeys(fileType.lid, data.img_name);
        }
      }
    });
  }


  reset(): void {
    this.incomingPackage = sessionStorage.getItem(sessionStorageObject.packageCategory);
    this.isMultiLingualForTemplate = JSON.parse(sessionStorage.getItem(sessionStorageObject.isMultiLingualArtwork));
    if (this.title === 'Artwork') {
      this.labelRightService.resetArt();
      this.artworkUpload = 0;
      this.artlidPreview.emit({
        title: this.title,
        value: false
      });
    } else {
      this.labelRightService.resetLID();
      this.lidrequestdataservice.lidFileName = [];
      this.pageservice.hide();
      if (this.incomingPackage === packageCategory.varietyPack ||
        JSON.parse(sessionStorage.getItem(sessionStorageObject.isMultiLingualArtwork))) {
        this.lidsSubmitted = false;
        this.artworkFirstMessage = messagesOnScreen.dropArtworkMessage;
        this.spinnerShow = false;
      }
      this.artlidPreview.emit({
        title: this.title,
        value: false
      });
      this.router.navigate([routeUrls.uploadqueued]);
    }
    this.labelRightService.artworkFile.subscribe((artEle) => {
      this.labelRightService.lidFile.subscribe((lidEle) => {
        if (artEle.file == null && lidEle.file == null) {
          this.selectedFile.emit(false);
          this.fileUploadStarted.emit(false);
        }
      })
    })
    this.incomingPackage = sessionStorage.getItem(sessionStorageObject.packageCategory);
    this.filekeyCondition = false;
  }

  fileBrowseHandlerForLid(event) {
    this.fileUploadStarted.emit(true);
    if ((this.incomingPackage == ''
      || this.incomingPackage == packageCategory.selectPackage ||
      this.incomingPackage == null) && ((sessionStorage.getItem(sessionStorageObject.packageCategory) == '') ||
        !(sessionStorage.getItem(sessionStorageObject.packageCategory)) || (sessionStorage.getItem(sessionStorageObject.packageCategory) == packageCategory.selectPackage))) {
      this.alertBarService.show(messagesOnScreen.selectPackageCategoryMessage);
    }
    else {
    this.isAdhocProject = false;
    this.file.state = 2;
    let counter = 0;
    let fileName = '';
    this.isSubmitEnabled = false;
    let analysisId = sessionStorage.getItem(sessionStorageObject.projectId) ? sessionStorage.getItem(sessionStorageObject.projectId) : '';
    for (let i = 0; i < event.length; i++) {
      this.lidrequestdataservice.lidOriginalFileName.push(event[i].name);
        fileName = '';
        let currentDate = formatDate(new Date(), 'yyyyMMdd HHmmss', 'en', 'GMT');
        let datepart = currentDate.substring(0, 8);
        let timepart = currentDate.substring(9, 15);
        let formattedDate = datepart + '-' + timepart;
        counter = 0;
        let tempName = (event[i].name.substring(0, event[i].name.lastIndexOf('.')) || event[i].name);
        let ext = (event[i].name.substring(event[i].name.lastIndexOf('.') + 1));
        fileName = tempName + '_' + formattedDate + '.' + ext;
        let updatedFile = new File([event[i]], fileName, { type: event[i].type });
        let indexToBeReplaced;
        if (this.uploadedLidFile.length > 0) {
          this.uploadedLidFile.forEach((lid, index) => {
            if (lid.name == fileName) {
              counter++;
              indexToBeReplaced = index;
            }
          });
          if (counter < 1) {
            let sasTokenVal;
            this.lidrequestdataservice.getSasToken().subscribe(val => {
              sasTokenVal = val;
              this.fileUploadStarted.emit(true);
              this.azureblobservice.uploadDocument(sasTokenVal, event[i], updatedFile.name, this.isAdhocProject, analysisId, () => {
                if (this.router.url === routeUrls.root + routeUrls.uploadqueued) {
                  this.isSubmitEnabled = true;
                }
              })
            });
            this.uploadedLidFile.push(updatedFile);
          }
          else {
            const dialogRef = this.dialog.open(LidReplacePopupComponent, {
              width: 500 + 'px',
              height: 190 + 'px',
              data: {
                fileName: fileName
              }
            });
            dialogRef.afterClosed().subscribe((result) => {
              if (result) {
                if (result.data.replace) {
                  this.uploadedLidFile[indexToBeReplaced] = event[i];
                }
                else { }
              }
            });
          }
        }
        else {
          let sasTokenVal;
          this.lidrequestdataservice.getSasToken().subscribe(val => {
            sasTokenVal = val;
            this.fileUploadStarted.emit(true);
            this.azureblobservice.uploadDocument(sasTokenVal, event[i], updatedFile.name, this.isAdhocProject, analysisId, () => {
              if (this.router.url === routeUrls.root + routeUrls.uploadqueued) {
                this.isSubmitEnabled = true;
              }
            })
          });
          this.uploadedLidFile.push(updatedFile);
        }
    }
    this.displayedColumns = ['UploadedFiles', 'CancelButton'];
    this.dataSource.data = this.uploadedLidFile;
  }
}

  onDeleteBtnClick(e, index) {
    let name = this.uploadedLidFile[index].name;
    let sasTokenVal;
    let analysisId = sessionStorage.getItem(sessionStorageObject.projectId) ? sessionStorage.getItem(sessionStorageObject.projectId) : '';
    this.lidrequestdataservice.getSasToken().subscribe(val => {
      sasTokenVal = val;
      this.azureblobservice.deleteImage(sasTokenVal, name, this.isAdhocProject, analysisId, () => { });
    })
    this.uploadedLidFile.splice(index, 1);
    this.lidrequestdataservice.lidOriginalFileName.splice(index, 1);
    this.dataSource.data = this.uploadedLidFile;
    this.uploadedLidFile = [...this.uploadedLidFile];
    this.dataSource.data = [...this.dataSource.data];
    this.lidrequestdataservice.lidOriginalFileName = [...this.lidrequestdataservice.lidOriginalFileName];
    if (this.uploadedLidFile.length < 1) {
      this.fileUploadStarted.emit(false);
      this.labelRightService.resetLID();
      this.artlidPreview.emit({
        title: this.title,
        value: false
      });
    }
  }

  submitLids(lidsData) {
    this.fileUploadStarted.emit(true);
    let lidsnames = []
    this.packageCategory = this.packageCategory == '' ? sessionStorage.getItem(sessionStorageObject.packageCategory) : this.packageCategory;
    sessionStorage.setItem(sessionStorageObject.packageCategory, this.packageCategory);
    lidsData.forEach(lidsname => {
      lidsnames.push(lidsname.name);
      this.lidrequestdataservice.lidFileName.push(lidsname.name);
    });
    this.spinnerShow = true;
    this.spinnerMsg = messagesOnScreen.uploadingLidFilesLoader;
    this.artworkFirstMessage = messagesOnScreen.dropFileMessage;
    if(this.router.url === routeUrls.root+routeUrls.uploadqueued) {
      this.processingLIDData(lidsnames, sessionStorage.getItem(sessionStorageObject.packageCategory));
      this.lidsSubmitted = true;
    }
  }
  uploadMoreLid(e) { }

  clickFile(e) { }
  
  onFileDroppedVarietyPack(e,title) {
    if(title === fileType.artwork){
      this.fileBrowseHandler(e);
    }
    else{
      if (this.incomingPackage === packageCategory.varietyPack ||
        JSON.parse(sessionStorage.getItem(sessionStorageObject.isMultiLingualArtwork))) {
          this.newBatchProcess(e);
        }
      else {
        this.fileBrowseHandlerForLid(e);
      }
    }
  }
  newBatchProcess(files) {
    this.artworkFirstMessage = messagesOnScreen.dropArtworkMessage;
    this.lidsSubmitted = false;
    this.spinnerShow = false;
    this.dataSource.data = [];
    this.uploadedLidFile = [];
    this.fileBrowseHandlerForLid(files);
  }

  /**
   * For both Artwork and Lid, we receive the preview file names from the corresponding orchestrator APIs, 
   * which are then passed as the payload of the polling API, to fetch the preview file URLs. 
   * API: “/artwork/ui/artworkReviewFilesBatchProcessing” 
   * @param artworkOrLid 
   * @param fileName 
   */
  
  pollingForFileKeys(artworkOrLid , fileName){
    interval(TimestampConstants.expiryTimeForUpload).pipe(takeWhile(() => !this.filekeyCondition))
      .subscribe(() => {
        this.lidrequestdataservice.getDataforlistenersForQueue(fileName).subscribe((data) => {
          //if (data.artworkReviewFilesErrorDTO.errorStatus === errorTypes.errorOccured) {
            //this.resultPageService.OrchErrorDisplay(data.artworkReviewFilesErrorDTO)
            //this.AppInsightsService.logException(data.artworkReviewFilesErrorDTO);
          //} else {
             if(!this.filekeyCondition) {
            if(artworkOrLid === fileType.artwork){
              let obj = [{
                blobUrl: data.blobUrl,
                fileKey: fileKeyObject.artMid,
                fileName: data.fileName
              },
              {
                blobUrl: data.blobUrl,
                fileKey: fileKeyObject.artFull,
                fileName: data.fileName
              },
              {
                blobUrl: data.blobUrl,
                fileKey: fileKeyObject.artOriginal,
                fileName: data.fileName
              }
              ];
              this.resultPageService.assaigningData(obj);
            }
            else{
              let obj = [{
                blobUrl: data.blobUrl,
                fileKey: fileKeyObject.lidPreview,
                fileName: data.fileName
              },
              {
                blobUrl: data.blobUrl,
                fileKey: fileKeyObject.lidOriginal,
                fileName: data.fileName
              }
              ]
              this.resultPageService.assaigningData(obj);
            }
            if ((data.blobUrl && data.fileName) || this.router.url !== routeUrls.root + routeUrls.uploadqueued) {
              this.filekeyCondition = true;
            }
          //}
          }
        })
      })
  }
  ngOnDestroy(): void {
    this.filekeyCondition = true;
    this.labelRightService.resetLID();
    this.labelRightService.resetArt();
  }
}

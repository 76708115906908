<div class="upload-component">
  <div *ngIf="incomingPackage!== varietyPack && !isMultiLingualForTemplate">
    <div class="d-flex justify-content-center title">{{ title }}</div>
    <div *ngIf="incomingPackage!==varietyPack && !isMultiLingualForTemplate" 
      class="upload-container container" 
      appDragNDrop
      [acceptedExt]="acceptedExt"
      (fileDropped)="onFileDropped($event)"
      (mouseenter)="toggleHovered('entered')"
      (mouseleave)="toggleHovered('exit')"
    >
        <div *ngIf="file.state == 2" class="inner-preview">
          <div class="inner-img" *ngIf="file.getPreviewURL()">
            <img #artworkImage [ngClass]="hoveredClass" src="{{file.getPreviewURL()}}"/>
          </div>
        </div>
        <div *ngIf="file.state == 2" class="inner-preview-name">
          {{file.getFileName()}}
        </div>
        <div *ngIf="file.state == 2 && hovered" class="fileOptions d-flex">
          <div class="center align-self-center">
            <div (click)="toggleZoom()" class="fileHoverOption"><img src="../../../assets/images/pepsi_zoom.svg" /></div>
            <div (click)="reset()" class="fileHoverOption" *ngIf="lidrequestdataservice.disableDeleteButton"><img src="../../../assets/images/bin.svg" /></div>
          </div>
        </div>
        <div *ngIf="spinnerShow" class="inner d-flex">
        <div class="inner-preview-uploading">
          <app-loading-spinner [spinnerMsg]="spinnerMsg"></app-loading-spinner>
        </div>
      </div>
      <div *ngIf="file.state == 0 && !spinnerShow" class="inner d-flex">
        <div class="center align-self-center">
          <div class="inner-align">
            <img *ngFor="let image of images" src="{{ image }}" />
          </div>
          <div class="inner-align">{{ firstMessage }}</div>
          <div class="inner-align textLink">Browse from computer</div>
        </div>
        <input type="file" #fileDropRef1 id="fileDropRef1" [accept]="acceptedExt" onclick="this.value = null"
          (change)="fileBrowseHandler($event.target.files)" />
      </div>
    </div>

  </div>

  <div *ngIf="(incomingPackage && incomingPackage===varietyPack) || (incomingPackage && isMultiLingualForTemplate)">
    <div class="d-flex justify-content-center title">{{ title }}</div>

    <div *ngIf="(incomingPackage && incomingPackage===varietyPack) || (incomingPackage && isMultiLingualForTemplate)" 
      class="upload-container container" 
      appDragNDrop [acceptedExt]="acceptedExt"
      (fileDropped)="onFileDroppedVarietyPack($event,title)"
      (mouseenter)="toggleHovered('entered')"
      (mouseleave)="toggleHovered('exit')"
    >
      <div *ngIf="file.state == 2 && firstMessage == artworkFirstMessage" class="inner-preview">
        <div class="inner-img" *ngIf="file.getPreviewURL()">
          <img #artworkImage [ngClass]="hoveredClass" src="{{file.getPreviewURL()}}" />
        </div>
      </div>
      <div *ngIf="file.state == 2 && firstMessage == artworkFirstMessage" class="inner-preview-name">
        {{file.getFileName()}}
      </div>

      <div *ngIf="dataSource.data.length > 0 && firstMessage != artworkFirstMessage && !spinnerShow && !lidsSubmitted">
        <div class="inner-img">
            <button (click)="fileInput.click()" class="btn btn-primary  upload-btn" [disabled]="dataSource.data.length==0 || !isSubmitEnabled">
              <span class="text-size">
                <img src="../../../assets/images/upload-lid.svg" class="img variety-lid-icon">
              </span>Upload File(s)
            </button>
          <input type="file" #fileInput [accept]="acceptedExt" (click)="$event.target.value=null"
            (change)="fileBrowseHandlerForLid($event.target.files)" style="display: none;" multiple />
          <button (click)="submitLids(dataSource.data)" class="btn btn-primary submit-btn" [disabled]="dataSource.data.length==0 || !isSubmitEnabled">
            <span class="text-size">
              <img src="../../../assets/images/submit-lid.svg" class="img1 variety-lid-icon">
            </span>Submit
          </button>
          <div class="table-container">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 lid-table">
              <ng-container matColumnDef="UploadedFiles">
                <th mat-header-cell *matHeaderCellDef></th>
                <td class="lid-file-name" mat-cell *matCellDef="let element"> {{ element.name }} </td>
              </ng-container>
              <ng-container matColumnDef="CancelButton">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element, let i = index">
                  <span>
                    <img src="../../../assets/images/delete-lid-files.svg" class="delete-lid"
                      (click)="onDeleteBtnClick($event , i)" />
                  </span>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns" class="lid-head"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="lid-row"></tr>
          </table>
        </div>
        </div>
      </div>
      <div *ngIf="file.state == 2 && hovered && firstMessage == artworkFirstMessage" class="fileOptions d-flex">
        <div class="center align-self-center">
          <div (click)="toggleZoom()" class="fileHoverOption"><img src="../../../assets/images/pepsi_zoom.svg" /></div>
          <div (click)="reset()" class="fileHoverOption" *ngIf="lidrequestdataservice.disableDeleteButton"><img
              src="../../../assets/images/bin.svg" /></div>
        </div>
      </div>
      <div *ngIf="spinnerShow" class="inner d-flex">
        <div class="inner-preview-uploading">
          <app-loading-spinner [spinnerMsg]="spinnerMsg"></app-loading-spinner>
        </div>
      </div>
      <div *ngIf="file.state == 0 && !spinnerShow && firstMessage == artworkFirstMessage" class="inner d-flex">
        <div class="center align-self-center">
          <div class="inner-align">
            <img *ngFor="let image of images" src="{{ image }}" />
          </div>
          <div class="inner-align">{{ firstMessage }}</div>
          <div class="inner-align textLink">Browse from computer</div>
        </div>
        <input type="file" #fileDropRef2 id="fileDropRef2" [accept]="acceptedExt" onclick="this.value = null"
          (change)="fileBrowseHandler($event.target.files)" />
      </div>

      <div *ngIf="file.state == 0 && !spinnerShow && firstMessage != artworkFirstMessage && dataSource.data.length == 0"
        class="inner d-flex">
        <div class="center align-self-center">
          <div class="inner-align">
            <img *ngFor="let image of images" src="{{ image }}" />
          </div>
          <div class="inner-align">{{ firstMessage }}</div>
          <div class="inner-align textLink">Browse from computer</div>
        </div>
        <input type="file" #fileDropRef3 id="fileDropRef3" [accept]="acceptedExt" onclick="this.value = null"
          (change)="fileBrowseHandlerForLid($event.target.files)" multiple />
      </div>
    </div>
  </div>
</div>
/**
 * 
 * The All-Done screen is the final stage of any transaction within the Angular app. 
 * It provides users with the option to download various files related to the transaction. 
 * It consists of a few buttons: Download PDF/XFD, Download Report, Share Result, Review Another Artwork,
 *  Report a Bug and Back. 
 * 
 * */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FileUploaded } from '../utils/utils';
import { LabelRightService } from '../services/label-right.service';
import { PageLoadingService } from '../utils/page-loading/page-loading.service';
import { HomepagedataService } from '../services/homepagedata.service';
import { ResultsPageDataService } from '../services/results-page-data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DashboardReusableService } from '../services/dashboard-reusable.service';
import { Subscription } from 'rxjs';
import { AppInsightsService } from '../services/app-insights.service';
import { fileKeyObject, messagesOnScreen, routeUrls, sessionStorageObject } from '../utils/constants';
import { CancelLoaderPopupComponent } from '../utils/cancel-loader-popup/cancel-loader-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-all-done',
  templateUrl: './all-done.component.html',
  styleUrls: ['../base.scss', './all-done.component.scss']
})
export class AllDoneComponent implements OnInit {
  artwork: FileUploaded;
  lid: FileUploaded;
  resultsFileURL: string;
  HumanAnnotationurl = '';
  isLoading = true;
  pdfSubscription;
  humandAnnotSubscription;
  resultFileSubscription:Subscription;
  dataAvailabilityForAlldone = false;
  constructor(
    private router: Router,
    private labelRightService: LabelRightService,
    private pageLoadingService: PageLoadingService,
    public HomepageService: HomepagedataService,
    private resultsPageDataService: ResultsPageDataService,
    private snackBar: MatSnackBar,
    private DashboardfilterService:DashboardReusableService,
    private AppInsightsService:AppInsightsService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.labelRightService.artworkFile.subscribe((artwork) => {
      this.artwork = artwork;
    });

    this.labelRightService.lidFile.subscribe((lid) => {
      this.lid = lid;
    });
    this.pageLoadingService.spinnerState.subscribe((spinnerState) => {
      this.isLoading = spinnerState.show;
    });

    this.resultFileSubscription = this.labelRightService.resultsFileURL.subscribe((url) => {
      this.resultsFileURL = url;
      if (this.resultsFileURL || this.resultsFileURL !=='') {
        this.AppInsightsService.logEvent("Downloaded Report - AllDone",{
          Report:this.resultsFileURL
        });
        this.pageLoadingService.hide();
      } else {
        this.updateLoadingMessage(messagesOnScreen.preparingResultsLoader);
      }
    });
    this.humandAnnotSubscription = this.labelRightService.humanAnnotationUrl.subscribe((data) => {
      this.HumanAnnotationurl = data;
      if (this.HumanAnnotationurl || this.HumanAnnotationurl !== '') {
        this.pageLoadingService.hide();
      } else {
        this.updateLoadingMessage(messagesOnScreen.preparingResultsLoader);
      }
    });

    if (this.resultsFileURL && this.HumanAnnotationurl) {
      this.pageLoadingService.hide();
    } else {
      this.updateLoadingMessage(messagesOnScreen.preparingResultsLoader);
      /**
       * 
       * If a user is on the All-Done page during a transaction and attempts to reload/refresh the page, 
       * the following logic applies: 
       * 
       * - Based on the project analysis ID stored in the session storage, files associated with that particular 
       * transaction are fetched from the database using a Java API named "/artwork/ui/artworkReviewFiles". 
       * 
       * - The project analysis ID is passed in the payload of the API request. 
       * 
       * - If the API response includes the “results” and “huxfd” file keys, the required fields for the All-Done page 
       * are set,and the page is loaded.
       * 
       * - If the required files are not available, the user receives a pop-up message indicating 
       * "Insufficient data to load the page" with an option to navigate to the Dashboard. 
       * 
       */
      if( this.router.url === routeUrls.root + routeUrls.alldone &&
        !this.dataAvailabilityForAlldone &&
         !this.labelRightService.resultsToAllDone){
          let projectId = sessionStorage.getItem(
            sessionStorageObject.projectId
          );
          if (projectId) {
            this.resultsPageDataService
              .gettingResultsData(projectId)
              .subscribe((data) => {
                let isHuXfdPresent = false;
                  let isResultsPresent = false;
                  data.forEach((file)=>{
                    if(file.fileKey === fileKeyObject.huXfd){
                      isHuXfdPresent = true;
                    }
                    if(file.fileKey === fileKeyObject.results){
                      isResultsPresent = true;
                    }
                  })
                  if(isHuXfdPresent && isResultsPresent){
                    this.HomepageService.adhocProjectName =
                    this.HomepageService.getProjectNameValue();
                    this.dataAvailabilityForAlldone = true;
                    this.resultsPageDataService.assaigningData(data);
                    this.dataInitializationForAlldone(data);
                  }
                  else{
                    this.pageLoadingService.hide();
                    const dialogForExit = this.dialog.open(CancelLoaderPopupComponent, {
                      width: 400 + 'px',
                      height: 150 + 'px'
                    });
                  }
              });
          } else {
            this.router.navigate([routeUrls.getstarted]);
          }
        }
    }
  }
  dataInitializationForAlldone(data){
    data.forEach(ele => {
      if (ele.fileKey === fileKeyObject.results) {
        this.resultsFileURL = ele.blobUrl;
      } else if (ele.fileKey === fileKeyObject.huXfd) {
       this.HumanAnnotationurl = ele.blobUrl;
      }
    });
    this.labelRightService.xfdDataUpdated = this.labelRightService.xdfData;
    this.pageLoadingService.hide();
  }

  /**
   * 
   * Clicking on this button opens the default mail client with a pre-filled subject and body. 
   * The content of the email is provided by the application. 
   * 
   */
  shareResults(): string {
    const mailto = '';
    const subject = `Pepsi Label Right results for ${this.artwork.getFileName()}`;

    const body = encodeURIComponent(
      'Hi,' +
      '\n\n' +
      `A Pepsi Label Right review was created for ${this.artwork.getFileName()} on ${this.getToday()}. You can review the results and suggested changes below:` +
      '\n\n' +
      'Download results with comments' +
      '\n' +
      this.resultsFileURL +
      '\n\n' +
      'Download original artwork' +
      '\n' +
      this.artwork.getOriginalURL() +
      '\n\n' +
      'Download XDFD' +
      '\n' +
      this.HumanAnnotationurl +
      '\n\n' +
      'Thanks for using Pepsi Label Right.' +
      '\n\n' +
      "That's what I Like!"
    );
    return `mailto:${mailto}?subject=${subject}&body=${body}`;
  }

  /**
   * 
   * This button resets all variables used in the current transaction and navigates the user back to the Upload screen. 
   * It allows users to start a new transaction seamlessly. 
   * 
   */
  reviewAnother(): void {
    this.resultsPageDataService.original_Xfd = '';
    this.resultsPageDataService.xfdDataForAWView = '';
    this.pageLoadingService.stopoperation = true;
    this.labelRightService.ReviewArtwork = 'Review';
    this.DashboardfilterService.resetDashBoardFilter();
    this.HomepageService.navigatingFromDashboard = false;
    this.labelRightService.reset();
  }
  /**
   * 
   * Back button simply takes the user to the last screen from the history list. 
   * 
   */
  back(): void {
    this.resultsPageDataService.xfdDataForAWView = this.labelRightService.xfdDataUpdated;
    this.pageLoadingService.stopoperation = true;
    this.labelRightService.resultsToAllDone = false;
    window.history.back();
  }

  /**
   * 
   * By clicking on this button, users can download a report in Excel format. 
   * Similar to the PDF/XFD download, the URL for the report is retrieved 
   * from the “generateResultsFile” response as “results”. 
   * 
   */
  downloadResults(): string {
    return this.resultsFileURL;
  }

  updateLoadingMessage(msg: string, forceShow = false): void {
    if (this.isLoading || forceShow) this.pageLoadingService.show(msg);
  }

  /**
   * 
   * Clicking on this button opens the default mail client with a pre-filled subject and body which contains 
   * project details to report a bug. The content of the email is provided by LabelRight. 
   * 
   */
  reportBug(): string {
    const mailto = 'Jingting.Hui@pepsico.com';
    const subject = `Pepsi Label Right: Someone sent you a bug report`;
    const body = encodeURIComponent(
      'Hi Jingting,' +
      '\n\n' +
      `A Label Right user has reported a bug and would like to share feedback with you, here are the details:` +
      '\n\n' +
      'File Name' +
      '\n' +
      this.artwork.getFileName() +
      '\n\n' +
      'Artwork PDF File: ' +
      '\n' +
      this.artwork.getOriginalURL() +
      '\n\n' +
      'Original LID File: ' +
      '\n' +
      this.lid.getOriginalURL() +
      '\n\n' +
      'Artwork with bounding boxes: ' +
      '\n' +
      this.artwork.overlayResponse.overlay.bb +
      '\n\n' +
      'Results: ' +
      '\n' +
      this.resultsFileURL +
      '\n\n' +
      'Additional Comments:' +
      '\n\n\n' +
      'Thanks for using Label Right' +
      '\n' +
      `That's what I like`
    );
    return `mailto:${mailto}?subject=${subject}&body=${body}`;
  }

  getToday(): string {
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    const date = new Date();

    return `${days[date.getDay()]} ${months[date.getMonth()]
      } ${date.getDate()}`;
  }

  /**
   * Clicking on this button enables users to download the Artwork PDF and XFDF file associated with the transaction. 
   * The URL for the updated XFDF file is obtained from the “generateResultsFile” response as “huxfd”. 
   * 
   */
  downloadxfdData() {
    let pdfFile='';
    this.pdfSubscription=this.labelRightService.pdf_Xfd.subscribe(async (pdf) => {
        if ((pdf.artwork_pdf.url) != undefined && (this.HumanAnnotationurl) != undefined
          && (pdf.artwork_pdf.url) != null && (this.HumanAnnotationurl) != null
          && (pdf.artwork_pdf.url) != '' && (this.HumanAnnotationurl) != '') {
          pdfFile = pdf.artwork_pdf.url;
         window.location.replace(this.HumanAnnotationurl);
          setTimeout(() => {
            window.location.replace(pdfFile);
            this.AppInsightsService.logEvent("Downloaded XFDF data - AllDone",
            {
                 xfdf:this.HumanAnnotationurl,
                 pdf:pdfFile
            });
          }, 2000);

        }
        else {
          this.snackBar.open(messagesOnScreen.filesGettingGeneratedMessage, '', {
            duration: 5000
          });
          this.AppInsightsService.logEvent("Download event for  XFDF data and PDF data failed - AllDone");
        } 
      });
  }


  sendStatustoAWH() {
    const ArtworkId = this.resultsPageDataService.artworkAnalysisId ?
      this.resultsPageDataService.artworkAnalysisId : null;
    this.HomepageService.statusChangeApi(ArtworkId).subscribe((ele) => {
      if (ele.status === 200) {
        this.snackBar.open(messagesOnScreen.xfdfAndReportReadyToBeSentToArtworkHouseMessage, '', { duration: 2 * 1000 })
        this.HomepageService.alldoneSentAWHButton = false;
        this.HomepageService.resultPreviousButton = false;
      }
    });
  }

  ngOnDestroy() {
    if(this.pdfSubscription)
      this.pdfSubscription.unsubscribe();
    if(this.humandAnnotSubscription)
      this.humandAnnotSubscription.unsubscribe();
    if(this.resultFileSubscription)
      this.resultFileSubscription.unsubscribe();
  }
}

/**
 * Upload screen is used by the user to initiate any Ad hoc transaction by uploading artwork and LID(s). 
 * It has the following components - Package Selection, Multilingual Checkbox, Artwork Upload Component, 
 * Lid Upload Component & Next button.  
 * 
 */
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router} from '@angular/router';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { HomepagedataService } from '../services/homepagedata.service';
import { LabelRightService } from '../services/label-right.service';
import { ResultsPageDataService } from '../services/results-page-data.service';
import { errorTypes, keyArraysConstants, messagesOnScreen, routeUrls, sessionStorageObject, TimestampConstants } from '../utils/constants';
import { PageLoadingService } from '../utils/page-loading/page-loading.service';
import { AppInsightsService } from '../services/app-insights.service';
import { DashboardReusableService } from '../services/dashboard-reusable.service';

@Component({
  selector: 'app-upload-page',
  templateUrl: './upload-page.component.html',
  styleUrls: ['./upload-page.component.scss']
})
export class UploadPageComponent implements OnInit {
  preview: string;
  pdfAvailable: boolean = false;
  excelAvailable: boolean = false;
  previewAvailable:boolean;
  projectId:number;
  packageCategory :string ='';
  disableValue:boolean = false;
  fileUploadStartedFlag : boolean = false;
  choosePackageCategoryMessage = messagesOnScreen.choosePackageCategoryMessage;
  dropArtworkMessage = messagesOnScreen.dropArtworkMessage;
  dropFilesMessage = messagesOnScreen.dropFileMessage;
  isMultiLingualArtwork : boolean = false;
  
  constructor(
    private router: Router,
    private labelRightService: LabelRightService,
    private pageservice:PageLoadingService,
    public HomepageService:HomepagedataService,
    public resultsPageDataService: ResultsPageDataService,
    private AppInsightsService:AppInsightsService,
    private reusableService:DashboardReusableService
  ) {} 

  ngOnInit(): void {
    this.preview = ''; 
    if(!this.labelRightService.verifyToUpload && !this.HomepageService.navigatingFromDashboard){
      this.HomepageService.clearSessionValue();
    }
    this.reusableService.filekeyConditionVerify = false;
  }
  previewImage(event)
  {
    if (event.title === 'Artwork')
    {
      this.pdfAvailable =  event.value == true ? true : false;
    }
    else
    {
      this.excelAvailable =  event.value == true ? true : false;
    }
    this.previewAvailable= this.pdfAvailable && this.excelAvailable;
    
  }
  buttonClicked(button: string): void {
    switch (button) {
      case 'Back':
        this.pageservice.stopoperation=true;
        this.router.navigate([routeUrls.getstarted]);
        this.labelRightService.ReviewArtwork="get-started"
        break;
        /**
         * "Next" button is enabled when the Artwork and Lid previews are loaded successfully based 
         * on the data received from the polling calls. Clicking on the Next button will - 
         * Trigger the Extraction orchestration API: “api/orchTrigger/extractionOrchestrator” 
         * Poll for the details required to load Verify screen: Polling is done to get the file data of 
         * ‘bbALL’ and ‘artDetect’ file keys and then the required variables for verify screen are initialized. 
         */
      case 'Next':
        this.pageservice.stopoperation=true;
        this.labelRightService.restBoundingBox();
        this.labelRightService.bbAllImageURL.next('')
        this.labelRightService.resetResults();
        this.labelRightService.resetBRRules();
        this.labelRightService.resetPDF_XFD();
        this.labelRightService.pdfFileUrl = '';
        this.labelRightService.xdfData = '';
        this.labelRightService.xfd_Data.next('')
        this.resultsPageDataService.xfdDataForAWView='';
        this.resultsPageDataService.original_Xfd = '';
        this.labelRightService.graypdfURL = '';
        this.labelRightService.uploadToVerify = true;
        if(this.pageservice.stopoperation)
        {
          let id = sessionStorage.getItem(sessionStorageObject.projectId);
          const artworkAnalysisId = id ? id :''
          this.labelRightService.ExtractionOrchestrator(artworkAnalysisId).subscribe(ele=>{
            this.labelRightService.currentExtractionOrchestrator = ele;
            let arrayKeys = keyArraysConstants.bbpgageKeys;
              setTimeout(() => {
                interval(TimestampConstants.expiryTimePeriodForVerify).pipe(takeWhile(() => !this.reusableService.filekeyConditionVerify))
                .subscribe(()=>{
                  let analysisId = sessionStorage.getItem(sessionStorageObject.projectId);             
                 this.HomepageService.getDataforlisteners(analysisId,arrayKeys).subscribe((data)=>{
                  if(data.artworkReviewFilesErrorDTO.errorStatus === errorTypes.errorOccured){
                    this.resultsPageDataService.OrchErrorDisplay(data.artworkReviewFilesErrorDTO)
                    this.AppInsightsService.logException(data.artworkReviewFilesErrorDTO);
                 }else{
                  let filterdArray = data.artworkReviewFilesDTOs.filter(ele=> arrayKeys.includes(ele.fileKey))
                  let responseFileKey = filterdArray.map(item=>{ 
                    this.AppInsightsService.logEvent("FileKey - Verify Bounding Box "+item.fileKey,{
                      "fileKey":item.fileKey
                    });
                    return item.fileKey});
                
                  arrayKeys = arrayKeys.filter(item =>!responseFileKey.includes(item) )
                  this.resultsPageDataService.assaigningData(data.artworkReviewFilesDTOs);
                  if (arrayKeys.length === 0
                       || this.router.url != routeUrls.root + routeUrls.verify){
                    this.reusableService.filekeyConditionVerify = true;
                  }
                 }
                })
                }) 
              }, 10000);
          });
          this.router.navigate([routeUrls.verify]);
        }
        break;
    }
  }

  zoomedIn(event: any): void {
    this.preview = event;
  }

  zoomedOut(event: any): void {
    if (event) {
      this.preview = '';
    }
  }
  selectedPackageValue(value){
   this.packageCategory = value;
  }
  fileSElected(val){
     this.disableValue = val;
  }

  selectedMultiLingualArtwork(value){
    this.isMultiLingualArtwork = value;
    sessionStorage.setItem(sessionStorageObject.isMultiLingualArtwork , JSON.stringify(this.isMultiLingualArtwork));
  }
 ngOnDestroy(){
  this.labelRightService.verifyToUpload=false;
 }

 fileUploadStarted(val){
    this.fileUploadStartedFlag = val;
 }
}

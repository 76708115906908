<div class="main-div" *ngIf="!(data.InActive)">
    <p *ngIf="!isChosenRouteDB" class="text-div">It appears you don't have access to Label Right. You can submit a request for 'Label Right Access Management' on <b>myIDM</b>.</p>
    <p *ngIf="isChosenRouteDB" class="text-div">It appears you don't have access to Label Right.  Kindly contact Label Right Admin to enable access.</p>
    <div class="button-div">
        <button mat-flat-button color="warn" class="logout-button" (click)="logout()">
            <img src="../../../assets/images/logout.svg" />
            Logout
        </button>
    </div>
</div>
<div class="main-div" *ngIf="(data.InActive)" >
    <h5 color ="warn">Logout Warning</h5>
    <div >
      <p>Your session is about to expire due to inactivity.</p>
      <p class="text-div">
        You will be logged out in:
      {{ countdownMinutes }}:{{ countdownSeconds | number: '2.0' }}</p>
    </div>
    <div class="button-div">
      <button mat-flat-button class="continue-button" (click)="closeDialog()">Stay Logged In</button>
    </div>
</div>


<header *ngIf="router.url !== '/' && router.url !== '/dashboardpage' " class ="dashboardHeader">
  <app-header ></app-header>
  <app-alert-bar></app-alert-bar>
</header>
<main *ngIf="router.url !== '/homepage'">
<router-outlet></router-outlet>
</main>

<router-outlet *ngIf="router.url === '/homepage'"></router-outlet>
<footer *ngIf="router.url !== '/homepage'">
</footer>
<app-page-loading *ngIf="router.url !== '/homepage'"></app-page-loading>

import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { interval, Observable, Subscription, throwError } from 'rxjs';
import { AlertBarService } from '../../utils/alert-bar/alert-bar.service';
import { UploadState, FileUploaded } from '../../utils/utils';
import { LabelRightService } from 'src/app/services/label-right.service';
import { Router } from '@angular/router';
import { HomepagedataService } from 'src/app/services/homepagedata.service';
import { PageLoadingService } from 'src/app/utils/page-loading/page-loading.service';
import { ResultsPageDataService } from 'src/app/services/results-page-data.service';
import { AzureBlobStorageServiceService } from 'src/app/services/azure-blob-storage-service.service';
import { formatDate } from '@angular/common';
import { catchError, takeUntil, takeWhile } from 'rxjs/operators';
import { AppInsightsService } from 'src/app/services/app-insights.service';
import { errorTypes, keyArraysConstants, routeUrls, TimestampConstants, packageCategory, messagesOnScreen, sessionStorageObject, fileType } from 'src/app/utils/constants';
import { MatTableDataSource } from '@angular/material/table';
import { LidReplacePopupComponent } from '../lid-replace-popup/lid-replace-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, OnChanges {
  @Input() images: Array<string>;
  @Input() title: string;
  @Input() firstMessage: string;
  @Input() acceptedExt: string;
  @Output() zoom = new EventEmitter<string>();
  @Input() projectId: number;
  @Input() packageCategory: string;
  @Input() isMultiLingualArtwork: boolean;
  @Output() selectedFile = new EventEmitter<boolean>();
  @Output() fileUploadStarted = new EventEmitter<boolean>();
  @Output() artlidPreview = new EventEmitter<{}>();


  @ViewChild('artworkImage', { static: false }) artworkImage: ElementRef;

  file: FileUploaded;
  hovered: boolean;
  hoveredClass: string;
  boundingSubscription: Subscription;
  spinnerMsg: string;
  spinnerShow: boolean = false;
  filekeyConditionArtwork = false;
  artworkUpload;
  public artworkAnalysisId: string;
  public incomingPackage: string;
  uploadedLidFile = [];
  artworkFirstMessage = messagesOnScreen.dropArtworkMessage;
  public displayedColumns: string[];
  public dataSource = new MatTableDataSource<any>([]);
  lidsSubmitted = false;
  isSubmitEnabled = false;
  isAdhocProject = false;
  varietyPack = packageCategory.varietyPack;
  private timerSubscription: any;
  public isMultiLingualForTemplate : boolean;
  originalFileNamesForMultiLid = [];

  constructor(
    private router: Router,
    private alertBarService: AlertBarService,
    private labelRightService: LabelRightService,
    private lidrequestdataservice: HomepagedataService,
    private pageservice: PageLoadingService,
    private azureblobservice: AzureBlobStorageServiceService,
    private resultPageService: ResultsPageDataService,
    private AppInsightsService: AppInsightsService,
    public dialog: MatDialog
  ) {
    this.dataSource.data = [];
    this.originalFileNamesForMultiLid = [];
  }

   ngOnInit(): void {
    sessionStorage.setItem(sessionStorageObject.isMultiLingualArtwork , 
      (sessionStorage.getItem(sessionStorageObject.isMultiLingualArtwork) != 'null' && 
      sessionStorage.getItem(sessionStorageObject.isMultiLingualArtwork) != '' && 
      sessionStorage.getItem(sessionStorageObject.isMultiLingualArtwork) != 'undefined') ?
      sessionStorage.getItem(sessionStorageObject.isMultiLingualArtwork) : 'false' );
    this.hovered = false;
    this.hoveredClass = '';
    this.artworkUpload = 0;
    if (this.title === 'Artwork') {
      this.labelRightService.artworkFile.subscribe((artwork: FileUploaded) => {
        this.spinnerMsg = messagesOnScreen.processingArtworkLoader;
        this.file = artwork;
        if  (this.file.state === UploadState.uploaded && this.file.getPreviewURL() && !this.artworkUpload) {
          const starttime = new Date().getTime();
          const img = new Image();
          img.src = this.file.getPreviewURL();
          img.onload = () => {
            const endTime = new Date().getTime();
            const timeTaken = endTime - starttime;
            this.AppInsightsService.logEvent("BLOB URL Call - Artwork",
              {
                url: this.file.getPreviewURL(),
                timeTaken: timeTaken
              });
          }
          this.spinnerShow = false;
          this.artworkUpload = 1;
          this.artlidPreview.emit({
            title: this.title,
            value: true
          });

          // this.boundingBoxService.fetchCoordinates(this.file.getMidURL());

        }
      });
    }
    else {
      this.labelRightService.lidFile.subscribe((lid: FileUploaded) => {
        this.spinnerMsg = messagesOnScreen.processingLidLoader;
        this.file = lid;
        if (this.file.state === UploadState.uploaded && this.file.getPreviewURL()) {
          this.artworkFirstMessage = messagesOnScreen.dropFileMessage;
          const starttime = new Date().getTime();
          const img = new Image();
          img.src = this.file.getPreviewURL();
          img.onload = () => {
            const endTime = new Date().getTime();
            const timeTaken = endTime - starttime;
            this.spinnerShow = false;
            this.AppInsightsService.logEvent(" BLOB URL Call - LID",
              {
                url: this.file.getPreviewURL(),
                timeTaken: timeTaken
              });
            this.artlidPreview.emit({
              title: this.title,
              value: true
            });
          }
          // this.boundingBoxService.fetchCoordinates(this.file.getMidURL());
        }
      });
    }
    this.incomingPackage = this.packageCategory ? this.packageCategory : sessionStorage.getItem(sessionStorageObject.packageCategory);
    this.isMultiLingualForTemplate = JSON.parse(sessionStorage.getItem(sessionStorageObject.isMultiLingualArtwork));
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.incomingPackage = this.packageCategory ? this.packageCategory : sessionStorage.getItem(sessionStorageObject.packageCategory);
      this.isMultiLingualForTemplate = JSON.parse(sessionStorage.getItem(sessionStorageObject.isMultiLingualArtwork));
      this.dataSource.data = [];
      this.uploadedLidFile = [];
      this.originalFileNamesForMultiLid = [];
    }
  }
  ngOnDestroy(): void {
    // Cleanup the subscription to avoid memory leaks
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.filekeyConditionArtwork = true;
  }
  /**
   * on file drop handler
   */
  onFileDropped($event): void {
    this.uploadFile($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files): void {
    this.uploadFile(files);
  }
  /**
   * For Non-Variety Pack and non-multilingual projects, the project creation API is called whenever any file upload starts. 
   * Project creation API: “/artwork/ui/manualArtworkReviewRequest”  
   * Response includes: Project ID, Project name, and other details. 
   * @param files (Files List)
   */
  uploadFile(files: FileList): void {
    this.spinnerMsg = messagesOnScreen.uploadingFileLoader;
    this.spinnerShow = false;
    this.alertBarService.hide();
    if ((this.incomingPackage == ''
      || this.incomingPackage == packageCategory.selectPackage ||
      this.incomingPackage == null) && ((sessionStorage.getItem(sessionStorageObject.packageCategory) == '') ||
        !(sessionStorage.getItem(sessionStorageObject.packageCategory)) || (sessionStorage.getItem(sessionStorageObject.packageCategory) == packageCategory.selectPackage))) {
      this.alertBarService.show(messagesOnScreen.selectPackageCategoryMessage);
    }
    else if (!this.isFileExtAccepted(files[0].name)) {
      this.alertBarService.show(messagesOnScreen.wrongFileUploadMessage);
      return;
    } else {
      if (!this.lidrequestdataservice.manualArtworkProgress) {
        this.fileUploadStarted.emit(true);
        this.lidrequestdataservice.clearSessionValue();
        let geoData = this.lidrequestdataservice.getgeocategoryData;
        let isMultiLingualArtworkFlag = sessionStorage.getItem(sessionStorageObject.isMultiLingualArtwork);
        this.packageCategory =
          this.packageCategory == ''
            ? sessionStorage.getItem(sessionStorageObject.packageCategory)
            : this.packageCategory;
        this.lidrequestdataservice
          .getProjectIdGenerated(
            geoData.region,
            geoData.category,
            geoData.modelCategory,
            this.packageCategory,
            isMultiLingualArtworkFlag
          )
          .subscribe(
            (ele) => {
              this.lidrequestdataservice.isprojectapiPending = false;
              sessionStorage.setItem(sessionStorageObject.projectId, ele.id);
              sessionStorage.setItem(sessionStorageObject.projectName,ele.projectName);
              sessionStorage.setItem(sessionStorageObject.packageCategory,this.packageCategory);
              this.uploadfileArchestratorcalls(
                files,
                sessionStorage.getItem(sessionStorageObject.projectId)
              );
              this.lidrequestdataservice.adhocProjectName = ele.projectName;
              this.lidrequestdataservice.projectAuther = ele.createdBy;
            },
            (error) => {
              this.lidrequestdataservice.isprojectapiPending = false;
              this.AppInsightsService.logException(error);
            }
          );
      } else {
        if (
          sessionStorage.getItem(sessionStorageObject.projectId) &&
          !this.lidrequestdataservice.isprojectapiPending
        ) {
          this.lidUploadOrchTrigger(files);
        } else {
          const intervalSeconds = 1;
          const warningTimeoutSeconds = 30;
          this.timerSubscription = interval(intervalSeconds * 1000) 
          .pipe(
            takeUntil(interval(warningTimeoutSeconds * 1000)), 
            catchError(error => {
              this.alertBarService.show(
                messagesOnScreen.problemCreatingprojectMessage
              );
              this.AppInsightsService.logException(error);
              return throwError(error);
            })
          )
            .subscribe(
              () => {
                if (
                  sessionStorage.getItem(sessionStorageObject.projectId) &&
                  !this.lidrequestdataservice.isprojectapiPending
                ) {
                  this.lidUploadOrchTrigger(files);
                  this.timerSubscription.unsubscribe();
                }
              }
            );
        }
      }
    }
  }
  lidUploadOrchTrigger(files){
    let id = sessionStorage.getItem(sessionStorageObject.projectId);
    this.artworkAnalysisId = id;
    let pkgCategory = this.incomingPackage ? this.incomingPackage : sessionStorage.getItem(sessionStorageObject.packageCategory);
    this.lidrequestdataservice.updatePackageCategory(pkgCategory, this.artworkAnalysisId);
    this.uploadfileArchestratorcalls(files,id);
  }
  formatfileName(fileName: string) {
    let currentDate = formatDate(new Date(), 'yyyyMMdd HHmmss', 'en', 'GMT');
    let datepart = currentDate.substring(0, 8);
    let timepart = currentDate.substring(9, 15);
    let formattedDate = datepart + '-' + timepart + '_';
    let formatedFileName: string = '';
    let key: string = '';
    fileName = fileName.replace(/[!@#$%^&*]/g, '_');
    if (this.title === 'Artwork') {
      if (fileName.includes("_ARTWORK_"))
        return fileName;
      key = 'ARTWORK_';
      formatedFileName = formattedDate + key + fileName;

    }
    else {
      if (fileName.includes("_LID_"))
        return fileName;
      key = 'LID_';
      formatedFileName = formattedDate + key + fileName;
    }
    return formatedFileName;
  }
  /**
   * After the files are successfully uploaded to the Blob storage, the orchestrator APIs are called. 
   * “/api/orchTrigger/artworkOrchestrator”: Called when artwork is uploaded successfully 
   * "/api/orchTrigger/lidOrchestrator": For non-VP and non-multilingual when a single Lid is uploaded successfully and 
   * for VP or multilingual projects, after multiple Lids are uploaded to the Blob storage successfully and “Submit” 
   * button is clicked. 
   * @param files 
   * @param analysisId 
   */
  uploadfileArchestratorcalls(files, analysisId) {
    if (analysisId !== '' && analysisId) {
      this.isAdhocProject = true;
      this.spinnerShow = true;
      let file_name: string = this.formatfileName(files[0].name);
      const file_title = this.title ? this.title : "LID";
      this.AppInsightsService.logEvent("Upload " + file_title + " file initiated", {
        title: this.title,
      });
      let projectId = sessionStorage.getItem(sessionStorageObject.projectId);
      if (this.title === 'Artwork') {
        let sasTokenVal;
        this.lidrequestdataservice.getSasToken().subscribe(val => {
          sasTokenVal = val;
          this.fileUploadStarted.emit(true);
          this.azureblobservice.uploadDocument(sasTokenVal, files[0], file_name, this.isAdhocProject, projectId, () => {
            if(this.router.url === routeUrls.root+routeUrls.upload) {
              this.labelRightService.artOrchestrator(files, file_name, this.azureblobservice.blockBlobClient.url, projectId);
              this.spinnerMsg = messagesOnScreen.uploadingArtworkLoader;
            }
          })
        });
      } else {
        let sasTokenVal;
        this.lidrequestdataservice.getSasToken().subscribe(val => {
          sasTokenVal = val;
          this.fileUploadStarted.emit(true);
          this.azureblobservice.uploadDocument(sasTokenVal, files[0], file_name, this.isAdhocProject, projectId, () => {
            if(this.router.url === routeUrls.root+routeUrls.upload) {
              this.labelRightService.lidOrchestrator(files, [file_name], this.azureblobservice.blockBlobCLientLID.url, projectId, 'artworkAnalysisId');
              this.spinnerMsg = messagesOnScreen.uploadingLidLoader;
            }
          })
        });
      }
      this.AppInsightsService.logEvent("Upload " + file_title + " file completed", {
        title: this.title,
        filename: file_name
      });
    }
    else {
      this.alertBarService.show(messagesOnScreen.problemCreatingprojectMessage);
    }
    if(this.router.url === routeUrls.root+routeUrls.upload) {
      this.selectedFile.emit(true)
      this.filekeyConditionArtwork = false;
      this.ProcessingFilesData(analysisId, this.title)
    }
  }
  isFileExtAccepted(fileName): boolean {
    const acceptedExt = this.acceptedExt.split(',').map((ext) => ext.trim().toLocaleLowerCase());
    const fileExt = fileName.substr(fileName.lastIndexOf('.'));
    return acceptedExt.includes(fileExt.toLocaleLowerCase());
  }

  toggleHovered(event: string): void {
    if (event === 'entered') {
      this.hovered = true;
      this.hoveredClass = 'file-opt-visible';
    } else {
      this.hovered = false;
      this.hoveredClass = '';
    }
  }
  toggleZoom(): void {
    this.zoom.emit(this.file.getPreviewURL());
  }

  /**
   * 
   * i. For Artworks, polling is initiated using the polling API: “/artwork/ui/artworkReviewFile?artworkAnalysisId=xxxxxx&artworkReviewFileKeys=artMid&artworkReviewFileKeys=artOriginal&artworkReviewFileKeys=language”
   * ii. For Lids, polling is initiated using the polling API: “/artwork/ui/artworkReviewFile?artworkAnalysisId=xxxxxx&artworkReviewFileKeys=lidPreview&artworkReviewFileKeys=lidOriginal”
   * Based on the data received from the polling APIs, the data initialization is done in “results-page-data-service.ts” 
   * @param analysisId 
   * @param tiltle 
   * 
   */
  ProcessingFilesData(analysisId, tiltle) {
    this.fileUploadStarted.emit(true);
    var arrayKeys = []
    if (tiltle === 'Artwork') {
      arrayKeys = keyArraysConstants.uploadPageKeysArtwork
    } else {
      arrayKeys = keyArraysConstants.uploadPageKeysLid
    }
    interval(TimestampConstants.expiryTimeForUpload).pipe(takeWhile(() => !this.filekeyConditionArtwork))
      .subscribe(() => {
        this.lidrequestdataservice.getDataforlisteners(analysisId, arrayKeys).subscribe((data) => {
          if(!this.filekeyConditionArtwork) {
          if (data.artworkReviewFilesErrorDTO.errorStatus === errorTypes.errorOccured) {
            this.resultPageService.OrchErrorDisplay(data.artworkReviewFilesErrorDTO)
            this.AppInsightsService.logException(data.artworkReviewFilesErrorDTO);
          } else {
            let filterdArray = data.artworkReviewFilesDTOs.filter(ele => arrayKeys.includes(ele.fileKey))
            let responseFileKey = filterdArray.map(item => {
              this.AppInsightsService.logEvent("FileKey - Upload " + this.title + " " + item.fileKey,
                {
                  'fileKey': item.fileKey
                }
              );
              return item.fileKey
            });
            arrayKeys = arrayKeys.filter(item => !responseFileKey.includes(item))
            this.resultPageService.assaigningData(data.artworkReviewFilesDTOs);
            if (arrayKeys.length === 0 || this.router.url !== routeUrls.root + routeUrls.upload) {
              this.filekeyConditionArtwork = true;
              this.spinnerShow = false;
            }
          }
        }
        })
      })
  }
  reset(): void {
    this.incomingPackage = sessionStorage.getItem(sessionStorageObject.packageCategory);
    this.isMultiLingualForTemplate = JSON.parse(sessionStorage.getItem(sessionStorageObject.isMultiLingualArtwork));
    let id = sessionStorage.getItem(sessionStorageObject.projectId);
    if (this.title === 'Artwork') {
      this.labelRightService.resetArt();
      this.artworkUpload = 0;
      this.artlidPreview.emit({
        title: this.title,
        value: false
      });
      this.spinnerShow = false;
    } else {
      this.labelRightService.resetLID();
      this.artlidPreview.emit({
        title: this.title,
        value: false
      });
      this.lidsSubmitted = false;
      this.artworkFirstMessage = messagesOnScreen.dropArtworkMessage;
      if (
        (this.incomingPackage === packageCategory.varietyPack || 
          JSON.parse(sessionStorage.getItem(sessionStorageObject.isMultiLingualArtwork))) && 
        (this.lidrequestdataservice.navigatingFromDashboard || 
          this.labelRightService.verifyToUpload)
      ) {
        let lidArray = [];
        this.spinnerShow = true;
        this.spinnerMsg = messagesOnScreen.gettingFilesDataLoader;
        this.originalFileNamesForMultiLid = [];
        this.lidrequestdataservice
          .getVarietypackLidfileNames(id)
          .subscribe((lidfiles) => {
            lidfiles.fileName.forEach((file) => {
              const uploadedFiles = new File([], file, { type: '' });
              lidArray.push(uploadedFiles);
            });
            let lidOriginalFileNameArr = this.extractionOfOriginalFileNameArrayFromString(lidfiles.originalFileName);
            lidOriginalFileNameArr.forEach((filename) => {
              this.originalFileNamesForMultiLid.push(filename);
            });
            this.displayedColumns = ['UploadedFiles', 'CancelButton'];
            this.uploadedLidFile = lidArray;
            this.dataSource.data = lidArray;
            this.isSubmitEnabled = true;
            this.spinnerShow = false;
            this.lidrequestdataservice
              .deletefileData(id, this.title)
              .subscribe((ele) => {
                this.filekeyConditionArtwork = false;
              });
          });
      } else {
        this.lidrequestdataservice
          .deletefileData(id, this.title)
          .subscribe((ele) => {
            this.filekeyConditionArtwork = false;
          });
      }
    }
    this.pageservice.hide();
    this.router.navigate(['upload']);

    this.labelRightService.artworkFile.subscribe((artEle) => {
      this.labelRightService.lidFile.subscribe((lidEle) => {
        if (artEle.file == null && lidEle.file == null) {
          this.selectedFile.emit(false);
          this.fileUploadStarted.emit(false);
        }
      });
    });

    this.labelRightService.restBoundingBox();
    this.labelRightService.bbAllImageURL.next('');
    this.labelRightService.resetResults();
    this.labelRightService.resetBRRules();
    this.resultPageService.original_Xfd = '';
    this.resultPageService.xfdDataForAWView = '';
    this.labelRightService.xdfData = '';
    this.labelRightService.xfd_Data.next('');
  }

  fileBrowseHandlerForLid(event) {
    if ((this.incomingPackage == ''
      || this.incomingPackage == packageCategory.selectPackage ||
      this.incomingPackage == null) && ((sessionStorage.getItem(sessionStorageObject.packageCategory) == '') ||
        !(sessionStorage.getItem(sessionStorageObject.packageCategory)) || (sessionStorage.getItem(sessionStorageObject.packageCategory) == packageCategory.selectPackage))) {
      this.alertBarService.show(messagesOnScreen.selectPackageCategoryMessage);
    }
    else {
    if (!this.lidrequestdataservice.manualArtworkProgress) {
     this.lidrequestdataservice.clearSessionValue();
    }
    this.isAdhocProject = true;
    this.file.state = 2;
    let counter = 0;
    let fileName = '';
    this.isSubmitEnabled = false;
    let analysisId = sessionStorage.getItem(sessionStorageObject.projectId) ? sessionStorage.getItem(sessionStorageObject.projectId) : '';
    for (let i = 0; i < event.length; i++) {
      this.originalFileNamesForMultiLid.push(event[i].name);
      fileName = '';
      let currentDate = formatDate(new Date(), 'yyyyMMdd HHmmss', 'en', 'GMT');
      let datepart = currentDate.substring(0, 8);
      let timepart = currentDate.substring(9, 15);
      let formattedDate = datepart + '-' + timepart;
      counter = 0;
      let tempName = (event[i].name.substring(0, event[i].name.lastIndexOf('.')) || event[i].name);
      let ext = (event[i].name.substring(event[i].name.lastIndexOf('.') + 1));
      fileName = tempName + '_' + formattedDate + '.' + ext;
      let updatedFile = new File([event[i]], fileName, { type: event[i].type });
      let indexToBeReplaced;
      if (this.uploadedLidFile.length > 0) {
        this.uploadedLidFile.forEach((lid, index) => {
          if (lid.name == fileName) {
            counter++;
            indexToBeReplaced = index;
          }
        });
        if (counter < 1) {
          let sasTokenVal;
          this.lidrequestdataservice.getSasToken().subscribe(val => {
            sasTokenVal = val;
            this.fileUploadStarted.emit(true);
            this.azureblobservice.uploadDocument(sasTokenVal, event[i], updatedFile.name, this.isAdhocProject, analysisId, () => {
              if(this.router.url === routeUrls.root+routeUrls.upload) {
                this.isSubmitEnabled = true;
              }
            })
          });
          this.uploadedLidFile.push(updatedFile);
        }
        else {
          //this.uploadedLidFile[indexToBeReplaced] = event[i];
          //this.alertBarService.show(event[i].name + " already exists...");
          const dialogRef = this.dialog.open(LidReplacePopupComponent, {
            width: 500 + 'px',
            height: 190 + 'px',
            data: {
              fileName: fileName
            }
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              if (result.data.replace) {
                this.uploadedLidFile[indexToBeReplaced] = event[i];
              }
              else { }
            }
          });
          // setTimeout(() => {
          //   this.alertBarService.hide()
          // }, 5000
          // )
        }
      }
      else {
        let sasTokenVal;
        this.lidrequestdataservice.getSasToken().subscribe(val => {
          sasTokenVal = val;
          this.fileUploadStarted.emit(true);
          this.azureblobservice.uploadDocument(sasTokenVal, event[i], updatedFile.name, this.isAdhocProject, analysisId, () => {
            if(this.router.url === routeUrls.root+routeUrls.upload) {
              this.isSubmitEnabled = true;
            }
          })
        });
        this.uploadedLidFile.push(updatedFile);
      }
    }
    this.displayedColumns = ['UploadedFiles', 'CancelButton'];
    this.dataSource.data = this.uploadedLidFile;
  }
}

  onDeleteBtnClick(e, index) {
    let name = this.uploadedLidFile[index].name;
    let sasTokenVal;
    let analysisId = sessionStorage.getItem(sessionStorageObject.projectId) ? sessionStorage.getItem(sessionStorageObject.projectId) : '';
    this.lidrequestdataservice.getSasToken().subscribe(val => {
      sasTokenVal = val;
      this.azureblobservice.deleteImage(sasTokenVal, name, this.isAdhocProject, analysisId, () => { });
    })
    this.uploadedLidFile.splice(index, 1);
    this.originalFileNamesForMultiLid.splice(index, 1);
    this.dataSource.data = this.uploadedLidFile;
    this.uploadedLidFile = [...this.uploadedLidFile];
    this.dataSource.data = [...this.dataSource.data];
    this.originalFileNamesForMultiLid = [...this.originalFileNamesForMultiLid];
    if (this.uploadedLidFile.length < 1) {
      this.fileUploadStarted.emit(false);
      this.labelRightService.resetLID();
      this.artlidPreview.emit({
        title: this.title,
        value: false
      });
    }
  }

  submitLids(lidsData) {
    let lidsnames = []
    lidsData.forEach(lidsname => {
      lidsnames.push(lidsname.name)
    });
    this.fileUploadStarted.emit(true);
    this.spinnerShow = true;
    this.spinnerMsg = messagesOnScreen.uploadingLidFilesLoader;
    this.artworkFirstMessage = messagesOnScreen.dropFileMessage;
    if (!this.lidrequestdataservice.manualArtworkProgress) {
      let geoData = this.lidrequestdataservice.getgeocategoryData;
      let isMultiLingualArtworkFlag = sessionStorage.getItem(sessionStorageObject.isMultiLingualArtwork);
      this.packageCategory = this.packageCategory == '' ? sessionStorage.getItem(sessionStorageObject.packageCategory) : this.packageCategory;
      this.lidrequestdataservice.getProjectIdGenerated(geoData.region, geoData.category, geoData.modelCategory, this.packageCategory, isMultiLingualArtworkFlag)
        .subscribe(ele => {
          if (this.router.url === routeUrls.root + routeUrls.upload) {
            sessionStorage.setItem(sessionStorageObject.projectId, ele.id);
            sessionStorage.setItem(sessionStorageObject.projectName, ele.projectName);
            sessionStorage.setItem(sessionStorageObject.packageCategory, this.packageCategory);
            this.lidrequestdataservice.adhocProjectName = ele.projectName;
            this.lidrequestdataservice.projectAuther = ele.createdBy;
            this.lidrequestdataservice.manualArtworkProgress = true;
            this.labelRightService.lidOrchestrator(lidsData, lidsnames, '', ele.id, 'artworkAnalysisId',this.originalFileNamesForMultiLid);
            this.ProcessingFilesData(ele.id, "LID")
          }
        })
    } else {
      if (this.router.url === routeUrls.root + routeUrls.upload) {
        this.labelRightService.lidOrchestrator(lidsData, lidsnames, '', sessionStorage.getItem(sessionStorageObject.projectId), 'artworkAnalysisId',this.originalFileNamesForMultiLid)
        this.ProcessingFilesData(sessionStorage.getItem(sessionStorageObject.projectId), "LID")
      }
    }

    this.lidsSubmitted = true;
  }

  uploadMoreLid(e) { }

  clickFile(e) { }

  onFileDroppedVarietyPack(e, title) {
    if(title === fileType.artwork){
      this.fileBrowseHandler(e);
    }
    else{
      this.fileBrowseHandlerForLid(e);
    }
  }

  extractionOfOriginalFileNameArrayFromString(fileNameString : String) : string[]{
    let fileNameArr= fileNameString.split(",")
      .filter(item => 
        item.endsWith(".pdf") || item.endsWith(".xsl") || item.endsWith(".xlsx") || item.endsWith(".xls") 
        || item.endsWith(".PDF") || item.endsWith(".XSL") || item.endsWith(".XLSX") || item.endsWith(".XLS"));
    return fileNameArr;   
  }
}


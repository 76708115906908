/**
 * All Java API calls within LabelRight are centralized and configured in the “homepagedata.service.ts” file. 
 * This ensures a structured approach to managing API requests related to the Java backend. 
 *
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { lidList } from '../models/lidList';
import { environment } from 'src/environments/environment';
import { sessionStorageObject } from '../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class HomepagedataService {
  public manualArtworkProgress = false;
  public isprojectapiPending = false;
  public navigatingFromDashboard = false;
  public disableDeleteButton = true;
  public resultPreviousButton = true;
  public alldoneSentAWHButton = false;
  public newAdhocReview: string = '';
  public adhocProjectName = '';
  public projectAuther = '';
  public artworkFileName= '';
  public lidFileName= [];
  public isAutomated = false;
  public isBatched = false;
  public navigateToUploadPageQueued = false;
  public artworkOriginalFileName = '';
  public lidOriginalFileName = [];
  javaApi = environment.javaApiUrl;
  pythonApi = environment.apiURL;
  lidUrl = '/lid/ui/lidRequests';
  projectIdUrl = '/artwork/ui/manualArtworkReviewRequest';
  ReviewedArtworkUrl = '/artwork/ui/artworkReviewRequests';
  previewUrl = '/lid/ui/file/';
  submitFileUrl = '/lid/ui/lidFileSubmit';
  geoAndCategoryUrl = '/user/ui/userRegionCategoryMapping';
  artworkReviewedFileUrl = '/artwork/ui/artworkReviewFiles';
  projectStatusChangeUrl = '/artwork/ui/updateArtworkStatusFromNotSentToPending';
  artworkDetailsUrl = '/artwork/ui/artworkReviewRequest';
  generateSas = '/common/generateSasToken';
  updatePackageAPI = '/artwork/ui/updateArtworkPackage';
  getPackageCategoryUrl = '/user/ui/regionCategoryPackageTypeMapping';
  geoCategoryValue: any;
  getfilekeysData ='/artwork/ui/artworkReviewFile';
  deleteFileDataUrl ='/deleteGeneratedFiles';
  artworkPreviewUrl = '/batchTrigger/artworkpreviewOrch';
  lidPreviewUrl = '/batchTrigger/lidpreviewOrch';
  varietypackLidfilesUrl ='/artwork/ui/artworkMultiLidFiles';
  getfilekeysDataForQueue ='/artwork/ui/artworkReviewFilesBatchProcessing';
  getPackageTableData ='/user/ui/regionsCategoryPackageTypeMapping';
  ResultsFileUrl = '/artwork/ui/artworkReviewFiles';

  constructor(private http: HttpClient) {}
  set setgeoCategoryData(value) {
    this.geoCategoryValue = value;
  }
  get getgeocategoryData() {
    return this.geoCategoryValue;
  }
  getgeoAndCategory(): Observable<any> {
    return this.http.get(`${this.javaApi}${this.geoAndCategoryUrl}`);
  }
  getReviwedData(geo, category): Observable<any> {
    return this.http.get(`${this.javaApi}${this.ReviewedArtworkUrl}`, {
      params: {
        category: category,
        region: geo
      }
    });
  }
  getLidData(geo, category): Observable<lidList[]> {
    return this.http.get<lidList[]>(`${this.javaApi}${this.lidUrl}`, {
      params: {
        category: category,
        region: geo
      }
    });
  }
  getProjectIdGenerated(
    geo,
    category,
    modelcategory,
    packageCat,
    isMultiLingualArtwork
  ): Observable<any> {
    this.isprojectapiPending = true;
    this.manualArtworkProgress = true;
    return this.http.post(`${this.javaApi}${this.projectIdUrl}`, {
      category: category,
      region: geo,
      modelCategory: modelcategory,
      selectedPackage: packageCat,
      isMultiLingualArtwork: JSON.parse(isMultiLingualArtwork)
    });
  }

  getSasToken(): Observable<any> {
    return this.http.get(`${this.javaApi}${this.generateSas}`,
      { responseType: 'text' as 'json' })
  }
  clearSessionValue() {
    sessionStorage.removeItem(sessionStorageObject.projectId);
    sessionStorage.removeItem(sessionStorageObject.projectName);
  }
  getsessionValue() {
    return sessionStorage.getItem(sessionStorageObject.projectId);
  }
  getProjectNameValue(){
    return sessionStorage.getItem(sessionStorageObject.projectName);
  }
  getPreviewURl(previewFilename): Observable<any> {
    let httpHeaders = new HttpHeaders().set('Accept', 'image/webp,*/*');
    return this.http.get(
      `${this.javaApi}${this.previewUrl}${previewFilename}`,
      {
        headers: httpHeaders,
        responseType: 'blob' as 'json',
        observe: 'response'
      }
    );
  }
  submitLidfile(lidId): Observable<any> {
    return this.http.post(
      `${this.javaApi}${this.submitFileUrl}`,
      {
        lidRequestId: lidId
      },
      { observe: 'response', responseType: 'text' as 'json' }
    );
  }
  statusChangeApi(artworkId): Observable<any> {
    return this.http.post(`${this.javaApi}${this.projectStatusChangeUrl}`, '', {
      responseType: 'text',
      observe: 'response',
      params: {
        artworkReviewRequestId: artworkId
      }
    });
  }
  getArtwordDetails(id): Observable<any> {
    return this.http.get(`${this.javaApi}${this.artworkDetailsUrl}`, {
      params: {
        artworkAnalysisId: id
      }
    });
  }
  getDataforlisteners(id,keysArray):Observable<any>{
    return this.http.get(`${this.javaApi}${this.getfilekeysData}`,{params:{
      artworkAnalysisId:id,
      artworkReviewFileKeys:keysArray
    }})
  }
  getDataforlistenersForQueue(fileName):Observable<any>{
    return this.http.get(`${this.javaApi}${this.getfilekeysDataForQueue}`,{params:{
      fileName:fileName
    }})
  }
  deletefileData(artworkId,filetype):Observable<any>{
    return this.http.post(`${this.pythonApi}${this.deleteFileDataUrl}`,
      {
        "artworkAnalysisId": artworkId,
        "fileType":filetype
      }
    )
    }

    artworkPreview(file_name: any,packageCategory: any):Observable<any>{
      return this.http.post(`${this.pythonApi}${this.artworkPreviewUrl}`,
        {
          "file_name": file_name,
          "package":packageCategory
        }
      )
    }

    lidPreview(file_name: any,packageCategory: any):Observable<any>{
      return this.http.post(`${this.pythonApi}${this.lidPreviewUrl}`,
        {
          "file_name": file_name,
          "package":packageCategory
        }
      )
    }

  updatePackageCategory(packageCategory, analysisId): any {
    return this.http.post(`${this.javaApi}${this.updatePackageAPI}`, '', {
      params: {
        artworkReviewRequestId: analysisId,
        updatedPackage: packageCategory
      }
    }).subscribe(
      (data) => { },
      (error) => {
        console.log(error);
      }
    );
  }
  getPackageCategory():Observable<any>{
      return this.http.get(`${this.javaApi}${this.getPackageCategoryUrl}`,{
        params:{
          region:JSON.parse(sessionStorage.getItem(sessionStorageObject.geo))?.region,
          category:JSON.parse(sessionStorage.getItem(sessionStorageObject.geo))?.category
        }
      })
  }
  getPackageMappingTableData():Observable<any>{
    return this.http.get(`${this.javaApi}${this.getPackageTableData}`)
  }
  getProjectIdGeneratedForQueued(
    geo,
    category,
    modelcategory,
    packageCat,
    is_batched,
    artworkFileName,
    lidFileName,
    isMultiLingualArtwork,
    artworkFileNameOriginal,
    lidFileNameOriginal
  ): Observable<any> {
    this.manualArtworkProgress = true;
    return this.http.post(`${this.javaApi}${this.projectIdUrl}`, {
      category: category,
        region: geo,
        modelCategory: modelcategory,
        selectedPackage: packageCat,
        isBatched: is_batched,
        artworkFileName: artworkFileName,
        lidFileName: lidFileName ,
        isMultiLingualArtwork: JSON.parse(isMultiLingualArtwork),
        artworkFileNameOriginal: artworkFileNameOriginal,
        lidFileNameOriginal: lidFileNameOriginal
    }
    );
  }
  getVarietypackLidfileNames(analysisId): Observable<any>{
     return this.http.get(`${this.javaApi}${this.varietypackLidfilesUrl}`,{
      params:{
        artworkAnalysisId:analysisId
      }
     })
  }

  gettingResultsData(id): Observable<any> {
    return this.http.get(`${this.javaApi}${this.ResultsFileUrl}`, {
      params: { artworkAnalysisId: id }
    });
  }

}

<div>
    <h3 class="labelright"><span class="headingAdjust" (click)="navigateTogetStarted()">Label Right</span></h3>
    <div class="dashboardmaindiv">
        <div class="profile ">
            <img src="../../../assets/images/profile.svg" style="height: 28px;" />
            <span class="profileName">{{userName}}</span>
        </div>
        <div class="personlocation">
            <img src="../../../assets/images/location.svg" style="margin-bottom:8px ;" />
            <mat-form-field>
                <mat-select [(value)]="selectedLocation" disableOptionCentering
                    (selectionChange)="geoChange($event.value)"
                    [disabled]="router.url !== '/dashboardpage' && router.url !== '/get-started'">
                    <mat-option [value]="getOptionValue(option)" *ngFor="let option of locations">
                        {{ option.region }}-{{option.category}}</mat-option>
                </mat-select>
                <mat-icon
                    *ngIf="locations.length > 1 && (router.url === '/dashboardpage' || router.url === '/get-started')"
                    matSuffix>arrow_drop_down</mat-icon>
            </mat-form-field>
        </div>
        <button class="user-button dashboard-logout"
            *ngIf="router.url !== '/get-started' && router.url !== '/dashboardpage' " routerLink="/dashboardpage">
            <img src="../../../assets/images/dashboardsmall.svg" style="margin-bottom:3px ;" />
            Dashboard </button>
        <button class="user-button dashboard-logout" (click)="oktaAuth.logout()">
            <img src="../../../assets/images/logout.svg" />
            Logout</button>
    </div>
</div>
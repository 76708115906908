import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { OktaAuthService } from '../app.service';
import { HomepagedataService } from './homepagedata.service';
import { ResultsPageDataService } from './results-page-data.service';

@Injectable({
  providedIn: 'root'
})
export class ResultsAuthGuard implements CanActivate {
  constructor(private homepageService:HomepagedataService,
   private resultPageDataService:ResultsPageDataService,
   private okta: OktaAuthService, private cookie: CookieService){}
   
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(this.cookie.get('access_token') && this.cookie.get('client_id')){
      if(route.queryParamMap.get('id')){
        const artworkId = route.queryParamMap.get('id'); 
        this.homepageService.navigatingFromDashboard = true;
        this.homepageService.getArtwordDetails(artworkId).subscribe((details)=>{
          this.resultPageDataService.dataMappingforResultPageNavigation(details);
          this.resultPageDataService.initialisingAllData(artworkId);
        }) 
       return true
      }else{
       return true
      }
    }else
    {
      this.okta.login(state.url);
    }
  } 
}

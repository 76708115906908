import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { KeyvaultaccessService } from './keyvaultaccess.service';
import { tap } from 'rxjs/internal/operators/tap';
import { ErrorHandlerService } from './error-handler.service';
import { AlertBarService } from '../utils/alert-bar/alert-bar.service';
import { allAPIs } from '../utils/constants';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  /**
  * Except for two polling APIs, error handling for both Java and Python APIs is implemented using “authorization.interceptor.ts”. 
  * This interceptor ensures consistent and centralized handling of errors across various API calls. The errors will be logged in 
  * Application Insights and on UI, user will get the error message as “Something went wrong, please try again...”. 
  * 
   * @param cookie 
   * @param errorHandlerService 
   * @param keyvaultService 
   * @param alertBarService 
   */
  constructor(
    private cookie: CookieService,
    private errorHandlerService: ErrorHandlerService,
    private keyvaultService: KeyvaultaccessService,
    private alertBarService: AlertBarService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('v1/introspect') > -1) {
      const modifiedReq = request.clone({
        headers: request.headers.set('Authorization', '')
      });
      return next.handle(modifiedReq);
    } else if (request.url.indexOf('assets') > -1 || request.url.indexOf('lrwebinterface') > -1) {
      return next.handle(request);
    } else {
      const modifiedReq = request.clone({
        headers: request.headers
          .set(
            'Authorization',
            'Bearer ' +
            this.cookie.get('access_token')
          )
          .set('x-functions-key', this.keyvaultService.getFunctionKey())
      });
      /**
       * All the Java and Python APIs are listed here except for 2 polling APIs
       * 
       */
      if (
        (request.url.includes(allAPIs.regionsCategoryPackageTypeMapping) || 
        request.url.includes(allAPIs.userRegionCategoryMapping) || 
        request.url.includes(allAPIs.manualArtworkReviewRequest) || 
        request.url.includes(allAPIs.generateSasToken) || 
        request.url.includes(allAPIs.updateArtworkPackage) || 
        request.url.includes(allAPIs.artworkReviewRequests) || 
        request.url.includes(allAPIs.artworkReviewFiles) ||
        request.url.includes(allAPIs.artworkOrchestrator) || 
        request.url.includes(allAPIs.lidOrchestrator) || 
        request.url.includes(allAPIs.extractionOrchestrator) || 
        request.url.includes(allAPIs.comparisonOrchestrator) || 
        request.url.includes(allAPIs.generateResultsFile) || 
        request.url.includes(allAPIs.artworkpreviewOrch) || 
        request.url.includes(allAPIs.lidpreviewOrch))
        && !request.url.includes(allAPIs.artworkReviewFilesBatchProcessing)) {
        return next.handle(modifiedReq).pipe(
          tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) { }
          },
            (error: any) => {
              if (error instanceof HttpErrorResponse) {
                this.errorHandlerService.handleError(error);
              }
            }
          )
        );      
      }
      else {
        return next.handle(modifiedReq);
      }
    }
  }
}

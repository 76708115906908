import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfiguration } from "./app-configuration";
import { environment} from 'src/environments/environment';
@Injectable({
  providedIn: "root",
})
export class JsonAppConfigService extends AppConfiguration {
  data: AppConfiguration;
  constructor(private http: HttpClient) {
    super();
  }

  // This function needs to return a promise
  load() {
    return this.http
      .get<AppConfiguration>("assets/app.config.json")
      .toPromise()
      .then((jsonData) => {
        this.serviceUrl = environment.serviceUrl;
        this.hostedUrl = environment.hostedUrl;
        this.oktaClientId = environment.oktaClientId;
        this.oktaIssuer = environment.oktaIssuer;
        this.requestTimeout = environment.requestTimeout;
        this.enableLogs = environment.enableLogs;
        this.idleTimeout=environment.idleTimeout;
        this.enableTool=environment.enableTool;
      })
      .catch(() => {
        console.error("Could not load configuration");
      });
  }
}

import { Component, OnInit } from '@angular/core';
import { OktaAuthService } from '../app.service';
import { LogoutPopupComponent } from '../header/logout-popup/logout-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { AppInsightsService } from '../services/app-insights.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {

  constructor(
    private okta: OktaAuthService,
    public dialog: MatDialog,
    private appInsightService :AppInsightsService
  ) { }
  displayStyle = "none";
  ngOnInit(): void {
    this.okta.handleAuthentication().catch((err) => {
      console.log(err);
      this.appInsightService.logEvent(
        'Error in Authentication - ' , err
      );
      const dialogRef = this.dialog.open(LogoutPopupComponent, {
        width: 500 + 'px',
        height: 150 + 'px',
        data: { InActive: false }
      });
    });
  }
}

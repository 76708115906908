<div class="drop-show" >
    <div class="userStyle">
    <h6 ><b>{{filterType}}</b></h6>
     </div>
    <input class="filterinputsearch" (keyup)="applyFilter($event)" placeholder="&nbsp;search" #input> 
    <div class="filterdiv">  
    <label *ngFor="let a of filteringList">
        <input type="checkbox" [checked]="getcheckedValue(a)"
            (change)="getSelectedValue($event.target.checked,a)" />
        <span class="spanText" *ngIf="filterType =='Results'">{{reusableDashboardService.getMappedStatus(a)}}</span>
        <span class="spanText" *ngIf="filterType !='Results'">{{a}}</span>
    </label>
</div>
    <button type="button"  class="clearfilter btn btn-secondary" (click)="cancelFilter()" 
    *ngIf="filteredCheckboxes.length > 0 ||selectedBoxes.length > 0">
        <img src="../../../assets/images/filterclose.svg" /><span style="padding-top: 6px;">Clear</span> 
    </button>
    <button type="button" class="submitButton btn btn-primary" (click)="applyFilterselected()">Apply Filter</button>
</div>


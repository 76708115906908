import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class KeyvaultaccessService {
  private KEYVAULT_SERVICE_ENDPOINT = '/lrwebinterface/accesskv/secret';
  private WEB_INTERFACE_URL = '';
  private functionKey: string = "";
  

  constructor(public http: HttpClient) {
    this.WEB_INTERFACE_URL = environment.webInterfaceApi;
  }

  public loadFunctionKey() : void {
    this.FetchKeyvaultSecret("FunctionAppMasterKey").subscribe(
      (response : string) => {
        let resp=atob(response)

        this.functionKey = resp;
      }
    )
  }

  public getFunctionKey = () => {
    return this.functionKey;
  }

  public FetchKeyvaultSecret(secretName : string) : Observable<any> {
    var data  = {
      SecretName:  secretName
    }
    
    return this.http.get(`${this.WEB_INTERFACE_URL}${this.KEYVAULT_SERVICE_ENDPOINT}`,
      {
        params:data,
        responseType:'text'
      });
  }
}

/**
 *
 * Artwork view incorporates the Adobe PDF Embed API to enable seamless viewing and collaboration on artwork 
 * within web pages. The integration of this API enhances user experience by providing various annotation tools 
 * and collaboration features. 
 *  
 * */
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter
} from '@angular/core';
import { ViewSDKClientService } from '../../services/view-sdkclient.service';
import { LabelRightService } from 'src/app/services/label-right.service';
import * as converter from 'xml-js';
import { v4 as uuid } from 'uuid';
import { ResultsPageDataService } from 'src/app/services/results-page-data.service';
import { HomepagedataService } from 'src/app/services/homepagedata.service';
import { componentProductLanguage, errorTypes, messagesOnScreen } from 'src/app/utils/constants';
import { error } from 'console';

@Component({
  selector: 'app-artworktool',
  templateUrl: './artworktool.component.html',
  styleUrls: ['./artworktool.component.css']
})
export class ArtworktoolComponent implements OnInit {
  @Input() selectedFilterLabel: string = errorTypes.noFilter;
  @Input() hummanAnnotations = [];
  @Input() displaypdf = true;
  @Output() showToolart = new EventEmitter<any>();
  @Output() replyadded = new EventEmitter<any>();
  @Output() deleteAnnotation = new EventEmitter<any>();
  @Output() selectedAnnotation = new EventEmitter<any>();
  constructor(
    public viewSDKClient: ViewSDKClientService,
    private labelright: LabelRightService,
    private resultsPageDataService: ResultsPageDataService,
    private homePageDataService: HomepagedataService
  ) {}
  previewFilePromise: any;

  viewerConfig = {
    /** 
     * Enable commenting APIs 
    */
    enableAnnotationAPIs: true /** 
    Default value is false 
    */,
    /** 
     * Include existing PDF annotations and save new annotations to PDF buffer 
    */
    includePDFAnnotations: true 
    /** 
     * Default value is false 
    */,
    showDisabledSaveButton: true
  };

  // replyAnnotation = []; to restrict multiple replies
  updatedAnnotaions = [];
  count = 0;
  showtool: boolean = true;
  showspinner: boolean = true;
  spinnerMsg: string = messagesOnScreen.processingToolLoader;
  profileName = '';
  AnnotationData = [];
  annotationData1 = [];
  id: any;
  Boundingboxvalue: any;
  selectedindexvalue: any;
  creatorId: any;
  I;
  xfdToJsonData = [];
  xfdToJson = [];
  humanAnot = [];
  xfdData = '';
  countData = 0;
  alertcount = 0;
  mismatchcount = 0;
  finalAnnotations = [];
  selectedData:any;
  idattributes :any;
  xfdformatedBackup =[];
  selectedProduct = componentProductLanguage.allProducts;
  selectedLanguage = componentProductLanguage.allLanguages;
  ngOnInit() {
    this.xfdToJsonData = [];
    this.humanAnot = [];
  }

  loadingArtwork() {
    if (
      this.selectedFilterLabel == errorTypes.noFilter ||
      this.selectedFilterLabel == errorTypes.noFilterWithoutSpace
    )
      this.selectedFilterLabel == errorTypes.noFilter;  
    // this.xfdData = this.resultsPageDataService.xfdDataForAWView
    //   ? this.resultsPageDataService.xfdDataForAWView
    //   : this.labelright.xdfData;
    this.xfdData =  this.labelright.xdfData;
    if (this.xfdData) {
      let result1 = converter.xml2json(this.xfdData, {
        compact: true,
        spaces: 2
      });
      const obj = JSON.parse(result1);
      this.xfdformatedBackup = obj['xfdf']['annots']['square']? obj['xfdf']['annots']['square']:[];
      var xfdformated = this.xfdformatedBackup;
      var xfdformatedReply = obj['xfdf']['annots']['text'];
      this.idattributes = obj['xfdf']['ids'];  
      this.profileName =
        xfdformated.length > 0
          ? xfdformated[0]['_attributes']['title'].replace(/[0-9]/g, '')
          : this.idattributes['_attributes']['name'];
      var soureid = '6d07d124-ac85-43b3-a867-36930f502ac6';
      this.countData = 0;
      this.filterSelect(xfdformated, xfdformatedReply, '1', soureid);
      if (this.selectedFilterLabel != errorTypes.noFilter)
        this.filterSelect(xfdformated, xfdformatedReply, '0', soureid);
      let xfdToJsonDataFinal = JSON.parse(JSON.stringify(this.xfdToJsonData));
      xfdToJsonDataFinal.map((e) => {
        if (e.creator.type != 'Person') e.creator.type = 'Person';
        if (e.creator.name != this.profileName)
          e.creator.name = this.profileName;
      });
      this.finalAnnotations = this.mappingFromOriginal_XFD(soureid)
      this.renderingArtworkView(xfdToJsonDataFinal);
    }
  }

  /**
   * 
   * The received XFDF data is converted to JSON object in “loadingArtwork()” function and 
   * then the JSON data is passed to the following function to load the artwork and the annotations in the PDF viewer.
   *  @param xfdToJsonDataFinal 
   * 
   */
  renderingArtworkView(xfdToJsonDataFinal){
    console.log(xfdToJsonDataFinal)
    this.viewSDKClient.ready().then(() => {
      /** 
       * Invoke the file preview and get the Promise object 
      */
      this.previewFilePromise = this.viewSDKClient.previewFile(
        'pdf-div',
        this.viewerConfig,
        this.labelright.graypdfURL
      );
      /**
       *  Use the annotation manager interface to invoke the commenting APIs 
      */
      this.previewFilePromise.then((adobeViewer: any) => {
        adobeViewer.getAnnotationManager().then((annotationManager: any) => {
          /** 
           * API to add annotations 
           */
          annotationManager
            .addAnnotations(xfdToJsonDataFinal)
            .then((result: any) => {
              this.showtool = false;
              this.showspinner = false
              this.showToolart.emit({ val: true });
            })
            .catch((error: any) => {
              this.showToolart.emit({ val: true });
              if (
                (error.code =
                  'INVALID_ANNOTATION' &&
                  error.message ==
                    'Invalid value of Annotations: should be a non empty array')
              )
                this.showtool = false;
              else {
                this.showtool = true;
                this.showspinner = true;
              }

              console.log(error);
            });
          /** 
           * API to register events listener 
          */
          annotationManager.registerEventListener(
            (event: any) => {
              /**
               * Users can add new annotations directly in the artwork view. 
               */
              if (event.type === 'ANNOTATION_ADDED') {
                if (event.data.target.selector == undefined) { 
                  const index = this.AnnotationData.findIndex(item => item.id === event.data.id);
                  if(index === -1)
                    this.AnnotationData.push(event.data);
                  const len = this.AnnotationData.length;
                  this.AnnotationData[len - 1].creator['type'] = event.data.creator.type
                  this.AnnotationData[len - 1].creator['name'] =
                    event.data.creator.name;
                  this.AnnotationData[len - 1].creator['id'] = event.data.motivation === 'replying' ? this.gettingParentsUiId(event.data.target.source) :this.creatorId
                    ? this.creatorId
                    : event.data.creator.id;
                  this.count++;
                  const ismanual = xfdToJsonDataFinal.some(
                    (ele) => ele.id === event.data.id
                  );
                  if (event.data.motivation === 'replying' && !ismanual) {
                    const comment = this.commentFormation(
                      event.data.creator.id
                    );
                    this.replyadded.emit({
                      replyComment: comment,
                      replyId: event.data.creator.id,
                      replyTimestamp: event.data.created
                    });
                  }
                } else {
                  const index = this.AnnotationData.findIndex(item => item.id === event.data.id);
                  if(index === -1)
                    this.AnnotationData.push(event.data);
                  this.count++;
                  this.countData++;
                }
                this.hummanAnnotations = this.AnnotationData
                this.labelright.annotationdata = this.AnnotationData;
              }

              /**
               * 
               * Users can select any annotation in the Artwork View by clicking on it. 
               * Upon selecting an annotation, the corresponding result in the Report View is highlighted. 
               * Additionally, the Report View automatically scrolls to ensure that the highlighted result 
               * row is brought into view. This ensures that users can easily locate and focus on the relevant result.  
               * 
               */
              if (event.type == 'ANNOTATION_SELECTED') {
                this.selectedData = event.data
                this.Boundingboxvalue =
                  event.data.target.selector.boundingBox;
                this.selectedindexvalue = event.data.id;
                this.creatorId = event.data.creator.id;
                this.hummanAnnotations.map((e) => {
                  if (e.id == event.data.id) {
                    e.target.selector.boundingBox =
                      event.data.target.selector.boundingBox;
                  }
                });
                this.selectedAnnotation.emit(event.data.creator.id)
              }
              /**
               * Users can delete machine-generated annotations, marking them as “user ignored” in the report view. 
               */
              if (event.type === 'ANNOTATION_DELETED') {
                let annotationId = '';
                if (event.data.motivation === 'replying') {
                  for (let i = 0; i <this.AnnotationData.length; i++) {
                    if (this.AnnotationData[i].id == event.data.id) {
                      annotationId = this.AnnotationData[i].creator.id;
                      this.AnnotationData.splice(i, 1);
                    }
                  }
                  for (let i = 0; i < this.hummanAnnotations.length; i++) {
                    if (this.hummanAnnotations[i].id == event.data.id) {
                      this.hummanAnnotations.splice(i, 1);
                    }
                  }
                  const comment = this.commentFormation(annotationId);
                  this.replyadded.emit({
                    replyComment: comment,
                    replyId: annotationId,
                    replyTimestamp: event.data.created
                  });
                } else if (event.data.motivation === 'commenting') {
                  annotationId = event.data.creator.id;
                  const data = this.AnnotationData.filter(array=>array.id === event.data.id || array.target.source === event.data.id);
                  const filteredArray=this.AnnotationData.filter(ele=>{
                    return !data.includes(ele)
                  })
                  this.AnnotationData = filteredArray;
                  this.hummanAnnotations = filteredArray;
                  const consolidatedAnnotation = this.AnnotationData.filter(
                    (x) => x.creator.id == annotationId
                  );
                  if (consolidatedAnnotation.length == 0) {
                    this.deleteAnnotation.emit(annotationId);
                  } else {
                    const comment = this.commentFormation(annotationId);
                    this.replyadded.emit({
                      replyComment: comment,
                      replyId: event.data.creator.id,
                      replyTimestamp: event.data.created
                    });
                  }
                }
                this.labelright.annotationdata = this.AnnotationData;
              
              }
              /**
               * Users can add replies to machine-generated comments in the artwork view, which are reflected in the report view. 
               */
              if (event.type === 'ANNOTATION_UPDATED') {
                  let annotationId = '';
                  for (let i = 0; i < this.AnnotationData.length; i++) {
                    if (this.AnnotationData[i].id == event.data.id) {
                      this.AnnotationData[i].bodyValue = event.data.bodyValue;
                      this.AnnotationData[i].created = event.data.created;
                      annotationId = this.AnnotationData[i].creator.id;
                    }
                  }
                  this.labelright.annotationdata = this.AnnotationData;
                  if (event.data.motivation === 'replying') {
                    const comment = this.commentFormation(annotationId);
                    this.replyadded.emit({
                      replyComment: comment,
                      replyId: annotationId,
                      replyTimestamp: event.data.created
                    });
                  }
              }
            },
            {
              /**
               *  Pass the list of events in listenOn. 
               * If no event is passed in listenOn, then all the annotation events will be received. 
               */
              listenOn: [
                /** 
                 * 'ANNOTATION_ADDED', 'ANNOTATION_CLICKED'
                */
              ]
            }
          );
        });
      });
      this.viewSDKClient.registerSaveApiHandler();
      this.viewSDKClient.userprofileDetailsApi(this.profileName);
      this.viewSDKClient.registerEventsHandler()
    });
  }
  productandLanguageFilter(){
    let filteredXfd :any;
    let data :any;
    if (
      this.selectedProduct === componentProductLanguage.allProducts &&
      this.selectedLanguage === componentProductLanguage.allLanguages
    ) {
       data = this.AnnotationData;
    } else if (
      this.selectedLanguage === componentProductLanguage.allLanguages &&
      this.selectedProduct !== componentProductLanguage.allProducts
    ) {
      filteredXfd = this.xfdformatedBackup.filter(
        (annot) => annot['_attributes']['product_name'] === this.selectedProduct
      );
      data = this.gettingfilteredAnnotations(filteredXfd);
    } else if (
      this.selectedLanguage !== componentProductLanguage.allLanguages &&
      this.selectedProduct === componentProductLanguage.allProducts
    ) {
      filteredXfd = this.xfdformatedBackup.filter(
        (annot) => annot['_attributes']['lang'].indexOf(this.selectedLanguage) > -1
      );
      data = this.gettingfilteredAnnotations(filteredXfd);
    } else {
      filteredXfd = this.xfdformatedBackup.filter(
        (annot) =>
          annot['_attributes']['product_name'] === this.selectedProduct &&
          annot['_attributes']['lang'].indexOf(this.selectedLanguage) > -1
      );
      data = this.gettingfilteredAnnotations(filteredXfd);
    } 
    if(data)
      this.renderingArtworkView(data);
    else 
     this.loadingArtwork()
  }
  gettingParentsUiId(id){
    let creatorIdData =this.AnnotationData.find(x=>x.id === id);
    let  value = creatorIdData.creator.id;
    return value;
  }
  gettingfilteredAnnotations(filteredXfd){
    this.hummanAnnotations = [];
    let filteredAnnotations = [];
    filteredXfd.forEach((filteredData)=>{
      this.AnnotationData.forEach((annotations)=>{
        if(filteredData['_attributes']['uiId'] === annotations.creator.id){
          filteredAnnotations.push(annotations)
        }
      })
    })
    return this.removingDuplicateComponents(filteredAnnotations,'id');
  }
  commentFormation(id) {
    const relatedReplies = this.AnnotationData.filter(
      (x) => x.creator.id === id && x.motivation === 'replying'
    );
    let commentString = '';
    relatedReplies.forEach((ele) => {
      const parentAnnotation = this.AnnotationData.filter(
        (x) => x.id === ele.target.source
      );
      const firstIndex = parentAnnotation[0].bodyValue.indexOf('Artwork:');
      const secondIndex = parentAnnotation[0].bodyValue.indexOf('Lid:');
      const commentForm =
        parentAnnotation[0].bodyValue.substring(firstIndex, secondIndex) +
        '<br> User Comments: ' +
        ele.bodyValue;
      commentString =
        commentString === ''
          ? commentForm
          : commentString + '<br> <br> ' + commentForm;
    });
    return commentString;
  }
  filterSelect(xfdformated, xfdformatedReply, Nofilter, soureid) {
    var xfdformatedData = [];
    this.xfdToJsonData = [];
    if (this.hummanAnnotations.length > 1) {
      this.hummanAnnotations.map((e) => {
        this.humanAnot.push(e);
      });
    }
    if (
      this.selectedFilterLabel == errorTypes.noFilter ||
      this.selectedFilterLabel == errorTypes.noFilterWithoutSpace
    )
      xfdformatedData = xfdformated;
    else if (this.selectedFilterLabel == errorTypes.match) xfdformatedData = [];
    else if (this.selectedFilterLabel == errorTypes.alert) {
      xfdformatedData = this.singlefilterValue(xfdformated,errorTypes.alert);
    } else if (this.selectedFilterLabel == errorTypes.mismatch) {
      xfdformatedData = this.singlefilterValue(xfdformated,errorTypes.mismatch);
    }else if(this.selectedFilterLabel == errorTypes.undetected){
      xfdformatedData = this.singlefilterValue(xfdformated,errorTypes.undetected);
    }else if(this.selectedFilterLabel == errorTypes.mismatchAlert){
      xfdformatedData = this.multifilterValue(xfdformated,errorTypes.mismatch,errorTypes.alert);
    }else if(this.selectedFilterLabel == errorTypes.mismatchNotDetected){
      xfdformatedData = this.multifilterValue(xfdformated,errorTypes.mismatch,errorTypes.undetected);
    }else if(this.selectedFilterLabel == errorTypes.alertNotDetected){
      xfdformatedData = this.multifilterValue(xfdformated,errorTypes.alert,errorTypes.undetected);
    }
    if (xfdformatedData) {
      if (xfdformatedData.length) {
        for (let i = 0; i < xfdformatedData.length; i++) {
          var id = uuid().toString();
          id = id.slice(0, -1);
          this.countData = i + 1;
          var myArr = xfdformatedData[i]['_attributes']['rect'].split(',');
          let currentDateTime = new Date().toISOString();
          this.xfdToJsonData[i] = {
            '@context': [
              'https://www.w3.org/ns/anno.jsonld',
              'https://comments.acrobat.com/ns/anno.jsonld'
            ],
            type: 'Annotation',
            id:
              xfdformatedData[i]['_attributes']['name'] == undefined
                ? id
                : xfdformatedData[i]['_attributes']['name'],
            bodyValue:
              xfdformatedData[i]['contents-richtext']['body']['_text'] ==
              undefined
                ? ''
                : xfdformatedData[i]['contents-richtext']['body']['_text'],
            motivation: 'commenting',
            target: {
              source: soureid,
              selector: {
                node: {
                  index: 0
                },
                opacity: 0.4,
                subtype: 'highlight',
                boundingBox: [+myArr[0], +myArr[1], +myArr[2], +myArr[3]],
                quadPoints: [
                  +myArr[2],
                  +myArr[3],
                  +myArr[0],
                  +myArr[3],
                  +myArr[2],
                  +myArr[1],
                  +myArr[0],
                  +myArr[1]
                ],
                strokeColor: xfdformatedData[i]['_attributes']['color'],
                strokeWidth: 3,
                type: 'AdobeAnnoSelector'
              }
            },
            creator: {
              type: 'Person',
              name: xfdformatedData[i]['_attributes']['title'],
              id: xfdformatedData[i]['_attributes']['uiId']
                ? xfdformatedData[i]['_attributes']['uiId']
                : ''
            },
            created: xfdformatedData[i]['_attributes']['creationdate']
              ? xfdformatedData[i]['_attributes']['creationdate']
              : currentDateTime, //'2020-04-03T14:56:54Z',
            modified: xfdformatedData[i]['_attributes']['creationdate']
              ? xfdformatedData[i]['_attributes']['creationdate']
              : currentDateTime //'2020-04-03T15:00:14Z',
          };
        }
      } else {
        var id = uuid().toString();
        id = id.slice(0, -1);
        this.countData = 0 + 1;
        let currentDateTime = new Date().toISOString();
        if (this.selectedFilterLabel != errorTypes.match && xfdformatedData.length !== 0) {
          var myArr = xfdformatedData['_attributes']['rect'].split(',');
          this.xfdToJsonData[0] = {
            '@context': [
              'https://www.w3.org/ns/anno.jsonld',
              'https://comments.acrobat.com/ns/anno.jsonld'
            ],
            type: 'Annotation',
            id:
              xfdformatedData['_attributes']['name'] == undefined
                ? id
                : xfdformatedData['_attributes']['name'],
            bodyValue:
              xfdformatedData['contents-richtext']['body']['_text'] == undefined
                ? ''
                : xfdformatedData['contents-richtext']['body']['_text'],
            motivation: 'commenting',
            target: {
              source: soureid,
              selector: {
                node: {
                  index: 0
                },
                opacity: 0.4,
                subtype: 'highlight',
                boundingBox: [+myArr[0], +myArr[1], +myArr[2], +myArr[3]],
                quadPoints: [
                  +myArr[2],
                  +myArr[3],
                  +myArr[0],
                  +myArr[3],
                  +myArr[2],
                  +myArr[1],
                  +myArr[0],
                  +myArr[1]
                ],
                strokeColor: xfdformatedData['_attributes']['color'],
                strokeWidth: 3,
                type: 'AdobeAnnoSelector'
              }
            },
            creator: {
              type: 'Person',
              name: xfdformatedData['_attributes']['title'],
              id: xfdformatedData['_attributes']['uiId']
                ? xfdformatedData['_attributes']['uiId']
                : ''
            },
            created: xfdformatedData['_attributes']['creationdate']
              ? xfdformatedData['_attributes']['creationdate']
              : currentDateTime, //'2020-04-03T14:56:54Z',
            modified: xfdformatedData['_attributes']['creationdate']
              ? xfdformatedData['_attributes']['creationdate']
              : currentDateTime //'2020-04-03T15:00:14Z'
          };
        }
      }
    }
    if (xfdformatedReply) {
      if (xfdformatedReply.length > 1) {
        for (let i = 0; i < xfdformatedReply.length; i++) {
          var id = uuid().toString();
          id = id.slice(0, -1);
          let currentDateTime = new Date().toISOString();
          var found = false;
          if(xfdformated.length >1){
             found = xfdformatedData.some((el) => {
              return (
                el._attributes.name ===
                xfdformatedReply[i]['_attributes']['inreplyto']
              );
            });
          }else{
            found = xfdformatedData['_attributes']['name'] ===
                xfdformatedReply[i]['_attributes']['inreplyto']
          }
          if (found) {
            this.xfdToJsonData.push({
              '@context': [
                'https://www.w3.org/ns/anno.jsonld',
                'https://comments.acrobat.com/ns/anno.jsonld'
              ],
              type: 'Annotation',
              id: id,
              bodyValue:
                xfdformatedReply[i]['contents-richtext']['body']['_text'] ==
                undefined
                  ? ''
                  : xfdformatedReply[i]['contents-richtext']['body']['_text'],
              motivation: 'replying',
              target: {
                source: xfdformatedReply[i]['_attributes']['inreplyto'] //soureid
              },
              creator: {
                type: 'Person',
                name: xfdformatedReply[i]['_attributes']['title'],
                id: xfdformatedReply[i]['_attributes']['uiId']
                  ? xfdformatedReply[i]['_attributes']['uiId']
                  : ''
              },
              created: xfdformatedReply[i]['_attributes']['creationdate']
                ? xfdformatedReply[i]['_attributes']['creationdate']
                : currentDateTime, //'2020-04-03T14:56:54Z',
              modified: xfdformatedReply[i]['_attributes']['creationdate']
                ? xfdformatedReply[i]['_attributes']['creationdate']
                : currentDateTime //'2020-04-03T15:00:14Z'
            });
          }
        }
      } else {
        var id = uuid().toString();
        id = id.slice(0, -1);
        let currentDateTime = new Date().toISOString();
        var found = false;
          if(xfdformated.length >1){
             found = xfdformatedData.some((el) => {
              return (
                el._attributes.name ===
                xfdformatedReply['_attributes']['inreplyto']
              );
            });
          }else{
            found = xfdformatedData['_attributes']['name'] ===
                xfdformatedReply['_attributes']['inreplyto']
          }
        if (found) {
          this.xfdToJsonData.push({
            '@context': [
              'https://www.w3.org/ns/anno.jsonld',
              'https://comments.acrobat.com/ns/anno.jsonld'
            ],
            type: 'Annotation',
            id: id,
            bodyValue:
              xfdformatedReply['contents-richtext']['body']['_text'] ==
              undefined
                ? ''
                : xfdformatedReply['contents-richtext']['body']['_text'],
            motivation: 'replying',
            target: {
              source: xfdformatedReply['_attributes']['inreplyto'] //soureid
            },
            creator: {
              type: 'Person',
              name: xfdformatedReply['_attributes']['title'],
              id: xfdformatedReply['_attributes']['uiId']
                ? xfdformatedReply['_attributes']['uiId']
                : ''
            },
            created: xfdformatedReply['_attributes']['creationdate']
              ? xfdformatedReply['_attributes']['creationdate']
              : currentDateTime, //'2020-04-03T14:56:54Z',
            modified: xfdformatedReply['_attributes']['creationdate']
              ? xfdformatedReply['_attributes']['creationdate']
              : currentDateTime //'2020-04-03T15:00:14Z'
          });
        }
      }
    }

    if (
      this.selectedFilterLabel == errorTypes.alert ||
      this.selectedFilterLabel == errorTypes.mismatch ||
      this.selectedFilterLabel == errorTypes.mismatchAlert ||
      this.selectedFilterLabel == errorTypes.mismatchNotDetected ||
      this.selectedFilterLabel == errorTypes.alertNotDetected || 
      this.selectedFilterLabel == errorTypes.undetected
    ) {
      let result12 = this.humanAnot.reduce((unique, o) => {
        if (!unique.some((obj) => obj.bodyValue === o.bodyValue)) {
          unique.push(o);
        }
        return unique;
      }, []);
      let results = result12.filter((e) => {
        return e.motivation == 'replying';
      });
      let results1 = [];
      if (results) {
        for (let i = 0; i < results.length; i++) {
          for (let j = 0; j < this.xfdToJsonData.length; j++) {
            if (results[i].bodyValue == this.xfdToJsonData[j].bodyValue){
            }
            else if (results[i].target.source == this.xfdToJsonData[j].id)
              results1.push(results[i]);
          }
        }
        results1.map((e) => {
          this.xfdToJsonData.push(e);
        });
        let replyFinalResult = this.xfdToJsonData.reduce((unique, o) => {
          if (!unique.some((obj) => obj.bodyValue === o.bodyValue)) {
            unique.push(o);
          }
          return unique;
        }, []);
        this.xfdToJsonData = replyFinalResult;
      }
    }

    if (Nofilter == '1') {
      let results = this.humanAnot.filter(
        ({ bodyValue: id1 }) =>
          !this.xfdToJsonData.some(({ bodyValue: id2 }) => id2 === id1)
      );
      let result12 = results.reduce((unique, o) => {
        if (
          !unique.some(
            (obj) => obj.id === o.id || obj.bodyValue === o.bodyValue
          )
        ) {
          unique.push(o);
        }
        return unique;
      }, []);
      result12.map((e) => {
        this.xfdToJsonData.push(e);
      });
      this.xfdToJsonData.filter((e) => {
        if (e.motivation == 'replying') {
          if (e.creator.type == 'Person' || e.creator.type == undefined) {
            for (let j = 0; j < this.xfdToJsonData.length; j++) {

              if (e.target.source == this.xfdToJsonData[j].id) {
                e.creator.type =
                  this.xfdToJsonData[j].target.selector.boundingBox;
                e.creator.name = this.xfdToJsonData[j].creator.id;
              }
            }
          }
        }
      });
    }
  }
  multifilterValue(xfdData, firstValue,secondValue){
    var xfdformatedData = [];
    if (xfdData.length) {
      let mismatchcount = 0;
      for (let i = 0; i < xfdData.length; i++) {
        if (
          xfdData[i]['contents-richtext']['body']['_text'].includes(
            firstValue
          ) ||
          xfdData[i]['contents-richtext']['body']['_text'].includes(secondValue)
        ) {
          xfdformatedData[mismatchcount] = xfdData[i];
          mismatchcount++;
        }
      }
    } else {
      if (
        xfdData['contents-richtext']['body']['_text'].includes(firstValue) ||
        xfdData['contents-richtext']['body']['_text'].includes(secondValue)
      ) {
        xfdformatedData[0] = xfdData;
      }
    }
    return xfdformatedData;
  }
  singlefilterValue(xfdData, filterValue){
    var xfdformatedData = [];
    if (xfdData.length) {
      let mismatchcount = 0;
      for (let i = 0; i < xfdData.length; i++) {
        if (
          xfdData[i]['contents-richtext']['body']['_text'].includes(filterValue)
        ) {
          xfdformatedData[mismatchcount] = xfdData[i];
          mismatchcount++;
        }
      }
    } else {
      if (xfdData['contents-richtext']['body']['_text'].includes(filterValue)) {
        xfdformatedData[0] = xfdData;
      }
    }
    return xfdformatedData;
  }
  deleteAnnotations(jsonId) {
    this.AnnotationData.forEach((ele) => {
      if (ele.creator.id === jsonId) {
        this.previewFilePromise.then((adobeViewer: any) => {
          let filter = {
            annotationIds: [ele.id]
          };
          adobeViewer.getAnnotationManager().then((annotationManager) => {
            annotationManager
              .deleteAnnotations(filter)
              .then(() => {
              })
              .catch((error) => console.log(error));
          });
        });
      }
    });
  }
  UpdateAnnotations(jsonId) {
    this.finalAnnotations.forEach((ele) => {
      if (ele.creator.id === jsonId) {
        this.previewFilePromise.then((adobeViewer) => {
          adobeViewer.getAnnotationManager().then((annotationManager) => {
            annotationManager
              .addAnnotations([ele])
              .then(() => console.log('Success'))
              .catch((error) => console.log(error));
          });
        });
      }
    });
  }
  mappingFromOriginal_XFD(soureid) {
    let originalAnnotations = [];
    let originalXfd = this.homePageDataService.navigatingFromDashboard
      ? this.resultsPageDataService.original_Xfd
      : this.labelright.xdfData;
    if (originalXfd) {
      let result2 = converter.xml2json(originalXfd, {
        compact: true,
        spaces: 2
      });
      const obj1 = JSON.parse(result2);
      var originalResults = obj1['xfdf']['annots']['square']? obj1['xfdf']['annots']['square']:[];
      if (originalResults.length) {
        for (let i = 0; i < originalResults.length; i++) {
          var id = uuid().toString();
          id = id.slice(0, -1);
          var myArr = originalResults[i]['_attributes']['rect'].split(',');
          let currentDateTime = new Date().toISOString();
          originalAnnotations[i] = {
            '@context': [
              'https://www.w3.org/ns/anno.jsonld',
              'https://comments.acrobat.com/ns/anno.jsonld'
            ],
            type: 'Annotation',
            id:
              originalResults[i]['_attributes']['name'] == undefined
                ? id
                : originalResults[i]['_attributes']['name'],
            bodyValue:
              originalResults[i]['contents-richtext']['body']['_text'] ==
              undefined
                ? ''
                : originalResults[i]['contents-richtext']['body']['_text'],
            motivation: 'commenting',
            target: {
              source: soureid,
              selector: {
                node: {
                  index: 0
                },
                opacity: 0.4,
                subtype: 'highlight',
                boundingBox: [+myArr[0], +myArr[1], +myArr[2], +myArr[3]],
                quadPoints: [
                  +myArr[2],
                  +myArr[3],
                  +myArr[0],
                  +myArr[3],
                  +myArr[2],
                  +myArr[1],
                  +myArr[0],
                  +myArr[1]
                ],
                strokeColor: originalResults[i]['_attributes']['color'],
                strokeWidth: 3,
                type: 'AdobeAnnoSelector'
              }
            },
            creator: {
              type: 'Person',
              name: originalResults[i]['_attributes']['title'],
              id: originalResults[i]['_attributes']['uiId']
                ? originalResults[i]['_attributes']['uiId']
                : ''
            },
            created: originalResults[i]['_attributes']['creationdate']
              ? originalResults[i]['_attributes']['creationdate']
              : currentDateTime, //'2020-04-03T14:56:54Z',
            modified: originalResults[i]['_attributes']['creationdate']
              ? originalResults[i]['_attributes']['creationdate']
              : currentDateTime //'2020-04-03T15:00:14Z',
          };
        }
      } else {
        var id = uuid().toString();
        id = id.slice(0, -1);
        if(originalResults.length !== 0){
        var myArr = originalResults['_attributes']['rect'].split(',');
        let currentDateTime = new Date().toISOString();
        originalAnnotations[0] = {
          '@context': [
            'https://www.w3.org/ns/anno.jsonld',
            'https://comments.acrobat.com/ns/anno.jsonld'
          ],
          type: 'Annotation',
          id:
            originalResults['_attributes']['name'] == undefined
              ? id
              : originalResults['_attributes']['name'],
          bodyValue:
            originalResults['contents-richtext']['body']['_text'] == undefined
              ? ''
              : originalResults['contents-richtext']['body']['_text'],
          motivation: 'commenting',
          target: {
            source: soureid,
            selector: {
              node: {
                index: 0
              },
              opacity: 0.4,
              subtype: 'highlight',
              boundingBox: [+myArr[0], +myArr[1], +myArr[2], +myArr[3]],
              quadPoints: [
                +myArr[2],
                +myArr[3],
                +myArr[0],
                +myArr[3],
                +myArr[2],
                +myArr[1],
                +myArr[0],
                +myArr[1]
              ],
              strokeColor: originalResults['_attributes']['color'],
              strokeWidth: 3,
              type: 'AdobeAnnoSelector'
            }
          },
          creator: {
            type: 'Person',
            name: originalResults['_attributes']['title'],
            id: originalResults['_attributes']['uiId']
              ? originalResults['_attributes']['uiId']
              : ''
          },
          created: originalResults['_attributes']['creationdate']
            ? originalResults['_attributes']['creationdate']
            : currentDateTime, //'2020-04-03T14:56:54Z',
          modified: originalResults['_attributes']['creationdate']
            ? originalResults['_attributes']['creationdate']
            : currentDateTime //'2020-04-03T15:00:14Z',
        };
      }
      }    
      originalAnnotations.map((e) => {
        if (e.creator.type != 'Person') e.creator.type = 'Person';
        if (e.creator.name != this.profileName)
          e.creator.name = this.profileName;
      });
      return originalAnnotations;
    } else {
      return [];
    }
  }
  updatingtoOlderAnnotation(data){
    this.previewFilePromise.then((adobeViewer)=>{
      adobeViewer.getAnnotationManager().then((annotationManager)=>{
        annotationManager.updateAnnotation(data)
        .then (()=> console.log("updated Successfully"))
        .catch(error => console.log(error));
      })
    })
  }
  removingDuplicateComponents(componentsArray, key) {
    const uniqueObjects = componentsArray.reduce(
      (accumulator, currentValue) => {
        if (!accumulator.some((obj) => obj[key] === currentValue[key])) {
          accumulator.push(currentValue);
        }
        return accumulator;
      },
      []
    );
    return uniqueObjects;
  }
}

/**
 * Application Insights is a powerful tool for monitoring and analysing the performance and usage of applications. 
 * Integrating Application Insights into LabelRight can provide valuable insights into how users interact with the 
 * application and help identify any issues or bottlenecks. 
 * 
 */

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import { sessionStorageObject } from '../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {
    appInsight:ApplicationInsights;
  constructor() { 
      this.appInsight = new ApplicationInsights(
        {
          config:{
            instrumentationKey:environment.appInsights.instrumentationKey,
            enableAutoRouteTracking:true
          }
        }
      );
      this.appInsight.loadAppInsights();
  }
  logPageView(name?:string,url?:string)
  {
    this.appInsight.trackPageView(
      {name:name,
      uri:url
    });
  }
  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsight.trackEvent({ name: name + " Analysis ID - " + sessionStorage.getItem(sessionStorageObject.projectId)}, properties);
  }
  logException(exception: Error, severityLevel?: number) {
    exception.message = exception.message + " Analysis ID - " + sessionStorage.getItem(sessionStorageObject.projectId)
    this.appInsight.trackException({ exception: exception, severityLevel: severityLevel});
  }
}

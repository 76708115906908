import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-zoom',
  templateUrl: './zoom.component.html',
  styleUrls: ['./zoom.component.scss']
})
export class ZoomComponent {
  @Input() image: string;
  @Output() closeZoom = new EventEmitter<boolean>();

  closeZoomWindow(): void {
    this.closeZoom.emit(true);
  }
}

import { Component } from '@angular/core';

import { AlertBarService } from './alert-bar.service';

@Component({
  selector: 'app-alert-bar',
  templateUrl: './alert-bar.component.html',
  styleUrls: ['./alert-bar.component.scss']
})
/**
 * 
 * This component is used to create a pop-up on the screen to show different types of error messages
 * 
 */
export class AlertBarComponent {
  messageInfo: { message; type };

  constructor(private alertBarService: AlertBarService) {
    this.alertBarService.alertState.subscribe((messageInfo) => {
      this.messageInfo = messageInfo;
    });
  }
}

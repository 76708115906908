import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-lid-replace-popup',
  templateUrl: './lid-replace-popup.component.html',
  styleUrls: ['./lid-replace-popup.component.css']
})
export class LidReplacePopupComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<LidReplacePopupComponent>,
  ) {
    dialogRef.disableClose = true;
  }

  fileName: string;

  ngOnInit(): void {
    this.fileName = this.data.fileName;
  }

  onReplaceClick() {
    this.dialogRef.close(
      {
        event: 'close',
        data: {
          replace: true
        }
      });
  }

  onCancelClick() {
    this.dialogRef.close(
      {
        event: 'close',
        data: {
          replace: false
        }
      });
  }

}

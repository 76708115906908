<div class="bb-dialog">
  <img class="close-icon-main" (click)="closeDialog()" src="../../../assets/images/close_language_pop_up.svg" />
  <div mat-dialog-content>
    <!--For Variety Pack-->
    <div *ngIf="data.isProductVisible">
      <p class="heading">Select Product</p>
      <mat-form-field appearance="fill" class="form-field"  matTooltip={{selectedProd}} #tooltip1="matTooltip" [matTooltipPosition]="'right'">
        <mat-select [(value)]="selectedProd" (selectionChange)="getProducts($event.value)">
          <mat-option [value]="option" *ngFor="let option of data.products" [style.width]="option.width" matTooltip={{option}} #tooltip1="matTooltip" [matTooltipPosition]="'right'">{{ option
            }}</mat-option>
        </mat-select>
        <mat-icon matSuffix>arrow_drop_down</mat-icon>
      </mat-form-field>
    </div>
    <div  *ngIf="data.isProductVisible" class="hidden"></div>
    <!--Component selection for Adding a Box-->
    <div *ngIf="isAddNewClicked">
      <p class="heading">Component</p>
      <mat-form-field appearance="fill" class="form-field" [matTooltip]="selectedBBType ? selectedBBType.titlename : null" #tooltip2="matTooltip" [matTooltipPosition]="'right'">
        <mat-select [(value)]="selectedBBType" (selectionChange)="getBBType($event.value)">
          <mat-option [value]="option" *ngFor="let option of data.bbType" [style.width]="option.width" matTooltip={{option.titlename}} #tooltip2="matTooltip" [matTooltipPosition]="'right'">{{
            option.titlename }}</mat-option>
        </mat-select>
        <mat-icon matSuffix>arrow_drop_down</mat-icon>
      </mat-form-field>
    </div>
    <!--Component selection for Editing a Box-->
    <div *ngIf="!isAddNewClicked">
      <p class="heading">Component</p>
      <mat-form-field appearance="fill" class="form-field" matTooltip={{data.currentBBType.titlename}} #tooltip3="matTooltip" [matTooltipPosition]="'right'">
        <mat-select [(value)]="data.currentBBType" (selectionChange)="getBBType($event.value)">
          <mat-option [value]="option" *ngFor="let option of data.bbType" matTooltip={{option.titlename}} #tooltip3="matTooltip" [matTooltipPosition]="'right'">{{ option.titlename }}</mat-option>
        </mat-select>
        <mat-icon matSuffix>arrow_drop_down</mat-icon>
      </mat-form-field>
    </div>
    <div class="hidden"></div>
    <!--Language selection for Adding/Editing a Box-->
    <div>
      <p class="heading">Select Language(s)</p>
      <mat-form-field appearance="fill" class="form-field" matTooltip={{selectedLang}} #tooltip4="matTooltip" [matTooltipPosition]="'right'">
        <mat-select #langList [(value)]="selectedLang" (selectionChange)="getLang($event.value)" multiple panelClass="lang-dropdown">
          <img class="close-icon" (click)="langList.close()" src="../../../assets/images/close_language_pop_up.svg" />
          <mat-option [value]="option" *ngFor="let option of data.lang" [style.width]="option.width" matTooltip={{option}} #tooltip4="matTooltip" [matTooltipPosition]="'right'">{{ option
            }}</mat-option>
        </mat-select>
        <mat-icon matSuffix>arrow_drop_down</mat-icon>
      </mat-form-field>
    </div>
    <div class="hidden"></div> 
    <!--Visible when all the attributes are selected-->
    <div *ngIf="isAddNewClicked">
      <button class="addNew" *ngIf="selectedBBType && selectedLang.length && selectedProd" (click)="addNewBox(selectedBBType, selectedLang)">
          <img class="addNew-icon" src="/assets/images/add-icon.svg"><span class="button-text">Add Box</span>
      </button>
    </div>
    <!--Visible when all the attributes are selected-->
    <div *ngIf="!isAddNewClicked">
      <button class="addNew" *ngIf="data.currentBBType && selectedLang.length && selectedProd" (click)="editBox(data.currentBBType, selectedLang)">
        <img class="edit-icon" src="/assets/images/edit-icon.svg"><span class="button-text">Edit Box</span>
      </button>
    </div>
</div>
import { Observable, Observer } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OktaAuth } from '@okta/okta-auth-js';
import { AppConfiguration } from '../config/app-configuration';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { LabelRightService } from './services/label-right.service';
import { environment } from 'src/environments/environment';
import { routeUrls, sessionStorageObject } from './utils/constants';
import { HomepagedataService } from './services/homepagedata.service';
@Injectable()
export class OktaAuthService {

  /**
 * This class handles the logic related to fetching login data from OKTA
 *
 */
  
  oktaAuth;
  //Azure client ID
  // CLIENT_ID = '0oatk1cmlz3KlJXXZ0h7';
  CLIENT_ID = '';
  ISSUER = '';
  LOGIN_REDIRECT_URI = '';
  LOGOUT_REDIRECT_URI = '';
  userData = {};
  loginOrDirectLaunch = false; 
  $isAuthenticated: Observable<boolean>;
  emailFromUserInfo = '';
  private observer: Observer<boolean>;
  private tokenRefreshTimer: any;
  constructor(
    private router: Router,
    //private dataService: DataService,
    private appConfig: AppConfiguration,
    private http: HttpClient,
    private cookie: CookieService,
    private labelright:LabelRightService,
    private homepageService: HomepagedataService
  ) {
    this.$isAuthenticated = new Observable((observer: Observer<boolean>) => {
      this.observer = observer;
      this.isAuthenticated().then((val) => {
        observer.next(val);
      });
    });
    this.CLIENT_ID = environment.oktaClientId;
    this.ISSUER = environment.oktaIssuer;
    this.LOGOUT_REDIRECT_URI = environment.hostedUrl;
    this.LOGIN_REDIRECT_URI = environment.hostedUrl + 'home';

    this.oktaAuth = new OktaAuth({
      clientId: this.CLIENT_ID,
      issuer: this.ISSUER,
      redirectUri: this.LOGIN_REDIRECT_URI,
      // pkce: false
      pkce: true,
    
    }); 
    const navigation = performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming;
    if (navigation.type === 'reload') {
    this.ValidateToken()
    }
  }

  async isAuthenticated() {
    // Checks if there is a current accessToken in the TokenManger.
    console.log("inside is authenticated");

    let tokenValue = !!((await this.oktaAuth.tokenManager.get("accessToken")) &&(this.cookie.get('access_token')));
    return tokenValue;
  
  }

  login(originalUrl) {
    const codeVerifier = this.createRandomString();
    // Save current URL before redirect
    sessionStorage.setItem(sessionStorageObject.oktaAppUrl, originalUrl || this.router.url);
    //Launches the login redirect.
    this.oktaAuth.token.getWithRedirect({
      // scopes: ['openid', 'email', 'profile', 'offline_access', 'pepapplabelrightgpc', 'pepappLabelRightroles'],
      scopes: ['openid', 'email', 'profile', 'offline_access', 'pepapplabelrightgpc', 'pepappLabelRightroles','pepapplabelrightdgpc'],
      // scopes: ['openid', 'email', 'profile', 'offline_access'],
      responseType: ['code'],
      // responseType: ['id_token', 'token']
      codeVerifier: codeVerifier
    });
  }
 async onloadrefreshtoken(){
  const accesstoken = await this.oktaAuth.tokenManager.get("accessToken");
      const tokens = await this.oktaAuth.token.renew(accesstoken);
      this.oktaAuth.tokenManager.add('accessToken', tokens);
      this.cookie.set('access_token', tokens.accessToken);
      this.cookie.set('client_id', tokens.claims.cid);
 }
  getUserInfo(bearertoken): any {
    this.emailFromUserInfo = '';
    let headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + bearertoken
    });
    let options = { headers: headers };
    this.http.get(this.ISSUER + '/v1/userinfo', options).subscribe(
      (response: any) => {
        //  this.labelright.oktaUserName = response.name;
        //   this.labelright.oktaEmail = response.email;
        this.cookie.set('currentUser', response.name);
        this.userData = JSON.stringify(response);
        // this.labelright.setIsUserNameSet(true);
        this.emailFromUserInfo = response.email;
      },
      (error) => {
        console.log(error);
      }
    );
  }
   async refreshToken(){
    const accesstoken = await this.oktaAuth.tokenManager.get("accessToken");
    const expireAt = accesstoken.expiresAt;
    if(Date.now() - expireAt <= 900000){
      this.onloadrefreshtoken();
    }
    this.tokenRefreshTimer = setInterval(async () => {
      try {
        if(Date.now() >= expireAt){ 
          this.onloadrefreshtoken();
        } else{
        }
      } catch (err) {
        console.error(err);
      }
    },600000)
    
   }
  async handleAuthentication() {
    const tokens = await this.oktaAuth.token.parseFromUrl();
    let token = tokens.tokens;
    let defaultValue = [];
    this.labelright.myIDMUserEmail = '';
    this.labelright.myIDMUserData = [];
    if (token.idToken) {
      this.oktaAuth.tokenManager.add('idToken', token.idToken);
    }
    if (token.accessToken) {
      this.oktaAuth.tokenManager.add('accessToken', token.accessToken);
      this.cookie.set('access_token',token.accessToken.accessToken);
      this.cookie.set('client_id',token.accessToken.claims.cid);
      this.labelright.myIDMUserEmail = token.accessToken.claims.email ? token.accessToken.claims.email : this.emailFromUserInfo;
      this.labelright.myIDMUserData = token.accessToken.claims.pepapplabelrightgpc ? token.accessToken.claims.pepapplabelrightgpc : [];
      this.labelright.myIDMUserRoles = token.accessToken.claims.pepappLabelRightroles ? token.accessToken.claims.pepappLabelRightroles : [];
      defaultValue = token.accessToken.claims.pepapplabelrightdgpc ? token.accessToken.claims.pepapplabelrightdgpc : [];
      this.getUserInfo(token.accessToken.accessToken);
    }
    this.labelright.myIDMUserDefaultValue = defaultValue.length > 0 ? defaultValue[0].split(" - ")[0]+'-'+defaultValue[0].split(" - ")[1] : '';
    if(token.refreshToken){
    this.oktaAuth.tokenManager.add('refreshToken',token.refreshToken)
    } 
   this.VerifyToken();

  } 
  async logout() {
    this.labelright.ReviewArtwork='';
    sessionStorage.removeItem(sessionStorageObject.name);
    sessionStorage.removeItem(sessionStorageObject.geo);
    sessionStorage.removeItem(sessionStorageObject.packageTable);
    this.userData ={};
    this.cookie.delete('currentUser');
    this.cookie.delete('access_token');
    this.cookie.delete('client_id');
    this.cookie.delete('refreshToken');
    var body = '';
    await this.oktaAuth.signOut({
      postLogoutRedirectUri:environment.hostedUrl,
    });
   this.http.post(this.ISSUER+'/v1/revoke',body);
    this.oktaAuth.tokenManager.clear();
    localStorage.clear();
  }

  verifyApi: boolean;
  VerifyToken(): any {
    const url = sessionStorage.getItem(sessionStorageObject.oktaAppUrl);
    var body = '';
    var headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded; charset=utf-8'
    );
    var options = {
      headers: headers
    };
    return this.http.post(
      environment.oktaIssuer +
        '/v1/introspect?client_id=' +
        this.cookie.get('client_id') +
        '&token=' +
        this.cookie.get('access_token') +
        '&token_type_hint=access_token',
      body,
      options
    ).subscribe((data) => {
      if (data['active']) {
        const redirectUrl = url=='/'|| url ==''? routeUrls.getstarted: url;
        this.router.navigateByUrl(redirectUrl);
        this.loginOrDirectLaunch = true;
        this.refreshToken();
        this.gettingPackageDetails();
      }
      else{
        this.logout()
      //  window.location.href = this.environment.hostedUrl;
      }
      }    
  )
  }

//validate token exp/valid
ValidateToken(): any {
  var body = '';
  var headers = new HttpHeaders().set(
    'Content-Type',
    'application/x-www-form-urlencoded; charset=utf-8'
  );
  var options = {
    headers: headers
  };

  return this.http.post(
    environment.oktaIssuer +
      '/v1/introspect?client_id=' +
      this.cookie.get('client_id') +
      '&token=' +
      this.cookie.get('access_token') +
      '&token_type_hint=access_token',
    body,
    options
  ).subscribe((data) => {
    if (!data['active']) {
      this.logout()
     // window.location.href = this.environment.hostedUrl;
    }else{
      if(!this.loginOrDirectLaunch){
      this.refreshToken();
      this.loginOrDirectLaunch = true;
        if(!sessionStorage.getItem(sessionStorageObject.packageTable)){
         this.gettingPackageDetails();
        }
      }
    }
})
}
 createRandomString() {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < 43; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}
gettingPackageDetails(){
  this.homepageService.getPackageMappingTableData().subscribe(packageData=>{
    sessionStorage.setItem(sessionStorageObject.packageTable,JSON.stringify(packageData))
    console.log(packageData);
  })
}
ngOnDestroy() {
  // Stop the timer when the component is destroyed
  clearInterval(this.tokenRefreshTimer);
}
}
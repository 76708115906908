import { Component } from '@angular/core';

import { Router } from '@angular/router';
import { AppConfiguration } from 'src/config/app-configuration';
import { OktaAuthService } from './app.service';
import { CookieService } from 'ngx-cookie-service';

import { MatDialog } from '@angular/material/dialog';
import { BnNgIdleService } from 'bn-ng-idle';
import { LabelRightService } from './services/label-right.service';
import { Event, NavigationEnd, NavigationStart } from '@angular/router';
import { PageLoadingService } from './utils/page-loading/page-loading.service';
import { messagesOnScreen } from './utils/constants';
import { InActivityService } from './services/in-activity.service';

export let browserRefresh = false;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'okta-app';
  isAuthenticated: boolean;
  validateUser: boolean = false;
  constructor(
    private appconfig: AppConfiguration,
    public router: Router,
    public oktaAuth: OktaAuthService,
    private cookie: CookieService,
    private bnIdle: BnNgIdleService,
    public dialog: MatDialog,
    public label: LabelRightService,
    private pageLoadingService: PageLoadingService,
    private inActivityService : InActivityService
  ) {
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          browserRefresh = !router.navigated;
          this.pageLoadingService.show(messagesOnScreen.navigatingLoader);
          break;
        }

        case event instanceof NavigationEnd: {
          this.pageLoadingService.hide();
        }
      }
    });
    this.bnIdle.startWatching(appconfig.idleTimeout).subscribe((res) => {
      if (res) {
        window.location.href = this.appconfig.hostedUrl;
      }
    });
  }
  verify: any;
  ngOnInit(): void {}
}

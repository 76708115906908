import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DashboardReusableService } from '../services/dashboard-reusable.service';
import { projectstatus } from '../utils/constants';

@Component({
  selector: 'app-filter-list',
  templateUrl: './filter-list.component.html',
  styleUrls: ['./filter-list.component.css']
})
export class FilterListComponent implements OnInit {
  @Input() CheckboxValues: any[];
  @Input() filterType: string;
  @Input () selectedBoxes :any[]
  @Output() selectedData =new EventEmitter<any>()
  @Output() cancelfilter = new EventEmitter<any>()
  filteringList: any[];
  filteredCheckboxes :any[]=[];
  selectedFilteredCheckboxes =[];
  constructor(public reusableDashboardService:DashboardReusableService) { }

  ngOnInit(): void {
     this.filteredCheckboxes = this.selectedBoxes;
    this.filteringList = this.CheckboxValues;
  }
  getSelectedValue(isChecked:boolean,value){
    if(isChecked) {
      if(value === projectstatus.ResultsGenQualified){
        this.filteredCheckboxes.push(projectstatus.ResultsMoved);
      }
        this.filteredCheckboxes.push(value);
    } else {
      if(value === projectstatus.ResultsGenQualified){
        let index = this.filteredCheckboxes.indexOf(projectstatus.ResultsMoved)
        this.filteredCheckboxes.splice(index,1);
      }
      let index = this.filteredCheckboxes.indexOf(value)
      this.filteredCheckboxes.splice(index,1);
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if(filterValue == '' || !filterValue){
      this.ngOnInit()
    }else{
      this.filteringList = this.filteringList.filter((data)=>{
        if(filterValue)
          return data.toLowerCase().indexOf(filterValue) >= 0;
          else
           return data
      })
    }
  }
  applyFilterselected(){
    this.selectedFilteredCheckboxes = this.filteredCheckboxes
    this.selectedData.emit({data:this.filteredCheckboxes,filterselected:this.filterType})
  }
  cancelFilter(){
   this.cancelfilter.emit({data:this.selectedFilteredCheckboxes,filterselected:this.filterType});
  }
  getcheckedValue(value){
    return this.selectedBoxes.includes(value)? true : false
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-bb-confirmation',
  templateUrl: './delete-bb-confirmation.component.html',
  styleUrls: ['./delete-bb-confirmation.component.css']
})
export class DeleteBBConfirmationComponent implements OnInit {
  
  /**
    * 
    * This class handles the logic to trigger a confirmation pop-up before deleting any bouding box on veriy page.
    * 
  */

  public deleted = "deleted";
  deletedBoxComponentName = '';
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  /**
   * 
   * The selected component type is passed and shown in the pop-up
   * 
  */
  ngOnInit(): void {
    this.deletedBoxComponentName = this.data.deletedBoxComponentName;
  }

}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ViewSDKClientService {
  pdfFile = '';
  cid = '';
  previewRendered : boolean = true;
  constructor() {
    this.cid = environment.adobeclientID;
  }
  readyPromise: Promise<any> = new Promise<void>((resolve) => {
    if (window.AdobeDC) {
      resolve();
    } else {
      /** 
       * 
       * Wait for Adobe Document Services PDF Embed API to be ready 
      */
      document.addEventListener('adobe_dc_view_sdk.ready', () => {
        resolve();
      });
    }
  });
  adobeDCView: any;
  ready() {
    return this.readyPromise;
  }
  previewFile(divId: string, viewerConfig: any, previewUrl:string) {
    const config: any = {
      /**
       * Pass your registered client id 
       */
      clientId: this.cid
    };
    if (divId) {
      /** 
       * Pass the div id in which PDF should be rendered 
       */
      config.divId = divId;
    }
    /** 
     * Initialize the AdobeDC View object 
     */
    this.adobeDCView = new window.AdobeDC.View(config);
    /**
     * Invoke the file preview API on Adobe DC View object 
     */
    const previewFilePromise = this.adobeDCView.previewFile(
      {
        /**
         *  Pass information on how to access the file 
         */
        content: {
          /**
           * Location of file where it is hosted 
           */
          location: {
            url: previewUrl
            /**
             * If the file URL requires some additional headers, then it can be passed as follows:-
             *      headers: [
             *          {
             *              key: '<HEADER_KEY>',
             *              value: '<HEADER_VALUE>',
             *          }
             *      ]
             *      */
          }
        },
        /**
         *  Pass meta data of file 
         */
        metaData: {
          /** 
           * file name 
           */
          fileName: 'Artwork.pdf',
          /**
           *  file ID 
           */
          id: '6d07d124-ac85-43b3-a867-36930f502ac6'
        }
      },
      viewerConfig
    );

    return previewFilePromise;
  }

  previewFileUsingFilePromise(
    divId: string,
    filePromise: Promise<string | ArrayBuffer>,
    fileName: any
  ) {
    /** 
     * Initialize the AdobeDC View object 
    */

    this.adobeDCView = new window.AdobeDC.View({
      /**
       *  Pass your registered client id 
      */
      clientId: this.cid,
      /**
       *  Pass the div id in which PDF should be rendered 
       */
      divId
    });

    /** 
     * Invoke the file preview API on Adobe DC View object 
    */
    this.adobeDCView.previewFile(
      {
        /** 
         * Pass information on how to access the file 
        */
        content: {
          /**
           *  pass file promise which resolve to arrayBuffer 
          */
          promise: filePromise
        },
        /**
         *  Pass meta data of file 
        */
        metaData: {
          /**
           *  file name
           */
          fileName
        }
      },
      {}
    );
  }

  registerSaveApiHandler() {
    const saveOptions = {
      autoSaveFrequency: 0,
      enableFocusPolling: false,
      showSaveButton:true
   }
    /**
     *  Define Save API Handler 
    */
    const saveApiHandler = (metaData: any, content: any, options: any) => {
      return new Promise((resolve) => {
        /** 
         * Dummy implementation of Save API, replace with your business logic 
        */
        setTimeout(() => {
          const response = {
            code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
            data: {
              metaData: Object.assign(metaData, {
                updatedAt: new Date().getTime()
              })
            }
          };
          resolve(response);
        }, 2000);
      });
    };

    this.adobeDCView.registerCallback(
      window.AdobeDC.View.Enum.CallbackType.SAVE_API,
      saveApiHandler,
      saveOptions
    );
  }

  registerEventsHandler() {
    /** 
     * Register the callback to receive the events 
     */
    this.adobeDCView.registerCallback(
      /** 
       * Type of call back 
      */
      window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
      /**
       *  call back function 
       */
      (event: any) => {
        console.log(event);
        if(event.type === 'PDF_VIEWER_OPEN'){
         this.previewRendered = true;
        }
        if(event.type === 'APP_RENDERING_DONE'){
        this.previewRendered = false;
        }
      },
      /** 
       * options to control the callback execution 
      */
      {
        /** 
         * Enable PDF analytics events on user interaction. 
        */
        enablePDFAnalytics: true
      }
    );
  }
  userprofileDetailsApi(profileName){
    const profile = {
      userProfile: {
          name:profileName,
          firstName: profileName,
          lastName: profileName,
          email: profileName
      }
    };
    
    this.adobeDCView.registerCallback(
       window.AdobeDC.View.Enum.CallbackType.GET_USER_PROFILE_API,
       function() {
          return new Promise((resolve, reject) => {
             resolve({
                code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
                data: profile
             });
          });
       },
    {});
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertBarService {
  alertState: Subject<any> = new Subject<any>();

  constructor() {
    this.alertState.next({ message: '', type: '' });
  }

  /**
   * 
   * Using this function, error pop-ups are shown on the screen in different types of error scenarios.
   * The error message is passed to the function, which is then shown on the screen.
   * 
   * @param message 
   * @param type 
   */
  show(message: string, type = 'danger'): void {
    this.alertState.next({ message, type });
  }

  /**
   * 
   * hide() function is used to hide the shown error pop-up from the screen
   */
  hide(): void {
    this.alertState.next({ message: '', type: '' });
  }
}

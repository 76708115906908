<div class="uploadMainDiv">
<div class="all-done-main">
  <div class="pepsi-banner">
    <img src="../../../assets/images/pepsi-banner.png" />
  </div>
  <div class="all-done-container d-flex">
    <div class="all-done-options center">
      <div class="title">All Done!</div>
      <div class="comment">
        Click below to share your results with your coworkers.
      </div>
      <div class="row no-gutters mb-3" *ngIf="HomepageService.alldoneSentAWHButton">
        <div class="col-12 pl-2">
          <button 
            type="button"
            class="btn btn-block btn-lg  px-1  w-100 artworkBtn"
           (click)="sendStatustoAWH()"
          >
          Send XFDF to Artwork House
          </button>
        </div>

        
      </div>
      <div class="row no-gutters mb-3">
        <div class="col-6 pr-2">
            
            <a class="btn btn-block btn-lg btn-light px-1 red-txt w-100" type="button" 
          
             (click)="downloadxfdData()">
              
                Download PDF/XFD
            </a>
        </div>
        <div class="col-6 pl-2">
          <a 
            type="button"
            class="btn btn-block btn-lg btn-light px-1 red-txt w-100"
            [href]="downloadResults()" 
            download
          >
            Download Report
           
          </a>
        </div>


      </div>
 
      <!-- download pdf/xfd-->
      <div class="row no-gutters mb-3">
        
        <div class="col-12 pl-2">
          <a 
            type="button"
            class="btn btn-block btn-lg btn-light px-1 red-txt w-100"
            [href]="shareResults()" 
           
          >
          Share results
          </a>
        </div>

        
      </div>



      <div class="row mb-3">
        <div class="col">
          <button
            type="button"
            class="btn btn-primary mx-auto btn-lg btn-block"
            (click)="reviewAnother()"
          >
            Review another artwork
          </button>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button class="back-btn mr-3" (click)="back()">
          <img src="../../../assets/images/back-arrow.svg" /> Back
        </button>
        
        <a type="button" class="bug-report ml-3" [href]="reportBug()">
            <img src="../../../assets/images/bug.svg" />
            Report a bug
        </a>
      </div>
    </div>
  </div>
</div>
</div>
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef
} from '@angular/core';
import { Router } from '@angular/router';
import {
  Subscription,
  BehaviorSubject,
  Subject,
  combineLatest,
  interval
} from 'rxjs';
import { PageLoadingService } from '../utils/page-loading/page-loading.service';
import { FetchStatus, BoundingBoxState, FileUploaded, pdf_xfd } from '../utils/utils';
import { LabelRightService } from '../services/label-right.service';
import { supportedLanguages } from '../utils/languages/language-codes';
import { ArtworkCanvasComponent } from '../utils/artwork-canvas/artwork-canvas.component';
import { takeUntil, takeWhile } from 'rxjs/operators';
import Konva from 'konva';
import { HomepagedataService } from '../services/homepagedata.service';
import { ResultsPageDataService } from '../services/results-page-data.service';
import { AddEditPopupComponent } from './add-edit-popup/add-edit-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  componentProductLanguage,
  errorTypes,
  fileKeyObject,
  keyArraysConstants,
  messagesOnScreen,
  routeUrls,
  sessionStorageObject,
  TimestampConstants
} from '../utils/constants';
import { AppInsightsService } from '../services/app-insights.service';
import { CancelLoaderPopupComponent } from '../utils/cancel-loader-popup/cancel-loader-popup.component';
import { DashboardReusableService } from '../services/dashboard-reusable.service';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['../base.scss', './verify.component.scss']
})
/**
 * 
 * In the Verify page of our Angular application, users can view and manipulate predicted bounding boxes for detected 
 * components received from our model. These bounding boxes are based on the "bbALL" file data and are displayed on 
 * an artwork canvas component. Users can add new boxes, adjust existing ones, delete unwanted boxes, and perform 
 * various other actions to improve OCR accuracy. 
 * 
 */
export class VerifyComponent implements OnInit, OnDestroy {
  @ViewChild(ArtworkCanvasComponent) artworkCanvas: ArtworkCanvasComponent;

  layer: Konva.Layer;
  @ViewChild('myCanvas')
  myCanvas: ElementRef<HTMLCanvasElement>;

  public context: CanvasRenderingContext2D;

  /**
   *  Subscription objects for artwork and bounding boxes
   */
  artworkSubscription: Subscription;
  boundingSubscription: Subscription;

  /** 
   * Emits true when the user clicks next, setting the spinner but waiting for comparison state to be true
   */
  submitState: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  /** 
   * Set true when full artwork image comes in
   */ 
  comparisonState: Subscription;

  destroy$: Subject<boolean> = new Subject<boolean>();

  artwork: FileUploaded;
  lid: FileUploaded;
  filekeyCondition = false;
  fileKeyConditionResults = false;
  artworkComparisonDataUrl: string;
  excelComparisonDataUrl: string;
  language: string; 
  detectedLanguageList: any[];
  selectedLanguage: any;
  supportedLanguageList: any[];

  showLanguageList = false;
  showSupportedLanguageList = false;
  userClickedNext = false;

  zoomLevel: any;
  boundingBoxes: any[];
  setWS: any;
  updatedWS: any;
  disableWS: boolean = false;
  disablePAC: boolean = false;
  disableART: boolean = false;
  langArr = [];
  supportedLangArr = [];
  selectedLang = '';
  isAddNewClicked = false;
  snackBarRef: any;
  snackBarRefEdit: any;
  deletedBBs = [];
  eventDataForEdit: any;
  productNameArr = [];
  selectedproductName = '';
  isProductNameVisible = false;
  ws = [
    {
      type: 'WS',
      predictions: [
        {
          x: 0,
          y: 0
        },
        {
          x: 0,
          y: 0
        }
      ]
    }
  ];
  delWS = [
    {
      type: 'WS',
      predictions: [
        {
          x: 0,
          y: 0
        },
        {
          x: 0,
          y: 0
        }
      ]
    }
  ];
  tbValue = '';
  isLoading = true;
  CanvasBB: any;
  Tiles = [];
  newImageURL = '';
  min = 50;
  max = 1500;
  valuezoom = 50;
  allComponents = componentProductLanguage.allComponents;
  dataAvailabilityForVerify = false;

  constructor(
    private router: Router,
    private pageLoadingService: PageLoadingService,
    public labelRightService: LabelRightService,
    public HomepageService: HomepagedataService,
    public resultsPageDataService: ResultsPageDataService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private AppInsightsService: AppInsightsService,
    private reusableService: DashboardReusableService
  ) {}

  ngOnInit(): void {
    this.labelRightService.isUndoButtonVisible=false;
    let tileArr = [];
    this.labelRightService.ReviewArtwork = 'Verify';
    this.tbValue = componentProductLanguage.allComponents;
    this.langArr.push(componentProductLanguage.allLanguages);
    this.productNameArr.push(componentProductLanguage.allProducts);
    this.selectedLang = this.langArr[0];
    this.selectedproductName = this.productNameArr[0];
    this.submitState.next(false);
    /**
     * Get spinner status
     */ 
    this.pageLoadingService.spinnerState.subscribe((spinnerState) => {
      this.isLoading = spinnerState.show;
    });

    /**
     * Start loading spinner by default op page load
     */ 
    this.updateLoadingMessage(messagesOnScreen.detectingLanguageLoader, true);

    /**
     * Get initial zoom level from zoom slider
     */ 
    this.zoomLevel = parseInt(
      (document.getElementById('zoom-slider') as HTMLInputElement).value,
      10
    );

    this.supportedLanguageList = supportedLanguages;

    /**
     * Checking if reload to kick back user to step 1
     */
    this.labelRightService.lidFile.subscribe((lid) => {
      this.lid = lid;

      if (!lid.file && !this.HomepageService.navigatingFromDashboard) {
        this.pageLoadingService.hide();
      }
    });
    this.submitState
      .pipe(takeUntil(this.destroy$))
      .subscribe((readyToSubmit) => {
        if (readyToSubmit) {
          this.pageLoadingService.show(messagesOnScreen.makingMagicLoader);
        }
      });

    this.submitState
      .pipe(takeUntil(this.destroy$))
      .subscribe((readyToSubmit) => {
        if (readyToSubmit) {
          this.updateLoadingMessage(
            messagesOnScreen.comparingArtworkToLidLoader,
            true
          );
        }
      });

    combineLatest([this.submitState, this.labelRightService.artworkFile])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([readyToSubmit, artwork]) => {
        if (readyToSubmit && artwork.full.url !== '') {
          if (!this.comparisonState) {
            this.getComparison();
          }
        }
      });

    this.labelRightService.language
      .pipe(takeUntil(this.destroy$))
      .subscribe((language) => {
        this.selectedLanguage = language;
        this.updateLoadingMessage(messagesOnScreen.detectingArtworkPartsLoader);
      });

    this.artworkSubscription = this.labelRightService.artworkFile
      .pipe(takeUntil(this.destroy$))
      .subscribe((artwork) => {
        this.artwork = artwork;
        this.newImageURL = artwork.full.url;
      });

    this.boundingSubscription = this.labelRightService.boundingBoxState
      .pipe(takeUntil(this.destroy$))
      .subscribe((boundingBoxState: BoundingBoxState) => {
        if (boundingBoxState.getFetchStatus() === FetchStatus.fetched) {
          const predictions = [];
          this.Tiles=[];
          this.deletedBBs=[];
          for (let i = 0; i < boundingBoxState.AllboundingBoxes.length; i++) {
            this.labelRightService.colorCodes[i] =
              boundingBoxState.colorCodes[i];
            this.Tiles.push({
              titles: boundingBoxState.boundingBoxType[i],
              colorcodes: boundingBoxState.colorCodes[i],
              titlename: boundingBoxState.boundingTitlename[i],
              titleCaption: boundingBoxState.boundingBoxType[i]
            });
            tileArr.push(boundingBoxState.boundingBoxType[i]);
            predictions.push({
              type: boundingBoxState.boundingBoxType[i],
              predictions: boundingBoxState.AllboundingBoxes[i],
              colorcodes: boundingBoxState.colorCodes[i]
            });
          }
          this.boundingBoxes = predictions;
          this.boundingBoxes.forEach((bb) => {
            bb.predictions.forEach((pred) => {
              pred[0].lang.forEach((language) => {
                if (this.langArr.indexOf(language) === -1) {
                  this.langArr.push(language);
                }
              });
            });
          });
          this.labelRightService.bbAllComponents.forEach((allBBs) => {
            if (tileArr.indexOf(allBBs.titles) === -1) {
              this.deletedBBs.push(allBBs);
            }
          });

          this.pageLoadingService.hide();

          this.labelRightService.bbAllSupportedProducts.forEach((prodName) => {
            this.productNameArr.push(prodName);
          });

          this.isProductNameVisible =
            this.labelRightService.isProductNameVisible;
          this.langArr = [...new Set(this.langArr)];
          this.productNameArr = [...new Set(this.productNameArr)];
          this.sortComponentTiles();
          this.labelRightService.uploadToVerify = false;
        }

        /**
         * If a user is on the Verify page during a transaction and attempts to reload/refresh the page, 
         * the following logic applies: 
         * - Based on the project analysis ID stored in the session storage, files associated with that 
         * particular transaction are fetched from the database using a Java API named "/artwork/ui/artworkReviewFiles".
         * - The project analysis ID is passed in the payload of the API request. 
         * - If the API response includes both the “bbALL” file key and the “artDetect” file key, 
         * the required fields for the Verify page are set, and the page is loaded. 
         * - If the required files are not available, the user receives a pop-up message indicating 
         * "Insufficient data to load the page" with an option to navigate to the Dashboard. 
         * 
         */
        else if(
          boundingBoxState.getFetchStatus() === FetchStatus.notStarted &&
          this.router.url === routeUrls.root + routeUrls.verify &&
          !this.dataAvailabilityForVerify &&
          this.resultsPageDataService.savedProjectName ==='' &&
          !this.HomepageService.navigatingFromDashboard &&
          !this.labelRightService.uploadToVerify &&
          !this.labelRightService.isResetBBClicked &&
          !this.labelRightService.isUndoResetClicked) {
            let projectId = sessionStorage.getItem(sessionStorageObject.projectId);
            if (projectId) {
              this.pageLoadingService.show(messagesOnScreen.detectingArtworkPartsLoader);
              this.resultsPageDataService.gettingResultsData(projectId)
                .subscribe((data) => {
                  let isArtDetectPresent = false;
                  let isBBAllPresent = false;
                  data.forEach((file)=>{
                    if(file.fileKey === fileKeyObject.artDetect){
                      isArtDetectPresent = true;
                    }
                    if(file.fileKey === fileKeyObject.bbAll){
                      isBBAllPresent = true;
                    }
                  })
                  if(isArtDetectPresent && isBBAllPresent){
                    this.HomepageService.adhocProjectName = this.HomepageService.getProjectNameValue();
                    this.dataAvailabilityForVerify = true;
                    this.resultsPageDataService.assaigningData(data);
                    this.pageLoadingService.hide();
                  }
                  else{
                    this.pageLoadingService.hide();
                    const dialogForExit = this.dialog.open(CancelLoaderPopupComponent, {
                      width: 400 + 'px',
                      height: 150 + 'px'
                    });
                  }
                });
            }
             else {
              this.router.navigate([routeUrls.getstarted]);
            }
          }
      });
  }
  ngOnDestroy(): void {
    this.snackBar.dismiss();
    this.destroy$.next(true);
    this.reusableService.filekeyConditionVerify = true;
    this.fileKeyConditionResults = true;
  }

  zoomChanged(event): void {
    this.artworkCanvas.zoom = event.target.value;
    this.valuezoom = event.target.value;
    this.artworkCanvas.ZoomLevel = Number(event.target.value);
  }

  zoomIconChange(value): void {
    let zoomValue = this.artworkCanvas.zoom;
    while (zoomValue % 10 != 0) {
      zoomValue++;
    }
    zoomValue = value == 'in' ? Number(zoomValue) + 10 : zoomValue - 10;
    if (zoomValue >= 50 && zoomValue <= 1500) {
      this.artworkCanvas.zoom = zoomValue;
      this.valuezoom = zoomValue;
      this.artworkCanvas.ZoomLevel = zoomValue;
    }
  }
  zoomUpdate(value) {
    this.valuezoom = value;
    this.artworkCanvas.zoom = value;
  }
  rotateClicked(): void {
    this.artworkCanvas.rotate();
  }

  toggleLanguageList(): void {
    this.showLanguageList = !this.showLanguageList;
  }

  switchLanguages(lang): void {
    this.selectedLanguage = lang;
    this.labelRightService.selectLanguage(this.selectedLanguage.code);
    this.showLanguageList = false;
    this.showSupportedLanguageList = false;
    this.triggerBoundingBoxOrchestrator(lang);
  }

  getLanguageList(): void {
    this.detectedLanguageList = this.supportedLanguageList;
  }

  async triggerBoundingBoxOrchestrator(lang): Promise<void> {
    const dataObject = {
      image_url: this.newImageURL, //this.artwork.getMidURL(),
      language: lang.code,
      userId: sessionStorage.getItem(sessionStorageObject.name)
    };

    try {
      this.AppInsightsService.logEvent('Verify BB Orchestrator Call Initiated');
      await this.labelRightService.bbOrchestrator(dataObject);
      this.AppInsightsService.logEvent('Verify BB Orchestrator Call Completed');
      this.updateLoadingMessage(messagesOnScreen.detectingArtworkPartsLoader);
    } catch (err) {
      this.AppInsightsService.logException(err);
      console.error(err);
      this.pageLoadingService.hide();
    }
  }

  /**
   * Clicking on the Next button will -
   * 
   * Trigger the Comparison orchestration API: “api/orchTrigger/ comparisonOrchestrator”
   * In the payload of Comparison Orchestrator, all the bounding box objects are passed with the latest properties. 
   * 
   * Poll for the details required to load Results screen: Polling is done to get the file data of ‘BRRules’, ‘resultsALL’, 
   * ‘grayPDF’, ‘PDF_XFD’ and ‘overlayALL’ file keys and then the required variables for results screen are initialized. 
   * Once the data is received for ‘resultsALL’, ‘grayPDF’, ‘PDF_XFD’ and ‘overlayALL’, the user will be navigated to the 
   * Results screen.
   */

  getComparison(): void {
    const coordinates = this.artworkCanvas.getUpdatedBoundingBoxes();
    this.labelRightService.UpdatedBoundingBoxes = coordinates;
    let Id = sessionStorage.getItem(sessionStorageObject.projectId);
    const dataObject = {
      artworkAnalysisId: Id,
      userId: sessionStorage.getItem(sessionStorageObject.name),
      userKey: this.HomepageService.projectAuther,
      detected_language: this.selectedLanguage.code,
      artwork_parts: [],
      lid_comparison_url: this.lid.comparisonData,
      lid_original: this.lid.original
    };

    Object.keys(coordinates).forEach((key) => {
      let value = coordinates[key];
      dataObject.artwork_parts.push({
        image_url: this.newImageURL,
        image_type: value[1].type.split('_')[0],
        prediction: coordinates[key]
      });
    });
    this.AppInsightsService.logEvent(
      'CompareOrchestrator call initiated for Generating Results'
    );
    this.labelRightService.compareOrchestrator(dataObject);
    let arrayKeys = keyArraysConstants.verifypageKeys;
    setTimeout(()=>{
      interval(TimestampConstants.expiryTimePeriadForResult)
        .pipe(takeWhile(() => !this.fileKeyConditionResults))
        .subscribe(() => {
          let analysisId = sessionStorage.getItem(
            sessionStorageObject.projectId
          );
          this.HomepageService.getDataforlisteners(
            analysisId,
            arrayKeys
          ).subscribe((data) => {
            if (
              data.artworkReviewFilesErrorDTO.errorStatus ===
              errorTypes.errorOccured
            ) {
              this.resultsPageDataService.OrchErrorDisplay(
                data.artworkReviewFilesErrorDTO
              );
              this.AppInsightsService.logException(
                data.artworkReviewFilesErrorDTO
              );
            } else if (data.artworkReviewFilesDTOs) {
              let filterdArray = data.artworkReviewFilesDTOs.filter((ele) =>
                arrayKeys.includes(ele.fileKey)
              );
              let responseFileKey = filterdArray.map((item) => {
                this.AppInsightsService.logEvent(
                  'FileKey - Results ' + item.fileKey,
                  {
                    FileKey: item.fileKey
                  }
                );
                return item.fileKey;
              });
              arrayKeys = arrayKeys.filter(
                (item) => !responseFileKey.includes(item)
              );
              this.resultsPageDataService.assaigningData(
                data.artworkReviewFilesDTOs
              );
              if (
                arrayKeys.length === 0 ||
                this.router.url !== routeUrls.root + routeUrls.verify
              ) {
                this.fileKeyConditionResults = true;
              }
            }
          });
        });
    }, 10000);

    this.comparisonState = this.labelRightService.results
      .pipe(takeUntil(this.destroy$))
      .subscribe((results) => {
        if (results.length > 0) {
          this.AppInsightsService.logEvent(
            'CompareOrchestrator call completed for Generating Results'
          );
          this.labelRightService.xfd_Data.subscribe((xfd) => {
            this.labelRightService.orignalXfdData = xfd;
            if (
              xfd &&
              this.labelRightService.graypdfURL &&
              this.artwork.getOverlay().overlay &&
              this.artwork.getOverlay().overlay.bb
            ) {
              this.router.navigate([routeUrls.results]);
            }
          });
        }
      });
  }

  buttonClicked(button: string): void {
    switch (button) {
      case 'Back':
        this.artworkCanvas.clickOff();
        this.dataAvailabilityForVerify = true;
        this.snackBar.dismiss();
        this.labelRightService.verifyToUpload = true;
        this.pageLoadingService.stopoperation = true;
        this.router.navigate([routeUrls.upload]);
        break;

        /**
         * When all the required operations are done on the Verify page and the user needs to navigate to the 
         * Results page, the Next button should be clicked. 
         */
      case 'Next':
        this.refreshFieldsBeforePollingForResult();
        this.artworkCanvas.clickOff();
        this.snackBar.dismiss();
        this.showAllBox();
        this.recenter_click();
        this.pageLoadingService.stopoperation = true;
        this.artworkSubscription.unsubscribe();
        this.submitState.next(true);
        break;
    }
  }

  resetWS(): void {
    let index = this.boundingBoxes.findIndex((x) => x.type === 'WS');
    const coordinates = this.artworkCanvas.getUpdatedBoundingBoxes();

    if (this.disableWS == false) {
      this.disableWS = true;
      this.ws[0].predictions = coordinates.WS;
      this.setWS = this.ws[0];
      this.boundingBoxes[index] = this.delWS[0];
      this.artworkCanvas.ngOnInit();
    } else {
      this.disableWS = false;
      this.boundingBoxes[index] = this.setWS;
      this.artworkCanvas.ngOnInit();
    }
  }

  highlight(type: any) {
    this.tbValue = type;
    this.artworkCanvas.highlightBox(
      this.tbValue,
      this.selectedLang,
      this.selectedproductName
    );
  }

  updateLoadingMessage(msg: string, forceShow = false): void {
    if (this.isLoading || forceShow) this.pageLoadingService.show(msg);
  }

  langSelected(lang: any) {
    if (
      lang === componentProductLanguage.allLanguages &&
      this.selectedproductName === componentProductLanguage.allProducts
    ) {
      this.filterReset();
    } else if (
      lang !== componentProductLanguage.allLanguages &&
      this.selectedproductName === componentProductLanguage.allProducts
    ) {
      let tileArray = [];
      let typeArray = [];
      let disableArray = [];
      this.labelRightService.boundingBoxState.value.AllboundingBoxes.forEach(
        (bb, i) => {
          bb.forEach((pred) => {
            if (pred[0].lang.includes(lang)) {
              tileArray.push({
                titles:
                  this.labelRightService.boundingBoxState.value.boundingBoxType[
                    i
                  ],
                colorcodes:
                  this.labelRightService.boundingBoxState.value.colorCodes[i],
                titlename:
                  this.labelRightService.boundingBoxState.value
                    .boundingTitlename[i],
                titleCaption:
                  this.labelRightService.boundingBoxState.value.boundingBoxType[
                    i
                  ]
              });
              typeArray.push(
                this.labelRightService.boundingBoxState.value.boundingBoxType[i]
              );
            }
          });
        }
      );
      this.labelRightService.bbAllComponents.forEach((allBBs) => {
        if (typeArray.indexOf(allBBs.titles) === -1) {
          disableArray.push(allBBs);
        }
      });
      const tiles = this.removingDuplicateComponents(tileArray, 'titles');
      const deletedArray = disableArray.filter(
        (value, index, self) => self.indexOf(value) === index
      );
      this.Tiles = tiles;
      this.deletedBBs = deletedArray;
    } else {
      let tileArray = [];
      let typeArray = [];
      let disableArray = [];
      this.labelRightService.boundingBoxState.value.AllboundingBoxes.forEach(
        (bb, i) => {
          bb.forEach((pred) => {
            if (
              pred[0].product_name === this.selectedproductName &&
              pred[0].lang.includes(lang)
            ) {
              tileArray.push({
                titles:
                  this.labelRightService.boundingBoxState.value.boundingBoxType[
                    i
                  ],
                colorcodes:
                  this.labelRightService.boundingBoxState.value.colorCodes[i],
                titlename:
                  this.labelRightService.boundingBoxState.value
                    .boundingTitlename[i],
                titleCaption:
                  this.labelRightService.boundingBoxState.value.boundingBoxType[
                    i
                  ]
              });
              typeArray.push(
                this.labelRightService.boundingBoxState.value.boundingBoxType[i]
              );
            }
            else if(pred[0].product_name === this.selectedproductName &&
              lang === componentProductLanguage.allLanguages){
              tileArray.push({
                titles:
                  this.labelRightService.boundingBoxState.value.boundingBoxType[
                    i
                  ],
                colorcodes:
                  this.labelRightService.boundingBoxState.value.colorCodes[i],
                titlename:
                  this.labelRightService.boundingBoxState.value
                    .boundingTitlename[i],
                titleCaption:
                  this.labelRightService.boundingBoxState.value.boundingBoxType[
                    i
                  ]
              });
              typeArray.push(
                this.labelRightService.boundingBoxState.value.boundingBoxType[i]
              );
            }
          });
        }
      );
      this.labelRightService.bbAllComponents.forEach((allBBs) => {
        if (typeArray.indexOf(allBBs.titles) === -1) {
          disableArray.push(allBBs);
        }
      });
      const tiles = this.removingDuplicateComponents(tileArray, 'titles');
      const deletedArray = disableArray.filter(
        (value, index, self) => self.indexOf(value) === index
      );
      this.Tiles = tiles;
      this.deletedBBs = deletedArray;
    }
    this.selectedLang = lang;
    this.artworkCanvas.highlightBox(
      this.tbValue,
      this.selectedLang,
      this.selectedproductName
    );
    this.sortComponentTiles();
  }
  removingDuplicateComponents(componentsArray, key) {
    const uniqueObjects = componentsArray.reduce(
      (accumulator, currentValue) => {
        if (!accumulator.some((obj) => obj[key] === currentValue[key])) {
          accumulator.push(currentValue);
        }
        return accumulator;
      },
      []
    );
    return uniqueObjects;
  }
  showAllBox() {
    this.artworkCanvas.showAllBoxes();
  }

  recenter_click() {
    this.artworkCanvas.zoom = 65;
    this.valuezoom = 65;
    this.artworkCanvas.recenter();
  }

  reset_click() {
    this.Tiles = [];
    this.deletedBBs = [];
    this.langArr = [componentProductLanguage.allLanguages];
    this.productNameArr = [componentProductLanguage.allProducts];
    this.selectedLang = this.langArr[0];
    this.selectedproductName = this.productNameArr[0];
    this.artworkCanvas.reset_bb();
    this.highlight(componentProductLanguage.allComponents);
    this.labelRightService.isUndoButtonVisible=true;
  }

  addNewBox(ev: any) {
    let targetAttr = ev.target.getBoundingClientRect();
    const dialogRef = this.dialog.open(AddEditPopupComponent, {
      width: '220px',
      data: {
        bbType: this.labelRightService.bbAllComponents,
        lang: this.labelRightService.bbAllSupportedLanguage,
        isAddNewClicked: true,
        isProductVisible: this.isProductNameVisible,
        products: this.labelRightService.bbAllSupportedProducts
      },
      position: {
        top: targetAttr.y + targetAttr.height + 30 + 'px',
        left: targetAttr.x - targetAttr.width + 30 + 'px'
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let counter = 0;
        let counter1 = 0;
        this.Tiles.forEach((tile) => {
          if (tile.titles === result.data.bbType.titles) {
            counter++;
          }
        });
        if (counter < 1) {
          this.Tiles.push(result.data.bbType);
          this.Tiles = [...new Set(this.Tiles)];
        }
        this.deletedBBs.forEach((bb) => {
          if (bb.titles === result.data.bbType.titles) {
            counter1++;
          }
        });
        if (counter1 >= 1) {
          this.deletedBBs.forEach((tile, index) => {
            if (tile.titles === result.data.bbType.titles) {
              this.deletedBBs.splice(index, 1);
            }
          });
          this.deletedBBs = [...new Set(this.deletedBBs)];
        }
        result.data.lang.forEach((newLang) => {
          if (this.langArr.indexOf(newLang) === -1) {
            this.langArr.push(newLang);
            this.langArr = [...new Set(this.langArr)];
          }
        });
        this.artworkCanvas.addNewBox(
          result.data.bbType,
          result.data.lang,
          result.data.product
        );
        this.artworkCanvas.highlightBox(
          this.tbValue,
          this.selectedLang,
          this.selectedproductName
        );
        this.sortComponentTiles();
      }
    });
  }

  getChildDataAdd(e) {
    this.valuezoom = 150;
    this.artworkCanvas.zoom = 150;
    this.labelRightService.addNewBB(e);
    this.highlight(e.type);
    this.labelRightService.isUndoButtonVisible = false;
  }

  getChildDataAddForEdit(e) {
    this.labelRightService.addNewBB(e);
  }

  getChildDataDelete(e) {
    let counter = 0;
    let languageCounter = 0;
    this.boundingBoxes.forEach((bb) => {
      if (bb.type === e.deleteBBType) {
        if (bb.predictions.length > 1) {
          counter = bb.predictions.length;
        }
      }
    });
    this.boundingBoxes.forEach((bb, index) => {
      if (bb.type === e.deleteBBType) {
        if (bb.predictions.length == 1) {
          this.boundingBoxes.splice(index, 1);
        }
      }
    });
    if (counter <= 1) {
      this.Tiles.forEach((tile, index) => {
        if (tile.titles === e.deleteBBType) {
          this.Tiles.splice(index, 1);
          this.deletedBBs.push(tile);
        }
      });
      this.Tiles = [...new Set(this.Tiles)];
      this.deletedBBs = [...new Set(this.deletedBBs)];
    }
    e.deletedLanguage.forEach((deletedLang) => {
      languageCounter = 0;
      this.boundingBoxes.forEach((bb) => {
        bb.predictions.forEach((pred) => {
          if (pred[0].lang.indexOf(deletedLang) > -1) {
            languageCounter++;
          }
        });
      });
      if (languageCounter < 1) {
        this.langArr.forEach((lang, index) => {
          if (lang === deletedLang) {
            this.langArr.splice(index, 1);
          }
        });
      }
    });
    this.langArr = [...new Set(this.langArr)];
    this.labelRightService.deleteBB(e.deleteBB);
    this.sortComponentTiles();
    this.labelRightService.isUndoButtonVisible = false;
  }

  getChildData(e) {
    let selectedType;
    let currentLang;
    let currentProduct;
    let targetAttr = this.eventDataForEdit.target.getBoundingClientRect();
    this.labelRightService.bbAllComponents.forEach((type) => {
      if (type.titles === e.type) {
        selectedType = type;
        currentLang = e.topLeft.lang;
        currentProduct = e.topLeft.product_name;
      }
    });
    const dialogRef = this.dialog.open(AddEditPopupComponent, {
      width: '220px',
      data: {
        currentBBType: selectedType,
        currentLang: currentLang,
        selectedProduct: currentProduct,
        bbType: this.labelRightService.bbAllComponents,
        lang: this.labelRightService.bbAllSupportedLanguage,
        isAddNewClicked: false,
        isProductVisible: this.isProductNameVisible,
        products: this.labelRightService.bbAllSupportedProducts
      },
      position: {
        top: targetAttr.y + targetAttr.height + 30 + 'px',
        left: targetAttr.x - targetAttr.width + 30 + 'px'
      }
    });
    this.eventDataForEdit = '';
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.artworkCanvas.editBBPostDialog(
          result.data.lang,
          selectedType,
          e,
          result.data.bbType,
          currentLang,
          result.data.product,
          currentProduct
        );
        this.artworkCanvas.highlightBox(
          this.tbValue,
          this.selectedLang,
          this.selectedproductName
        );
      }
    });
  }

  getChildDataToDeleteAddForEdit(ev) {
    let counter = 0;
    let counter1 = 0;
    let bbForTiles = {
      titles: ev.type,
      colorcodes: ev.colorcode,
      titlename: ev.typeName,
      titleCaption: ev.type
    };
    let deleteObj = {
      deleteBB: ev.pred[0].type,
      deleteBBType: ev.oldType1.titles,
      deletedLanguage: ev.oldLanguage
    };
    this.getChildDataDelete(deleteObj);
    this.Tiles.forEach((tile) => {
      if (tile.titles === ev.type) {
        counter++;
      }
    });
    if (counter < 1) {
      this.Tiles.push(bbForTiles);
      this.Tiles = [...new Set(this.Tiles)];
    }
    this.deletedBBs.forEach((bb) => {
      if (bb.titles === ev.type) {
        counter1++;
      }
    });
    if (counter1 >= 1) {
      this.deletedBBs.forEach((tile, index) => {
        if (tile.titles === ev.type) {
          this.deletedBBs.splice(index, 1);
        }
      });
      this.deletedBBs = [...new Set(this.deletedBBs)];
    }
    ev.pred[0].lang.forEach((newLang) => {
      if (this.langArr.indexOf(newLang) === -1) {
        this.langArr.push(newLang);
        this.langArr = [...new Set(this.langArr)];
      }
    });
    let addObject = {
      type: ev.type,
      pred: ev.pred,
      prediction: ev.prediction,
      colorcode: ev.colorcode,
      typeName: ev.typeName,
      language: ev.language
    };
    this.sortComponentTiles();
    this.getChildDataAddForEdit(addObject);
    this.highlight(ev.type);
    this.labelRightService.isUndoButtonVisible = false;
  }

  editBB(ev: any) {
    this.eventDataForEdit = ev;
    this.artworkCanvas.clickOff();
    this.snackBarRefEdit = this.snackBar.open(
      messagesOnScreen.selectBoxToEditMessage,
      'Cancel',
      {  
        panelClass: ['edit-delete-snackbar']
      }
    );
    this.snackBarRefEdit.onAction().subscribe(() => {
      this.artworkCanvas.clickOff();
    });
    this.artworkCanvas.editBB();
  }

  deleteBB() {
    this.artworkCanvas.clickOff();
    this.snackBarRef = this.snackBar.open(
      messagesOnScreen.selectBoxToDeleteMessage,
      'Cancel',
      {  
        panelClass: ['edit-delete-snackbar']
      }
    );
    this.snackBarRef.onAction().subscribe(() => {
      this.artworkCanvas.clickOff();
    });
    this.artworkCanvas.deleteBB();
  }

  getChildDataDeleteForSnackbar() {
    this.snackBarRef.dismiss();
  }

  getChildDataEditForSnackbar() {
    this.snackBarRefEdit.dismiss();
  }

  getautoFocus(e) {
    this.valuezoom = e;
    this.artworkCanvas.zoom = e;
  }

  productNameSelected(prodName) {
    if(prodName === componentProductLanguage.allProducts){
      this.selectedLang = componentProductLanguage.allLanguages;
    }
    if (
      prodName === componentProductLanguage.allProducts &&
      this.selectedLang === componentProductLanguage.allLanguages
    ) {
      this.filterReset();
    } else {
      let lanArray = [componentProductLanguage.allLanguages];
      let tileArray = [];
      let typeArray = [];
      let disableArray = [];
      this.labelRightService.boundingBoxState.value.AllboundingBoxes.forEach(
        (bb, i) => {
          bb.forEach((pred) => {
            if (pred[0].product_name === prodName) {
              pred[0].lang.forEach((lan) => {
                lanArray.includes(lan) ? lanArray : lanArray.push(lan);
                tileArray.push({
                  titles:
                    this.labelRightService.boundingBoxState.value
                      .boundingBoxType[i],
                  colorcodes:
                    this.labelRightService.boundingBoxState.value.colorCodes[i],
                  titlename:
                    this.labelRightService.boundingBoxState.value
                      .boundingTitlename[i],
                  titleCaption:
                    this.labelRightService.boundingBoxState.value
                      .boundingBoxType[i]
                });
                typeArray.push(
                  this.labelRightService.boundingBoxState.value.boundingBoxType[
                    i
                  ]
                );
              });
            }
            else if(prodName === componentProductLanguage.allComponents){
              tileArray.push({
                titles:
                  this.labelRightService.boundingBoxState.value.boundingBoxType[
                    i
                  ],
                colorcodes:
                  this.labelRightService.boundingBoxState.value.colorCodes[i],
                titlename:
                  this.labelRightService.boundingBoxState.value
                    .boundingTitlename[i],
                titleCaption:
                  this.labelRightService.boundingBoxState.value.boundingBoxType[
                    i
                  ]
              });
              typeArray.push(
                this.labelRightService.boundingBoxState.value.boundingBoxType[i]
              );
            }
          });
        }
      );
      this.selectedLang = lanArray[0];
      this.langArr = lanArray;
      this.labelRightService.bbAllComponents.forEach((allBBs) => {
        if (typeArray.indexOf(allBBs.titles) === -1) {
          disableArray.push(allBBs);
        }
      });
      const tiles = this.removingDuplicateComponents(tileArray, 'titles');
      const deletedArray = disableArray.filter(
        (value, index, self) => self.indexOf(value) === index
      );
      this.Tiles = tiles;
      this.deletedBBs = deletedArray;
    }
    this.selectedproductName = prodName;
    this.artworkCanvas.highlightBox(
      this.tbValue,
      this.selectedLang,
      this.selectedproductName
    );
    this.sortComponentTiles();
  }
  getChildDataBrandLanguage(e) {
    let languageCounter = 0;
    e.oldLanguage.forEach((deletedLang) => {
      languageCounter = 0;
      this.boundingBoxes.forEach((bb) => {
        bb.predictions.forEach((pred) => {
          if (pred[0].lang.indexOf(deletedLang) > -1) {
            languageCounter++;
          }
        });
      });
      if (languageCounter < 1) {
        this.langArr.forEach((lang, index) => {
          if (lang === deletedLang) {
            this.langArr.splice(index, 1);
          }
        });
      }
    });
    e.language.forEach((newLang) => {
      if (this.langArr.indexOf(newLang) === -1) {
        this.langArr.push(newLang);
      }
    });
    this.langArr = [...new Set(this.langArr)];
  }
  filterReset() {
    let lanArray = [componentProductLanguage.allLanguages];
    let tileArray = [];
    let typeArray = [];
    let disableArray = [];
    this.labelRightService.boundingBoxState.value.AllboundingBoxes.forEach(
      (bb, i) => {
        bb.forEach((pred) => {
          pred[0].lang.forEach((lan) => {
            lanArray.includes(lan) ? lanArray : lanArray.push(lan);
            tileArray.push({
              titles:
                this.labelRightService.boundingBoxState.value.boundingBoxType[
                  i
                ],
              colorcodes:
                this.labelRightService.boundingBoxState.value.colorCodes[i],
              titlename:
                this.labelRightService.boundingBoxState.value.boundingTitlename[
                  i
                ],
              titleCaption:
                this.labelRightService.boundingBoxState.value.boundingBoxType[i]
            });
            typeArray.push(
              this.labelRightService.boundingBoxState.value.boundingBoxType[i]
            );
          });
        });
      }
    );
    this.labelRightService.bbAllComponents.forEach((allBBs) => {
      if (typeArray.indexOf(allBBs.titles) === -1) {
        disableArray.push(allBBs);
      }
    });
    const tiles = this.removingDuplicateComponents(tileArray, 'titles');
    const deletedArray = disableArray.filter(
      (value, index, self) => self.indexOf(value) === index
    );
    this.Tiles = tiles;
    this.deletedBBs = deletedArray;
    this.langArr = lanArray;
    this.sortComponentTiles();
  }

  functionToSortArray(componentTypeArray) {
    return componentTypeArray.sort((a, b) =>
      a.titlename < b.titlename ? -1 : 1
    );
  }

  sortComponentTiles() {
    this.Tiles = [...new Set(this.functionToSortArray(this.Tiles))];
    this.deletedBBs = [...new Set(this.functionToSortArray(this.deletedBBs))];
  }

  refreshFieldsBeforePollingForResult(){
    this.labelRightService.resultsAllSupportedBrand = [];
    this.labelRightService.resultsAllSupportedLang = [];
    this.labelRightService.results.next({});
    this.labelRightService.pdfFileUrl = '';
    this.labelRightService.xdfData = '';
    this.labelRightService.xfd_Data.next('');
    this.labelRightService.pdf_Xfd.next(new pdf_xfd());
    this.labelRightService.graypdfURL = ''; 
  }

  undo_click(){
    this.artworkCanvas.undo_reset_clicked();
    this.labelRightService.isUndoButtonVisible=false;
  }
}

/**
 * The “homepage” component serves as the parent component of Dashboard, displaying a list of projects from the last 3 months. 
 * The data is fetched using the Java API "artwork/ui/artworkReviewRequests" with the ability to filter projects by changing the 
 * region-category. The projects are categorized into "Review in Progress" and "Reviewed". Users can navigate to the ad hoc 
 * project upload screen, queued project upload screen with the provided buttons on the dashboard.
 * 
 */

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { lidList } from '../models/lidList';
import { HomepagedataService } from '../services/homepagedata.service';
import { PageLoadingService } from '../utils/page-loading/page-loading.service';
import { artworkStatus, messagesOnScreen, routeUrls } from '../utils/constants'
import { LabelRightService } from '../services/label-right.service';
import { DashboardReusableService } from '../services/dashboard-reusable.service';
import { ResultsPageDataService } from '../services/results-page-data.service';
import { DashboardtableComponent } from './dashboardtable/dashboardtable.component';
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  @ViewChild(DashboardtableComponent) childComponent: DashboardtableComponent;

  lidtable = false;
  Reviewedtable = true;
  ReviewInprogressTable = false;
  firsttab = "lidrequest"
  secondtab = "inprogresstab"
  thirdtab = "Reviewdtab"
  lidRequestData: lidList[];
  inprogressData: any[];
  reviewedData: any[];
  data: any;
  spinnerMsg = messagesOnScreen.gettingRecordsLoader;
  constructor(
    private router: Router,
    private loader: PageLoadingService,
    private HomepageService: HomepagedataService,
    private dashboardFilterService: DashboardReusableService,
    private resultsPageDataService: ResultsPageDataService,
    private labelrightService: LabelRightService) {
  }

  ngOnInit(): void {
    if (this.dashboardFilterService.isReviewed == 'inprogresstab') {
      this.lidtable = false;
      this.Reviewedtable = false;
      this.ReviewInprogressTable = true;
    }
    if (this.HomepageService.getsessionValue()) {
      this.HomepageService.clearSessionValue();
    }
  }
  labellidselect() {
    this.lidtable = true;
    this.Reviewedtable = false;
    this.ReviewInprogressTable = false;
  }
  labelinprogressselect() {
    this.lidtable = false;
    this.Reviewedtable = false;
    this.ReviewInprogressTable = true;
    this.dashboardFilterService.resetDashBoardFilter();
    this.dashboardFilterService.isReviewed = 'inprogresstab';
  }
  labelreviewdselect() {
    this.lidtable = false;
    this.Reviewedtable = true;
    this.ReviewInprogressTable = false;
    this.dashboardFilterService.resetDashBoardFilter();
    this.dashboardFilterService.isReviewed = 'Reviewdtab';
  }
  newAdhocReview() {
    this.HomepageService.clearSessionValue();
    this.resultsPageDataService.original_Xfd = '';
    this.resultsPageDataService.xfdDataForAWView = '';
    this.dashboardFilterService.resetDashBoardFilter();
    this.HomepageService.navigatingFromDashboard = false;
    this.labelrightService.reset();
  }

  /**
   * 
   * Based on the “uiStatus” received from the API “artwork/ui/artworkReviewRequests”, the projects are categorized 
   * into "Review in Progress" and "Reviewed” tab. 
   * Reviewed Tab: Projects with "uiStatus" as "Completed" are listed.
   * Review in Progress Tab: Projects with "uiStatus" not equal to "Completed" are listed.
   * @param changedValue 
   * 
   */
   changeintabledata(changedValue) {
    if (this.lidtable) {
      this.loader.show(this.spinnerMsg);
      this.HomepageService.getLidData(changedValue.region, changedValue.category).subscribe((data) => {
        this.lidRequestData = data;
        this.loader.hide()
      });
    }
    else if (this.Reviewedtable || this.ReviewInprogressTable) {
      this.loader.show(this.spinnerMsg);
      this.HomepageService.getReviwedData(changedValue.region, changedValue.category).subscribe((data) => {
        let Inprogress = data.filter(x => x.uiStatus?.toLowerCase() !== artworkStatus.Completed);
        this.inprogressData = Inprogress ? Inprogress : [];
        let Reviewed = data.filter(x => x.uiStatus?.toLowerCase() === artworkStatus.Completed);
        this.reviewedData = Reviewed ? Reviewed : [];
        this.clearingfilter();
        this.loader.hide();
      })
    }
  }
  newQueuedReview() {
    this.HomepageService.clearSessionValue();
    this.labelrightService.resetQueued();
    this.dashboardFilterService.resetDashBoardFilter();
    this.resultsPageDataService.original_Xfd = '';
    this.resultsPageDataService.xfdDataForAWView = '';
    this.HomepageService.navigatingFromDashboard = false;
    this.HomepageService.navigateToUploadPageQueued = true;
    this.router.navigate([routeUrls.uploadqueued]);
  }

  clearingfilter(): void {
    if (this.childComponent) {
      this.childComponent.clearFiltersWhenPackageCategoryChanged();
    }
  }
}

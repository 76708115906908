<div class="get-started-container container-fluid h-100 d-flex pepsi-gradient">
    <div class="row align-self-center w-100">
        <div class="col-8 mx-auto">
            <div class="container">
                <div class="row"><img src="assets/images/pepsi-logo-word-t.svg" class="pepsi-logo mx-auto" /></div>
                <div class="row text-center">
                    <h2 class="mx-auto">Label Right</h2>
                </div>
                <div class="row text-center"><span class="check-msg w-100">Check your artwork before production</span>
                </div>
                <div class="menulist">
                    <button routerLink="/dashboardpage" class="btn btn-primary  dashboard-btn">
                        <span class="text-size">
                            <img src="../../../assets/images/dashboard.svg" class="img">
                        </span>Dashboard </button>
                    <button (click)="newAdhocReview()" class="btn btn-primary adhoc-btn">
                        <span class="text-size">
                            <img src="../../../assets/images/powerlarge.svg" class="img1">
                        </span>
                        New Ad hoc Review</button>
                    <button (click)="newQueuedReview()" class="btn btn-primary adhoc-btn">
                        <span class="text-size">
                            <img src="../../../assets/images/add-to-queue.svg" class="img2">
                        </span>
                        New Queued Review</button>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { messagesOnScreen, routeUrls } from '../constants';
import { HomepagedataService } from 'src/app/services/homepagedata.service';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent {
  @Input() spinnerMsg: string;

  isUploadPage: boolean;
  isExitButtonHidden: boolean;
  isGoToDashboard: boolean;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    public HomepageService: HomepagedataService
  ){}

  ngOnInit() {
    if ((this.router.url === routeUrls.root + routeUrls.upload ||
      this.router.url === routeUrls.root + routeUrls.uploadqueued) &&
      this.spinnerMsg !== messagesOnScreen.addingProjectToQueueLoader
    ) {
      this.isUploadPage = true;
    }
    else {
      this.isUploadPage = false;
    }

    if (this.spinnerMsg === messagesOnScreen.gettingRecordsLoader ||
      this.spinnerMsg === messagesOnScreen.fetchingDataLoader) {
      this.isExitButtonHidden = true;
    }
    else if (this.spinnerMsg === messagesOnScreen.navigatingLoader) {
      if (this.router.url === routeUrls.root + routeUrls.upload ||
        this.router.url === routeUrls.root + routeUrls.uploadqueued ||
        this.router.url === routeUrls.root + routeUrls.verify ||
        this.router.url === routeUrls.root + routeUrls.results ||
        this.router.url === routeUrls.root + routeUrls.alldone ||
        this.router.url === routeUrls.root + routeUrls.dashboard
      ) {
        this.isExitButtonHidden = false;
      }
      else {
        this.isExitButtonHidden = true;
      }
    }
  }

  cancelLoading() {
    this.router.navigate([routeUrls.dashboard]);
    this.HomepageService.lidFileName = [];
  }
}

import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { LabelRightService } from 'src/app/services/label-right.service';
import { HomepagedataService } from 'src/app/services/homepagedata.service';
import { DashboardReusableService } from 'src/app/services/dashboard-reusable.service';
import { routeUrls, sessionStorageObject } from 'src/app/utils/constants';
import { ResultsPageDataService } from 'src/app/services/results-page-data.service';

@Component({
  selector: 'app-queued-project-popup',
  templateUrl: './queued-project-popup.component.html',
  styleUrls: ['./queued-project-popup.component.scss']
})
export class QueuedProjectPopupComponent implements OnInit {
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<QueuedProjectPopupComponent>,
    private router:Router,
    private labelRightService:LabelRightService,
    public HomepageService:HomepagedataService,
    private resultpageService:ResultsPageDataService,
    private DashboardfilterService:DashboardReusableService,
  ) { 
    dialogRef.disableClose = true;
  }

  projectID : string;
  isMultiLingualArtwork : string;

  ngOnInit(): void {
    this.projectID = this.data.projectID;
    this.isMultiLingualArtwork = (this.data.isMultiLingualArtwork);
  }

  goToDashboard(){
    this.dialogRef.close(
      {
        event: 'close',
        data: {
        }
      });
    this.router.navigate([routeUrls.dashboard]);
    this.HomepageService.lidFileName=[];
  }
  
  newQueuedReview(){
    this.dialogRef.close(
      {
        event: 'close',
        data: {
        }
      });
    this.HomepageService.clearSessionValue();
    this.resultpageService.original_Xfd='';
    this.resultpageService.xfdDataForAWView ='';
    this.labelRightService.xdfData=''
    this.labelRightService.xfd_Data.next('');
    this.labelRightService.resetQueued();
    this.DashboardfilterService.resetDashBoardFilter();
    this.HomepageService.navigatingFromDashboard = false;
    this.HomepageService.navigateToUploadPageQueued=true;
    sessionStorage.setItem(sessionStorageObject.isMultiLingualArtwork , this.isMultiLingualArtwork);
    this.router.navigate([routeUrls.uploadqueued]);
    this.HomepageService.lidFileName =[];
   }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-zoom-queued',
  templateUrl: './zoom-queued.component.html',
  styleUrls: ['./zoom-queued.component.scss']
})

export class ZoomQueuedComponent {
  @Input() image: string;
  @Output() closeZoom = new EventEmitter<boolean>();

  closeZoomWindow(): void {
    this.closeZoom.emit(true);
  }
}

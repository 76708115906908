/**
 * This screen is used by the user to add any project to the queue by uploading artwork and LID(s). 
 * It has the following components - Package Selection, Multilingual Checkbox, Artwork Upload Component, 
 * Lid Upload Component & Add to Queue Button.  
 * 
 */
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HomepagedataService } from '../services/homepagedata.service';
import { LabelRightService } from '../services/label-right.service';
import { ResultsPageDataService } from '../services/results-page-data.service';
import { messagesOnScreen, routeUrls, sessionStorageObject } from '../utils/constants';
import { PageLoadingService } from '../utils/page-loading/page-loading.service';
import { MatDialog } from '@angular/material/dialog';
import { QueuedProjectPopupComponent } from './queued-project-popup/queued-project-popup.component';
import { FileUploadQueuedComponent } from './file-upload-queued/file-upload-queued.component';
import { AppInsightsService } from '../services/app-insights.service';

@Component({
  selector: 'app-upload-page-queued',
  templateUrl: './upload-page-queued.component.html',
  styleUrls: ['./upload-page-queued.component.scss']
})
export class UploadPageQueuedComponent implements OnInit {

  @ViewChild(FileUploadQueuedComponent) fileUploadQueue: FileUploadQueuedComponent;
  preview: string;
  pdfAvailable: boolean = false;
  excelAvailable: boolean = false;
  previewAvailable:boolean= false;
  projectId: number;
  packageCategory: string = '';
  disableValue: boolean = false;
  fileUploadStartedFlag : boolean = false;
  filekeyCondition = false;
  componentType = "Upload-queue";
  choosePackageCategoryMessage = messagesOnScreen.choosePackageCategoryMessage;
  dropArtworkMessage = messagesOnScreen.dropArtworkMessage;
  dropFilesMessage = messagesOnScreen.dropFileMessage;
  isMultiLingualArtwork : boolean = false;

  constructor(
    private router: Router,
    private labelRightService: LabelRightService,
    private pageservice: PageLoadingService,
    public HomepageService: HomepagedataService,
    public resultsPageDataService: ResultsPageDataService,
    public dialog: MatDialog,
    private AppInsightsService:AppInsightsService
  ) { }

  ngOnInit(): void {
    this.HomepageService.navigateToUploadPageQueued = true;
    this.HomepageService.isBatched = false;
    this.packageCategory = '';
    this.preview = '';
    this.labelRightService.verifyToUpload = false;
  }
  previewImage(event?)
  {
    if(!event)
    {
      this.pdfAvailable= false;
      this.excelAvailable= false;
    }
    else if (event.title === 'Artwork')
    {
      this.pdfAvailable =  event.value == true ? true : false;
    }
    else
    {
      this.excelAvailable =  event.value == true ? true : false;
    }
    this.previewAvailable= this.pdfAvailable && this.excelAvailable;
    
  }
  buttonClicked(button: string): void {
    switch (button) {
      case 'Back':
        this.pageservice.stopoperation = true;
        this.router.navigate([routeUrls.getstarted]);
        this.labelRightService.ReviewArtwork = "get-started"
        break;
      case 'Next':
        this.pageservice.stopoperation = true;
        this.addToQueue();
        break;
    }
  }

  zoomedIn(event: any): void {
    this.preview = event;
  }

  zoomedOut(event: any): void {
    if (event) {
      this.preview = '';
    }
  }
  selectedPackageValue(value) {
    this.packageCategory = value;
  }
  fileSElected(val) {
    this.disableValue = val;
  }

  /**
   * After receiving the file URLs of artwork and lid files from the polling API and the files are successfully loaded 
   * in the UI, the Add to Queue button is enabled. After clicking on this button, the project creation API is called, 
   * and after successful project creation, the project is added to the queue and the user gets a pop-up message on 
   * the screen with the Project name, and buttons to navigate the Dashboard or to start a New queued project. 
   * 
   */
  addToQueue() {
    this.pageservice.show(messagesOnScreen.addingProjectToQueueLoader);
    this.HomepageService.clearSessionValue();
    let is_batched = true;
    this.previewImage();
    this.fileUploadQueue.artworkUpload = 0;
    let geoData = this.HomepageService.getgeocategoryData;
    let isMultiLingualArtworkFlag = sessionStorage.getItem(sessionStorageObject.isMultiLingualArtwork);
    this.packageCategory = this.packageCategory == '' ? sessionStorage.getItem(sessionStorageObject.packageCategory) : this.packageCategory;
    this.HomepageService.getProjectIdGeneratedForQueued(geoData.region, geoData.category, geoData.modelCategory, this.packageCategory, is_batched, this.HomepageService.artworkFileName, this.HomepageService.lidFileName, isMultiLingualArtworkFlag, this.HomepageService.artworkOriginalFileName , this.HomepageService.lidOriginalFileName)
      .subscribe(
        (ele) => {
        sessionStorage.setItem(sessionStorageObject.projectId, ele.id);
        sessionStorage.setItem(sessionStorageObject.projectName, ele.projectName);
        sessionStorage.setItem(sessionStorageObject.packageCategory, this.packageCategory);
        this.HomepageService.adhocProjectName = ele.projectName;
        this.HomepageService.projectAuther = ele.createdBy;
        this.pageservice.hide();
        this.fileUploadStarted(false);
        const dialogRef = this.dialog.open(QueuedProjectPopupComponent, {
          width: 500 + 'px',
          height: 240 + 'px',
          data: {
            projectID: this.HomepageService.adhocProjectName,
            isMultiLingualArtwork : sessionStorage.getItem(sessionStorageObject.isMultiLingualArtwork)
          }
        });
      },
      (error) => {
        this.AppInsightsService.logException(error);
      }
      );
  }
  selectedMultiLingualArtwork(value){
    this.isMultiLingualArtwork = value;
    sessionStorage.setItem(sessionStorageObject.isMultiLingualArtwork , JSON.stringify(this.isMultiLingualArtwork));
  }
  
  fileUploadStarted(val){
    this.fileUploadStartedFlag = val;
  }

}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { routeUrls } from '../utils/constants';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
/**
 * 
 * Whenever any incorrect route path is entered, user is navigated to this page 
 * with an option to return to the get-started page.
 * 
 */
export class PageNotFoundComponent {
  constructor(private router:Router){

  }

  /**
   * 
   * "Go Home" button takes user to the Get-started page of our aplication.
   * 
   */
  navigateTogetStarted() {
    this.router.navigate([routeUrls.getstarted]);
  }
}

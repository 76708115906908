import { Component, Input, HostListener, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';
import Konva from 'konva';
import { CanvasBB } from '../canvas-bb';
import { Prediction, AllAnchors, FetchStatus } from '../utils';
import { LabelRightService } from 'src/app/services/label-right.service';
import { PageLoadingService } from '../page-loading/page-loading.service';
import { DeleteBBConfirmationComponent } from 'src/app/verify/delete-bb-confirmation/delete-bb-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { HomepagedataService } from 'src/app/services/homepagedata.service';
import { AppInsightsService } from 'src/app/services/app-insights.service';
import { componentProductLanguage, messagesOnScreen, packageCategory, sessionStorageObject } from '../constants';
@Component({
  selector: 'app-artwork-canvas',
  templateUrl: './artwork-canvas.component.html',
  styleUrls: ['./artwork-canvas.component.scss']
})
/**
 * The Artwork Canvas component handles all logic related to the Konva canvas. It integrates Konva with the LabelRight 
 * application, loads artwork onto the canvas, draws bounding boxes based on model responses, and enables user interaction 
 * such as adding, editing, adjusting, and deleting boxes. It also contains the logic of features like auto-focusing on 
 * selected component boxes, filtering boxes, custom tooltips, resetting, undo resetting, re-centering, zooming, and 
 * rotation. 
 * 
 * */
export class ArtworkCanvasComponent {
  @Input()
  set zoom(value) {
    this.zoom$.next(value);
  }
  get zoom(): number {
    return this.zoom$.value;
  }
  @Input()
  set artworkUrl(value) {
    this.artworkUrl$.next(value);
  }
  get artworkUrl(): string {
    return this.artworkUrl$.value;
  }
  @Input()
  set boundingBoxes(value) {
    this.boundingBoxes$.next(value);
  }
  get boundingBoxes(): any[] {
    return this.boundingBoxes$.value;
  }
  @Output() editItemEvent = new EventEmitter<any>();
  @Output() addItemEvent = new EventEmitter<any>();
  @Output() deleteItemEvent = new EventEmitter<any>();
  @Output() deleteItemEventForSnackbar = new EventEmitter<any>();
  @Output() deleteItemEventForSnackbarCancel = new EventEmitter<any>();
  @Output() editItemEventForSnackbar = new EventEmitter<any>();
  @Output() editItemEventForSnackbarCancel = new EventEmitter<any>();
  @Output() autoFocusEvent = new EventEmitter<any>();
  @Output() editItemEventForDeleteAdd = new EventEmitter<any>();
  @Output() editItemEventBrandLanguage = new EventEmitter<any>();
  @Output() zoomValueChanged = new EventEmitter<any>();
  @ViewChild('bbvalueEle') bbvalueEle;
  @ViewChild('imgContainer') imgContainer;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  private zoom$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private artworkUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  private boundingBoxes$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    []
  );

  private upperContainerWidth: number;
  private upperContainerHeight: number;
  private stage: Konva.Stage;
  private imageLayer: Konva.Layer;
  private image: Konva.Image;
  private rotation: number;
  private canvasBBs: CanvasBB[];
  private scalar: number;
  rect: Konva.Rect;
  layer: Konva.Layer;
  public ZoomLevel = 50;
  @HostListener('window:resize', ['$event']) onResize(): void {
    const container = document.getElementById('upper-artwork-container');
    this.upperContainerWidth = container.offsetWidth;
    this.upperContainerHeight = container.offsetHeight;

    if (!!this.stage) {
      this.stage.width(this.upperContainerWidth);
      this.stage.height(this.upperContainerHeight);
    }
  }

  bbvalue: string = '';
  bbDef: any;
  isBBReceived = false;
  undo_object = {};

  constructor(private labelRightService: LabelRightService,
    private artworkCanvasPageLoadingService: PageLoadingService,
    private dialog: MatDialog,
    public HomepageService: HomepagedataService,
    private AppInsightsService: AppInsightsService) { }
  ngOnInit(): void {
    this.isBBReceived = false;
    this.labelRightService.isResetBBClicked = false;
    this.labelRightService.isUndoResetClicked = false;
    this.canvasBBs = [];
    this.artworkUrl$.pipe(takeUntil(this.destroy$)).subscribe((url) => {
      if (!!url) {
        this.rotation = 0;
        this.artworkCanvasPageLoadingService.show(messagesOnScreen.loadingArtworkPartsLoader);
        this.initStage(url);
      }
    });
  }

  initStage(imgUrl: string): void {
    this.isBBReceived = false;
    this.upperContainerWidth = document.getElementById(
      'upper-artwork-container'
    ).offsetWidth;
    this.upperContainerHeight = document.getElementById(
      'upper-artwork-container'
    ).offsetHeight;
    this.stage = new Konva.Stage({
      container: 'artwork-verify-container',
      width: this.upperContainerWidth,
      height: this.upperContainerHeight,
      y: this.upperContainerHeight / 2,
      x: this.upperContainerWidth / 2,
      scale: {
        x: 1,
        y: 1
      },
      draggable: true
    });

    this.stage.on('wheel', (e) => {
      e.evt.preventDefault();
      const delta = Math.sign(e.evt.deltaY);
      this.ZoomLevel = this.ZoomLevel <= 50 ? 50 : ((this.ZoomLevel >= 1500) ? 1500 : this.ZoomLevel);
      if (delta > 0) {
        this.ZoomLevel = this.ZoomLevel + 10;
      } else {
        this.ZoomLevel =
          this.ZoomLevel == 50 ? this.ZoomLevel : this.ZoomLevel - 10;
      }
      this.zoomValueChanged.emit(this.ZoomLevel);
      this.zoomStage(this.ZoomLevel);
    });

    this.imageLayer = new Konva.Layer();
    this.stage.add(this.imageLayer);

    const imageObj = new Image();
    (imageObj as any).src = imgUrl;
    (imageObj as any).crossOrigin = 'Anonymous';

    this.image = new Konva.Image();
    /**
     * Add the image to the layer
     */ 
    this.imageLayer.add(this.image);
    const startTime = new Date().getTime();
    imageObj.onload = () => {
      /**
       * Store and cache image
       */
      const endTime = new Date().getTime();
      const timeTaken = endTime - startTime;
      this.AppInsightsService.logEvent("BLOB URL Call - Verify",
        {
          timeTaken: timeTaken,
          blobURL: imgUrl
        });
      this.image.image(imageObj as any);
      this.image.cache();
      this.image.filters([Konva.Filters.Grayscale]);

      /**
       * Scale stage based on smallest image to canvas ratio
       */
      const { width: imgWidth, height: imgHeight } = this.image.size();

      this.scalar = Math.min(
        this.upperContainerWidth / imgWidth,
        this.upperContainerHeight / imgHeight
      );
      this.stage.scale({ x: this.scalar, y: this.scalar });


      /**
       * Set zoom point to center of image
       */ 
      this.stage.offsetX(imgWidth / 2);
      this.stage.offsetY(imgHeight / 2);
      this.boundingBoxes$
        .pipe(takeWhile(() => !this.isBBReceived))
        .subscribe((boxes) => {
          if (boxes && !this.isBBReceived) {
            this.canvasBBs = [];
            if (!!boxes && boxes.length !== 0) {
              for (const box of boxes) {
                if (this.canvasBBs.length >= 0) {
                  if (this.checkBBExists(box.type)) {
                    this.updateBB(
                      box.type,
                      box.predictions[0]
                      //box.predictions[1]
                    );
                  }
                  else {
                    if (box.predictions[0].length > 1 || box.predictions[1].length > 1) {
                      for (let i = 0; i < box.predictions.length; i++) {
                        let lang;
                        (box.predictions[i].forEach(pred => {
                          lang = pred.lang;
                          pred.x = Number(pred.x);
                          pred.y = Number(pred.y)
                        }
                        ));
                        const newBB = new CanvasBB(box.type, box.predictions[i], box.colorcodes, lang);
                        this.stage.add(newBB.layer);
                        newBB.setupTransformer();
                        this.canvasBBs.push(newBB);
                      }
                    } else {
                      const newBB = new CanvasBB(box.type, box.predictions, box.colorcodes, box.predictions.lang);
                      this.stage.add(newBB.layer);
                      newBB.setupTransformer();
                      this.canvasBBs.push(newBB);
                    }
                  }
                }
              }
              if (boxes.length < this.canvasBBs.length) {
                // tslint:disable-next-line: forin
                for (const bb in this.canvasBBs) {
                  let found = false;
                  for (const box of boxes) {
                    if (this.canvasBBs[bb].type === box.type) {
                      found = true;
                    }
                  }
                  if (!found) {
                    this.canvasBBs[bb].layer.hide();
                  }
                }
              }
              this.artworkCanvasPageLoadingService.hide();
            } else {
              this.artworkCanvasPageLoadingService.show(messagesOnScreen.loadingArtworkPartsLoader);
            }
            this.isBBReceived = true;
          }
        });
      this.imageLayer.batchDraw();
      this.stage.draw();
    };
    this.stage.on('mouseover', (e) => {
      this.hoverValue(e);
    });

    this.stage.on('mouseout', (e) => {
      this.bbvalue = '';
    });
    this.zoom$.pipe(takeUntil(this.destroy$)).subscribe((zoomLevel) => {
      this.zoomStage(zoomLevel);
    });
  }

  /**
   * Customized tooltips are implemented for bounding boxes
   * due to technical difficulties with Konva tooltips caused by multiple layers on the stage. 
   * @param e 
   */
  hoverValue(e) {
    if (e.target.attrs.width) {
      this.canvasBBs.forEach((bbs) => {
        if (bbs.topLeft.x == e.target.attrs.x && bbs.topLeft.y == e.target.attrs.y
          && bbs.bottomRight.x == (e.target.attrs.x + e.target.attrs.width)
          && bbs.bottomRight.y == (e.target.attrs.y + e.target.attrs.height)) {
          this.labelRightService.bbAllComponents.forEach(bbAllComp => {
            if (bbAllComp.titles === bbs.type) {
              this.bbvalue = bbAllComp.titlename;
              let x = (e.evt.clientX) / 2;
              let y = (e.evt.clientY) - 100;
              let cursor = bbAllComp.titlename;
              this.bbvalueEle.nativeElement.style.display = 'block';
              this.bbvalueEle.nativeElement.innerHTML = cursor;
              this.bbvalueEle.nativeElement.style.marginLeft = x + 'px';
              this.bbvalueEle.nativeElement.style.top = y + 'px';
              this.bbvalueEle.nativeElement.style.color = 'white';
              this.bbvalueEle.nativeElement.style.fontFamily = '"Comfortaa", cursive';
              this.bbvalueEle.nativeElement.style.fontSize = 14 + 'px';
              this.bbvalueEle.nativeElement.style.backgroundColor = '#323232';
              this.bbvalueEle.nativeElement.style.paddingTop = 5 + 'px';
              this.bbvalueEle.nativeElement.style.paddingBottom = 5 + 'px';
              this.bbvalueEle.nativeElement.style.paddingLeft = 10 + 'px';
              this.bbvalueEle.nativeElement.style.paddingRight = 10 + 'px';
              this.bbvalueEle.nativeElement.style.borderRadius = 5 + 'px';
              this.bbvalueEle.nativeElement.style.width = 'fit-content';
              this.bbvalueEle.nativeElement.style.textAlign = 'center';
              this.bbvalueEle.nativeElement.style.zIndex = 1;
              this.imgContainer.nativeElement.style.cursor = 'default';
            }
          })
        }
      });
    }
    else {
      this.bbvalueEle.nativeElement.style.color = 'transparent';
      this.bbvalueEle.nativeElement.style.backgroundColor = 'transparent';
      this.bbvalueEle.nativeElement.style.width = 160 + 'px';
      this.bbvalueEle.nativeElement.style.zIndex = 1;
      this.bbvalueEle.nativeElement.style.height = 30 + 'px';
      this.imgContainer.nativeElement.style.cursor = 'pointer';
    }
  }

  ngOnDestroy(): void {
    this.labelRightService.isResetBBClicked = false;
    this.labelRightService.isUndoResetClicked = false;
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }

  public rotate(recenter?: boolean): void {
    const enabledAnchors = {
      0: AllAnchors,
      90: AllAnchors,
      180: AllAnchors,
      270: AllAnchors
    };
    if (recenter)
      this.rotation = 0;
    else
      this.rotation = this.rotation === 270 ? 0 : this.rotation + 90;
    this.rotateAroundCenter(this.stage, this.rotation);
    for (const bb of this.canvasBBs) {
      bb.transformer.enabledAnchors(enabledAnchors[this.rotation]);
    }
    this.imageLayer.batchDraw();
    this.stage.draw();
  }

  /**
   * Brings the artwork and boxes back to the original position on the canvas. 
   * 
   */
  recenter() {
    this.stage.x(this.upperContainerWidth / 2);
    this.stage.y(this.upperContainerHeight / 2);
    this.stage.draw();
    if (this.rotation != 0)
      this.rotate(true);
  }

  zoomStage(zoomValue: number): void {
    this.stage.scale({
      // tslint:disable-next-line: radix
      x: parseInt(`${zoomValue * this.scalar}`) / 60,
      // tslint:disable-next-line: radix
      y: parseInt(`${zoomValue * this.scalar}`) / 60
    });
    this.imageLayer.batchDraw();
    this.stage.draw();
  }

  updateStageSize(width: number, height: number): void {
    this.stage.size({
      width,
      height
    });
    this.imageLayer.batchDraw();
    this.stage.draw();
  }

  rotatePoint = ({ x, y }: Prediction, rad: number): Prediction => {
    const rcos = Math.cos(rad);
    const rsin = Math.sin(rad);
    return { x: x * rcos - y * rsin, y: y * rcos + x * rsin };
  };

  rotateAroundCenter(node: Konva.Node, rotation: number): void {
    const center = { x: 0, y: 0 };

    const current = this.rotatePoint(
      center,
      (Konva as any).getAngle(node.rotation())
    );
    const rotated = this.rotatePoint(center, (Konva as any).getAngle(rotation));
    const dx = rotated.x - current.x;
    const dy = rotated.y - current.y;

    node.rotation(rotation);
    node.x(node.x() + dx);
    node.y(node.y() + dy);
  }

  checkBBExists(type: string): boolean {
    if (this.canvasBBs.length > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (const x in this.canvasBBs) {
        if (this.canvasBBs[x].checkType(type)) {
          return true;
        }
      }
    }
    return false;
  }

  updateBB(type: string, predictions: any): void {
    let topLeft: Prediction;
    let bottomRight: Prediction;
    topLeft = predictions[0];
    bottomRight = predictions[1];
    if (this.canvasBBs.length > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (const x in this.canvasBBs) {
        if (this.canvasBBs[x].checkType(type)) {
          this.canvasBBs[x].update(topLeft, bottomRight);
        }
      }
    }
  }

  public getUpdatedBoundingBoxes(): any {
    const bbs = [];
    //const bbs = {};
    for (const bb of this.canvasBBs) {
      if (bb.layer.visible()) {
        bbs.push([bb.topLeft, bb.bottomRight]);
      }
    }
    return bbs;
  }
  /**
   * Based on selected Product name(for Variety packs), selected language and selected component type, 
   * the bounding boxes are filtered and shown on the canvas. 
   * 
   * @param type 
   * @param lang 
   * @param product 
   */
  highlightBox(type: any, lang: any, product: any) {
    this.zoomStage(70);
    this.recenter();
    if (type === componentProductLanguage.allComponents && lang === componentProductLanguage.allLanguages && product === componentProductLanguage.allProducts) {
      this.canvasBBs.forEach((c) => {
        c.layer.show();
      });
    }
    else if (type === componentProductLanguage.allComponents && lang === componentProductLanguage.allLanguages && product !== componentProductLanguage.allProducts) {
      this.canvasBBs.forEach((c) => {
        c.layer.show();
        if (c.topLeft.product_name !== product) {
          c.layer.hide();
        }
      });
    }
    else if (type === componentProductLanguage.allComponents && lang !== componentProductLanguage.allLanguages && product === componentProductLanguage.allProducts) {
      this.canvasBBs.forEach((c) => {
        c.layer.show();
        if (c.lang.indexOf(lang) === -1) {
          c.layer.hide();
        }
      });
    }
    else if (type === componentProductLanguage.allComponents && lang !== componentProductLanguage.allLanguages && product !== componentProductLanguage.allProducts) {
      this.canvasBBs.forEach((c) => {
        c.layer.show();
        if (c.lang.indexOf(lang) === -1 || c.topLeft.product_name !== product) {
          c.layer.hide();
        }
      });
    }
    else if (type !== componentProductLanguage.allComponents && lang === componentProductLanguage.allLanguages && product === componentProductLanguage.allProducts) {
      this.canvasBBs.forEach((c) => {
        c.layer.show();
        if (c.type !== type) {
          c.layer.hide();
        }
      });
    }
    else if (type !== componentProductLanguage.allComponents && lang === componentProductLanguage.allLanguages && product !== componentProductLanguage.allProducts) {
      this.canvasBBs.forEach((c) => {
        c.layer.show();
        if (c.type !== type || c.topLeft.product_name !== product) {
          c.layer.hide();
        }
      });
    }
    else if (type !== componentProductLanguage.allComponents && lang !== componentProductLanguage.allLanguages && product === componentProductLanguage.allProducts) {
      this.canvasBBs.forEach((c) => {
        c.layer.show();
        if (c.type !== type || c.lang.indexOf(lang) === -1) {
          c.layer.hide();
        }
      });
    }
    else {
      this.canvasBBs.forEach((c) => {
        c.layer.hide();
        if (c.lang.indexOf(lang) >= 0 && c.type === type && c.topLeft.product_name === product) {
          c.layer.show();
        }
      });
    }
    this.autoFocusBB(this.stage, this.image, this.canvasBBs);
    this.imageLayer.batchDraw();
    this.stage.draw();
  }

  /**
   * 
   * "showAllBoxes()" is used to show all the boxes on th canvas.
   * 
   */
  showAllBoxes() {
    this.canvasBBs.forEach((c) => {
      c.layer.show();
    });
  }

  /**
   * Resets all actions performed on the canvas to the original state. 
   */
  reset_bb() {
    this.undoObjectCreation();
    this.canvasBBs.forEach((bb) => {
      bb.layer.remove();
    });
    this.canvasBBs = [];
    this.isBBReceived = false;
    this.labelRightService.isResetBBClicked = true;
    this.labelRightService.isUndoResetClicked = false;
    this.labelRightService.restBoundingBox();
    this.labelRightService.boundingBoxState.value.setFetchStatus(FetchStatus.fetched);
    this.labelRightService.mappingBoundingBoxes(this.labelRightService.bbAllDataBackup, this.labelRightService.bbAllDataBackupURL);
  }

  /**
   * Adds a new box on the canvas. Users can choose product (only for Variety pack), language, and component type for 
   * the new box. The box is then created at the centre of the canvas and can be dragged and adjusted. 
   * @param bbType 
   * @param lang 
   * @param bbBrand 
   */

  addNewBox(bbType, lang, bbBrand) {
    let counter = 0;
    let colorcode = '';
    let type = '';
    let language = '';
    let pred = [];
    let roi_id = 0;
    type = bbType.titles;
    let typeName = bbType.titlename;
    colorcode = bbType.colorcodes;
    language = lang;
    if (this.labelRightService.isRoiIdAvailable)
      roi_id = Number(this.labelRightService.bbAllRoiIdObject[this.labelRightService.bbAllRoiIdObject.length - 1].roi_id) + 1;
    this.canvasBBs.forEach((bbs) => {
      if (type === bbs.type) {
        counter = Number(bbs.bottomRight.type.split('_')[1]);
      }
    });
    counter = counter + 1;
    pred[0] = {
      x: ((this.stage.width() / 2) * (this.image.size().width / this.stage.width())) - 500,
      y: ((this.stage.height() / 2) * (this.image.size().height / this.stage.height())) - 500,
      type: type + '_' + counter,
      lang: language,
      type_name: bbType.titlename,
      ...(this.labelRightService.isProductNameAvailable && { product_name: bbBrand }),
      ...(this.labelRightService.isRoiIdAvailable && { box_refinement: true }),
      ...(this.labelRightService.isRoiIdAvailable && { roi_id: roi_id })
    };
    pred[1] = {
      x: ((this.stage.width() / 2) * (this.image.size().width / this.stage.width())) + 500,
      y: ((this.stage.height() / 2) * (this.image.size().height / this.stage.height())) + 500,
      type: type + '_' + counter,
      lang: language,
      type_name: bbType.titlename,
      ...(this.labelRightService.isProductNameAvailable && { product_name: bbBrand }),
      ...(this.labelRightService.isRoiIdAvailable && { box_refinement: true }),
      ...(this.labelRightService.isRoiIdAvailable && { roi_id: roi_id })
    };
    this.recenter();
    this.zoomStage(150);
    const addNewBB = new CanvasBB(type, pred, colorcode, language);
    this.stage.add(addNewBB.layer);
    addNewBB.setupTransformer();
    this.canvasBBs.push(addNewBB);
    let prediction = [];
    prediction[0] = pred;
    let returnObject = {
      type,
      pred,
      prediction,
      colorcode,
      typeName,
      language
    }
    this.addItemEvent.emit(returnObject);
  }
  /**
   * Allows users to delete a selected box after confirmation. 
   */
  deleteBB() {
    let selectedLayerX, selectedLayerY, selectedLayerWidth, selectedLayerHeight;
    let deleteBB;
    let deleteBBType;
    let returnObject;
    let deletedLanguage;
    let deletedBoxComponentName;
    this.stage.on('click', (e) => {
      if (e.target.attrs.width) {
        this.deleteItemEventForSnackbar.emit();
        this.canvasBBs.forEach((bbs) => {
        if (bbs.topLeft.x == e.target.attrs.x && bbs.topLeft.y == e.target.attrs.y
            && bbs.bottomRight.x == (e.target.attrs.x + e.target.attrs.width)
            && bbs.bottomRight.y == (e.target.attrs.y + e.target.attrs.height)) {
              deletedBoxComponentName = bbs.topLeft.type_name;
          }
        });
        const dialogRef = this.dialog.open(DeleteBBConfirmationComponent,{
          data: { 
            deletedBoxComponentName: deletedBoxComponentName 
          }
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            selectedLayerX = e.target.attrs.x;
            selectedLayerY = e.target.attrs.y;
            selectedLayerWidth = e.target.attrs.width;
            selectedLayerHeight = e.target.attrs.height;
            this.canvasBBs.forEach((bbs, index) => {
              if (bbs.topLeft.x == selectedLayerX && bbs.topLeft.y == selectedLayerY
                && bbs.bottomRight.x == (selectedLayerX + selectedLayerWidth)
                && bbs.bottomRight.y == (selectedLayerY + selectedLayerHeight)) {
                deleteBB = bbs.bottomRight.type;
                deleteBBType = bbs.type
                deletedLanguage = bbs.bottomRight.lang;
                returnObject = {
                  deleteBB,
                  deleteBBType,
                  deletedLanguage
                }
                this.deleteItemEvent.emit(returnObject);
                bbs.layer.remove();
                this.canvasBBs.splice(index, 1);
              }
            });
          }
        });
        this.stage.off('click');
      }
      else {
        this.stage.off('click');
        this.deleteItemEventForSnackbarCancel.emit();
      }
    });
  }

  /**
   * 
   * Upon clicking the Edit-box button user is asked to select a box which is to be edited. 
   * This function is used to pass the data of the selected box to the parent component, 
   * which then will be used in the Edit Box pop-up, to load the default value for Product name, Language and Component. 
   * 
   */

  editBB(): any {
    let selectedLayerX, selectedLayerY, selectedLayerWidth, selectedLayerHeight;
    let returnObject;
    this.stage.on('click', (e) => {
      if (e.target.attrs.width) {
        this.editItemEventForSnackbar.emit();
        selectedLayerX = e.target.attrs.x;
        selectedLayerY = e.target.attrs.y;
        selectedLayerWidth = e.target.attrs.width;
        selectedLayerHeight = e.target.attrs.height;
        this.canvasBBs.forEach((bbs) => {
          if (bbs.topLeft.x == selectedLayerX && bbs.topLeft.y == selectedLayerY
            && bbs.bottomRight.x == (selectedLayerX + selectedLayerWidth)
            && bbs.bottomRight.y == (selectedLayerY + selectedLayerHeight)) {
            returnObject = bbs;
          }
        });
        this.stage.off('click');
        this.editItemEvent.emit(returnObject);
      }
      else {
        this.stage.off('click');
        this.editItemEventForSnackbarCancel.emit();
      }
    });
  }

  /**
   * Enables users to modify the component type, product name (if Variety pack), and language of a box. 
   * @param lang 
   * @param oldType 
   * @param bb 
   * @param newbb 
   * @param oldLanguage 
   * @param product 
   * @param oldProduct 
   */

  editBBPostDialog(lang, oldType, bb, newbb, oldLanguage, product, oldProduct) {
    let colorcode = '';
    let type = '';
    let language = '';
    let pred = [];
    let prediction = [];
    let oldBoxType = '';
    let oldType1 = oldType;
    let typeName = newbb.titlename;
    type = newbb.titles;
    colorcode = newbb.colorcodes;
    language = lang;
    let counter = 0;
    let oldTypeCounter = 0;
    let newTypeCounter = 0;
    this.canvasBBs.forEach((bbs) => {
      if (bbs.type === newbb.titleCaption) {
        counter++;
      }
    });
    counter = counter + 1;
    this.canvasBBs.forEach((bbs) => {
      if (bbs.topLeft == bb.topLeft && bbs.bottomRight == bb.bottomRight) {
        bbs.topLeft.lang = lang;
        bbs.bottomRight.lang = lang;
        bbs.topLeft.type_name = newbb.titlename;
        bbs.bottomRight.type_name = newbb.titlename;
        bbs.lang = lang;
        if (this.labelRightService.isProductNameAvailable) {
          bbs.topLeft.product_name = product;
          bbs.bottomRight.product_name = product;
        }
        if (this.labelRightService.isRoiIdAvailable) {
          bbs.topLeft.box_refinement = true;
          bbs.bottomRight.box_refinement = true;
        }
        if (newbb.titleCaption === bb.type) {
          let returnObject = {
            language,
            oldLanguage
          }
          this.editItemEventBrandLanguage.emit(returnObject);
        }
        else {
          oldBoxType = bbs.topLeft.type;
          bbs.type = newbb.titleCaption;
          bbs.topLeft.type = newbb.titleCaption + '_' + counter;
          bbs.bottomRight.type = newbb.titleCaption + '_' + counter;
          bbs.bbDef = newbb.colorcodes;
          bbs.setupTransformer(bbs.bbDef);
          bbs.colorChange(bbs.bbDef);
          pred[0] = bbs.topLeft;
          pred[1] = bbs.bottomRight;
          prediction[0] = pred;
          this.canvasBBs.forEach((bbs) => {
            if (bbs.type === newbb.titleCaption) {
              newTypeCounter++;
            }
          });
          this.canvasBBs.forEach((bbs) => {
            if (bbs.type === bb.titleCaption) {
              oldTypeCounter++;
            }
          });
          let returnObject = {
            type,
            pred,
            prediction,
            colorcode,
            typeName,
            language,
            oldBoxType,
            oldType1,
            newTypeCounter,
            oldTypeCounter,
            oldLanguage
          }
          this.editItemEventForDeleteAdd.emit(returnObject);
        }
      }
    });
  }

  /**
   * 
   * "clickOff()" is used to stop all the click events on the canvas.
   * 
   */
  clickOff() {
    this.stage.off('click');
  }

  /**
   * Firstly, we have categorized the images in three categories based on the widths and heights of multiple artworks. 
   * Then based on the coordinates of the boxes and which quadrant it is placed in on the image, we have categorized 
   * them in further four sections. Using the initial rendering ratio and considering the image size category and 
   * box quadrant, various formulas have been established to auto-focus specific canvas areas while zoomed in. 
   * Rigorous testing and optimization have demonstrated that, in the majority of cases, the auto-focusing mechanism 
   * performs as expected, with only a few exceptions. 
   * 
   * @param stage 
   * @param image 
   * @param boxes 
   */

  autoFocusBB(stage, image, boxes) {
    let counter = 0;
    boxes.forEach((c) => {
      if (c.layer.isVisible()) {
        counter++;
      }
    })
    if (counter === 1) {
      boxes.forEach((c) => {
        if (c.layer.isVisible()) {
          stage.scale({
            x: 0.5,
            y: 0.5
          })
          /**
           * 
           * For images with height or width >= 5000
           * 
          */
          if (stage.getClientRect().height >= 5000 || stage.getClientRect().width >= 5000) {
            stage.scale({
              x: 0.1,
              y: 0.1
            })
            if (c.topLeft.x > image.size().width / 2) {
              /**
               * 
               * Top left quadrant
               * 
              */
              if (c.bottomRight.y > image.size().height / 2) {
                let xCoordinate = (image.size().width - c.topLeft.x + ((c.bottomRight.x - c.topLeft.x) / 2)) / 14;
                let yCoordinate = (image.size().height - c.topLeft.y + ((c.bottomRight.y - c.topLeft.y) / 2)) / 64;
                stage.position({
                  x: xCoordinate,
                  y: yCoordinate
                })
              }
              /**
               * 
               * Bottom left quadrant
               * 
              */ 
              else {
                let xCoordinate = (image.size().width - c.topLeft.x + ((c.bottomRight.x - c.topLeft.x) / 2)) / 14;
                let yCoordinate = (image.size().height - c.topLeft.y + ((c.bottomRight.y - c.topLeft.y) / 2)) / 24;
                stage.position({
                  x: xCoordinate,
                  y: yCoordinate
                })
              }
            }
            else {
              /**
               * 
               * Top right quadrant
               * 
               */ 
              if (c.bottomRight.y > image.size().height / 2) {
                let xCoordinate = (image.size().width - c.topLeft.x + ((c.bottomRight.x - c.topLeft.x) / 2)) / 12;
                let yCoordinate = (image.size().height - c.topLeft.y + ((c.bottomRight.y - c.topLeft.y) / 2)) / 64;
                stage.position({
                  x: xCoordinate,
                  y: yCoordinate
                })
              }
              /**
               * 
               * Bottom right quadrant
               * 
               */ 
              else {
                let xCoordinate = (image.size().width - c.topLeft.x + ((c.bottomRight.x - c.topLeft.x) / 2)) / 12;
                let yCoordinate = (image.size().height - c.topLeft.y + ((c.bottomRight.y - c.topLeft.y) / 2)) / 24;
                stage.position({
                  x: xCoordinate,
                  y: yCoordinate
                })
              }
            }
          }
          /**
           * 
           * For images with height >= 4000 or width >= 2000
           * 
           */ 
          else if (stage.getClientRect().height >= 4000 || stage.getClientRect().width >= 2000) {
            /**
             * 
             * For Mid size - Non secondary pack images
             * 
             */ 
            if (sessionStorage.getItem(sessionStorageObject.packageCategory) !== packageCategory.secondaryPack) {
              stage.scale({
                x: 0.25,
                y: 0.25
              })
              if (c.topLeft.x > image.size().width / 2) {
                /**
                 * 
                 * Top left quadrant
                 * 
                 */ 
                if (c.bottomRight.y > image.size().height / 2) {
                  let xCoordinate = (image.size().width - c.topLeft.x + ((c.bottomRight.x - c.topLeft.x) / 2)) / 12;
                  let yCoordinate = (image.size().height - c.topLeft.y + ((c.bottomRight.y - c.topLeft.y) / 2)) / 20;
                  stage.position({
                    x: xCoordinate,
                    y: yCoordinate
                  })
                }
                /**
                 * 
                 * Bottom left quadrant
                 * 
                 */ 
                else {
                  let xCoordinate = (image.size().width - c.topLeft.x + ((c.bottomRight.x - c.topLeft.x) / 2)) / 12;
                  let yCoordinate = (image.size().height - c.topLeft.y + ((c.bottomRight.y - c.topLeft.y) / 2)) / 8;
                  stage.position({
                    x: xCoordinate,
                    y: yCoordinate
                  })
                }
              }
              else {
                /**
                 * 
                 * Top right quadrant
                 * 
                 */ 
                if (c.bottomRight.y > image.size().height / 2) {
                  let xCoordinate = (image.size().width - c.topLeft.x + ((c.bottomRight.x - c.topLeft.x) / 2)) / 12;
                  let yCoordinate = (image.size().height - c.topLeft.y + ((c.bottomRight.y - c.topLeft.y) / 2)) / 20
                  stage.position({
                    x: xCoordinate,
                    y: yCoordinate
                  })
                }
                /**
                 * 
                 * Bottom right quadrant
                 * 
                 */ 
                else {
                  let xCoordinate = (image.size().width - c.topLeft.x + ((c.bottomRight.x - c.topLeft.x) / 2)) / 8;
                  let yCoordinate = (image.size().height - c.topLeft.y + ((c.bottomRight.y - c.topLeft.y) / 2)) / 8;
                  stage.position({
                    x: xCoordinate,
                    y: yCoordinate
                  })
                }
              }
            }
            /**
             * 
             * For mid size Secondary pack images
             * 
             */ 
            else {
              stage.scale({
                x: 0.15,
                y: 0.15
              })
              if (c.topLeft.x > image.size().width / 2) {
                /**
                 * 
                 * Top left quadrant
                 * 
                 */ 
                if (c.bottomRight.y > image.size().height / 2) {
                  let xCoordinate = (image.size().width - c.topLeft.x + ((c.bottomRight.x - c.topLeft.x) / 2)) / 12;
                  let yCoordinate = (image.size().height - c.topLeft.y + ((c.bottomRight.y - c.topLeft.y) / 2)) / 28;
                  stage.position({
                    x: xCoordinate,
                    y: yCoordinate
                  })
                }
                /**
                 * 
                 * Bottom left quadrant
                 * 
                 */ 
                else {
                  let xCoordinate = (image.size().width - c.topLeft.x + ((c.bottomRight.x - c.topLeft.x) / 2)) / 12;
                  let yCoordinate = (image.size().height - c.topLeft.y + ((c.bottomRight.y - c.topLeft.y) / 2)) / 10;
                  stage.position({
                    x: xCoordinate,
                    y: yCoordinate
                  })
                }
              }
              else {
                /**
                 * 
                 * Top right quadrant
                 * 
                 */ 
                if (c.bottomRight.y > image.size().height / 2) {
                  let xCoordinate = (image.size().width - c.topLeft.x + ((c.bottomRight.x - c.topLeft.x) / 2)) / 12;
                  let yCoordinate = (image.size().height - c.topLeft.y + ((c.bottomRight.y - c.topLeft.y) / 2)) / 28
                  stage.position({
                    x: xCoordinate,
                    y: yCoordinate
                  })
                }
                /**
                 * 
                 * Bottom right quadrant
                 * 
                 */ 
                else {
                  let xCoordinate = (image.size().width - c.topLeft.x + ((c.bottomRight.x - c.topLeft.x) / 2)) / 8;
                  let yCoordinate = (image.size().height - c.topLeft.y + ((c.bottomRight.y - c.topLeft.y) / 2)) / 10;
                  stage.position({
                    x: xCoordinate,
                    y: yCoordinate
                  })
                }
              }
            }
          }
          /**
           * 
           * For smaller images
           * 
           */ 
          else {
            if (c.topLeft.x > image.size().width / 2) {
              /**
               * 
               * Top left quadrant
               * 
               */ 
              if (c.bottomRight.y > image.size().height / 2) {
                let xCoordinate = (image.size().width - c.topLeft.x + ((c.bottomRight.x - c.topLeft.x) / 2)) / 8;
                let yCoordinate = (image.size().height - c.topLeft.y + ((c.bottomRight.y - c.topLeft.y) / 2)) / 6;
                stage.position({
                  x: xCoordinate,
                  y: yCoordinate
                })
              }
              /**
               * 
               * Bottom left quadrant
               * 
               */ 
              else {
                let xCoordinate = (image.size().width - c.topLeft.x + ((c.bottomRight.x - c.topLeft.x) / 2)) / 8;
                let yCoordinate = (image.size().height - c.topLeft.y + ((c.bottomRight.y - c.topLeft.y) / 2)) / 6;
                stage.position({
                  x: xCoordinate,
                  y: yCoordinate
                })
              }
            }
            else {
              /**
               * 
               * Top right quadrant
               * 
               */ 
              if (c.bottomRight.y > image.size().height / 2) {
                let xCoordinate = (image.size().width - c.topLeft.x + ((c.bottomRight.x - c.topLeft.x) / 2)) / 6;
                let yCoordinate = (image.size().height - c.topLeft.y + ((c.bottomRight.y - c.topLeft.y) / 2)) / 6;
                stage.position({
                  x: xCoordinate,
                  y: yCoordinate
                })
              }
              /**
               * 
               * Bottom right quadrant
               * 
               */ 
              else {
                let xCoordinate = (image.size().width - c.topLeft.x + ((c.bottomRight.x - c.topLeft.x) / 2)) / 6;
                let yCoordinate = (image.size().height - c.topLeft.y + ((c.bottomRight.y - c.topLeft.y) / 2)) / 6;
                stage.position({
                  x: xCoordinate,
                  y: yCoordinate
                })
              }
            }
          }
        }
      })
    }
    let zoomVal = Math.round(this.stage.attrs.scaleX * 60 / this.scalar);
    this.autoFocusEvent.emit(zoomVal);
  }
  
  /**
   * This function is used to convert the boundingBoxState.value.AllboundingBoxes data to an object structure
   * which is expected by the "mappingBoundingBoxes()" function.
   * This is done to make the "mappingBoundingBoxes()" function re-usuable.
   */
  undoObjectCreation(){
    this.undo_object = {};
    let bounding_boxes = [];
    this.labelRightService.boundingBoxState.value.AllboundingBoxes.forEach((bb,index)=>{
      let prediction=[];
      bb.forEach((lb,i)=>{
        prediction.push({
            roi_id: lb[0].roi_id,
            box: {
                x1: lb[0].x,
                y1: lb[0].y,
                x2: lb[1].x,
                y2: lb[1].y
            },
            iconNames: lb[0].type,
            lang: lb[0].lang,
            product_name: lb[0].product_name,
            box_refinement: lb[0].box_refinement
        })
      })
      bounding_boxes.push(
        {
          bbType : this.labelRightService.boundingBoxState.value.boundingTitlename[index],
          iconNames : this.labelRightService.boundingBoxState.value.boundingBoxType[index],
          prediction : prediction,
          colorCodes : this.labelRightService.boundingBoxState.value.colorCodes[index],
        }
      )
    })
    this.undo_object = {
      image_url: this.labelRightService.bbAllDataBackup.image_url,
      bounding_boxes: JSON.stringify(bounding_boxes),
      all_components: this.labelRightService.bbAllDataBackup.all_components,
      supported_language: this.labelRightService.bbAllDataBackup.supported_language,
      supported_product_name: this.labelRightService.bbAllDataBackup.supported_product_name
    };
  }

  /**
   * Allows users to undo the reset operation and revert to the previous state. 
   */
  undo_reset_clicked(){
    this.canvasBBs.forEach((bb) => {
      bb.layer.remove();
    });
    this.canvasBBs = [];
    this.isBBReceived = false;
    this.labelRightService.isResetBBClicked = false;
    this.labelRightService.isUndoResetClicked = true;
    this.labelRightService.restBoundingBox();
    this.labelRightService.boundingBoxState.value.setFetchStatus(FetchStatus.fetched);
    this.labelRightService.mappingBoundingBoxes(this.undo_object, this.labelRightService.bbAllDataBackupURL);
  }
}

<div class="mainDivmodel">
    <h6 style="color: black;"> Project Details</h6>
    <mat-divider></mat-divider>
    <div class="subDiv">
        <div>
            <p class="HeadingPara heading1">Project Name</p>
            <p class="paradesc desc5"> {{data.projectName}} ({{data.projectId}}) </p>
        </div>
        <div class="secondDiv">
            <p class="HeadingPara heading2">LabelRight ID</p>
            <p class="paradesc desc6"> {{data.artworkReviewRequestId}} </p>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="subDiv">
        <div>
            <p class="HeadingPara heading1">Category</p>
            <p class="paradesc desc1"> {{data.artworkCategory}} </p>
        </div>
        <div class="secondDiv">
            <p class="HeadingPara heading1">Brand</p>
            <p class="paradesc desc1"> {{data.brandName}} </p>
        </div>
        <div>
            <p class="HeadingPara heading1"> Product</p>
            <p class="paradesc desc3"> {{data.productName}} </p>
        </div>
        <div class="secondDiv">
            <p class="HeadingPara heading1">Flavor</p>
            <p class="paradesc desc1"> {{data.flavour}} </p>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="subDiv">
        <div>
            <p class="HeadingPara heading1">Package Category</p>
            <p class="paradesc desc1"> {{ data.selectedPackage ? data.selectedPackage : data.packageCategory}} {{ multilingual }} </p>
        </div>
        <div class="secondDiv">
            <p class="HeadingPara heading1"> Package Name </p>
            <p class="paradesc desc1"> {{data.packageName}} </p>
        </div>
        <div>
            <p class="HeadingPara heading1">Package Size</p>
            <p class="paradesc desc1"> {{data.packageSize}} </p>
        </div>
        <div class="secondDiv">
            <p class="HeadingPara heading1"> Package Container Size </p>
            <p class="paradesc desc1"> {{data.containerSize}} </p>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="subDiv">
        <div>
            <p class="HeadingPara heading1"> Artwork House </p>
            <p class="paradesc desc1"> {{data.artworkHouseDetails.artworkHouseName}} </p>
        </div>
        <div class="secondDiv">
            <p class="HeadingPara heading1">Review Cycle </p>
            <p class="paradesc desc1"> {{data.artworkReviewCycle}} </p>
        </div>
    </div>
    <div>
        <mat-divider></mat-divider>
    </div>
    <div class="subDiv">
        <div>
            <p class="HeadingPara heading1">Artwork File Name</p>
            <span *ngFor="let DTOs of data.artworkReviewFilesDTOs">
                <p class="paradesc desc4" *ngIf="DTOs.fileKey === 'artwork_original_filename'"> {{DTOs.fileName}} </p>
            </span>
        </div>
    </div>
    <div>
        <mat-divider></mat-divider>
    </div>
    <div class="subDiv">
        <div>
            <p class="HeadingPara heading1">Lid File Name</p>
            <div class="lidname-div">
                <span *ngFor="let DTOs of data.originalLidFilesDTOs">
                    <span *ngIf="DTOs.fileKey === 'lid_original_filename'"></span>
                    <p class="paradesc desc4" *ngFor="let fileName of DTOs.originalLidFileName"> {{fileName}} </p>
                </span>
            </div>
        </div>
    </div>
    <div>
        <mat-divider></mat-divider>
    </div>
</div>
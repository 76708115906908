// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  
  // added a js script which inject the apiKey value from external env. go to src/assests/env.js for more info.
  apiURL: window["env"]["apiURL"] || "default",
  signalRHub: window["env"]["signalRHub"] || "default",
  adobeclientID: window["env"]["adobeclientID"] || "default",
  webInterfaceApi: window["env"]["webInterfaceApi"] || "default",
  javaApiUrl: window["env"]["javaApiUrl"] || "default",
  serviceUrl: window["env"]["serviceUrl"] || "default",
  oktaClientId: window["env"]["oktaClientId"] || "default",
  oktaIssuer: window["env"]["oktaIssuer"] || "default",
  hostedUrl: window["env"]["hostedUrl"] || "default",
  requestTimeout: window["env"]["requestTimeout"] || "default",
  enableLogs: window["env"]["enableLogs"] || "default",
  idleTimeout: window["env"]["idleTimeout"] || "default",
  enableTool: window["env"]["enableTool"] || "default",
  accountName: window["env"]["accountName"] || "default",
  containerName: window["env"]["containerName"] || "default",
  appInsights:{
    instrumentationKey: window["env"]["instrumentationKey"] || "default"
  }
  // added a js script which inject the apiKey value from external env. go to src/assests/env.js for more info.

};

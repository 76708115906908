/**
 * This class handles all logics related to Azure Blob Storage.
 */
import { Injectable } from '@angular/core';
import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import { environment } from 'src/environments/environment';
import { blobStorageFolderStructure } from 'src/app/utils/constants';


@Injectable({
  providedIn: 'root'
})
export class AzureBlobStorageServiceService {
  blockBlobClient: any;
  blockBlobCLientLID: any;

  constructor() { }

  accountName = environment.accountName;
  containerName = environment.containerName;
/**
 * For Ad hoc projects - Artwork/Lid file uploaded to Azure Blob storage based on Project ID status following the below folder structure. 
 * If project ID is generated: "/Manual/{{AnalysisID}}" 
 * If project ID is not generated yet: "/Manual/temp" 
 * For Batch projects - Artwork/Lid file uploaded to Azure Blob storage following the folder structure: "/Batch/temp". 
 * @param sas 
 * @param content 
 * @param name 
 * @param isAdhocProject 
 * @param analysisId 
 * @param handler 
 * 
 */
  public async uploadDocument(sas: string, content: Blob, name: string, isAdhocProject: boolean, analysisId: any, handler: () => void) {
    const client = this.containerClient(sas, isAdhocProject, analysisId);
    if (name.includes('_ARTWORK_')) {
      this.blockBlobClient = client.getBlockBlobClient(name);
      this.blockBlobClient.uploadData(content, { blobHTTPHeaders: { blobContentType: 'application/octet-stream' } })
        .then(() => handler())
    }
    else {
      this.blockBlobCLientLID = client.getBlockBlobClient(name);
      this.blockBlobCLientLID.uploadData(content, { blobHTTPHeaders: { blobContentType: 'application/octet-stream' } })
        .then(() => handler())
    }
  }

  public deleteImage(sas: string, name: string, isAdhocProject: boolean, analysisId : string , handler: () => void) {
    this.deleteBlob(name, this.containerClient(sas, isAdhocProject, analysisId), handler)
  }

  private containerClient(sas: string, isAdhocProject: boolean, analysisId: string): ContainerClient {
    let mainFolderName = '';
    let subfolderName = '';
    if (!isAdhocProject) {
      mainFolderName = blobStorageFolderStructure.batchProjectFolder;
    }
    else {
      mainFolderName = blobStorageFolderStructure.manualProjectFolder;
    }
    if (analysisId !== null && analysisId !== '' && analysisId !== undefined) {
      subfolderName = analysisId;
    }
    else {
      subfolderName = blobStorageFolderStructure.tempFolder;
    }
    return new BlobServiceClient(`https://${this.accountName}.blob.core.windows.net/${this.containerName}/${mainFolderName}?${sas}`)
      .getContainerClient(subfolderName);

  }

  private deleteBlob(name: string, client: ContainerClient, handler: () => void) {
    client.deleteBlob(name).then(() => {
      handler()
    })
  }
}
import { Injectable } from '@angular/core';
import { projectstatus } from '../utils/constants';
import { currentStatus } from '../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class DashboardReusableService {
  dashboardPageNo:number;
  filterType:string;
  isReviewed:string;
  filterSearch:string;
  filekeyConditionVerify = false;
  constructor() { }
  getMappedStatus(status): string {
    if (status === projectstatus.ResultsGenNotQualified) {
      return projectstatus.UiResultsGenNotQualified
    } else if (status === projectstatus.ResultsGenQualified || status === projectstatus.ResultsMoved) {
      return projectstatus.UiResultsGenQualified
    } else if (status === projectstatus.ResultsNotified) {
      return projectstatus.UiResultsNotified
    } else if(status === projectstatus.ResultsNotificationError){
       return projectstatus.UiResultsNotificationError
    } else if(status === projectstatus.ResultsNotificationTimedout){
      return projectstatus.UiResultsNotificationTimedout
    }else{
      return projectstatus.completed
    }
  }
  resetDashBoardFilter()
  {
    this.dashboardPageNo=null;
    this.filterType=null;
    this.isReviewed=null;
    this.filterSearch=null;
  }
  getCurrentStatus(status:string,statusDescription:string){
    if(status == currentStatus.Requested || status == currentStatus.InProgress)
      return currentStatus.ProjectCreated;
    else if(status == currentStatus.FilesMoved)
      return currentStatus.FilesReceived;
    else if(status == currentStatus.AnalysisInProgress)
      return currentStatus.AnalysisInProgress;
    else if(status == currentStatus.ActivityScheduled||status == currentStatus.InProgress||status==currentStatus.Completed||status ==currentStatus.ActivityInProgress||status == currentStatus.ActivityCompleted)
    {
      if(statusDescription==currentStatus.ProcessArt)
        return currentStatus.ArtworkProcessing;
      else if(statusDescription==currentStatus.detectLanguage)
          return currentStatus.LanguageDetection;
      else if(statusDescription==currentStatus.ProcessLID)
          return currentStatus.LIDProcessing;
      else if(statusDescription==currentStatus.getBBOverlay)
          return currentStatus.getBBOverlayDesc;
      else if(statusDescription==currentStatus.LIDExtraction)
          return currentStatus.LIDExtractionDesc;
      else if(statusDescription==currentStatus.GetBoudingBox)
          return currentStatus.BoxesDetection;
      else if(statusDescription==currentStatus.Generategreypdf)
          return currentStatus.GreyPDFGeneration;
      else if(statusDescription==currentStatus.getOCR)
          return currentStatus.ResultsGeneration;
    }
    else if(status==currentStatus.FileNotFound)
      return currentStatus.FileNotReceived;
    else if(status == currentStatus.Resultsgen)
      return currentStatus.Resultsgen;
    else if(status==currentStatus.ErrorOccurred)
      return currentStatus.ErrorOccurred;
    else
      return '';
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-modelpopup',
  templateUrl: './modelpopup.component.html',
  styleUrls: ['./modelpopup.component.css']
})
export class ModelpopupComponent implements OnInit {
  
  multilingual : string = '';
  
  constructor(@Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.multilingual = '';
    this.multilingual = this.data.isMultiLingualArtwork ? ' - Multilingual' : '';
  }

}
